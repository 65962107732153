const isDevelopment = process.env.NODE_ENV === 'development';
export const API_BASE = isDevelopment
  ? 'https://fla-associate-dev.nautes.eu'
  : process.env.REACT_APP_CLIENT || 'https://fla-associate-dev.nautes.eu';
export const AUTH_ROOT = API_BASE + '/api/auth';
export const REFRESH_TOKEN = AUTH_ROOT + '/refreshToken';
export const LOGIN = AUTH_ROOT + '/login';
export const REGISTER_CHECK = AUTH_ROOT + '/registerCheck';
export const REGISTER_DRUPAL = AUTH_ROOT + '/register';
export const UPDATE_USER_DRUPAL = AUTH_ROOT + '/updateUser';
export const LIST_USERS_DRUPAL = AUTH_ROOT + '/listUsers';
export const LIST_SURVEYS_DRUPAL = AUTH_ROOT + '/listSurveys';
export const DRUPAL_BASE = process.env.REACT_APP_HOST;
export const OAUTH_USER_INFO = DRUPAL_BASE + '/oauth/userinfo';
export const EMAIL_CHECK_DRUPAL = AUTH_ROOT + '/emailCheck';
// Password Recovery Routes
export const RESET_PASSWORD_DRUPAL =
  AUTH_ROOT + '/passwordRecovery/passwordReset';
export const UPDATE_PASSWORD_DRUPAL =
  AUTH_ROOT + '/passwordRecovery/passwordUpdate';
export const STRIPE_CHECKOUT = API_BASE + '/api/checkout/createSession';
