import { Box, alpha, useTheme } from '@mui/material';
import { m } from 'framer-motion';
import { RootStyle } from './LoadingScreen.style';
import { ILoadingScreenProps } from './LoadingScreen.types';
import { Logo } from 'assets';
import { ProgressBar } from 'components';

const LoadingScreen = ({
  loading,
  isDashboard,
  ...other
}: ILoadingScreenProps) => {
  if (!loading) return null;
  //hooks
  const theme = useTheme();
  const animationColor = other.seeThrough
    ? alpha(theme.palette.common.white, 0.4)
    : alpha(theme.palette.primary.dark, 0.24);
  return (
    <>
      <ProgressBar />

      {!isDashboard && (
        <RootStyle {...other}>
          <m.div
            animate={{
              scale: [1, 0.9, 0.9, 1, 1],
              opacity: [1, 0.48, 0.48, 1, 1],
            }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              repeatDelay: 1,
              repeat: Infinity,
            }}
          >
            <Logo color={theme.palette.primary.main} sx={{ width: 60 }} />
          </m.div>

          <Box
            component={m.div}
            animate={{
              scale: [1.2, 1, 1, 1.2, 1.2],
              rotate: [270, 0, 0, 270, 270],
              opacity: [0.25, 1, 1, 1, 0.25],
              borderRadius: ['25%', '25%', '50%', '50%', '25%'],
            }}
            transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
            sx={{
              width: 100,
              height: 100,
              borderRadius: '25%',
              position: 'absolute',
              border: `solid 3px ${animationColor}`,
            }}
          />

          <Box
            component={m.div}
            animate={{
              scale: [1, 1.2, 1.2, 1, 1],
              rotate: [0, 270, 270, 0, 0],
              opacity: [1, 0.25, 0.25, 0.25, 1],
              borderRadius: ['25%', '25%', '50%', '50%', '25%'],
            }}
            transition={{
              ease: 'linear',
              duration: 3.2,
              repeat: Infinity,
            }}
            sx={{
              width: 120,
              height: 120,
              borderRadius: '25%',
              position: 'absolute',
              border: `solid 8px ${animationColor}`,
            }}
          />
        </RootStyle>
      )}
    </>
  );
};

export { LoadingScreen };
