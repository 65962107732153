import { create } from 'zustand';

interface Step {
  step: number;
  setStep: (newStep: number) => void;
}

const useStore = create<Step>(set => ({
  step: 0,
  setStep: (newStep: number) => set({ step: newStep }),
}));

const setPaymentStep = (newStep: number) => {
  const setStep = useStore.getState().setStep;
  setStep(newStep);
};

export { setPaymentStep, useStore as useStepStore };
