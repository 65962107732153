import { Button, Stack, Typography, useTheme } from '@mui/material';
import { ISurveyInitModalBody } from './SurveyInitModalBody.types';
import { useResponsive, useTranslations } from 'hooks';

const SurveyInitModalBody = ({
  onHistoryClick,
  onContinueClick,
}: ISurveyInitModalBody) => {
  //Hooks
  const i18n = useTranslations();
  const theme = useTheme();
  const isMobile = useResponsive('down', 'md');
  const isXsDown = useResponsive('down', 365);
  return (
    <Stack>
      <Typography
        variant={'body1'}
        color={theme.palette.grey[600]}
        sx={{ maxWidth: isMobile ? 'unset' : '540px' }}
      >
        {i18n.tool.homepage.newSurveyModal.body}
      </Typography>
      <Stack
        justifyContent={'flex-end'}
        alignItems={'center'}
        flexDirection={isXsDown ? 'column' : 'row'}
        gap={2}
        sx={{ mt: isMobile ? 2 : 5 }}
      >
        <Typography
          variant={'subtitle1'}
          sx={{ fontSize: 14, cursor: 'pointer' }}
          onClick={onHistoryClick}
          color={'black'}
        >
          {i18n.tool.homepage.header.goToHistory}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={onContinueClick}
          sx={{ textTransform: 'unset' }}
        >
          {i18n.tool.homepage.newSurveyModal.continueBtn}
        </Button>
      </Stack>
    </Stack>
  );
};

export { SurveyInitModalBody };
