// ----------------------------------------------------------------------

export default function DateRangePicker() {
  return {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#5DB0B4 !important',
          },
          '&.Mui-selected:focus': {
            backgroundColor: '#5DB0B4 !important',
          },
          '&:focus.Mui-selected': {
            backgroundColor: '#5DB0B4 !important',
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#278084',
          },
          '&.MuiPickersDay-root.Mui-selected': {
            backgroundColor: '#5DB0B4 !important',
          },
          '&.MuiPickersDay-root.Mui-selected:hover': {
            backgroundColor: '#278084',
          },
        },
      },
    },
  };
}
