import { Box, styled } from '@mui/material';
import { NAVBAR } from 'config';

export const FooterWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: '#0A2233',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: '20px',
  width: '100%',
  padding: '16px',
  [`@media (min-width: ${theme?.breakpoints.values.lg}px)`]: {
    width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    padding: '20px 40px',
  },
}));

export const FooterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '10px 0px',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
  [`@media (max-width: ${theme?.breakpoints.values.md}px)`]: {
    flexDirection: 'column',
    gap: '20px',
  },
}));

export const ImagesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '60px',
  alignItems: 'center',
  [`@media (max-width: ${theme?.breakpoints.values.md}px)`]: {
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'flex-start',
    marginRight: 'auto',
  },
}));

export const StyledHr = styled('hr')(({ theme }) => ({
  width: '100%',
  color: '#FFF',
  marginTop: '20px',
  backgroundColor: theme.palette.grey[500_56],
  height: '0.5px',
  border: 'none',
  [`@media (max-width: ${theme?.breakpoints.values.md}px)`]: {
    margin: '12px 0',
  },
}));

export const StyledLink = styled('a')({
  textDecoration: 'none',
  fontSize: '13px',
  color: '#FFF',
});
