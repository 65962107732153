import { Box, BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IColorsIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function ColorsIcon({
  color = '"#637381"',
  style,
  ...other
}: IColorsIconProps) {
  return (
    <Box {...other}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill={color}
        style={style}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M9.00033 17.3346C4.40866 17.3346 0.666992 13.593 0.666992 9.0013C0.666992 4.40964 4.40866 0.667969 9.00033 0.667969C13.592 0.667969 17.3337 4.03464 17.3337 8.16797C17.3337 10.9263 15.092 13.168 12.3337 13.168H10.8587C10.6253 13.168 10.442 13.3513 10.442 13.5846C10.442 13.6846 10.4837 13.7763 10.5503 13.8596C10.892 14.2513 11.0837 14.743 11.0837 15.2513C11.0837 16.4013 10.1503 17.3346 9.00033 17.3346ZM9.00033 2.33464C5.32533 2.33464 2.33366 5.3263 2.33366 9.0013C2.33366 12.6763 5.32533 15.668 9.00033 15.668C9.23366 15.668 9.41699 15.4846 9.41699 15.2513C9.41699 15.118 9.35033 15.018 9.30033 14.9596C8.95866 14.5763 8.77533 14.0846 8.77533 13.5846C8.77533 12.4346 9.70866 11.5013 10.8587 11.5013H12.3337C14.1753 11.5013 15.667 10.0096 15.667 8.16797C15.667 4.9513 12.6753 2.33464 9.00033 2.33464Z" />
        <path d="M4.41699 9.83464C5.10735 9.83464 5.66699 9.27499 5.66699 8.58464C5.66699 7.89428 5.10735 7.33464 4.41699 7.33464C3.72664 7.33464 3.16699 7.89428 3.16699 8.58464C3.16699 9.27499 3.72664 9.83464 4.41699 9.83464Z" />
        <path d="M6.91699 6.5013C7.60735 6.5013 8.16699 5.94166 8.16699 5.2513C8.16699 4.56095 7.60735 4.0013 6.91699 4.0013C6.22664 4.0013 5.66699 4.56095 5.66699 5.2513C5.66699 5.94166 6.22664 6.5013 6.91699 6.5013Z" />
        <path d="M11.0837 6.5013C11.774 6.5013 12.3337 5.94166 12.3337 5.2513C12.3337 4.56095 11.774 4.0013 11.0837 4.0013C10.3933 4.0013 9.83366 4.56095 9.83366 5.2513C9.83366 5.94166 10.3933 6.5013 11.0837 6.5013Z" />
        <path d="M13.5837 9.83464C14.274 9.83464 14.8337 9.27499 14.8337 8.58464C14.8337 7.89428 14.274 7.33464 13.5837 7.33464C12.8933 7.33464 12.3337 7.89428 12.3337 8.58464C12.3337 9.27499 12.8933 9.83464 13.5837 9.83464Z" />
      </svg>
    </Box>
  );
}

export default ColorsIcon;
