import { BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IRightArrowIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function RightNoBoxIcon({ color = '#E28352', style }: IRightArrowIconProps) {
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill={color}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0479 0L10.8492 1.175L14.7428 5H0.996094V6.66667H14.7428L10.8407 10.4917L12.0479 11.6667L17.9989 5.83333L12.0479 0Z"
        color={color}
      />
    </svg>
  );
}

export default RightNoBoxIcon;
