import * as API from 'constants/api';
import { useAuthSelector, useGlobalSelector } from 'stores';
import {
  IHttpMutateHelperProps,
  ILoginInput,
  ILoginResponse,
  IResetPasswordDrupalInput,
  IRegisterCheckResponse,
  IRegisterDrupalInput,
  IRegisterInput,
  IUserLoginInput,
  IRecoverPasswordDrupalResponse,
  IUpdatePasswordDrupalInput,
  IEmailCheckResponse,
} from 'types/httpClient';
import { IUser, IUserToken } from 'types/user';
import { logApiError } from 'utils/api';

const useAuth = () => {
  const { showLoader, hideLoader } = useGlobalSelector();
  const { tokens, storeTokens, handleLogout } = useAuthSelector();

  const login = async ({
    useLoader,
    setData,
    body,
  }: IHttpMutateHelperProps<ILoginResponse, ILoginInput>): Promise<
    ILoginResponse | null | { errorCode: number; errorMessage: string }
  > => {
    let data: ILoginResponse;
    try {
      useLoader && showLoader();
      const response = await fetch(API.LOGIN, {
        body: JSON.stringify(body),
        method: 'POST',
      });
      if (response && response.ok) {
        const apiResponse = await response.json();
        data = apiResponse.data;
        setData?.(data);
        return data;
      } else {
        const err = await response.json();
        return {
          errorCode: response.status || 400,
          errorMessage: err.errorMessag || '',
        };
      }
    } catch (err) {
      logApiError(err, 'login');
      return null;
    } finally {
      useLoader && hideLoader();
    }
  };

  const refreshTokens = async (
    newTokens?: IUserToken
  ): Promise<void | IUserToken> => {
    const refreshToken = newTokens?.refresh_token || tokens?.refresh_token;
    try {
      if (!refreshToken) return;
      const response = await fetch(`${API.REFRESH_TOKEN}`, {
        body: JSON.stringify({
          refreshToken,
        }),
        method: 'POST',
      });
      if (response && response.ok) {
        const { data: refreshedTokens } = await response.json();
        const updatedUserTokens: IUserToken = {
          ...refreshedTokens,
        };
        storeTokens(updatedUserTokens);
        return updatedUserTokens;
      } else {
        handleLogout();
      }
    } catch (err) {
      handleLogout();
    }
  };

  const getUserInfo = async ({
    useLoader,
    setData,
    body,
  }: IHttpMutateHelperProps<IUser, IUserLoginInput>): Promise<IUser | null> => {
    let data: IUser;
    try {
      useLoader && showLoader();
      if (body?.access_token) {
        const response = await fetch(API.OAUTH_USER_INFO, {
          headers: {
            Authorization: 'Bearer ' + body.access_token,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });
        data = await response.json();
        setData?.(data);
        return data;
      } else {
        return null;
      }
    } catch (err) {
      logApiError(err, 'login');
      return null;
    } finally {
      useLoader && hideLoader();
    }
  };

  const register = async ({
    useLoader,
    setData,
    body,
  }: IHttpMutateHelperProps<
    ILoginResponse,
    IRegisterDrupalInput
  >): Promise<ILoginResponse | null> => {
    let data: ILoginResponse;
    try {
      useLoader && showLoader();
      const response = await fetch(API.REGISTER_DRUPAL, {
        body: JSON.stringify(body),
        method: 'POST',
      });
      const apiResponse = await response.json();
      data = apiResponse.data;
      setData?.(data);
      return data;
    } catch (err) {
      logApiError(err, 'register-drupal');
      return null;
    } finally {
      useLoader && hideLoader();
    }
  };

  const emailCheck = async ({
    useLoader,
    setData,
    body,
  }: IHttpMutateHelperProps<
    IEmailCheckResponse,
    IResetPasswordDrupalInput
  >): Promise<IEmailCheckResponse> => {
    let data: IEmailCheckResponse;
    try {
      useLoader && showLoader();
      const response = await fetch(API.EMAIL_CHECK_DRUPAL, {
        body: JSON.stringify(body),
        method: 'POST',
      });
      const apiResponse = await response.json();
      data = apiResponse.data;
      setData?.(data);
      if (apiResponse.errorMessage) return { status: 404, userMail: `found` };
      return {
        status: 200,
        userMail: 'found',
      };
    } catch (err) {
      logApiError(err, 'email-check');
      return { status: 404, userMail: `found` };
    } finally {
      useLoader && hideLoader();
    }
  };

  const resetPassword = async ({
    useLoader,
    setData,
    body,
  }: IHttpMutateHelperProps<
    IRecoverPasswordDrupalResponse,
    IResetPasswordDrupalInput
  >): Promise<IRecoverPasswordDrupalResponse> => {
    let data: IRecoverPasswordDrupalResponse;
    try {
      useLoader && showLoader();
      const response = await fetch(API.RESET_PASSWORD_DRUPAL, {
        body: JSON.stringify(body),
        method: 'POST',
      });
      const apiResponse = await response.json();
      data = apiResponse.data;
      setData?.(data);
      if (apiResponse.errorMessage)
        return { status: 404, message: `Email not found` };
      return {
        status: 200,
        message: `Password reset requested for ${body?.email}.`,
      };
    } catch (err) {
      logApiError(err, 'reset-password-drupal');
      return { status: 404, message: `Email not found` };
    } finally {
      useLoader && hideLoader();
    }
  };

  const updateRecoveryPassword = async ({
    useLoader,
    setData,
    body,
  }: IHttpMutateHelperProps<
    IRecoverPasswordDrupalResponse,
    IUpdatePasswordDrupalInput
  >): Promise<IRecoverPasswordDrupalResponse> => {
    let data: IRecoverPasswordDrupalResponse;
    try {
      useLoader && showLoader();
      const response = await fetch(API.UPDATE_PASSWORD_DRUPAL, {
        body: JSON.stringify(body),
        method: 'POST',
      });
      const apiResponse = await response.json();
      data = apiResponse.data;
      setData?.(data);
      if (apiResponse.errorMessage)
        return { status: 404, message: `Link not valid` };
      return {
        status: 200,
        message: `Password reset requested for ${body?.email}.`,
      };
    } catch (err) {
      logApiError(err, 'update-password-drupal');
      return { status: 404, message: `Link not valid` };
    } finally {
      useLoader && hideLoader();
    }
  };

  const registerCheck = async ({
    useLoader,
    setData,
    body,
  }: IHttpMutateHelperProps<
    IRegisterCheckResponse,
    IRegisterInput
  >): Promise<IRegisterCheckResponse | null> => {
    let data: IRegisterCheckResponse;
    try {
      useLoader && showLoader();
      const response = await fetch(API.REGISTER_CHECK, {
        body: JSON.stringify(body),
        method: 'POST',
      });
      const apiResponse = await response.json();
      if (response.status === 404 && apiResponse.errorMessage === 'Not Found') {
        return null;
      }
      data = apiResponse.data;
      setData?.(data);
      return data;
    } catch (err) {
      return null;
    } finally {
      useLoader && hideLoader();
    }
  };

  return {
    login,
    register,
    refreshTokens,
    getUserInfo,
    registerCheck,
    resetPassword,
    updateRecoveryPassword,
    emailCheck,
  };
};

export { useAuth };
