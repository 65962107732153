import { Typography, Stack, Button } from '@mui/material';
import { StyledFooter } from './Academyfooter.style';
import FondImpresaLogo from 'assets/images/fondimpresa-logo.png';
import { useResponsive, useTranslations } from 'hooks';

const AcademyFooter = ({ style }: { style?: React.CSSProperties }) => {
  const { academy } = useTranslations();
  const isMdUp = useResponsive('up', 'md');
  const isSmDown = useResponsive('down', 'sm');
  return (
    <StyledFooter style={style}>
      <Stack
        flexDirection={isMdUp ? 'row' : 'column'}
        gap={isSmDown ? '16px' : '37px'}
        alignItems={isSmDown ? 'flex-start' : 'center'}
      >
        <img
          src={FondImpresaLogo}
          style={{ width: '18%', minWidth: '235px' }}
        />
        <div>
          <Typography variant={'h6'}>{academy.footer.title}</Typography>
          <Typography variant={'body2'}>{academy.footer.subtitle}</Typography>
        </div>
      </Stack>
      <Button
        variant="contained"
        color="primary"
        sx={{
          textDecoration: 'underline',
          minWidth: 180,
          height: 44,
          // marginLeft: 8,
          ...(isSmDown && {
            width: '100%',
          }),
        }}
        size="small"
        onClick={() =>
          window.open(
            `https://www.fondimpresa.it/accedere-ai-finanziamenti-fondimpresa`,
            '_blank'
          )
        }
      >
        {academy.footer.button}
      </Button>
    </StyledFooter>
  );
};

export { AcademyFooter };
