import { format } from 'date-fns';
import it from 'date-fns/locale/it';
import { IGeneralArticle, IParsedGeneralArticle } from 'types/finance';

export enum ArticleTypeEnum {
  FINANCE = 'finance',
  INTELLECTUAL_PROPERTY = 'intellectual_property',
  ACADEMY = 'academy',
}

export const standardArticleIncludes = ['field_image.field_media_image'];

export const parseGeneralArticleCard = (
  article: IGeneralArticle,
  addMisureTypeToBody = ''
): IParsedGeneralArticle => {
  const misureType = addMisureTypeToBody
    ? `<p>${addMisureTypeToBody.toUpperCase()}</p>`
    : '';

  return {
    id: article.id,
    body: `${misureType}${article.field_description?.value}` || '',
    image: article.field_image?.field_media_image?.image_style_uri?.wide || '',
    title: article.title || '',
    ...(article.field_highlights?.value && {
      highlights: article.field_highlights?.value,
    }),
    ...(article.field_measure_type &&
      article.field_article_type === ArticleTypeEnum.FINANCE && {
        measureType: article.field_measure_type,
      }),
    date: format(new Date(article.created), 'dd MMMM yyyy', { locale: it }),
  };
};

export const measureTypeI18nKey = (drupalValue: string) =>
  drupalValue === 'national' ? 'national' : 'region';
