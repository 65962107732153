import { Box, BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface ICircolarityIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function CircolarityIcon({
  color = '#7b8a72',
  style,
  ...other
}: ICircolarityIconProps) {
  return (
    <Box {...other}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 66.279 75"
        style={style}
      >
        <path
          id="Tracciato_479"
          data-name="Tracciato 479"
          d="M40.553,43.281a8.83,8.83,0,0,1-1.291-4.57,8.724,8.724,0,0,1,8.721-8.721h3.488c5.268,0,6.977-3.488,6.977-3.488s1.919,20.93-10.465,20.93a8.572,8.572,0,0,1-4.6-1.326,51.711,51.711,0,0,0-2.651,10.326c4.4.768,7.953,3.1,9.663,6.174a22.69,22.69,0,0,0,9.8-18.663H70.658A33.14,33.14,0,1,1,37.518,10.8V2.083L51.472,16.037,37.518,29.99V21.269A22.672,22.672,0,0,0,24.646,62.607c1.779-3.209,5.686-5.651,10.4-6.279a32.83,32.83,0,0,0-1.709-7.081A6.913,6.913,0,0,1,28.8,50.921a7,7,0,0,1-6.977-6.977V40.49a8.915,8.915,0,0,0-1.744-5.268s15.523-.8,15.7,8.721a6.666,6.666,0,0,1-.593,2.791,21.865,21.865,0,0,0-4.64-2.791,27.578,27.578,0,0,1,6.977,9.07A50.346,50.346,0,0,1,47.983,36.967,46.89,46.89,0,0,0,40.553,43.281Z"
          transform="translate(-4.379 -2.083)"
          fill={color}
        />
      </svg>
    </Box>
  );
}

export default CircolarityIcon;
