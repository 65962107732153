import { IGeneralSurvey } from './generalSurvey';
import { IRegisterCheckValue } from './httpClient';
import { ISession, IUser, IUserToken } from './user';
import { ICardPropertyContent } from 'pages/DashboardV2/WidgetContents/types';
import {
  OrderByValueMaterialEnum,
  OrderMaterialEnum,
} from 'pages/Sustainability/MaterialLibrary/index.types';
import { Locales } from 'types/intl';

export interface IGlobalStore {
  activeLocale: Locales;
  loading: boolean;
  requestedLocation: string | null;
  dynamicBreadcrumbs: IDynamicBreadcrumb;
  showLoader: () => void;
  hideLoader: () => void;
}

export interface IDynamicBreadcrumb {
  courseId: string;
  categoryId: string;
  financeReq: string;
  moreInfoReq: string;
  studyCategoryId: string;
  studyContentId: string;
  materialId: string;
  materialCompanyId: string;
  masterName: string;
  generalSurveyName: string;
  intellectualPropertyName: string;
  intellectualPropertyCourseName: string;
  intellectualPropertyFocusName: string;
  circularityToolContext: string;
}

export interface IRegistrationStore {
  code?: string;
  vatNumber?: string;
  businessName?: string;
}

export interface IAuthStore {
  isAuthenticated: boolean;
  remember: boolean;
  user: IUser | null;
  registeredAccount: IRegisterCheckValue | null;
  tokens: IUserToken | null;
  isChangingPassword: boolean;
  isLoggingOut: boolean;
  storeSession: (sessionData: ISession, remember?: boolean) => void;
  storeUser: (userData: IUser) => void;
  storeRegisteredAccount: (registeredData: IRegisterCheckValue) => void;
  storeTokens: (tokens: IUserToken) => void;
  handleLogout: () => void;
  reset: () => void;
}

export interface IGeneralSurveyStore {
  selectedSurveyWebformId: string;
  selectedSurveyId?: string;
  selectedSurveyStatus: 'open' | 'closed' | undefined;
  allSurveys: IGeneralSurvey[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submittedData?: { [key: string]: any };
  selectedSurveyIsDraft: boolean;
}

export type AuthStoreDefaultValues = Pick<
  IAuthStore,
  | 'isAuthenticated'
  | 'user'
  | 'registeredAccount'
  | 'tokens'
  | 'remember'
  | 'isChangingPassword'
  | 'isLoggingOut'
>;

export enum DashboardCardEnum {
  STUDY_CENTER,
  FINANCE,
  ACADEMY,
  INTELLECTUAL_PROPERTY,
  DESIGNWOODS,
  CUSTOM_HOUSE_OFFICE,
  MATERIAL,
  BOSCO,
  SPORTELLO,
  TECLA,
}

export interface ITeclaDashboardValues {
  questions_group_1_total_formatted: string;
  questions_group_2_total_formatted: string;
  questions_group_3_total_formatted: string;
  questions_group_4_total_formatted: string;
  questions_group_5_total_formatted: string;
  questions_group_6_total_formatted: string;
  survey_total_formatted: string;
}

export type IDashboardStore = {
  [DashboardCardEnum.STUDY_CENTER]?: StudyCenterValues;
  [DashboardCardEnum.FINANCE]?: ICardPropertyContent[];
  [DashboardCardEnum.ACADEMY]?: ICardPropertyContent[];
  [DashboardCardEnum.INTELLECTUAL_PROPERTY]?: ICardPropertyContent[];
  [DashboardCardEnum.MATERIAL]?: MaterialValues;
  [DashboardCardEnum.BOSCO]?: {
    trees: {
      label: string;
      value: string;
    };
    co2: {
      label: string;
      value: string;
    };
  };
  [DashboardCardEnum.SPORTELLO]?: ICardPropertyContent[];
  [DashboardCardEnum.TECLA]?: ITeclaDashboardValues;
  hasCachedData: boolean;
  triggerCachedData: () => void;
  materialsSearchParams?: { cat?: string; sub?: string; type?: string };
  certificationSearchParams?: {
    sector?: string;
    nation?: string[];
  };
  librarySearchText?: string;
  hasDashboardFilters: boolean;
};

export type IMaterialFiltersStore = {
  values: {
    pageOffset: number;
    limit: number;
    sortingOrder: `${OrderMaterialEnum}`;
    sortingFieldName: `${OrderByValueMaterialEnum}`;
    field_categories?: string[];
    field_subcategories?: string[];
    field_material_types?: string[];
    field_admission_criteria?: string[];
    field_sustainabilities?: string[];
    search?: string;
  };
  hasPrevFilters: boolean;
  pageSelectedTrigger: boolean;
};

export type StudyCenterValues = {
  inovicePerc?: number;
  exportPerc?: number;
  importPerc?: number;
  invoiceValue?: string;
  exportValue: string;
  importValue: string;
};

export type GenericCardValues = {
  title: string;
  subtitle: string;
  preview: string;
  id: string;
};

export type MaterialValues = {
  categories?: {
    categoryId: string;
    categoryName: string;
    subcategories: { subcategoryId: string; subcategoryName: string }[];
  }[];
  materials?: { label: string; value: string }[];
};

export enum ToolContextEnum {
  WELCOME = 'welcome',
  CUSTOMIZE = 'customize',
  LOADING = 'loading',
  SURVEY = 'survey',
  FEEDBACK = 'feedback',
}

export enum SurveyCategoriesEnum {
  MATERIA = 'materia',
  ENERGY = 'energy',
  WASTE = 'waste',
  LOGISTICS = 'logistics',
  PRODUCTS = 'products',
  HR = 'hr',
}

export type FeedbackDataProps = {
  category: `${SurveyCategoriesEnum}`;
  value: number;
};

export interface ISavedSurvey {
  id: string;
  created: string;
  changed: string;
  userName?: string;
  isDraft: boolean;
  surveySelectedChoices?: {
    [key in SurveyCategoriesEnum]?: { [key: string]: string };
  };
  customizedSelectedChoices?: {
    A?: 'yes' | 'no';
    B?: 'yes' | 'no';
    C?: 'yes' | 'no';
    D?: 'yes' | 'no';
    E?: 'yes' | 'no';
    F?: 'yes' | 'no';
    G?: 'yes' | 'no';
    H?: 'yes' | 'no';
  };
  pathSelected: 'complete' | 'customize';
  totalResult?: string;
}

export type IToolStore = {
  clickedTextId?: string;
  currentContext: `${ToolContextEnum}`;
  pathSelected?: 'complete' | 'customize';
  feedbackData?: {
    savedSurvey?: ISavedSurvey;
    calculated: FeedbackDataProps[];
  };
  feedbackChartCanvasImg?: string;
  feedbackTotalPercentage: number;
  calculatedQuestions?: {
    id: string;
    category: SurveyCategoriesEnum;
    label: string;
    notes?: string;
    possibleOptions: {
      value: string;
      label: string;
    }[];
  }[];
  questionsPossibleOptions?: {
    id: string;
    possibleOptions: {
      value: string;
      label: string;
    }[];
  }[];
  customizedSelectedChoices?: {
    A?: 'yes' | 'no';
    B?: 'yes' | 'no';
    C?: 'yes' | 'no';
    D?: 'yes' | 'no';
    E?: 'yes' | 'no';
    F?: 'yes' | 'no';
    G?: 'yes' | 'no';
    H?: 'yes' | 'no';
  };
  surveySelectedChoices?: {
    [key in SurveyCategoriesEnum]?: { [key: string]: string };
  };
  draftedSurvey?: ISavedSurvey;
  completedSurvey?: ISavedSurvey;
  wantFeedbackOnly: boolean;
  wantCompletedSurveyFeedback: boolean;
  wantViewCompletedSurvey: boolean;
  isEditing: boolean;
  temporaryDraftId?: string;
};

export interface INotificationsStore {
  notifications?: INotification[];
}

export type INotification = {
  id: string;
  title: string;
  description: string;
  avatar: string | null;
  link: string | null;
  type: string;
  createdAt: Date;
  isUnRead: boolean;
  isArchieved?: boolean;
};

export type IInterest = {
  interestType: string | null;
  iconType: string;
  drupalId: number;
};

export type IInterestStore = {
  interests: IInterest[];
};

export interface IBannerItem {
  id: string;
  image: string;
  title: string;
  description: string;
  color: string;
  link?: string;
}

export interface IBannerStore {
  bannerItems?: IBannerItem[];
}
