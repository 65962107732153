export interface ISearchProps {
  search?: string;
}

export enum ListVisibilityEnum {
  CARD_LIST,
  TABLE,
}

export enum OrderMaterialEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum OrderByValueMaterialEnum {
  TITLE = 'title',
  COMPANY_NAME = 'field_company.title',
}

export interface IFormFiltersProps {
  sortingOrder: `${OrderMaterialEnum}`;
  sortingFieldName: `${OrderByValueMaterialEnum}`;
  field_categories: string[];
  field_subcategories: string[];
  field_material_types: string[];
  field_admission_criteria: string[];
  field_sustainabilities: string[];
  search?: string;
}

export interface IParsedMaterials {
  id: string;
  title: string;
  image: string;
  producer?: TableOption;
  categories?: TableOption[];
  subcategories?: TableOption[];
  sustainabilityCriteria: { color: string }[];
  summaries?: { cam?: string[]; circ?: string[]; proc?: string[] };
}

export interface TableOption {
  label: string;
  value: string;
}

export interface IListHeaderProps {
  listVisibility: ListVisibilityEnum;
  onVisibilityChange: (context: ListVisibilityEnum) => void;
  onSelectChange: ({
    key,
    value,
  }: {
    key: 'sortingOrder' | 'sortingFieldName';
    value: `${OrderMaterialEnum}` | `${OrderByValueMaterialEnum}`;
  }) => void;
  totalCount: number;
  totalVisible: number;
  pageSelected: number;
}

export interface ICardListProps {
  materialsCount: number;
  materials: IParsedMaterials[];
  pageSelected: number;
  onPaginationChange: (number: number) => void;
  circlesOnMouseEnter?: (
    event: React.MouseEvent<HTMLSpanElement>,
    key: 'cam' | 'lca' | 'circ' | 'proc' | 'tox',
    color?: string,
    id?: string
  ) => void;
  circlesOnMouseLeave?: () => void;
}

export interface IActiveFilters {
  pageOffset: number;
  limit: number;
  sortingOrder: `${OrderMaterialEnum}`;
  sortingFieldName: `${OrderByValueMaterialEnum}`;
  field_categories?: string[];
  field_subcategories?: string[];
  field_material_types?: string[];
  field_admission_criteria?: string[];
  field_sustainabilities?: string[];
  search?: string;
}

export interface FiltersDrawerProps {
  onDrawerClose: () => void;
  onFilterSubmitted: (
    data: IFormFiltersProps,
    fetchedFilters?: IDrawerFilters
  ) => void;
  isOpen: boolean;
  fetchedFilters?: IDrawerFilters;
  setFetchedFilters?: React.Dispatch<
    React.SetStateAction<IDrawerFilters | undefined>
  >;
  onClearAll: () => void;
}

export interface IDrawerFilters {
  categories?: {
    categoryId: string;
    categoryName: string;
    weight: number;
    subcategories: IDrawerSubCategory[];
  }[];
  materials?: { label: string; value: string }[];
  criteria?: { label: string; value: string }[];
  sustainabilities?: { label: string; value: string }[];
}

export interface IDrawerSubCategory {
  subcategoryId: string;
  subcategoryName: string;
}

export interface IFilterChipsContainerProps {
  chipData: ChipData[];
  onChipDelete: (data: ChipData) => void;
}

export interface ISearchPaperProps extends IFilterChipsContainerProps {
  onButtonClick: () => void;
  selectedFilters?: string[][];
  setCharacters?: React.Dispatch<React.SetStateAction<string>>;
  setUserHasEmptySearchBar?: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ChipData {
  value: string;
  label: string;
  key: string;
}

export interface FiltersMapping {
  field_categories: string;
  field_subcategories: string;
  field_material_types: string;
  field_admission_criteria: string;
  field_sustainabilities: string;
}

type Arrow =
  | 'top-left'
  | 'top-center'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-center'
  | 'bottom-right'
  | 'left-top'
  | 'left-center'
  | 'left-bottom'
  | 'right-top'
  | 'right-center'
  | 'right-bottom';

export type ArrowStyleProps = {
  arrow: Arrow;
  bgColor: string;
};
