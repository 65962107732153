// @mui

import { CSSProperties } from 'react';

// ----------------------------------------------------------------------

function SideBarSportelloIcon({
  color = '#919EAB',
  style,
}: {
  color?: string;
  style?: CSSProperties;
}) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g id="ICONS">
        <path
          id="Icon"
          d="M28 16.5C28 23.1274 22.6274 28.5 16 28.5M28 16.5C28 9.87258 22.6274 4.5 16 4.5M28 16.5H4M16 28.5C9.37258 28.5 4 23.1274 4 16.5M16 28.5C19.0015 25.214 20.7073 20.9496 20.8 16.5C20.7073 12.0504 19.0015 7.78602 16 4.5M16 28.5C12.9985 25.214 11.2927 20.9496 11.2 16.5C11.2927 12.0504 12.9985 7.78602 16 4.5M4 16.5C4 9.87258 9.37258 4.5 16 4.5"
          stroke={color}
          strokeWidth="2.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default SideBarSportelloIcon;
