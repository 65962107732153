import { Typography } from '@mui/material';
import { OptionsObject, useSnackbar as useNotistack } from 'notistack';

const useSnackbar = () => {
  const notistackMethods = useNotistack();

  const { enqueueSnackbar } = notistackMethods;

  const successSnackbar = (message: string, options?: OptionsObject) =>
    enqueueSnackbar(
      <Typography sx={{ fontWeight: 700, fontSize: 14 }}>{message}</Typography>,
      {
        variant: 'success',
        ...options,
      }
    );

  const errorSnackbar = (message: string, options?: OptionsObject) =>
    enqueueSnackbar(
      <Typography sx={{ fontWeight: 700, fontSize: 14 }}>{message}</Typography>,
      {
        variant: 'error',
        ...options,
      }
    );

  const infoSnackbar = (message: string, options?: OptionsObject) =>
    enqueueSnackbar(
      <Typography sx={{ fontWeight: 700, fontSize: 14 }}>{message}</Typography>,
      {
        variant: 'info',
        ...options,
      }
    );

  const warningSnackbar = (message: string, options?: OptionsObject) =>
    enqueueSnackbar(
      <Typography sx={{ fontWeight: 700, fontSize: 14 }}>{message}</Typography>,
      {
        variant: 'warning',
        ...options,
      }
    );

  return {
    successSnackbar,
    errorSnackbar,
    infoSnackbar,
    warningSnackbar,
    ...notistackMethods,
  };
};

export { useSnackbar };
