import { styled, Paper } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  width: '100%',
  padding: '20px 0px',
  display: 'flex',
  gap: '25px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const StyledPaper = styled(Paper)(
  ({
    width,
    height,
    padding,
  }: {
    width?: string;
    height: string;
    center?: boolean;
    padding?: string;
  }) => ({
    borderRadius: '20px',
    ...(width && {
      width: width,
    }),
    height: height,
    padding: padding ? padding : '0',
  })
);
