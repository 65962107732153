// @mui

import { CSSProperties } from 'react';

// ----------------------------------------------------------------------

function SearchV2Icon({
  color = '#0A2233',
  style,
}: {
  color?: string;
  style?: CSSProperties;
}) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M1.80078 18.75C1.80078 18.1533 2.03783 17.581 2.45979 17.159C2.88175 16.7371 3.45404 16.5 4.05078 16.5H16.2008V21H4.05078C3.45404 21 2.88175 20.7629 2.45979 20.341C2.03783 19.919 1.80078 19.3467 1.80078 18.75ZM1.80078 18.75V5.25C1.80078 4.65326 2.03783 4.08097 2.45979 3.65901C2.88175 3.23705 3.45404 3 4.05078 3H16.2008V5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.668 17.7002L19.768 14.8002M21.3346 11.0335C21.3346 13.979 18.9468 16.3669 16.0013 16.3669C13.0558 16.3669 10.668 13.979 10.668 11.0335C10.668 8.08801 13.0558 5.7002 16.0013 5.7002C18.9468 5.7002 21.3346 8.08801 21.3346 11.0335Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SearchV2Icon;
