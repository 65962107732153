import {
  Button,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Avatar as MuiAvatar,
} from '@mui/material';

import { Table } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Avatar, RootStyle, StyledTableHead } from './CommunityProfile.style';
import { RHFTextField } from 'components';
import { useResponsive, useTranslations } from 'hooks';
import { FormProvider } from 'providers';
import { useAuthSelector } from 'stores';
import { IUser } from 'types/user';

const defaultValues: {
  field_name?: string;
  field_surname?: string;
  email?: string;
  field_telephone?: string;
} = {
  email: '',
  field_name: '',
  field_surname: '',
  field_telephone: '',
};

const CommunityProfile = ({ communityUsers }: { communityUsers?: IUser[] }) => {
  //hooks
  const methods = useForm<{
    field_name?: string;
    field_surname?: string;
    email?: string;
    field_telephone?: string;
  }>({ defaultValues });
  const i18n = useTranslations();
  const isMobile = useResponsive('down', 'md');
  const [activeUser, setActiveUser] = useState<IUser>();
  const { user } = useAuthSelector();

  const columns = [
    {
      field: 'name',
      headerName: i18n.auth.personalProfile.communityTab.tableHeaderLabel.name,
      width: 250,
    },
    {
      field: 'role',
      headerName: i18n.auth.personalProfile.communityTab.tableHeaderLabel.role,
      width: 250,
    },
    {
      field: 'action',
      headerName: '',
      width: 250,
    },
  ];

  useEffect(() => {
    if (communityUsers?.length && communityUsers.length > 1 && !activeUser) {
      setActiveUser(communityUsers[0]);
    }
  }, [communityUsers, activeUser]);

  useEffect(() => {
    if (activeUser) {
      methods.setValue('email', activeUser.email ? activeUser.email : '');
      methods.setValue(
        'field_name',
        activeUser.field_name ? activeUser.field_name : ''
      );
      methods.setValue(
        'field_surname',
        activeUser.field_surname ? activeUser.field_surname : ''
      );
      methods.setValue(
        'field_telephone',
        activeUser.field_telephone ? activeUser.field_telephone : ''
      );
    }
  }, [activeUser]);

  //renders
  const renderTable = () => {
    const tableData = communityUsers?.map((u, idx) => ({
      id: u.uuid || idx + 1,
      avatar: u.user_picture ? (
        <Avatar image={u.user_picture} fixSize="40px" />
      ) : (
        <MuiAvatar />
      ),
      name: `${u.field_name} ${u.field_surname ? u.field_surname : ''}`,
      role: u.field_role,
      displayName: u.email,
    }));
    return communityUsers && tableData ? (
      <Table>
        <StyledTableHead>
          <TableRow>
            {columns.map((el, index) => (
              <TableCell key={`header${index}`}>
                <Typography variant="subtitle2" sx={{ fontWeight: '600' }}>
                  {el.headerName}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {tableData.map((el, index) => (
            <TableRow key={`bodycell${index}`}>
              <TableCell>
                <Stack flexDirection={'row'} alignItems={'center'} gap={'10px'}>
                  {el.avatar}
                  <Typography variant="body1">
                    {el.name} {el.displayName === user?.email ? '( Io )' : ''}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell>
                <Typography variant="body1">{el.role}</Typography>
              </TableCell>
              <TableCell
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  sx={{ marginTop: '2px' }}
                  onClick={() =>
                    setActiveUser(
                      communityUsers?.find(u => u.email === el.displayName)
                    )
                  }
                  disabled={activeUser?.email === el.displayName}
                >
                  {i18n.common.buttons.viewDetails}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    ) : null;
  };

  return (
    <Stack flexDirection={!isMobile ? 'row' : 'column'} gap="10px">
      <Paper
        sx={{
          padding: '40px',
          margin: '20px 0px',
          borderRadius: '20px',
          width: !isMobile ? '60%' : '100%',
        }}
      >
        <Stack gap="20px">
          <Typography variant="h5" sx={{ fontWeight: '700' }}>
            {user?.field_company}
          </Typography>
          {renderTable()}
        </Stack>
      </Paper>
      <Paper
        sx={{
          padding: '40px',
          margin: '20px 0px',
          borderRadius: '20px',
          width: !isMobile ? '40%' : '100%',
        }}
      >
        <Stack gap="40px">
          <RootStyle big={true}>
            <Avatar image={activeUser?.user_picture} />
          </RootStyle>
          <FormProvider methods={methods}>
            <Stack gap="30px">
              <RHFTextField
                name="field_name"
                label={i18n.common.fields.firstName}
                disabled
              />
              <RHFTextField
                name="field_surname"
                label={i18n.common.fields.lastName}
                disabled
              />
              <RHFTextField
                name="email"
                label={i18n.common.fields.email}
                disabled
              />
              <RHFTextField
                name="field_telephone"
                label={i18n.common.fields.phone}
                disabled
              />
            </Stack>
          </FormProvider>
        </Stack>
      </Paper>
    </Stack>
  );
};

export { CommunityProfile };
