import { IntlShape } from 'react-intl';
import { PERSONAL_PROFILE } from 'constants/paths';

const htmlParserOptions = {
  a: (str: string) => (
    <a
      href={`${PERSONAL_PROFILE}?activeTab=5`}
      style={{ color: 'inherit', fontWeight: 'bold' }}
    >
      {str}
    </a>
  ),
};

export const checkoutTranslations = (intl: IntlShape) => ({
  checkout: {
    title: intl.formatMessage({
      defaultMessage: 'Checkout',
      description: 'checkout title',
    }),
    stepper: {
      participantsDatas: intl.formatMessage({
        defaultMessage: 'Dati dei partecipanti',
        description: 'checkout stepper participantsDatas',
      }),
      invoiceDatas: intl.formatMessage({
        defaultMessage: 'Dati di fatturazione',
        description: 'checkout stepper invoiceDatas',
      }),
      summaryAndPayment: intl.formatMessage({
        defaultMessage: 'Riepilogo e pagamento',
        description: 'checkout stepper summaryAndPayment',
      }),
      confirm: intl.formatMessage({
        defaultMessage: 'Conferma',
        description: 'checkout stepper confirm',
      }),
    },
    participantsDataCheckout: {
      insertData: intl.formatMessage({
        defaultMessage: 'Inserisci i dati dei partecipanti per il corso',
        description: 'checkout participantsDataCheckout insertData',
      }),
      participant: intl.formatMessage({
        defaultMessage: 'Partecipante',
        description: 'checkout participantsDataCheckout participant',
      }),
      form: {
        name: intl.formatMessage({
          defaultMessage: 'Nome',
          description: 'checkout participantsDataCheckout form name',
        }),
        surname: intl.formatMessage({
          defaultMessage: 'Cognome',
          description: 'checkout participantsDataCheckout form surname',
        }),
        email: intl.formatMessage({
          defaultMessage: 'Email',
          description: 'checkout participantsDataCheckout form email',
        }),
        role: intl.formatMessage({
          defaultMessage: 'Ruolo',
          description: 'checkout participantsDataCheckout form role',
        }),
      },
      addParticipants: intl.formatMessage({
        defaultMessage: 'Aggiungi partecipanti',
        description: 'checkout participantsDataCheckout addParticipants',
      }),
    },
    participantsDataSummary: {
      participantsData: intl.formatMessage({
        defaultMessage: 'Dati dei partecipanti',
        description: 'checkout participantsDataSummary participantsData',
      }),
      modify: intl.formatMessage({
        defaultMessage: 'Modifica',
        description: 'checkout participantsDataSummary modify',
      }),
      title: intl.formatMessage({
        defaultMessage: 'Dati di fatturazione',
        description: 'checkout participantsDataSummary invoiceData title',
      }),
      companyName: intl.formatMessage({
        defaultMessage: 'Ragione sociale',
        description: 'checkout participantsDataSummary invoiceData companyName',
      }),
      vatNumber: intl.formatMessage({
        defaultMessage: 'P.Iva',
        description: 'checkout participantsDataSummary invoiceData vatNumber',
      }),
      SDI: intl.formatMessage({
        defaultMessage: 'SDI',
        description: 'checkout participantsDataSummary invoiceData SDI',
      }),
      invoiceEmail: intl.formatMessage({
        defaultMessage: 'PEC',
        description:
          'checkout participantsDataSummary invoiceData invoiceEmail',
      }),
      communicationEmail: intl.formatMessage({
        defaultMessage: 'Indirizzo mail per eventuali comunicazioni',
        description:
          'checkout participantsDataSummary invoiceData communicationEmail',
      }),
      telephone: intl.formatMessage({
        defaultMessage: 'Telefono',
        description: 'checkout participantsDataSummary invoiceData telephone',
      }),
    },
    invoiceDataCheckout: {
      insertData: intl.formatMessage({
        defaultMessage: 'Inserisci i dati di fatturazione',
        description: 'checkout invoiceDataCheckout insertData',
      }),
      insertMyData: intl.formatMessage({
        defaultMessage: "Inserisci i miei dati dell'account FLA Plus",
        description: 'checkout invoiceDataCheckout insertMyData',
      }),
      form: {
        nameSurname: intl.formatMessage({
          defaultMessage: 'Nome e cognome persona di riferimento',
          description: 'checkout invoiceDataCheckout form nameSurname',
        }),
        companyName: intl.formatMessage({
          defaultMessage: 'Ragione sociale azienda',
          description: 'checkout invoiceDataCheckout form companyName',
        }),
        SDI: intl.formatMessage({
          defaultMessage: 'Codice di fatturazione elettronica (SDI)',
          description: 'checkout invoiceDataCheckout form SDI',
        }),
        vatNumber: intl.formatMessage({
          defaultMessage: 'P.IVA azienda',
          description: 'checkout invoiceDataCheckout form vatNumber',
        }),
        invoiceEmail: intl.formatMessage({
          defaultMessage: 'PEC',
          description: 'checkout invoiceDataCheckout form invoiceEmail',
        }),
        communicationEmail: intl.formatMessage({
          defaultMessage: 'Indirizzo email per eventuali comunicazioni',
          description: 'checkout invoiceDataCheckout form communicationEmail',
        }),
        telephone: intl.formatMessage({
          defaultMessage: 'Telefono di riferimento',
          description: 'checkout invoiceDataCheckout form telephone',
        }),
      },
      privacy: intl.formatMessage({
        defaultMessage:
          'Accetto il contratto per la vendita online e la Privacy Policy di FLA Plus',
        description: 'checkout invoiceDataCheckout privacy',
      }),
    },
    orderSummaryCard: {
      yourOrder: intl.formatMessage({
        defaultMessage: 'Il tuo ordine',
        description: 'checkout orderSummaryCard yourOrder',
      }),
      course: intl.formatMessage({
        defaultMessage: 'Corso',
        description: 'checkout orderSummaryCard course',
      }),
      participants: intl.formatMessage({
        defaultMessage: 'Partecipanti',
        description: 'checkout orderSummaryCard participants',
      }),
      subTotal: intl.formatMessage({
        defaultMessage: 'Subtotale',
        description: 'checkout orderSummaryCard subTotal',
      }),
      discount: intl.formatMessage({
        defaultMessage: 'Sconto',
        description: 'checkout orderSummaryCard discount',
      }),
      IVA: intl.formatMessage({
        defaultMessage: 'IVA 22%',
        description: 'checkout orderSummaryCard IVA',
      }),
      total: intl.formatMessage({
        defaultMessage: 'Totale',
        description: 'checkout orderSummaryCard total',
      }),
    },
    completedPurchase: {
      successTitle: intl.formatMessage({
        defaultMessage: 'Grazie di aver acquistato i contenuti di FLA Plus!',
        description: 'checkout completedPurchase successTitle',
      }),
      successBody: intl.formatMessage(
        {
          defaultMessage:
            'Riceverai una email con i dettagli dell’acquisto e la fattura. Puoi trovare i tuoi acquisti sulla sezione <a>Acquisti</a> del tuo profilo.',
          description: 'checkout completedPurchase successBody',
        },
        { ...htmlParserOptions }
      ),
      unsuccessTitle: intl.formatMessage({
        defaultMessage: 'Ci dispiace ma qualcosa è andato storto.',
        description: 'checkout completedPurchase unsuccessTitle',
      }),
      unuccessBody: intl.formatMessage({
        defaultMessage: 'Non ti preoccupare, non ti abbiamo addebitato nulla.',
        description: 'checkout completedPurchase unsuccessBody',
      }),
      dashboardButtonSuccess: intl.formatMessage({
        defaultMessage: 'Vai alla dashboard',
        description: 'checkout completedPurchase dashboardButton',
      }),
      dashboardButtonUnsuccess: intl.formatMessage({
        defaultMessage: 'Torna sul carrello',
        description: 'checkout completedPurchase dashboardButton',
      }),
    },
  },
});
