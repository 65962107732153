import { TextFieldProps } from '@mui/material';
import { IRHFMultiCheckboxProps } from '../RHFCheckbox/RHFCheckbox.type';
import { IRHFRadiosProps } from '../RHFRadios/RHFRadios.types';
import {
  CustomSelectProps,
  IRHFSelectProps,
} from '../RHFSelect/RHFSelect.type';
import { IRHFTextFieldProps } from '../RHFTextField/RHFTextField.types';
import { IDynamicFieldForm } from 'types/webform';

export interface IDynamicFieldFormProps {
  fieldData: IDynamicFieldForm;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
}

export interface IDynamicComponent {
  text: (data: IRHFTextFieldProps) => JSX.Element;
  number: (data: IRHFTextFieldProps) => JSX.Element;
  email: (data: IRHFTextFieldProps) => JSX.Element;
  textarea: (data: IRHFTextFieldProps) => JSX.Element;
  radios: (data: IRHFRadiosProps & { variant?: 'standard' }) => JSX.Element;
  webform_radios_other: (
    data: IRHFRadiosProps & { variant?: 'standard' }
  ) => JSX.Element;
  select: (data: IRHFSelectProps & TextFieldProps) => JSX.Element;
  multiSelect: (data: CustomSelectProps) => JSX.Element;
  checkboxes: (data: IRHFMultiCheckboxProps) => JSX.Element;
  webform_checkboxes_other: (data: IRHFMultiCheckboxProps) => JSX.Element;
}

export enum RadiosOtherValue {
  CUSTOM_OTHER_VALUE,
}
