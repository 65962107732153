import { Navigate, useLocation } from 'react-router-dom';

import { AuthGuardProps } from './AuthGuard.types';
import * as PATHS from 'constants/paths';
import { useAuthSelector, useGlobalSelector, useGlobalStore } from 'stores';

const AuthGuard = ({ children }: AuthGuardProps) => {
  const { isAuthenticated, isLoggingOut } = useAuthSelector();
  const { requestedLocation } = useGlobalSelector();

  const { pathname } = useLocation();

  if (!isAuthenticated) {
    if (pathname !== requestedLocation && !isLoggingOut)
      useGlobalStore.setState({ requestedLocation: pathname });

    return <Navigate to={PATHS.LOGIN} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    useGlobalStore.setState({ requestedLocation: null });
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

export { AuthGuard };
