import { Outlet } from 'react-router-dom';
import { ContentWrapper, LeftCol, RightCol, Wrapper } from './AuthLayout.style';
import { Logo } from 'assets';
import backgroundImage from 'assets/images/FLA-plus-login-forest.png';
import { Image, Scrollbar } from 'components';

const AuthLayout = () => {
  return (
    <>
      <Wrapper>
        <LeftCol>
          <Image
            src={backgroundImage}
            sx={{
              height: '100%',
              '& img': {
                objectPosition: 'right',
              },
            }}
          />
          <Logo
            color="#FFF"
            sx={{
              width: '120px',
              height: '61.015px',
              marginBottom: '50px',
              position: 'absolute',
              top: '17.55px',
              left: '60px',
            }}
            onClick={() => window.open('https://www.fla-plus.it/')}
          />
        </LeftCol>
        <Scrollbar sx={{ width: '100%' }}>
          <RightCol>
            <ContentWrapper>
              <Outlet />
            </ContentWrapper>
          </RightCol>
        </Scrollbar>
      </Wrapper>
    </>
  );
};

export default AuthLayout;
