import { styled, Theme } from '@mui/material';

export const StyledContactCard = styled('div')(
  ({ theme, color }: { theme?: Theme; color?: string }) => ({
    padding: 25,
    gap: '28px',
    display: 'flex',
    width: '408px',
    flexDirection: 'row',
    borderRadius: '16px',
    backgroundColor: color,
    [`@media (max-width: ${theme?.breakpoints.values.sm}px)`]: {
      width: 408,
      flexDirection: 'column',
      gap: '8px',
    },
  })
);
