import { Box, BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IFolderIcon extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function FolderIcon({ color = '#212B36', style, ...other }: IFolderIcon) {
  return (
    <Box {...other}>
      <svg
        width="18"
        height="14"
        viewBox="0 0 18 14"
        fill="none"
        style={style}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.64102 1.99967L8.30768 3.66634H15.666V11.9997H2.33268V1.99967H6.64102ZM7.33268 0.333008H2.33268C1.41602 0.333008 0.674349 1.08301 0.674349 1.99967L0.666016 11.9997C0.666016 12.9163 1.41602 13.6663 2.33268 13.6663H15.666C16.5827 13.6663 17.3327 12.9163 17.3327 11.9997V3.66634C17.3327 2.74967 16.5827 1.99967 15.666 1.99967H8.99935L7.33268 0.333008Z"
          fill={color}
        />
      </svg>
    </Box>
  );
}

export default FolderIcon;
