import { BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface INotificationIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function NotificationIcon({
  color = '#7B8A72',
  style,
}: INotificationIconProps) {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M10.5601 18.125C11.5281 18.125 12.3202 17.375 12.3202 16.4583H8.79993C8.79993 17.375 9.59199 18.125 10.5601 18.125ZM15.8405 13.125V8.95833C15.8405 6.4 14.406 4.25833 11.8802 3.69167V3.125C11.8802 2.43333 11.2905 1.875 10.5601 1.875C9.82961 1.875 9.23996 2.43333 9.23996 3.125V3.69167C6.72297 4.25833 5.27966 6.39167 5.27966 8.95833V13.125L3.51953 14.7917V15.625H17.6006V14.7917L15.8405 13.125ZM14.0803 13.9583H7.0398V8.95833C7.0398 6.89167 8.3687 5.20833 10.5601 5.20833C12.7514 5.20833 14.0803 6.89167 14.0803 8.95833V13.9583Z"
        fill={color}
      />
    </svg>
  );
}

export default NotificationIcon;
