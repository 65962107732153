import {
  Stack,
  Table,
  TableHead,
  TableRow,
  Fade,
  Button,
  TableBody,
  useTheme,
  IconButton,
  InputAdornment,
} from '@mui/material';

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Letter,
  MainContainer,
  StyledButton,
  StyledDrawer,
  StyledTableCell,
  ContainerScroll,
} from './GlossarySearchBar.style';
import { IGlossaryWord } from './GlossatySearchBar.types';
import MobileResultCard from './MobileResultCard';
import { Iconify, RHFTextField } from 'components';
import {
  buildJsonApiGroupFilter,
  buildJsonApiSimpleFilter,
  FilterOperatorEnum,
  useDebounce,
  useJsonApi,
  useResponsive,
  useSnackbar,
  useTranslations,
} from 'hooks';
import { useGlossary } from 'hooks/useGlossary';
const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
import { FormProvider } from 'providers';
import { useAuthSelector, useGlobalStore } from 'stores';
import { sendGTMData } from 'utils/dataLayerHelper';

const TABLE_HEAD = [
  { key: 'name', label: 'Italiano' },
  { key: 'field_name_en', label: 'Inglese' },
  { key: 'field_name_fr', label: 'Francese' },
  { key: 'field_name_es', label: 'Spagnolo' },
  { key: 'field_name_de', label: 'Tedesco' },
  { key: 'field_name_ru', label: 'Russo' },
  { key: 'field_name_zh', label: 'Cinese' },
];

const charactersCount = 3;

const GlossarySearchBar = ({ openNav }: { openNav: boolean }) => {
  //hooks
  const methods = useForm();
  const i18n = useTranslations();
  const { user } = useAuthSelector();
  const { showLoader, hideLoader } = useGlobalStore();
  const { listEntities } = useJsonApi({ showLoader, hideLoader });
  const {
    glossaryState,
    closeGlossary,
    isExpanded,
    typing,
    setTyping,
    expandGlossary,
    reduceGlossary,
  } = useGlossary();
  const { errorSnackbar } = useSnackbar();
  const theme = useTheme();
  // Mobile Responsive
  const is970Down = useResponsive('down', 970);
  const is882Down = useResponsive('down', 882);
  const isMdDown = useResponsive('down', 768);

  //state
  const [characters, setCharacters] = useState('');
  const debouncedText = useDebounce(characters, 500);
  const [selectedLetter, setSelectedLetter] = useState<string>('A');
  const [glossaryWords, setGlossaryWords] = useState<IGlossaryWord[]>([]);
  const [selectedLetterIndex, setSelectedLetterIndex] = useState<number>();

  useEffect(() => {
    if (isExpanded && selectedLetter) {
      const filters = buildJsonApiSimpleFilter({
        key: 'name',
        value: selectedLetter,
        operator: FilterOperatorEnum.STARTS_WITH,
      });
      searchVocabularies(filters);
    }
  }, [isExpanded, selectedLetter]);

  useEffect(() => {
    if (debouncedText && debouncedText.length >= charactersCount) {
      const filters = buildJsonApiGroupFilter({
        keys: TABLE_HEAD.map(th => th.key),
        values: debouncedText,
        operator: FilterOperatorEnum.CONTAINS,
        conjuction: 'OR',
      });
      const gtmGlossaryData = {
        eventName: 'ricerca_glossario',
        data: {
          glossario: debouncedText,
          username: user?.email,
          ragione_sociale: user?.field_company,
        },
      };
      sendGTMData(gtmGlossaryData);
      searchVocabularies(filters);
    }
  }, [debouncedText]);

  // Search Function
  const searchVocabularies = async (filters: string[][]) => {
    const res = await listEntities({
      useLoader: true,
      body: {
        entity: 'taxonomy_term',
        bundle: 'glossary',
        additionalParams: filters,
        specificResValue: TABLE_HEAD.map(k => k.key),
      },
    });
    if (res) {
      setGlossaryWords(res.map(e => e.attributes) as IGlossaryWord[]);
    } else {
      return errorSnackbar(i18n.general.errors.generic);
    }
  };

  const handleCloseGlossary = () => {
    methods.setValue('searchGlossaryBar', '');
    reduceGlossary();
    closeGlossary();
    setTyping(false);
  };

  return (
    <StyledDrawer
      open={glossaryState}
      anchor={'bottom'}
      variant={'persistent'}
      hideBackdrop={true}
      openNav={openNav}
      expandDrawer={isExpanded}
      typing={typing}
      className="glossary_drawer"
    >
      {isMdDown ? (
        <MobileResultCard
          onClose={handleCloseGlossary}
          methods={methods}
          setTyping={setTyping}
          reduceGlossary={reduceGlossary}
          setGlossaryWords={setGlossaryWords}
          setCharacters={setCharacters}
          isExpanded={isExpanded}
          typing={typing}
          glossaryWords={glossaryWords}
        />
      ) : (
        <>
          <Stack
            sx={{ width: '100%' }}
            alignItems="flex-end"
            justifyContent="flex-start"
          >
            <Button
              size="small"
              color="primary"
              onClick={() => handleCloseGlossary()}
              endIcon={
                <Iconify
                  icon="ic:outline-close"
                  style={{ color: theme.palette.grey[600] }}
                />
              }
              sx={{
                minHeight: '0px',
                minWidth: '0px',
                padding: '0px 10px',
              }}
            >
              Chiudi
            </Button>
          </Stack>

          <MainContainer expandDrawer={isExpanded} typing={typing}>
            <Fade
              in={isExpanded || typing}
              style={{ transitionDelay: isExpanded ? '0.3s' : '0' }}
            >
              <Stack
                flexDirection="row"
                sx={{ width: '100%' }}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {typing || is882Down ? null : (
                  <Stack
                    sx={{
                      width: '60px',
                      height: '100%',
                      overflowY: 'auto',
                    }}
                    alignItems="center"
                    justifyContent="space-evenly"
                    onMouseLeave={() => setSelectedLetterIndex(undefined)}
                  >
                    {letters.map((el: string, index: number) => (
                      <a
                        href={`#${el}`}
                        key={`letter${index}`}
                        style={{ textDecoration: 'none' }}
                      >
                        <Letter
                          active={selectedLetter === el}
                          index={index}
                          hoverIndex={selectedLetterIndex}
                          onMouseEnter={() => setSelectedLetterIndex(index)}
                          onClick={() => setSelectedLetter(el)}
                        >
                          {el}
                        </Letter>
                      </a>
                    ))}
                  </Stack>
                )}

                <ContainerScroll typing={typing}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {TABLE_HEAD.map(
                          (
                            el: { key: string; label: string },
                            index: number
                          ) => (
                            <StyledTableCell
                              key={`head${index}`}
                              style={{
                                fontWeight: 700,
                                ...(is970Down && {
                                  paddingLeft: is882Down ? 2 : 10,
                                  paddingRight: is882Down ? 2 : 10,
                                }),
                              }}
                            >
                              {el.label}
                            </StyledTableCell>
                          )
                        )}
                      </TableRow>
                    </TableHead>

                    <TableBody id={selectedLetter}>
                      {glossaryWords.map((el, index) => (
                        <TableRow key={`cell${index}`}>
                          {TABLE_HEAD.map(th => (
                            <StyledTableCell
                              key={th.key}
                              style={{
                                fontSize: 12,
                                ...(is970Down && {
                                  paddingLeft: is882Down ? 2 : 10,
                                  paddingRight: is882Down ? 2 : 10,
                                }),
                              }}
                            >
                              {el[th.key as keyof IGlossaryWord]}
                            </StyledTableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </ContainerScroll>
              </Stack>
            </Fade>
          </MainContainer>
          <Stack
            flexDirection="column"
            justifyContent="flex-end"
            gap="15px"
            sx={{
              position: 'absolute',
              bottom: '20px',
              width: '97%',
            }}
          >
            <Stack flexDirection="row" alignItems="center" gap="20px">
              {!is882Down ? (
                <StyledButton
                  onClick={() => {
                    setTyping(false);
                    isExpanded ? reduceGlossary() : expandGlossary();
                    methods.setValue('searchGlossaryBar', '');
                  }}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  <Iconify
                    icon="material-symbols:expand-less"
                    style={{
                      fontSize: '25px',
                      transition: 'all 0.3s ease',
                      rotate: isExpanded ? '180deg' : '0deg',
                    }}
                  />
                  A-Z
                </StyledButton>
              ) : null}
              <FormProvider methods={methods} style={{ width: '100%' }}>
                <RHFTextField
                  name="searchGlossaryBar"
                  label="Cerca nel glossario"
                  onKeyUp={event => {
                    if (event.code !== 'Enter') {
                      setTyping(true);
                      reduceGlossary();
                      setGlossaryWords([]);
                    }
                  }}
                  onKeyDown={event => {
                    if (event.code === 'Enter') {
                      event.preventDefault();
                    }
                  }}
                  onBlur={e =>
                    e.target.value.length === 0 ? setTyping(false) : null
                  }
                  onChange={e => setCharacters(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment component="div" position="start">
                        <Iconify
                          icon="ic:baseline-search"
                          style={{ width: '17px' }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment:
                      methods.watch('searchGlossaryBar')?.length > 0 ? (
                        <IconButton
                          onClick={() => {
                            methods.setValue('searchGlossaryBar', '');
                          }}
                        >
                          {' '}
                          <Iconify
                            icon="mdi:close-circle-outline"
                            style={{ width: '17px' }}
                          />
                        </IconButton>
                      ) : null,
                  }}
                />
              </FormProvider>
            </Stack>
          </Stack>
        </>
      )}
    </StyledDrawer>
  );
};

export { GlossarySearchBar };

/**
 * Questo è il filtro per le iniziali::
 */
// ?filter[name][value]=b&filter[name][operator]=STARTS_WITH

/**
 * Questo è il filtro per il glossario generico::
/
/**
?filter[or-group][group][conjunction]=OR
&filter[ita-filter][condition][path]=name
&filter[ita-filter][condition][operator]=CONTAINS
&filter[ita-filter][condition][value]=acia
&filter[ita-filter][condition][memberOf]=or-group
&filter[en-filter][condition][path]=field_name_en
&filter[en-filter][condition][operator]=CONTAINS
&filter[en-filter][condition][value]=acia
&filter[en-filter][condition][memberOf]=or-group
 */
