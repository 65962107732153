import { IntlShape } from 'react-intl';

const htmlParserOptions = {
  br: () => <br />,
  strong: (text: string) => <strong>{text}</strong>,
  a: (text: string) => <a href={`mailto:${text}`}>{text}</a>,
  indicam: (str: string) => (
    <a
      href={'https://www.indicam.it/'}
      target="_blank"
      rel="noreferrer"
      style={{ color: '#637381' }}
    >
      {str}
    </a>
  ),
};

export const intellectualTranslations = (intl: IntlShape) => ({
  intellectual: {
    contact: {
      footerText: intl.formatMessage({
        defaultMessage:
          "Compila il form per richiedere più informazioni in materia di Proprietà Intellettuale all'Ufficio Tutela.",
        description: 'intellectual contact footerText',
      }),
    },
    home: {
      title: intl.formatMessage({
        defaultMessage: 'News in evidenza',
        description: 'intellectual home title',
      }),
      services: intl.formatMessage({
        defaultMessage: 'Servizi di consulenza',
        description: 'intellectual home services',
      }),
      insights: intl.formatMessage({
        defaultMessage: 'Altri approfondimenti',
        description: 'intellectual home insights',
      }),
      contractual_format: intl.formatMessage({
        defaultMessage: 'Format Contrattuale',
        description: 'intellectual home contractual format',
      }),
      convention: {
        title: intl.formatMessage({
          defaultMessage: 'Convenzione Studio Torta',
          description: 'intellectual home convention title',
        }),
        subtitle: intl.formatMessage({
          defaultMessage:
            'Lo Studio Torta è uno dei principali studi di consulenti in Proprietà Intellettuale e fornisce i più alti livelli di assistenza alle imprese nella gestione e nella difesa del proprio portafoglio marchi, design e brevetti.',
          description: 'intellectual home convention subtitle',
        }),
      },
      customReqeust: {
        title: intl.formatMessage({
          defaultMessage: 'Richiedi assistenza personalizzata',
          description: 'intellectual home customReqeust title',
        }),
        subtitle: intl.formatMessage({
          defaultMessage:
            'Possibilità di richiedere una prima consulenza ad avvocati esperti in materia di Proprietà intellettuale.',
          description: 'intellectual home customReqeust subtitle',
        }),
        btn: intl.formatMessage({
          defaultMessage: 'Compila il form',
          description: 'intellectual home customReqeust btn',
        }),
      },
      onDemand: {
        title: intl.formatMessage({
          defaultMessage:
            'DESIGN & PROPRIETÀ INTELLETTUALE | ARCHIVIO CORSI DI FORMAZIONE',
          description: 'intellectual home onDemand title',
        }),
        subtitle: intl.formatMessage({
          defaultMessage:
            'Rivedi tutte le lezioni dei corsi di formazione. Un percorso gratuito in collaborazione con professionisti selezionati.',
          description: 'intellectual home onDemand subtitle',
        }),
        btn: intl.formatMessage({
          defaultMessage: 'Guarda',
          description: 'intellectual home onDemand btn',
        }),
        seeAll: intl.formatMessage({
          defaultMessage: 'Vedi tutte le lezioni disponibili',
          description: 'intellectual home onDemand seeAll',
        }),
        meeting: intl.formatMessage({
          defaultMessage: 'incontri',
          description: 'intellectual home onDemand meeting',
        }),
      },
      newCourse: {
        title: intl.formatMessage({
          defaultMessage:
            'DESIGN & PROPRIETÀ INTELLETTUALE | NUOVO CORSO DI FORMAZIONE',
          description: 'intellectual home newCourse title',
        }),
        subtitle: intl.formatMessage(
          {
            defaultMessage:
              "Un nuovo percorso formativo attraverso lezioni dedicate, che si svolgeranno in modalità ibrida - in presenza o da remoto -, studiato per fornire alle aziende associate gli strumenti teorico-pratici per affrontare, con piena consapevolezza, le sfide e le opportunità poste dalla concorrenza dei mercati internazionali e dai c.d. “market-place”.<br></br><br></br>Il percorso formativo si pone in particolare l'obiettivo di fornire ai partecipanti le competenze per sviluppare e gestire correttamente i diritti di proprietà industriale e intellettuale in un’ottica di costante crescita del valore dell’impresa permettendo altresì di affrontare le sfide della comunicazione, anche in un’ottica di sostenibilità.<br></br><br></br><strong>Il percorso formativo gratuito si compone di quattro incontri, a partire da luglio 2024.</strong><br></br><br></br><strong>Scopri il dettaglio degli argomenti e le date delle lezioni</strong><br></br><br></br>Se vuoi iscriverti, compila il form in pagina o scrivi a <a>tutela@federlegnoarredo</a><br></br><br></br>Resta aggiornato!<br></br><br></br>",
            description: 'intellectual home newCourse subtitle',
          },
          { ...htmlParserOptions }
        ),
        btn: intl.formatMessage({
          defaultMessage: 'Vedi il programma del corso',
          description: 'intellectual home newCourse btn',
        }),
      },
    },
    list: {
      title: intl.formatMessage({
        defaultMessage: 'Approfondimenti e news',
        description: 'intellectual list title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'I diritti di proprietà intellettuale rappresentano uno degli asset principali di un’impresa, essenza stessa del business. Scopri come valorizzare e tutelare le tue idee e prodotti.',
        description: 'intellectual list subtitle',
      }),
      others: intl.formatMessage({
        defaultMessage: 'Altri argomenti che potrebbero interessarti',
        description: 'intellectual list others',
      }),
      contact: {
        title: intl.formatMessage({
          defaultMessage: 'Richiedi più informazioni',
          description: 'intellectual list contact title',
        }),
        subtitle: intl.formatMessage({
          defaultMessage:
            'Compila il form per richiedere più informazioni su questo articolo.',
          description: 'intellectual list contact subtitle',
        }),
      },
      form: {
        results: {
          measures: intl.formatMessage({
            defaultMessage: 'Ultimi aggiornamenti',
            description: 'finance list form results measures',
          }),
          text: intl.formatMessage({
            defaultMessage: 'risultati',
            description: 'intellectual list form results text',
          }),
        },
        search: intl.formatMessage({
          defaultMessage: 'Ricerca contenuto di interesse',
          description: 'intellectual list form results search',
        }),
        asc: intl.formatMessage({
          defaultMessage: 'Ordina per data meno recente',
          description: 'intellectual list form asc',
        }),
        desc: intl.formatMessage({
          defaultMessage: 'Ordina per data più recente',
          description: 'intellectual list form desc',
        }),
      },
    },
    onDemandList: {
      title: intl.formatMessage({
        defaultMessage:
          'DESIGN & PROPRIETÀ INTELLETTUALE | ARCHIVIO CORSI DI FORMAZIONE',
        description: 'intellectual onDemandList title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'Rivedi tutte le lezioni dei corsi di formazione. Un percorso gratuito in collaborazione con professionisti selezionati.',
        description: 'intellectual onDemandList subtitle',
      }),
    },
    onDemandDetail: {
      materialTitle: intl.formatMessage({
        defaultMessage: 'MATERIALE ALLEGATO',
        description: 'finance onDemandDetail materialTitle',
      }),
      prevCourse: intl.formatMessage({
        defaultMessage: 'Incontro precedente',
        description: 'finance onDemandDetail prevCourse',
      }),
      nextCourse: intl.formatMessage({
        defaultMessage: 'Incontro successivo',
        description: 'finance onDemandDetail prevCourse',
      }),
    },
    detail: {
      download: intl.formatMessage({
        defaultMessage: 'Scarica articolo',
        description: 'finance detail download',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'Troverai tutti gli approfondimenti e ultimi aggiornamenti in materia di Prorpietà Intellettuale.',
        description: 'intellectual detail subtitle',
      }),
      highlights: intl.formatMessage({
        defaultMessage: 'INFORMAZIONI IN EVIDENZA',
        description: 'finance detail highlights',
      }),
      measureType: intl.formatMessage({
        defaultMessage: 'Agevolazione di carattere',
        description: 'finance detail measureType',
      }),
      region: intl.formatMessage({
        defaultMessage: 'regionale',
        description: 'finance detail region',
      }),
      national: intl.formatMessage({
        defaultMessage: 'nazionale',
        description: 'finance detail nation',
      }),
    },
  },
});
