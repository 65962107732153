import { FormProvider as Form } from 'react-hook-form';
import { CustomFormProviderProps } from './FormProvider.types';

const FormProvider = ({
  children,
  onSubmit,
  methods,
  onKeyDown,
  blockEvent,
  ...other
}: CustomFormProviderProps) => {
  const handleSubmit: CustomFormProviderProps['onSubmit'] = e => {
    if (blockEvent) {
      e.stopPropagation();
      e.preventDefault();
    }
    onSubmit?.(e);
  };
  return (
    <Form {...methods}>
      <form onSubmit={handleSubmit} noValidate {...other} onKeyDown={onKeyDown}>
        {children}
      </form>
    </Form>
  );
};

export { FormProvider };
