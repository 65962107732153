import { Box, BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IPersonIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function PersonIcon({ color = '#5db0b4', style, ...other }: IPersonIconProps) {
  return (
    <Box {...other}>
      <svg
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill={color}
        style={style}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 3.10965C9.1 3.10965 10 4.17611 10 5.47956C10 6.78301 9.1 7.84946 8 7.84946C6.9 7.84946 6 6.78301 6 5.47956C6 4.17611 6.9 3.10965 8 3.10965ZM8 14.9592C10.7 14.9592 13.8 16.4878 14 17.3291H2C2.23 16.4759 5.31 14.9592 8 14.9592ZM8 0.739746C5.79 0.739746 4 2.86081 4 5.47956C4 8.0983 5.79 10.2194 8 10.2194C10.21 10.2194 12 8.0983 12 5.47956C12 2.86081 10.21 0.739746 8 0.739746ZM8 12.5893C5.33 12.5893 0 14.1771 0 17.3291V19.699H16V17.3291C16 14.1771 10.67 12.5893 8 12.5893Z"
          fill="#919EAB"
        />
      </svg>
    </Box>
  );
}

export default PersonIcon;
