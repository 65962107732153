import { CSSProperties } from 'react';
// @mui

interface IIconProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function FooterbeITIcon({ color = 'white', style }: IIconProps) {
  return (
    <svg
      width="62"
      viewBox="0 0 62 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g clipPath="url(#clip0_1410_5799)">
        <path
          d="M17.2544 32.72H10.625V33.9205H17.2544V32.72Z"
          fill="url(#paint0_linear_1410_5799)"
        />
        <path
          d="M23.8872 32.72H17.2578V33.9205H23.8872V32.72Z"
          fill="url(#paint1_linear_1410_5799)"
        />
        <path
          d="M30.5161 32.72H23.8867V33.9205H30.5161V32.72Z"
          fill="url(#paint2_linear_1410_5799)"
        />
        <path
          d="M10.625 54.9135V45.0264L16.0507 40.9741V54.9109L10.625 54.9135Z"
          fill={color}
        />
        <path
          d="M0.673375 54.2083V54.8951H0.078125V48.3159H0.673375V51.5269H0.691812C0.865646 51.2595 1.09479 51.0577 1.37398 50.9214C1.65317 50.7851 1.94289 50.7169 2.24052 50.7169C2.56185 50.7169 2.85157 50.772 3.10969 50.882C3.36781 50.9921 3.59168 51.1442 3.77342 51.3355C3.95515 51.5269 4.10002 51.7523 4.20274 52.0144C4.30546 52.2765 4.3555 52.557 4.3555 52.8584C4.3555 53.1598 4.30546 53.4403 4.20274 53.7024C4.10002 53.9645 3.95779 54.19 3.77342 54.3813C3.58905 54.5726 3.36781 54.7247 3.10969 54.8348C2.84894 54.9449 2.55921 54.9999 2.24052 54.9999C1.94289 54.9999 1.6558 54.9344 1.37398 54.8007C1.09479 54.667 0.865646 54.4704 0.691812 54.2083H0.673375ZM3.72601 52.8584C3.72601 52.633 3.69177 52.418 3.62065 52.2188C3.54954 52.0196 3.44945 51.844 3.31776 51.6972C3.18607 51.5504 3.0254 51.4325 2.83313 51.346C2.64086 51.2595 2.42225 51.2149 2.1773 51.2149C1.95079 51.2149 1.74009 51.2569 1.54781 51.3407C1.35554 51.4246 1.18961 51.54 1.05002 51.6894C0.910422 51.8388 0.7998 52.0118 0.720785 52.211C0.641769 52.4102 0.602261 52.6277 0.602261 52.8584C0.602261 53.0891 0.641769 53.3066 0.720785 53.5058C0.7998 53.705 0.907788 53.878 1.05002 54.0248C1.19224 54.1716 1.35554 54.2843 1.54781 54.3682C1.74009 54.4521 1.95079 54.494 2.1773 54.494C2.42225 54.494 2.64086 54.4521 2.83313 54.3682C3.0254 54.2843 3.18607 54.169 3.31776 54.0196C3.44945 53.8728 3.54954 53.6972 3.62065 53.498C3.69177 53.2988 3.72601 53.0838 3.72601 52.8584Z"
          fill={color}
        />
        <path
          d="M8.83176 52.7378V52.8688C8.83176 52.916 8.82912 52.9658 8.82386 53.0156H5.43146C5.43672 53.2201 5.47886 53.4114 5.55525 53.5897C5.63163 53.7705 5.73698 53.9252 5.87131 54.0589C6.00563 54.1925 6.16367 54.2974 6.34277 54.376C6.5245 54.4546 6.71941 54.494 6.92748 54.494C7.23564 54.494 7.5043 54.4232 7.72817 54.2817C7.95205 54.1401 8.12325 53.975 8.23914 53.7889L8.68426 54.1375C8.43931 54.4442 8.17066 54.6643 7.87567 54.798C7.58068 54.9317 7.26462 54.9972 6.92748 54.9972C6.62459 54.9972 6.34277 54.9448 6.08465 54.8373C5.8239 54.7299 5.60266 54.5805 5.41565 54.3891C5.22865 54.1978 5.08115 53.9724 4.97317 53.7102C4.86518 53.4481 4.8125 53.165 4.8125 52.8584C4.8125 52.5517 4.86518 52.266 4.97053 52.0065C5.07589 51.7444 5.22075 51.5189 5.40775 51.3276C5.59475 51.1363 5.81336 50.9868 6.06358 50.8794C6.3138 50.7719 6.58508 50.7195 6.87744 50.7195C7.19877 50.7195 7.48059 50.7719 7.72554 50.8794C7.97049 50.9868 8.17329 51.131 8.33659 51.3145C8.49989 51.498 8.62368 51.7103 8.70797 51.9541C8.79225 52.1978 8.83439 52.4599 8.83439 52.7378H8.83176ZM8.22861 52.5464C8.21017 52.1637 8.08638 51.8466 7.85723 51.5923C7.62809 51.3407 7.29886 51.2149 6.87217 51.2149C6.67463 51.2149 6.49026 51.2516 6.3217 51.3224C6.15313 51.3957 6.003 51.4927 5.87658 51.6185C5.74752 51.7444 5.64743 51.8859 5.57105 52.0458C5.49467 52.2057 5.45253 52.3734 5.43936 52.5464H8.22861Z"
          fill={color}
        />
        <path
          d="M17.2622 35.1392V35.1208H10.6328V41.7183H11.2755C11.257 41.5244 11.2465 41.3252 11.2465 41.1259C11.2465 37.818 13.9409 35.1366 17.2648 35.1366L17.2622 35.1392Z"
          fill={color}
        />
        <path
          d="M30.5083 35.1208H23.8789V35.1392C23.8789 35.1392 23.8868 35.1392 23.8921 35.1392C27.216 35.1392 29.9104 37.8207 29.9104 41.1286C29.9104 41.3278 29.8999 41.5244 29.8815 41.721H30.5109V35.1235L30.5083 35.1208Z"
          fill={color}
        />
        <path
          d="M23.8872 35.1208H17.2578V54.9159H23.8872V35.1208Z"
          fill={color}
        />
        <path
          d="M10.6719 0.422119H12.052V6.65002H10.6719V0.422119Z"
          fill={color}
        />
        <path
          d="M15.8853 3.431H14.7185V4.85692C14.7185 4.97487 14.7238 5.08234 14.737 5.1767C14.7475 5.27368 14.7738 5.35756 14.816 5.42833C14.8581 5.4991 14.9213 5.55415 15.0056 5.59084C15.0899 5.63016 15.2032 5.64851 15.3454 5.64851C15.4165 5.64851 15.5087 5.64064 15.6246 5.62754C15.7405 5.61181 15.8274 5.58036 15.8853 5.52531V6.6262C15.7378 6.67863 15.5851 6.71532 15.4244 6.73105C15.2664 6.7494 15.1083 6.75726 14.9556 6.75726C14.7317 6.75726 14.5263 6.73367 14.3366 6.68649C14.147 6.63931 13.9837 6.56592 13.8415 6.46107C13.6992 6.35884 13.5886 6.22517 13.5096 6.06003C13.4306 5.8949 13.3911 5.69569 13.3911 5.46241V3.431H12.543V2.37467H13.3911V1.10864H14.7159V2.37467H15.8827V3.431H15.8853Z"
          fill={color}
        />
        <path
          d="M19.0784 6.1151H19.0599C18.9124 6.34314 18.7175 6.50827 18.4726 6.60788C18.2276 6.70748 17.9695 6.75728 17.6982 6.75728C17.4981 6.75728 17.3058 6.72845 17.1188 6.6734C16.9344 6.61836 16.7685 6.53448 16.6289 6.42177C16.4867 6.31168 16.376 6.17276 16.2918 6.00763C16.2101 5.84249 16.168 5.65377 16.168 5.43621C16.168 5.18982 16.2127 4.98275 16.3049 4.81237C16.3971 4.642 16.5209 4.50046 16.6763 4.39037C16.8317 4.28028 17.0108 4.19378 17.211 4.13087C17.4111 4.07058 17.6192 4.0234 17.8352 3.99457C18.0512 3.96574 18.2645 3.94739 18.4805 3.94215C18.6965 3.9369 18.894 3.93428 19.0784 3.93428C19.0784 3.701 18.9941 3.51227 18.8255 3.37597C18.657 3.23967 18.4594 3.1689 18.2303 3.1689C18.0011 3.1689 17.8141 3.21346 17.6324 3.3052C17.4533 3.39694 17.2926 3.52014 17.1504 3.68003L16.4419 2.95921C16.6895 2.73117 16.9792 2.55817 17.3084 2.44546C17.6377 2.33013 17.9801 2.27246 18.333 2.27246C18.7228 2.27246 19.0415 2.31964 19.2917 2.41663C19.5419 2.51361 19.7421 2.65515 19.8922 2.84388C20.0424 3.0326 20.1477 3.26064 20.2057 3.53324C20.2636 3.80585 20.2952 4.12039 20.2952 4.47949V6.65244H19.0757V6.1151H19.0784ZM18.7518 4.7783C18.6517 4.7783 18.5253 4.78354 18.3751 4.79141C18.225 4.79927 18.0801 4.82548 17.9432 4.86742C17.8036 4.90936 17.6877 4.96964 17.5902 5.05352C17.4928 5.1374 17.4454 5.25011 17.4454 5.3969C17.4454 5.55417 17.5139 5.67212 17.6482 5.74813C17.7825 5.82415 17.9247 5.86346 18.0722 5.86346C18.2013 5.86346 18.3277 5.84512 18.4489 5.81104C18.57 5.77696 18.678 5.72716 18.7702 5.66163C18.865 5.5961 18.9388 5.51485 18.9967 5.41524C19.052 5.31564 19.081 5.19769 19.081 5.06401V4.78354H18.7544L18.7518 4.7783Z"
          fill={color}
        />
        <path d="M20.9688 0H22.2962V6.64991H20.9688V0Z" fill={color} />
        <path
          d="M25.5524 7.34711C25.4628 7.57516 25.3786 7.77699 25.2969 7.95523C25.2153 8.13084 25.1099 8.28025 24.9835 8.3982C24.8571 8.51878 24.6964 8.61052 24.5015 8.6708C24.3066 8.73109 24.0511 8.76255 23.7324 8.76255C23.39 8.76255 23.0608 8.71012 22.7421 8.60528L22.9185 7.52273C23.1187 7.60923 23.3242 7.65379 23.5375 7.65379C23.685 7.65379 23.8061 7.63806 23.901 7.60661C23.9958 7.57516 24.0722 7.52797 24.1354 7.46507C24.1986 7.40216 24.2486 7.32877 24.2908 7.23965C24.3329 7.15053 24.3751 7.0483 24.4225 6.93297L24.5199 6.68658L22.6367 2.37476H24.0695L25.1758 5.19776H25.1942L26.1397 2.37476H27.5014L25.5577 7.34449L25.5524 7.34711Z"
          fill={color}
        />
        <path
          d="M10.7188 10.731C10.7188 10.6209 10.7556 10.5266 10.8346 10.4505C10.911 10.3745 11.0058 10.3352 11.1165 10.3352C11.2271 10.3352 11.3219 10.3745 11.3983 10.4505C11.4747 10.5266 11.5142 10.6209 11.5142 10.731C11.5142 10.8411 11.4747 10.9355 11.3983 11.0115C11.3219 11.0875 11.2271 11.1268 11.1165 11.1268C11.0058 11.1268 10.911 11.0875 10.8346 11.0115C10.7583 10.9355 10.7188 10.8411 10.7188 10.731ZM10.8504 12.3404H11.3798V16.4058H10.8504V12.3404Z"
          fill={color}
        />
        <path
          d="M12.7148 15.368C12.8255 15.5856 12.9914 15.7507 13.21 15.866C13.4286 15.9814 13.6577 16.039 13.9001 16.039C14.0107 16.039 14.1239 16.0207 14.2372 15.9866C14.3478 15.9525 14.4505 15.9027 14.5375 15.8372C14.6244 15.7717 14.6981 15.6957 14.7534 15.6039C14.8087 15.5122 14.8377 15.41 14.8377 15.292C14.8377 15.1033 14.7771 14.9644 14.656 14.87C14.5348 14.7756 14.3873 14.7022 14.2082 14.6498C14.0318 14.5974 13.8369 14.5528 13.6261 14.5135C13.4154 14.4742 13.2205 14.4165 13.0441 14.3379C12.8676 14.2593 12.7175 14.1492 12.5963 14.0076C12.4751 13.8661 12.4146 13.6669 12.4146 13.41C12.4146 13.2108 12.4567 13.0378 12.5384 12.891C12.62 12.7442 12.728 12.6237 12.8623 12.5267C12.994 12.4297 13.1441 12.3589 13.3101 12.3117C13.476 12.2646 13.6419 12.241 13.8131 12.241C14.1503 12.241 14.44 12.3039 14.685 12.4271C14.9299 12.5503 15.1195 12.7442 15.2565 13.0063L14.7798 13.2868C14.6797 13.0981 14.5533 12.9565 14.4031 12.8596C14.253 12.7626 14.0581 12.7154 13.8158 12.7154C13.721 12.7154 13.6235 12.7311 13.5208 12.76C13.4181 12.7888 13.3259 12.8307 13.2416 12.8832C13.16 12.9356 13.0888 13.0037 13.0335 13.085C12.9782 13.1662 12.9492 13.2606 12.9492 13.3654C12.9492 13.5463 13.0098 13.6826 13.131 13.7691C13.2521 13.8582 13.3996 13.9264 13.5787 13.9762C13.7552 14.026 13.9501 14.0679 14.1608 14.1046C14.3742 14.1387 14.5664 14.1964 14.7429 14.2776C14.9194 14.3563 15.0695 14.4716 15.1907 14.621C15.3118 14.7704 15.3724 14.9827 15.3724 15.2579C15.3724 15.4702 15.3329 15.6511 15.2539 15.8084C15.1748 15.963 15.0642 16.0941 14.9273 16.1989C14.7877 16.3038 14.6323 16.385 14.4532 16.4375C14.2767 16.4899 14.0897 16.5161 13.8974 16.5161C13.5445 16.5161 13.2205 16.4479 12.9308 16.3143C12.6384 16.1806 12.4146 15.9709 12.2539 15.6904L12.7227 15.3733L12.7148 15.368Z"
          fill={color}
        />
        <path
          d="M18.4258 15.368C18.5364 15.5856 18.7023 15.7507 18.9209 15.866C19.1395 15.9814 19.3687 16.039 19.611 16.039C19.7216 16.039 19.8349 16.0207 19.9481 15.9866C20.0588 15.9525 20.1615 15.9027 20.2484 15.8372C20.3353 15.7717 20.4091 15.6957 20.4644 15.6039C20.5197 15.5122 20.5487 15.41 20.5487 15.292C20.5487 15.1033 20.4881 14.9644 20.3669 14.87C20.2458 14.7756 20.0983 14.7022 19.9192 14.6498C19.7427 14.5974 19.5478 14.5528 19.3371 14.5135C19.1264 14.4742 18.9315 14.4165 18.755 14.3379C18.5785 14.2593 18.4284 14.1492 18.3072 14.0076C18.1861 13.8661 18.1255 13.6669 18.1255 13.41C18.1255 13.2108 18.1676 13.0378 18.2493 12.891C18.3309 12.7442 18.4389 12.6237 18.5733 12.5267C18.705 12.4297 18.8551 12.3589 19.021 12.3117C19.1869 12.2646 19.3529 12.241 19.5241 12.241C19.8612 12.241 20.1509 12.3039 20.3959 12.4271C20.6408 12.5503 20.8305 12.7442 20.9674 13.0063L20.4907 13.2868C20.3906 13.0981 20.2642 12.9565 20.1141 12.8596C19.9639 12.7626 19.769 12.7154 19.5267 12.7154C19.4319 12.7154 19.3344 12.7311 19.2317 12.76C19.129 12.7888 19.0368 12.8307 18.9525 12.8832C18.8709 12.9356 18.7998 13.0037 18.7445 13.085C18.6892 13.1662 18.6602 13.2606 18.6602 13.3654C18.6602 13.5463 18.7208 13.6826 18.8419 13.7691C18.9631 13.8582 19.1106 13.9264 19.2897 13.9762C19.4661 14.026 19.661 14.0679 19.8718 14.1046C20.0851 14.1387 20.2774 14.1964 20.4538 14.2776C20.6303 14.3563 20.7804 14.4716 20.9016 14.621C21.0227 14.7704 21.0833 14.9827 21.0833 15.2579C21.0833 15.4702 21.0438 15.6511 20.9648 15.8084C20.8858 15.963 20.7752 16.0941 20.6382 16.1989C20.4986 16.3038 20.3432 16.385 20.1641 16.4375C19.9876 16.4899 19.8006 16.5161 19.6084 16.5161C19.2554 16.5161 18.9315 16.4479 18.6417 16.3143C18.3494 16.1806 18.1255 15.9709 17.9648 15.6904L18.4337 15.3733L18.4258 15.368Z"
          fill={color}
        />
        <path
          d="M21.9609 10.731C21.9609 10.6209 21.9978 10.5266 22.0768 10.4505C22.1532 10.3745 22.248 10.3352 22.3586 10.3352C22.4693 10.3352 22.5641 10.3745 22.6405 10.4505C22.7169 10.5266 22.7564 10.6209 22.7564 10.731C22.7564 10.8411 22.7169 10.9355 22.6405 11.0115C22.5641 11.0875 22.4693 11.1268 22.3586 11.1268C22.248 11.1268 22.1532 11.0875 22.0768 11.0115C22.0004 10.9355 21.9609 10.8411 21.9609 10.731ZM22.0926 12.3404H22.622V16.4058H22.0926V12.3404Z"
          fill={color}
        />
        <path
          d="M23.9484 13.4859C23.9484 13.4099 23.9458 13.3181 23.9405 13.208C23.9352 13.098 23.93 12.9905 23.9273 12.8778C23.9247 12.7677 23.9194 12.6628 23.9142 12.5659C23.9089 12.4689 23.9062 12.3955 23.9062 12.3404H24.4357C24.4409 12.4925 24.4462 12.6393 24.4488 12.7808C24.4515 12.9223 24.4594 13.0115 24.4699 13.0534H24.4962C24.6016 12.8306 24.7781 12.6393 25.0256 12.4767C25.2732 12.3142 25.5629 12.2356 25.8922 12.2356C26.1635 12.2356 26.411 12.3037 26.6349 12.4427C26.8588 12.5816 27.0195 12.8044 27.1222 13.1163C27.2512 12.8123 27.4435 12.5895 27.7016 12.4479C27.9597 12.3064 28.2284 12.2356 28.5102 12.2356C28.8289 12.2356 29.087 12.2854 29.2872 12.385C29.4874 12.4846 29.6454 12.6157 29.7613 12.7756C29.8772 12.9354 29.9562 13.1242 30.001 13.3339C30.0458 13.5436 30.0668 13.7611 30.0668 13.9839V16.4033H29.5374V14.0101C29.5374 13.845 29.5216 13.6851 29.4926 13.5305C29.4637 13.3758 29.411 13.2369 29.3373 13.1137C29.2635 12.9905 29.1608 12.8935 29.0291 12.8201C28.8974 12.7467 28.7236 12.71 28.5129 12.71C28.2653 12.71 28.0625 12.7546 27.9018 12.8463C27.7438 12.9381 27.6173 13.0534 27.5252 13.1949C27.433 13.3365 27.3698 13.4885 27.3303 13.6536C27.2907 13.8188 27.2723 13.9734 27.2723 14.1202V16.4085H26.7429V13.9629C26.7429 13.5881 26.6744 13.2867 26.5348 13.056C26.3952 12.828 26.1819 12.7126 25.8948 12.7126C25.6999 12.7126 25.5155 12.7467 25.3417 12.8149C25.1679 12.883 25.0177 12.9853 24.8913 13.1216C24.7649 13.2605 24.6648 13.4335 24.5911 13.6405C24.5173 13.8476 24.4804 14.094 24.4804 14.3745V16.4059H23.951V13.4859H23.9484Z"
          fill={color}
        />
        <path
          d="M31.1914 12.3405H31.7208V13.0535H31.7392C31.8209 12.9119 31.9236 12.7913 32.0474 12.6891C32.1712 12.5869 32.3055 12.5004 32.4504 12.4349C32.5952 12.3667 32.7454 12.3169 32.906 12.2855C33.0641 12.254 33.2221 12.2383 33.3749 12.2383C33.6883 12.2383 33.9754 12.2907 34.2361 12.3956C34.4995 12.5004 34.726 12.6498 34.9157 12.8385C35.1079 13.0299 35.2554 13.2553 35.3634 13.5148C35.4688 13.7769 35.5215 14.0626 35.5215 14.3719C35.5215 14.6812 35.4688 14.9695 35.3634 15.229C35.2581 15.4912 35.1079 15.7166 34.9157 15.9053C34.7234 16.094 34.4969 16.2434 34.2361 16.3509C33.9728 16.4557 33.6857 16.5082 33.3749 16.5082C33.2221 16.5082 33.0667 16.4924 32.906 16.461C32.748 16.4295 32.5952 16.3797 32.4504 16.3116C32.3055 16.2434 32.1712 16.1596 32.0474 16.0573C31.9236 15.9551 31.8209 15.8319 31.7392 15.693H31.7208V18.4111H31.1914V12.3405ZM31.6681 14.3719C31.6681 14.6 31.7129 14.8149 31.7998 15.0193C31.8894 15.2212 32.0079 15.3968 32.1633 15.5462C32.3187 15.6956 32.4952 15.8136 32.7032 15.9027C32.9087 15.9918 33.1326 16.0337 33.3749 16.0337C33.6172 16.0337 33.8463 15.9892 34.0465 15.9027C34.2467 15.8136 34.4179 15.6956 34.5601 15.5462C34.7023 15.3968 34.8103 15.2212 34.8841 15.0193C34.9578 14.8175 34.9947 14.6026 34.9947 14.3719C34.9947 14.1413 34.9578 13.9263 34.8841 13.7245C34.8103 13.5227 34.7023 13.347 34.5601 13.1976C34.4179 13.0482 34.2467 12.9303 34.0465 12.8412C33.8463 12.752 33.6225 12.7101 33.3749 12.7101C33.1273 12.7101 32.9087 12.7547 32.7032 12.8412C32.4978 12.9276 32.3161 13.0482 32.1633 13.1976C32.0105 13.347 31.8894 13.5227 31.7998 13.7245C31.7103 13.9263 31.6681 14.1413 31.6681 14.3719Z"
          fill={color}
        />
        <path d="M36.6172 9.7561H37.1466V16.406H36.6172V9.7561Z" fill={color} />
        <path
          d="M39.6249 17.664C39.5248 17.9287 39.3852 18.1358 39.2087 18.2878C39.0323 18.4399 38.7952 18.5159 38.5029 18.5159C38.4133 18.5159 38.3264 18.508 38.2368 18.4949C38.1473 18.4792 38.063 18.4608 37.9814 18.4372L38.034 17.9445C38.1104 17.968 38.1894 17.989 38.2684 18.01C38.3475 18.031 38.4291 18.0414 38.5108 18.0414C38.6056 18.0414 38.6899 18.0205 38.7636 17.9785C38.8374 17.9366 38.9006 17.8842 38.9532 17.8213C39.0059 17.7557 39.0533 17.685 39.0902 17.6063C39.1297 17.5277 39.1613 17.4464 39.1929 17.3652L39.5643 16.4242L37.9023 12.343H38.495L39.8488 15.7663L41.1657 12.343H41.732L39.6275 17.6666L39.6249 17.664Z"
          fill={color}
        />
        <path
          d="M14.3674 25.3966C14.162 25.7085 13.9065 25.9313 13.6036 26.065C13.3007 26.1987 12.9662 26.2668 12.6001 26.2668C12.2867 26.2668 12.0048 26.2144 11.752 26.1096C11.4991 26.0047 11.2805 25.8553 11.0988 25.664C10.9171 25.4726 10.7748 25.2472 10.68 24.9877C10.5826 24.7256 10.5352 24.4372 10.5352 24.1201C10.5352 23.8029 10.5852 23.5329 10.6853 23.2761C10.7854 23.0192 10.9276 22.7938 11.1093 22.6024C11.2911 22.4111 11.507 22.2617 11.7546 22.1542C12.0022 22.0467 12.2761 21.9917 12.5764 21.9917C12.8767 21.9917 13.1637 22.0467 13.4034 22.1542C13.6431 22.2617 13.8433 22.4058 14.0039 22.5841C14.1672 22.7623 14.2884 22.9668 14.37 23.1948C14.4517 23.4229 14.4938 23.6561 14.4938 23.8894V24.259H11.0646C11.0646 24.3717 11.0856 24.5133 11.1304 24.6863C11.1752 24.8593 11.2542 25.027 11.3727 25.1921C11.4912 25.3573 11.6493 25.4962 11.8468 25.6142C12.0444 25.7321 12.2998 25.7898 12.6106 25.7898C12.8819 25.7898 13.1427 25.7242 13.3982 25.5958C13.651 25.4674 13.8459 25.2918 13.9829 25.069L14.3727 25.394L14.3674 25.3966ZM13.9618 23.7872C13.9618 23.6063 13.9249 23.436 13.8512 23.2761C13.7774 23.1188 13.6773 22.9773 13.5509 22.8593C13.4245 22.7387 13.2744 22.6444 13.1005 22.5736C12.9267 22.5028 12.7423 22.4688 12.5474 22.4688C12.2577 22.4688 12.018 22.5264 11.8257 22.6444C11.6335 22.7623 11.4833 22.896 11.3701 23.048C11.2568 23.2001 11.1778 23.3495 11.1304 23.4936C11.083 23.6378 11.0593 23.7348 11.0593 23.7872H13.9592H13.9618Z"
          fill={color}
        />
        <path
          d="M16.5439 23.9627L15.0847 22.0964H15.7642L16.8968 23.6351L18.0188 22.0964H18.6825L17.2418 23.9627L18.9933 26.1619H18.3033L16.881 24.3244L15.485 26.1619H14.8477L16.5439 23.9627Z"
          fill={color}
        />
        <path
          d="M21.5721 22.5709H20.4053V25.2183C20.4053 25.3991 20.4553 25.5407 20.5554 25.6403C20.6555 25.7399 20.7951 25.7897 20.9716 25.7897C21.0717 25.7897 21.1744 25.7766 21.2771 25.7504C21.3798 25.7242 21.4799 25.6901 21.5721 25.6482L21.6169 26.1226C21.5168 26.1567 21.4009 26.1907 21.2718 26.2196C21.1428 26.2484 21.0269 26.2641 20.9268 26.2641C20.7372 26.2641 20.5765 26.2353 20.4395 26.1802C20.3026 26.1252 20.1946 26.0492 20.1129 25.9548C20.0313 25.8605 19.9707 25.7504 19.9312 25.6246C19.8917 25.4987 19.8733 25.3624 19.8733 25.2157V22.5683H18.9883V22.0938H19.8733V20.9248H20.4027V22.0938H21.5695V22.5683L21.5721 22.5709Z"
          fill={color}
        />
        <path
          d="M22.339 23.242C22.339 23.166 22.3364 23.0742 22.3311 22.9642C22.3258 22.8541 22.3206 22.7466 22.3179 22.6339C22.3153 22.5238 22.31 22.419 22.3048 22.322C22.2995 22.225 22.2969 22.1516 22.2969 22.0965H22.8263C22.8315 22.2486 22.8368 22.3954 22.8394 22.5369C22.8421 22.6784 22.85 22.7676 22.8605 22.8095C22.9948 22.5684 23.1687 22.3718 23.382 22.2197C23.5954 22.0677 23.8535 21.9917 24.159 21.9917C24.2117 21.9917 24.2644 21.9969 24.3144 22.0048C24.3644 22.0127 24.4171 22.0232 24.4698 22.0363L24.4092 22.5553C24.3381 22.5317 24.2696 22.5212 24.2064 22.5212C23.9773 22.5212 23.7771 22.5579 23.6085 22.6313C23.44 22.7047 23.303 22.8069 23.1924 22.9353C23.0818 23.0638 23.0027 23.2158 22.9501 23.3888C22.8974 23.5618 22.8711 23.7479 22.8711 23.9471V26.1646H22.3416V23.2446L22.339 23.242Z"
          fill={color}
        />
        <path
          d="M28.2814 24.4556C28.2814 24.6548 28.2814 24.833 28.2867 24.9929C28.2893 25.1502 28.2946 25.297 28.2998 25.4333C28.3051 25.5696 28.313 25.6954 28.3209 25.8107C28.3288 25.9287 28.342 26.0466 28.3604 26.162H27.8652C27.831 25.9628 27.8126 25.7426 27.8126 25.5014H27.7941C27.6414 25.7662 27.4517 25.9602 27.2226 26.0833C26.9961 26.2065 26.709 26.2668 26.3613 26.2668C26.1849 26.2668 26.011 26.2459 25.8398 26.2013C25.6686 26.1567 25.5159 26.086 25.3789 25.989C25.2419 25.892 25.1339 25.7688 25.047 25.6142C24.9627 25.4621 24.918 25.276 24.918 25.0611C24.918 24.7492 24.997 24.5028 25.1577 24.3219C25.3157 24.1411 25.5132 24.0048 25.745 23.913C25.9768 23.8213 26.2244 23.7636 26.4877 23.7374C26.7511 23.7112 26.9803 23.6981 27.1804 23.6981H27.7994V23.4517C27.7994 23.1005 27.6914 22.8488 27.4728 22.6942C27.2542 22.5395 26.9803 22.4661 26.651 22.4661C26.1796 22.4661 25.7634 22.6182 25.4052 22.9248L25.0971 22.5631C25.292 22.3744 25.5343 22.2328 25.8214 22.1359C26.1085 22.0389 26.3877 21.9917 26.6537 21.9917C27.1488 21.9917 27.5439 22.107 27.8389 22.3403C28.1339 22.5736 28.2814 22.9458 28.2814 23.4622V24.4556ZM27.22 24.1201C26.9961 24.1201 26.7801 24.1358 26.5694 24.1646C26.3613 24.1935 26.1717 24.2433 26.0031 24.3141C25.8345 24.3848 25.7002 24.4792 25.6001 24.5945C25.5001 24.7125 25.45 24.8593 25.45 25.0349C25.45 25.1581 25.479 25.2682 25.5343 25.3651C25.5896 25.4621 25.6633 25.5408 25.7503 25.6037C25.8398 25.664 25.9373 25.7111 26.0453 25.7452C26.1532 25.7767 26.2639 25.795 26.3771 25.795C26.6589 25.795 26.8934 25.7531 27.0751 25.6718C27.2568 25.5906 27.4043 25.4831 27.5123 25.352C27.6203 25.221 27.6967 25.0716 27.7388 24.9038C27.781 24.7361 27.802 24.5683 27.802 24.3979V24.1253H27.22V24.1201Z"
          fill={color}
        />
        <path
          d="M29.2891 24.1278C29.2891 23.8159 29.3417 23.5301 29.4471 23.2706C29.5524 23.0085 29.6999 22.7831 29.8896 22.5944C30.0792 22.403 30.3057 22.2563 30.5691 22.1514C30.8351 22.0466 31.1222 21.9941 31.4357 21.9941C31.7491 21.9941 32.0362 22.0466 32.2969 22.1514C32.5603 22.2563 32.7868 22.4057 32.9765 22.5944C33.1687 22.7857 33.3162 23.0112 33.4216 23.2706C33.5269 23.5328 33.5796 23.8185 33.5796 24.1278C33.5796 24.4371 33.5269 24.7254 33.4216 24.9849C33.3162 25.247 33.1661 25.4724 32.9765 25.6612C32.7868 25.8499 32.5577 25.9993 32.2969 26.1068C32.0335 26.2116 31.7464 26.264 31.4357 26.264C31.1249 26.264 30.8351 26.2116 30.5691 26.1068C30.3031 26.0019 30.0766 25.8525 29.8896 25.6612C29.6999 25.4698 29.5524 25.2444 29.4471 24.9849C29.3417 24.7228 29.2891 24.4371 29.2891 24.1278ZM29.8185 24.1278C29.8185 24.3558 29.8553 24.5707 29.9291 24.7752C30.0028 24.977 30.1108 25.1526 30.2504 25.3021C30.3926 25.4515 30.5638 25.5694 30.764 25.6585C30.9642 25.7477 31.1881 25.7896 31.4357 25.7896C31.6832 25.7896 31.9071 25.745 32.1073 25.6585C32.3075 25.5694 32.4787 25.4515 32.6209 25.3021C32.7631 25.1526 32.8711 24.977 32.9422 24.7752C33.016 24.5734 33.0528 24.3584 33.0528 24.1278C33.0528 23.8971 33.016 23.6822 32.9422 23.4803C32.8685 23.2785 32.7605 23.1029 32.6209 22.9535C32.4787 22.8041 32.3075 22.6861 32.1073 22.597C31.9071 22.5079 31.6832 22.4659 31.4357 22.4659C31.1881 22.4659 30.9642 22.5105 30.764 22.597C30.5638 22.6835 30.3926 22.8041 30.2504 22.9535C30.1082 23.1029 30.0002 23.2785 29.9291 23.4803C29.8553 23.6822 29.8185 23.8971 29.8185 24.1278Z"
          fill={color}
        />
        <path
          d="M34.5929 23.242C34.5929 23.166 34.5903 23.0742 34.585 22.9642C34.5798 22.8541 34.5745 22.7466 34.5719 22.6339C34.5692 22.5238 34.564 22.419 34.5587 22.322C34.5534 22.225 34.5508 22.1516 34.5508 22.0965H35.0802C35.0855 22.2486 35.0907 22.3954 35.0934 22.5369C35.096 22.6784 35.1039 22.7676 35.1144 22.8095C35.2488 22.5684 35.4226 22.3718 35.6359 22.2197C35.8493 22.0677 36.1074 21.9917 36.4129 21.9917C36.4656 21.9917 36.5183 21.9969 36.5683 22.0048C36.6184 22.0127 36.671 22.0232 36.7237 22.0363L36.6631 22.5553C36.592 22.5317 36.5235 22.5212 36.4603 22.5212C36.2312 22.5212 36.031 22.5579 35.8624 22.6313C35.6939 22.7047 35.5569 22.8069 35.4463 22.9353C35.3357 23.0638 35.2567 23.2158 35.204 23.3888C35.1513 23.5618 35.125 23.7479 35.125 23.9471V26.1646H34.5956V23.2446L34.5929 23.242Z"
          fill={color}
        />
        <path
          d="M40.7733 25.4488H40.7549C40.6732 25.5904 40.5679 25.711 40.4467 25.8132C40.3229 25.9154 40.1886 26.0019 40.0437 26.0674C39.8989 26.1356 39.7487 26.1854 39.5881 26.2168C39.4274 26.2483 39.272 26.264 39.1192 26.264C38.8058 26.264 38.5187 26.2116 38.2527 26.1068C37.9867 26.0019 37.7602 25.8525 37.5732 25.6612C37.3835 25.4698 37.236 25.2444 37.1307 24.9849C37.0253 24.7228 36.9727 24.4371 36.9727 24.1278C36.9727 23.8185 37.0253 23.5302 37.1307 23.2707C37.236 23.0085 37.3835 22.7831 37.5732 22.5944C37.7628 22.403 37.9893 22.2563 38.2527 22.1514C38.5187 22.0466 38.8058 21.9941 39.1192 21.9941C39.272 21.9941 39.4274 22.0099 39.5881 22.0413C39.7461 22.0728 39.8989 22.1226 40.0437 22.1907C40.1886 22.2589 40.3229 22.3428 40.4467 22.445C40.5705 22.5472 40.6732 22.6704 40.7549 22.8093H40.7733V19.5093H41.3027V26.1592H40.7733V25.4462V25.4488ZM40.826 24.1304C40.826 23.9024 40.7812 23.6848 40.6943 23.483C40.6047 23.2811 40.4862 23.1055 40.3308 22.9561C40.1781 22.8067 39.999 22.6888 39.7909 22.5996C39.5854 22.5105 39.3616 22.4686 39.1192 22.4686C38.8769 22.4686 38.6478 22.5131 38.4476 22.5996C38.2474 22.6861 38.0762 22.8067 37.934 22.9561C37.7918 23.1055 37.6838 23.2811 37.6127 23.483C37.5389 23.6848 37.5021 23.8997 37.5021 24.1304C37.5021 24.3611 37.5389 24.5734 37.6127 24.7778C37.6864 24.9797 37.7944 25.1553 37.934 25.3047C38.0736 25.4541 38.2474 25.572 38.4476 25.6612C38.6478 25.7503 38.8717 25.7922 39.1192 25.7922C39.3668 25.7922 39.5854 25.7477 39.7909 25.6612C39.9963 25.572 40.1754 25.4541 40.3308 25.3047C40.4862 25.1553 40.6047 24.9797 40.6943 24.7778C40.7838 24.576 40.826 24.3611 40.826 24.1304Z"
          fill={color}
        />
        <path
          d="M42.3906 20.4871C42.3906 20.377 42.4275 20.2827 42.5065 20.2066C42.5829 20.1306 42.6777 20.0913 42.7883 20.0913C42.899 20.0913 42.9938 20.1306 43.0702 20.2066C43.1465 20.2827 43.186 20.377 43.186 20.4871C43.186 20.5972 43.1465 20.6916 43.0702 20.7676C42.9938 20.8436 42.899 20.8829 42.7883 20.8829C42.6777 20.8829 42.5829 20.8436 42.5065 20.7676C42.4301 20.6916 42.3906 20.5972 42.3906 20.4871ZM42.5223 22.0965H43.0517V26.1619H42.5223V22.0965Z"
          fill={color}
        />
        <path
          d="M44.3781 23.242C44.3781 23.166 44.3754 23.0742 44.3702 22.9642C44.3649 22.8541 44.3596 22.7466 44.357 22.6339C44.3544 22.5238 44.3491 22.419 44.3438 22.322C44.3386 22.225 44.3359 22.1516 44.3359 22.0965H44.8653C44.8706 22.2486 44.8759 22.3954 44.8785 22.5369C44.8811 22.6784 44.889 22.7676 44.8996 22.8095H44.9259C45.0313 22.5867 45.2077 22.3954 45.4553 22.2328C45.7029 22.0703 45.9926 21.9917 46.3219 21.9917C46.6511 21.9917 46.8987 22.0415 47.0988 22.1411C47.299 22.2407 47.4571 22.3718 47.5729 22.5317C47.6888 22.6916 47.7678 22.8803 47.8126 23.09C47.8574 23.2997 47.8785 23.5172 47.8785 23.74V26.1594H47.3491V23.7662C47.3491 23.6011 47.3333 23.4412 47.3043 23.2866C47.2753 23.1319 47.2226 22.993 47.1489 22.8698C47.0751 22.7466 46.9724 22.6496 46.8407 22.5762C46.709 22.5028 46.5352 22.4661 46.3245 22.4661C46.1296 22.4661 45.9452 22.5002 45.7714 22.5684C45.5976 22.6365 45.4474 22.7387 45.321 22.875C45.1946 23.014 45.0945 23.187 45.0207 23.394C44.947 23.6011 44.9101 23.8475 44.9101 24.128V26.1594H44.3807V23.2394L44.3781 23.242Z"
          fill={color}
        />
        <path
          d="M52.2892 24.4556C52.2892 24.6548 52.2892 24.833 52.2945 24.9929C52.2971 25.1502 52.3024 25.297 52.3076 25.4333C52.3129 25.5696 52.3208 25.6954 52.3287 25.8107C52.3366 25.9287 52.3498 26.0466 52.3682 26.162H51.8731C51.8388 25.9628 51.8204 25.7426 51.8204 25.5014H51.8019C51.6492 25.7662 51.4595 25.9602 51.2304 26.0833C51.0039 26.2065 50.7168 26.2668 50.3691 26.2668C50.1927 26.2668 50.0188 26.2459 49.8476 26.2013C49.6764 26.1567 49.5237 26.086 49.3867 25.989C49.2497 25.892 49.1418 25.7688 49.0548 25.6142C48.9706 25.4621 48.9258 25.276 48.9258 25.0611C48.9258 24.7492 49.0048 24.5028 49.1655 24.3219C49.3235 24.1411 49.521 24.0048 49.7528 23.913C49.9846 23.8213 50.2322 23.7636 50.4956 23.7374C50.7589 23.7112 50.9881 23.6981 51.1883 23.6981H51.8072V23.4517C51.8072 23.1005 51.6992 22.8488 51.4806 22.6942C51.262 22.5395 50.9881 22.4661 50.6589 22.4661C50.1874 22.4661 49.7712 22.6182 49.413 22.9248L49.1049 22.5631C49.2998 22.3744 49.5421 22.2328 49.8292 22.1359C50.1163 22.0389 50.3955 21.9917 50.6615 21.9917C51.1567 21.9917 51.5517 22.107 51.8467 22.3403C52.1417 22.5736 52.2892 22.9458 52.2892 23.4622V24.4556ZM51.2278 24.1201C51.0039 24.1201 50.7879 24.1358 50.5772 24.1646C50.3691 24.1935 50.1795 24.2433 50.0109 24.3141C49.8424 24.3848 49.708 24.4792 49.6079 24.5945C49.5079 24.7125 49.4578 24.8593 49.4578 25.0349C49.4578 25.1581 49.4868 25.2682 49.5421 25.3651C49.5974 25.4621 49.6712 25.5408 49.7581 25.6037C49.8476 25.664 49.9451 25.7111 50.0531 25.7452C50.1611 25.7767 50.2717 25.795 50.3849 25.795C50.6668 25.795 50.9012 25.7531 51.0829 25.6718C51.2646 25.5906 51.4121 25.4831 51.5201 25.352C51.6281 25.221 51.7045 25.0716 51.7466 24.9038C51.7888 24.7361 51.8098 24.5683 51.8098 24.3979V24.1253H51.2278V24.1201Z"
          fill={color}
        />
        <path
          d="M53.5265 23.242C53.5265 23.166 53.5239 23.0742 53.5186 22.9642C53.5133 22.8541 53.5081 22.7466 53.5054 22.6339C53.5028 22.5238 53.4975 22.419 53.4923 22.322C53.487 22.225 53.4844 22.1516 53.4844 22.0965H54.0138C54.019 22.2486 54.0243 22.3954 54.0269 22.5369C54.0296 22.6784 54.0375 22.7676 54.048 22.8095C54.1823 22.5684 54.3562 22.3718 54.5695 22.2197C54.7829 22.0677 55.041 21.9917 55.3465 21.9917C55.3992 21.9917 55.4519 21.9969 55.5019 22.0048C55.5519 22.0127 55.6046 22.0232 55.6573 22.0363L55.5967 22.5553C55.5256 22.5317 55.4571 22.5212 55.3939 22.5212C55.1648 22.5212 54.9646 22.5579 54.796 22.6313C54.6275 22.7047 54.4905 22.8069 54.3799 22.9353C54.2693 23.0638 54.1902 23.2158 54.1376 23.3888C54.0849 23.5618 54.0586 23.7479 54.0586 23.9471V26.1646H53.5291V23.2446L53.5265 23.242Z"
          fill={color}
        />
        <path
          d="M57.4452 27.4201C57.3451 27.6848 57.2055 27.8919 57.029 28.0439C56.8526 28.196 56.6155 28.272 56.3232 28.272C56.2336 28.272 56.1467 28.2641 56.0572 28.251C55.9676 28.2353 55.8833 28.2169 55.8017 28.1933L55.8543 27.7006C55.9307 27.7242 56.0097 27.7451 56.0888 27.7661C56.1678 27.7871 56.2494 27.7975 56.3311 27.7975C56.4259 27.7975 56.5102 27.7766 56.5839 27.7346C56.6577 27.6927 56.7209 27.6403 56.7736 27.5774C56.8262 27.5118 56.8737 27.4411 56.9105 27.3624C56.95 27.2838 56.9816 27.2025 57.0132 27.1213L57.3846 26.1803L55.7227 22.0991H56.3153L57.6691 25.5224L58.986 22.0991H59.5523L57.4478 27.4227L57.4452 27.4201Z"
          fill={color}
        />
        <path
          d="M60.2266 22.466C60.2266 22.348 60.2687 22.2484 60.3504 22.1671C60.432 22.0859 60.5321 22.0439 60.6506 22.0439C60.7691 22.0439 60.8692 22.0859 60.9509 22.1671C61.0325 22.2484 61.0747 22.348 61.0747 22.466C61.0747 22.5839 61.0325 22.6835 60.9509 22.7648C60.8692 22.846 60.7691 22.888 60.6506 22.888C60.5321 22.888 60.432 22.846 60.3504 22.7648C60.2687 22.6835 60.2266 22.5839 60.2266 22.466ZM60.2266 25.7922C60.2266 25.6743 60.2687 25.5747 60.3504 25.4934C60.432 25.4121 60.5321 25.3702 60.6506 25.3702C60.7691 25.3702 60.8692 25.4121 60.9509 25.4934C61.0325 25.5747 61.0747 25.6743 61.0747 25.7922C61.0747 25.9102 61.0325 26.0098 60.9509 26.091C60.8692 26.1723 60.7691 26.2142 60.6506 26.2142C60.5321 26.2142 60.432 26.1723 60.3504 26.091C60.2687 26.0098 60.2266 25.9102 60.2266 25.7922Z"
          fill={color}
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1410_5799"
          x1="10.6118"
          y1="32.7881"
          x2="17.2133"
          y2="33.8544"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3EAA37" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1410_5799"
          x1="17.2525"
          y1="32.7462"
          x2="23.8511"
          y2="33.8998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#F4FAFE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1410_5799"
          x1="23.9025"
          y1="32.7934"
          x2="30.5013"
          y2="33.8597"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E95045" />
          <stop offset="1" stopColor="#CC2428" />
        </linearGradient>
        <clipPath id="clip0_1410_5799">
          <rect
            width="61"
            height="55"
            fill={color}
            transform="translate(0.078125)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FooterbeITIcon;
