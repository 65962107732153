import { FormHelperText, ListItemText, MenuItem, styled } from '@mui/material';
import { TextField, Select, Checkbox } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomSelectProps } from './RHFSelect.type';

const StyledMenuItem = styled(MenuItem)({
  '&.Mui-disabled': {
    opacity: '1 !important',
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const RHFSelect = ({
  name,
  label,
  options,
  rules,
  onChange,
  className,
  ...other
}: CustomSelectProps) => {
  //hooks
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <TextField
          select
          fullWidth
          variant="outlined"
          label={label}
          {...field}
          error={!!error}
          helperText={error?.message}
          {...other}
          onChange={e => {
            field.onChange(e.target.value);
            onChange?.(e);
          }}
        >
          {options?.map((el, index) => (
            <MenuItem
              key={`options${index}`}
              className={className}
              value={el.value}
            >
              {el.label}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};

const RHFCheckmarksSelect = ({
  name,
  label,
  options,
  rules,
  color,
}: CustomSelectProps) => {
  //hooks
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <>
          <Select
            style={{ width: '100%' }}
            multiple
            label={label}
            {...field}
            displayEmpty={true}
            error={!!error}
            renderValue={(selected: string[]) =>
              options
                ?.filter(option => selected.find(id => id === option.value))
                .map(o => o.label)
                .join(', ') || ''
            }
            onChange={e => {
              field.onChange(e.target.value);
            }}
            className={'selectCustom'}
            MenuProps={{ ...MenuProps, variant: 'selectedMenu' }}
          >
            {options?.map(option => (
              <StyledMenuItem
                key={option.value}
                value={option.value}
                disabled={!option.value}
              >
                {option.value ? (
                  <Checkbox
                    checked={field.value.includes(option.value)}
                    color={color || 'primary'}
                  />
                ) : null}
                <ListItemText primary={option.label} />
              </StyledMenuItem>
            ))}
          </Select>
          {error && (
            <FormHelperText style={{ color: '#FF0000', marginLeft: 14 }}>
              {error?.message}
            </FormHelperText>
          )}
        </>
      )}
    />
  );
};

export { RHFSelect, RHFCheckmarksSelect };
