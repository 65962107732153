import { IntlShape } from 'react-intl';

export const CustomizeQuestions = {
  ['question_A']:
    "A. L'organizzazione utilizza <click id={1}>modelli o meccanismi</click> di <click id={2}>simbiosi industriale</click>?",
  ['question_B']:
    "B. L'organizzazione monitora i requisiti ambientali dei propri fornitori e/o considera la distanza di approvvigionamento per le <click id={3}>materie prime</click> e i <click id={4}>componenti</click>?",
  ['question_C']:
    "C. L'organizzazione ha effettuato investimenti in <click id={5}>progettazione circolare</click>? (dei prodotti, dei servizi, degli asset aziendali).",
  ['question_D']:
    'D. La vostra organizzazione ha impianti di autoproduzione di energia termica e/o elettrica?',
  ['question_E']:
    'E. La vostra azienda ha svolto un calcolo della <click id={6}>Carbon Footprint</click>? su organizzazione e/o prodotti, e/o acquista e/o vende prodotti corredati da una <click id={6}>Carbon FootPrint</click> e/o <click id={7}>EPD</click>?',
  ['question_F']:
    'F. Nella vostra organizzazione ci sono policy per la <click id={8}>mobilità</click> dei dipendenti, per ottimizzare carico e tragitto del trasporto prodotti e/o per la cessione dei beni dismessi?',
  ['question_G']:
    'G. La vostra organizzazione commercializza prodotti riparati, offre servizi di riparazione, <click id={9}>rigenerazione</click> e/o fornisce istruzioni per la riparazione dei propri prodotti?',
  ['question_H']:
    "H. La vostra azienda ha effettuato e/o effettua attività di formazione per il personale sui temi dell'<click id={10}>economia circolare</click>?",
};

export const customizeModalsExplanations = (intl: IntlShape) => ({
  1: {
    title: intl.formatMessage({
      defaultMessage: 'Modello',
      description: 'homepage modalsExplanations 1 title',
    }),
    body: intl.formatMessage({
      defaultMessage:
        'Schema teorico che rappresenta i soli elementi fondamentali di un sistema o li rapporta a situazioni reali al fine di spiegarne il funzionamento.',
      description: 'homepage modalsExplanations 1 body',
    }),
  },
  2: {
    title: intl.formatMessage({
      defaultMessage: 'Simbiosi industriale',
      description: 'homepage modalsExplanations 2 title',
    }),
    body: intl.formatMessage({
      defaultMessage:
        "Parte dell'ecologia industriale che coinvolge industrie tradizionalmente separate in un collettivo approccio al vantaggio competitivo che implica il trasferimento e/o condivisione di materiali, energia, acqua e sottoprodotti, e/o servizi,capacità e competenze in maniera tale che le risorse in esubero o di residuo in output di una organizzazione diventino input per una o più organizzazioni. Per simbiosi Industriale si intende quindi il trasferimento e/o condivisione di risorse, tra due o più organizzazioni.",
      description: 'homepage modalsExplanations 2 body',
    }),
  },
  3: {
    title: intl.formatMessage({
      defaultMessage: 'Materia prima',
      description: 'homepage modalsExplanations 3 title',
    }),
    body: intl.formatMessage({
      defaultMessage:
        'Materiale fornito dallo sfruttamento delle risorse naturali e destinato a successive lavorazioni per la produzione di beni.',
      description: 'homepage modalsExplanations 3 body',
    }),
  },
  4: {
    title: intl.formatMessage({
      defaultMessage: 'Componente',
      description: 'homepage modalsExplanations 4 title',
    }),
    body: intl.formatMessage({
      defaultMessage:
        'Parte di un prodotto, identificabile univocamente, realizzata nello stesso sito produttivo o in un diverso sito produttivo rispetto al prodotto finito.',
      description: 'homepage modalsExplanations 4 body',
    }),
  },
  5: {
    title: intl.formatMessage({
      defaultMessage: 'Progettazione circolare',
      description: 'homepage modalsExplanations 5 title',
    }),
    body: intl.formatMessage({
      defaultMessage:
        'Approccio che integra i principi dell’economia circolare all’interno della concezione, progettazione e sviluppo del prodotto, processo e servizio (ad esempio: progettazione di prodotti che possono essere disassemblati, rigenerati, riparati o atti a mantenere il più alto valore delle risorse).',
      description: 'homepage modalsExplanations 5 body',
    }),
  },
  6: {
    title: intl.formatMessage({
      defaultMessage:
        'Carbon footprint (impronta climatica) di un prodotto (CFP)',
      description: 'homepage modalsExplanations 6 title',
    }),
    body: intl.formatMessage({
      defaultMessage:
        'Somma delle emissioni di GHG (gas effetto serra) e delle rimozioni di GHG (gas effetto serra) in un sistema di prodotto espressa come CO2 equivalenti e basata su una valutazione del ciclo di vita utilizzando la sola categoria di impatto del cambiamento climatico. [UNI EN ISO 14067:2018, modificata, punto 3.1.1.1]',
      description: 'homepage modalsExplanations 6 body',
    }),
  },
  7: {
    title: intl.formatMessage({
      defaultMessage: 'EPD',
      description: 'homepage modalsExplanations 7 title',
    }),
    body: intl.formatMessage({
      defaultMessage:
        'EPD (Environmental Product Declaration, ovvero Dichiarazione Ambientale di Prodotto) è una certificazione che descrive le prestazioni ambientali legate al Ciclo di Vita dei prodotti o servizi, in accordo con lo Standard Internazionale ISO 14025.',
      description: 'homepage modalsExplanations 7 body',
    }),
  },
  8: {
    title: intl.formatMessage({
      defaultMessage: 'Iniziative di mobilità',
      description: 'homepage modalsExplanations 8 title',
    }),
    body: intl.formatMessage({
      defaultMessage:
        'Iniziative che consentono la realizzazione di spostamenti “sostenibili” (ad esempio sistemi di navette casa-lavoro, contributo per il trasporto pubblico, car pooling, sharing mobility, ecc.).',
      description: 'homepage modalsExplanations 8 body',
    }),
  },
  9: {
    title: intl.formatMessage({
      defaultMessage: 'Prodotto rigenerato',
      description: 'homepage modalsExplanations 9 title',
    }),
    body: intl.formatMessage({
      defaultMessage:
        'Prodotto mantenuto in circolo grazie alla sostituzione delle parti obsolete o danneggiate per il riutilizzo e la vendita',
      description: 'homepage modalsExplanations 9 body',
    }),
  },
  10: {
    title: intl.formatMessage({
      defaultMessage: 'Economia circolare',
      description: 'homepage modalsExplanations 10 title',
    }),
    body: intl.formatMessage({
      defaultMessage:
        'Sistema economico che, attraverso un approccio sistemico e olistico, mira a mantenere circolare il flusso delle risorse, conservandone, rigenerandone o aumentandone il valore, e che al contempo contribuisce allo sviluppo sostenibile.',
      description: 'homepage modalsExplanations 10 body',
    }),
  },
});

export const customizeModalsNotes = (
  intl: IntlShape,
  htmlParserOptions?: object
) => ({
  question_A: intl.formatMessage(
    {
      defaultMessage:
        'La simbiosi industriale è un modello di cooperazione tra imprese che si scambiano materiali, energia e servizi per ridurre gli sprechi e gli impatti ambientali e per creare valore aggiunto.<br></br>La vostra organizzazione implementa modelli o meccanisimi di simbiosi industriale trasferendo e/o condividendo risorse (materiali, energia, acqua, servizi) con altre organizzazioni (anche non appartenenti alla filiera legno-arredo)?',
      description: 'homepage notes question_A title',
    },
    { ...htmlParserOptions }
  ),
  question_B: intl.formatMessage(
    {
      defaultMessage:
        'L’organizzazione si assicura che i propri fornitori abbiano una buona gestione ambientale (ad esempio possesso di certificazioni di sostenibilità e/o di prodotto) e/o cerca di minimizzare la distanza tra il luogo di origine e quello di utilizzo delle materie prime e dei componenti, per cui possono essere considerati fornitori locali (< 100 Km)?',
      description: 'homepage notes question_B title',
    },
    { ...htmlParserOptions }
  ),
  question_C: intl.formatMessage(
    {
      defaultMessage:
        "L'organizzazione ha effettuato investimenti in progettazione circolare per quanto riguarda i propri prodotti, servizi o processi?<br></br>Esempi di progettazione circolare sono:<br></br><ul><li>utilizzare maggior quantità di materie prime riciclate, riciclabili o rinnovabili</li><li>progettare un prodotto che sia disassemblabile, riparabile</li><li>ridurre i consumi di risorse (ad es. energia, acqua, risorse materiche) durante il processo produttivo</li><li>effettuare corsi di formazione sui temi di economia circolare ai propri dipendenti/collaboratori</li><li>assumere figure qualificate nell'ambito dei temi di economia circolare (ad es. energy manager, mobility manager)</li></ul>",
      description: 'homepage notes question_C title',
    },
    { ...htmlParserOptions }
  ),
  question_D: intl.formatMessage(
    {
      defaultMessage:
        'La vostra organizzazione si autoalimenta con energia termica e/o elettrica prodotta dai propri impianti?<br></br>I tipi di impianti di autoproduzione di energia più diffusi sono quelli che sfruttano le energie rinnovabili, come il solare, l’eolico, l’idroelettrico e la geotermica. Questi impianti permettono di produrre energia elettrica e/o termica senza dipendere da fonti esterne e di ridurre le emissioni di CO2.',
      description: 'homepage notes question_D title',
    },
    { ...htmlParserOptions }
  ),
  question_E: intl.formatMessage(
    {
      defaultMessage:
        'La vostra azienda ha misurato le emissioni di CO2 generate dalla propria attività e/o dai propri prodotti, e/o compra e/o vende prodotti che riportano le emissioni di CO2 associate al loro ciclo di vita e/o una dichiarazione ambientale di prodotto (EPD)?',
      description: 'homepage notes question_E title',
    },
    { ...htmlParserOptions }
  ),
  question_F: intl.formatMessage(
    {
      defaultMessage:
        'Con la finalità di ridurre l’impatto ambientale degli spostamenti casa-lavoro, diminuendo le emissioni di CO2 e altri inquinanti, nella vostra azienda ci sono regole per il trasporto dei dipendenti?<br></br>Esistono delle linee guida o criteri aziendali per rendere più efficiente il viaggio e il peso del trasporto prodotti e/o per lo smaltimento dei beni non più usati?',
      description: 'homepage notes question_F title',
    },
    { ...htmlParserOptions }
  ),
  question_G: intl.formatMessage(
    {
      defaultMessage:
        'Un prodotto rigenerato o ricondizionato è un prodotto usato che viene riparato e riportato allo stato di origine e poi reimmesso sul mercato normalmente ad un prezzo inferiore;<br></br>Un servizio di riparazione è un’attività che consente di ripristinare il funzionamento di un prodotto difettoso o danneggiato senza doverlo sostituire con uno nuovo.<br></br>Le istruzioni per la riparazione sono delle guide che forniscono informazioni su come smontare, diagnosticare e riparare i componenti di un prodotto.<br></br>Queste pratiche hanno lo scopo di ridurre gli sprechi, prolungare la vita dei prodotti e favorire il risparmio dei consumatori',
      description: 'homepage notes question_G title',
    },
    { ...htmlParserOptions }
  ),
  question_H: intl.formatMessage(
    {
      defaultMessage:
        'La formazione del personale sull’economia circolare è un’attività che mira a trasmettere ai dipendenti le conoscenze e le competenze necessarie per applicare i principi e le strategie dell’economia circolare alla propria attività lavorativa. Può essere svolta attrverso personale interno e/o con personale esterno.<br></br>Una formazione al personale sul tema di economia circolare per un’azienda del settore legno arredo può essere ad esempio: la progettazione ecocompatibile, la gestione dei rifiuti, l’uso di materiali riciclati e rinnovabili, la valutazione del ciclo di vita dei prodotti, le etichette ecologiche e la comunicazione ambientale.',
      description: 'homepage notes question_H title',
    },
    { ...htmlParserOptions }
  ),
});
