// @mui

import { CSSProperties } from 'react';

// ----------------------------------------------------------------------

function SideBarCertificationsIcon({
  color = '#919EAB',
  style,
}: {
  color?: string;
  style?: CSSProperties;
}) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style }}
    >
      <g id="ICONS">
        <path
          id="Icon"
          d="M15.9999 28.5C15.9999 28.5 25.5999 23.7 25.5999 16.5V8.1L15.9999 4.5L6.3999 8.1V16.5C6.3999 23.7 15.9999 28.5 15.9999 28.5Z"
          stroke={color}
          strokeWidth="2.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default SideBarCertificationsIcon;
