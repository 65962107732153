import { BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IPointerIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function PointerIcon({ color = '#7B8A72', style }: IPointerIconProps) {
  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M11.3099 14.7833C8.44705 14.6583 6.1694 12.475 6.1694 9.79168C6.1694 7.03334 8.59109 4.79168 11.571 4.79168C14.4698 4.79168 16.8285 6.90001 16.9635 9.55001L15.073 9.02501C14.7039 7.55001 13.2724 6.45834 11.571 6.45834C9.58138 6.45834 7.96991 7.95001 7.96991 9.79168C7.96991 11.3667 9.14925 12.6917 10.7427 13.0333L11.3099 14.7833ZM20.5735 9.79168C20.5735 10.0417 20.5645 10.2917 20.5375 10.5417L18.764 10.05C18.773 9.96668 18.773 9.87501 18.773 9.79168C18.773 6.10834 15.5501 3.12501 11.571 3.12501C7.59181 3.12501 4.36888 6.10834 4.36888 9.79168C4.36888 13.475 7.59181 16.4583 11.571 16.4583C11.661 16.4583 11.76 16.4583 11.85 16.45L12.3812 18.0917C12.1111 18.1167 11.841 18.125 11.571 18.125C6.60152 18.125 2.56836 14.3917 2.56836 9.79168C2.56836 5.19168 6.60152 1.45834 11.571 1.45834C16.5404 1.45834 20.5735 5.19168 20.5735 9.79168ZM17.1796 13.3417L20.5735 12.2917L11.571 9.79168L14.2717 18.125L15.4061 14.9833L19.2502 18.5417L21.0327 16.8917L17.1796 13.3417Z"
        fill={color}
      />
    </svg>
  );
}

export default PointerIcon;
