import { StyledTabs } from './Tabs.style';
import { ITabsProps } from './Tabs.type';

const Tabs = ({ colorIndicator, children, ...other }: ITabsProps) => {
  return (
    <StyledTabs colorIndicator={colorIndicator} {...other}>
      {children}
    </StyledTabs>
  );
};

export { Tabs };
