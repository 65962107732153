import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  // minWidth: '200px',
  width: '100%',
  height: 215,
  borderRadius: '16px',
  padding: '40px 24px 36px',
  display: 'flex',
  backgroundColor: theme.palette.grey[0],
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: '25px',
  [theme.breakpoints.down('sm')]: {
    padding: '15px 24px',
    height: 150,
  },
}));
