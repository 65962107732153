import './index.css';
// scroll bar
import 'simplebar/src/simplebar.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { LicenseInfo } from '@mui/x-license-pro';
import it from 'date-fns/locale/it';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
// contexts
import App from './App';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { HttpProvider, IntlProvider } from 'providers';

LicenseInfo.setLicenseKey(process.env.REACT_APP_PICKER_LICENSE_KEY as string);

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID as string,
};

TagManager.initialize(tagManagerArgs);

//

// ----------------------------------------------------------------------

ReactDOM.render(
  <HelmetProvider>
    <HttpProvider>
      <IntlProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
          <SettingsProvider>
            <CollapseDrawerProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </CollapseDrawerProvider>
          </SettingsProvider>
        </LocalizationProvider>
      </IntlProvider>
    </HttpProvider>
  </HelmetProvider>,
  document.getElementById('root')
);
