import { Box, BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IBibliotecaIcon extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function BibliotecaIcon({
  color = '#7b8a72',
  style,
  ...other
}: IBibliotecaIcon) {
  return (
    <Box {...other}>
      <svg
        viewBox="0 0 20 26"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <path d="M17.5 0.5H2.5C1.125 0.5 0 1.625 0 3V23C0 24.375 1.125 25.5 2.5 25.5H17.5C18.875 25.5 20 24.375 20 23V3C20 1.625 18.875 0.5 17.5 0.5ZM6.25 3H8.75V9.25L7.5 8.3125L6.25 9.25V3ZM17.5 23H2.5V3H3.75V14.25L7.5 11.4375L11.25 14.25V3H17.5V23Z" />
      </svg>
    </Box>
  );
}

export default BibliotecaIcon;
