import { IntlShape } from 'react-intl';

const htmlParserOptions = {
  br: () => <br />,
  b: (str: string) => <b>{str}</b>,
};

export const dashboardTranslations = (intl: IntlShape) => ({
  dashboard: {
    title: intl.formatMessage({
      defaultMessage: 'Benvenuto in Plus,',
      description: 'Dashboard Page title',
    }),
    subtitle: intl.formatMessage({
      defaultMessage:
        'Seleziona il tuo obiettivo e scorri la pagina per scoprire tutti i servizi dedicati a te.',
      description: 'Dashboard page subtitle',
    }),
    macrocategoriesSubtitle: {
      trainingAndConsulting: intl.formatMessage({
        defaultMessage:
          'Servizi di formazione e consulenza per lo sviluppo e la competitività delle imprese. I dati, le informazioni e gli aggiornamenti per una gestione efficace delle risorse aziendali.',
        description: 'Dashboard Page trainingAndConsulting subtitle',
      }),
      sustainability: intl.formatMessage({
        defaultMessage:
          'Guardiamo alla sostenibilità come leva di competitività. Scopri i nostri servizi che ti aiutano a produrre meglio e fare la tua parte per rendere più green Legno-Arredo.',
        description: 'Dashboard Page sustainability subtitle',
      }),
      internazionalization: intl.formatMessage({
        defaultMessage:
          'Ricerca certificati per esportare i tuoi prodotti, approfondisci tutti i temi collegati alle dogane, traduci termini specifici rapidamente in 7 lingue.',
        description: 'Dashboard Page internazionalization subtitle',
      }),
    },
    studyCenter: {
      subtitle: intl.formatMessage(
        {
          defaultMessage:
            'Il Centro Studi di FederlegnoArredo mette a disposizione degli Associati dati e ricerche sulla Filiera Legno-Arredo. Consulta i report e i numeri di settore della Filiera.',
          description: 'Dashboard Page studyCenter Card subtitle',
        },
        { ...htmlParserOptions }
      ),
      labels: {
        totalExport: intl.formatMessage({
          defaultMessage: 'Totale Export',
          description: 'Dashboard Page studyCenter Card totalExport label',
        }),
        totalImport: intl.formatMessage({
          defaultMessage: 'Totale Import',
          description: 'Dashborda Page sudyCenter Card totalImport label',
        }),
      },
      button: intl.formatMessage({
        defaultMessage: 'Consulta i prodotti',
        description: 'Dashboard Page studyCenter Card button label',
      }),
    },
    academy: {
      subtitle: intl.formatMessage({
        defaultMessage:
          'Un’offerta formativa interaziendale che viene progettata semestralmente dall’Ufficio Formazione FLA e che propone percorsi di training per le imprese del settore Legno-Arredo. Si trova a disposizione la programmazione aggiornata di tutti i corsi Academy FLA e Academy Architetti.',
        description: 'Dashboard Page academy Card subtitle',
      }),
      text: intl.formatMessage(
        {
          defaultMessage:
            'Per supportare la formazione continua e renderla sostenibile, tutti i corsi sono finanziabili con il Conto Formazione di <b>Fondimpresa</b>.',
          description: 'Dashboard Page academy Card text',
        },
        { ...htmlParserOptions }
      ),
      button: intl.formatMessage({
        defaultMessage: 'Entra in Academy FLA',
        description: 'Dashboard Page academy Card button label',
      }),
    },
    finance: {
      subtitle: intl.formatMessage({
        defaultMessage:
          'Un servizio di informazione, consulenza e assistenza per gli Associati sugli strumenti di finanza agevolata e sui temi della finanza di impresa.',
        description: 'Dashboard Page finance Card subtitle',
      }),
      button: intl.formatMessage({
        defaultMessage: 'Tieniti aggiornato',
        description: 'Dashboard Page finance Card button label',
      }),
    },
    intellectualProperty: {
      subtitle: intl.formatMessage({
        defaultMessage:
          'Un servizio di consulenza in materia PI e un percorso fomativo che ha l’obbietivo di fornire strumenti utili a proteggere i propri asset.',
        description: 'Dashboard Page intellectualProperty Card subtitle',
      }),
      button: intl.formatMessage({
        defaultMessage: 'Guarda il corso completo',
        description: 'Dashboard Page intellectualPropery Card button label',
      }),
    },
    library: {
      subtitle: intl.formatMessage({
        defaultMessage:
          'Un servizio che mette a disposizione dei soci molte opportunità formative. In questa sezione troverai tutti i contenuti formativi a tua disposizione, da guardare in qualsiasi momento.',
        description: 'Dashboard Page library Card subtitle',
      }),
      inputSearch: intl.formatMessage({
        defaultMessage: 'Ricerca contenuto formativo',
        description: 'Dashboard Page library Card input search label',
      }),
      button: intl.formatMessage({
        defaultMessage: 'Cerca',
        description: 'Dashboard Page library Card button label',
      }),
    },
    materialLibrary: {
      subtitle: intl.formatMessage({
        defaultMessage:
          'Un database di materiali e produttori per il settore Legno-Arredo che pone il focus sulle loro caratteristiche di sostenibilità ambientale.',
        description: 'Dashboard Page materialLibrary Card subtitle',
      }),
      fields: {
        category: intl.formatMessage({
          defaultMessage: 'Categoria',
          description:
            'Dashboard Page materialLibrary Card category input label',
        }),
        subCategory: intl.formatMessage({
          defaultMessage: 'Sottocategoria',
          description:
            'Dashboard Page materialLibrary Card subcategory input label',
        }),
        typology: intl.formatMessage({
          defaultMessage: 'Tipo di materiale',
          description:
            'Dashboard Page materialLibrary Card typology input label',
        }),
      },
      button: intl.formatMessage({
        defaultMessage: 'Cerca',
        description: 'Dashboard Page materialLibrary Card button label',
      }),
    },
    circularityTool: {
      subtitle: intl.formatMessage({
        defaultMessage:
          'TECLA (Tool per l’Economia Circolare nel LegnoArredo), è uno strumento  finalizzato a misurare la circolarità dei processi delle organizzazioni, grazie alla specifica tecnica UNI/TS 11820, per evidenziare in maniera puntuale le aree critiche e le soluzioni per il loro miglioramento.',
        description: 'Dashboard Page circularityTool Card subtitle',
      }),
      button: intl.formatMessage({
        defaultMessage: 'Accedi a TECLA',
        description: 'Dashboard Page circularityTool Card button label',
      }),
    },
    designWood: {
      subtitle: intl.formatMessage({
        defaultMessage:
          'Una campagna di riforestazione, nata nel 2019 come programma nell’ambito delle attività forestali di Rete Clima, in collaborazione con il Comune di Milano.',
        description: 'Dashboard Page designWood Card subtitle',
      }),
      labels: {
        treesPlanted: intl.formatMessage({
          defaultMessage: 'Alberi Piantati',
          description: 'Dashboard Page designWood Card label',
        }),
        co2: intl.formatMessage({
          defaultMessage: 'CO2',
          description: 'DashboardPage designWood Card label',
        }),
      },
      button: intl.formatMessage({
        defaultMessage: 'Visita il sito',
        description: 'Dashboard Page designWood Card button label',
      }),
    },
    generalSurvey: {
      subtitle: intl.formatMessage(
        {
          defaultMessage:
            'Il questionario viene attivato esclusivamente dalla Federazione una volta l’anno per la sua compilazione. Quando riceverai la notifica ti suggeriamo di avere a portata di mano i dati relativi alla quantità di rifiuti prodotti, l’energia consumata e le certificazioni di sistema e ambientali di prodotto.',
          description: 'Dashboard Page generalSurvey Card subtitle',
        },
        { ...htmlParserOptions }
      ),
      button: intl.formatMessage({
        defaultMessage: 'Compila il questionario',
        description: 'Dashboard Page generalSurvey Card button',
      }),
    },
    certifications: {
      subtitle: intl.formatMessage({
        defaultMessage:
          'Uno strumento semplice e intuitivo per ricercare velocemente le conformità tecniche necessarie per esportare i tuoi prodotti.',
        description: 'Dashboard Page certifications Card subtitle',
      }),
      inputSearch: intl.formatMessage({
        defaultMessage: 'Ricerca Paese di Destinazione',
        description: 'Dashboard Page certifications Card input search label',
      }),
      select: {
        sector: intl.formatMessage({
          defaultMessage: 'Settore',
          description: 'Dashboard Page certifications Card input select sector',
        }),
        nation: intl.formatMessage({
          defaultMessage: 'Paese di destinazione',
          description: 'Dashboard Page certifications Card input select nation',
        }),
      },
      button: intl.formatMessage({
        defaultMessage: 'Cerca',
        description: 'Dashboard Page certifications Card button label',
      }),
    },
    customHouseOffice: {
      subtitle: intl.formatMessage({
        defaultMessage:
          'Un servizio di consulenza che pone il focus sul tema Dogane con lo scopo di fornire tutte le informazioni e la documentazione necessaria per mettere le aziende associate in condizioni di operare in modo adeguato nei mercati internazionali.',
        description: 'Dashboard Page customHouseOffice Card subtitle',
      }),
      button: intl.formatMessage({
        defaultMessage: 'Guarda i webinar dedicati',
        description: 'Dashboard Page customHouseOffice Card button label',
      }),
    },
    glossary: {
      subtitle: intl.formatMessage({
        defaultMessage:
          'Un tool pratico per tradurre i termini specifici del nostro settore in sette lingue: Italiano, Francese, Inglese, Spagnolo, Tedesco, Russo e Cinese.',
        description: 'Dashboard Page glossary Card subtitle',
      }),
      button: intl.formatMessage({
        defaultMessage: 'Apri il Glossario',
        description: 'Dashboard Page glossary Card button label',
      }),
    },
  },
});
