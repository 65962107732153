import { BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IMoreProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function MoreIcon({ color = 'white', style }: IMoreProps) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <circle cx="10.9626" cy="10.0007" r="1.66667" fill={color} />
      <ellipse
        cx="10.9626"
        cy="4.16667"
        rx="1.66667"
        ry="1.66667"
        fill={color}
      />
      <ellipse
        cx="10.9626"
        cy="15.8327"
        rx="1.66667"
        ry="1.66667"
        fill={color}
      />
    </svg>
  );
}

export default MoreIcon;
