/* eslint-disable @typescript-eslint/no-unused-vars */
import { Stack, Typography, useTheme } from '@mui/material';
import { StyledContactCard } from './ContactCard.style';
import { IContactCardProps } from './ContactCard.types';
import { useResponsive } from 'hooks';

const ContactCard = ({ icon, contact, color, details }: IContactCardProps) => {
  const isSmDown = useResponsive('down', 'sm');
  const theme = useTheme();
  return isSmDown ? (
    <StyledContactCard color={theme.palette.info.main}>
      <Stack direction={'row'} gap={2}>
        {icon}
        <Typography variant="subtitle1" color={theme.palette.primary.main}>
          {contact}
        </Typography>
      </Stack>
      <Typography color={theme.palette.primary.main}>{details}</Typography>
    </StyledContactCard>
  ) : (
    <StyledContactCard color={theme.palette.info.main}>
      {icon}
      <Stack justifyContent={'space-between'} gap={'10px'}>
        <Typography variant="subtitle1" color={theme.palette.primary.main}>
          {contact}
        </Typography>
        <Typography color={theme.palette.primary.main}>{details}</Typography>
      </Stack>
    </StyledContactCard>
  );
};

export { ContactCard };
