// @mui

import { CSSProperties } from 'react';

// ----------------------------------------------------------------------

function SideBarCopyrightIcon({
  color = '#919EAB',
  style,
}: {
  color?: string;
  style?: CSSProperties;
}) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g id="ICONS">
        <circle
          id="Ellipse 8"
          cx="16"
          cy="16.5"
          r="12"
          stroke={color}
          strokeWidth="2.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="C"
          d="M18.4017 14.7532C18.3617 14.4833 18.2717 14.2384 18.1318 14.0185C17.9918 13.7886 17.8169 13.5887 17.607 13.4188C17.3971 13.2489 17.1572 13.1189 16.8873 13.029C16.6274 12.929 16.3526 12.879 16.0627 12.879C15.5329 12.879 15.0831 12.984 14.7133 13.1939C14.3435 13.3938 14.0436 13.6687 13.8137 14.0185C13.5838 14.3584 13.4139 14.7482 13.3039 15.188C13.204 15.6278 13.154 16.0826 13.154 16.5524C13.154 17.0022 13.204 17.442 13.3039 17.8718C13.4139 18.2916 13.5838 18.6714 13.8137 19.0113C14.0436 19.3511 14.3435 19.626 14.7133 19.8359C15.0831 20.0358 15.5329 20.1358 16.0627 20.1358C16.7824 20.1358 17.3421 19.9159 17.742 19.4761C18.1518 19.0363 18.4017 18.4565 18.4916 17.7368H20.7706C20.7106 18.4066 20.5557 19.0113 20.3058 19.551C20.0559 20.0908 19.7261 20.5506 19.3162 20.9304C18.9064 21.3103 18.4266 21.6001 17.8769 21.8C17.3271 21.9999 16.7224 22.0999 16.0627 22.0999C15.2431 22.0999 14.5034 21.96 13.8437 21.6801C13.194 21.3902 12.6442 20.9954 12.1944 20.4956C11.7446 19.9958 11.3998 19.4111 11.1599 18.7414C10.92 18.0617 10.8 17.332 10.8 16.5524C10.8 15.7527 10.92 15.0131 11.1599 14.3334C11.3998 13.6437 11.7446 13.0439 12.1944 12.5342C12.6442 12.0244 13.194 11.6246 13.8437 11.3347C14.5034 11.0448 15.2431 10.8999 16.0627 10.8999C16.6524 10.8999 17.2072 10.9849 17.727 11.1548C18.2567 11.3247 18.7265 11.5746 19.1363 11.9045C19.5561 12.2243 19.901 12.6241 20.1709 13.1039C20.4407 13.5837 20.6107 14.1335 20.6806 14.7532H18.4017Z"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default SideBarCopyrightIcon;
