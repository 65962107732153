import { IntlShape } from 'react-intl';

export const EnergyQuestions = {
  ['question_11']:
    "<click id={101}>Energia elettrica autoprodotta</click> da <click id={102}>fonti rinnovabili</click> e/o da processi di recupero rispetto al totale dell'energia elettrica consumata.",
  ['question_12']:
    "<click id={103}>Energia termica autoprodotta</click> da <click id={102}>fonti rinnovabili</click> e/o da processi di recupero nell'anno n, rispetto al totale dell'energia termica consumata.",
  ['question_13']:
    "Se non si acquista energia elettrica segnare Non applicabile. Energia elettrica acquistata da <click id={102}>fonti rinnovabili</click>, rispetto al totale dell'energia elettrica acquistata.",
  ['question_14']:
    "Se non si acquista energia termica segnare Non applicabile. Energia termica acquistata da <click id={102}>fonti rinnovabili</click>, rispetto al totale dell'energia termica acquistata.",
  ['question_15']:
    'Quantità di acqua proveniente da recupero e/o riciclo rispetto al totale del <click id={104}>fabbisogno idrico</click>.',
};

export const energyModalsExplanations = (
  intl: IntlShape,
  htmlParserOptions?: object
) => ({
  101: {
    title: intl.formatMessage({
      defaultMessage: 'Energia elettrica autoprodotta',
      description: 'homepage modalsExplanations 101 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage:
          'Quantitativo di energia elettrica generata internamente al sistema stesso.',
        description: 'homepage modalsExplanations 101 body',
      },
      { ...htmlParserOptions }
    ),
  },
  102: {
    title: intl.formatMessage({
      defaultMessage: 'Fonti rinnovabili',
      description: 'homepage modalsExplanations 102 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Fonti di energia che, a differenza delle fonti fossili, non sono soggette ad esaurimento (energia solare, fotovoltaico, solare termodinamico, solare termico, energia eolica, energia idroelettrica, biomasse ed energia marina).`,
        description: 'homepage modalsExplanations 102 body',
      },
      { ...htmlParserOptions }
    ),
  },
  103: {
    title: intl.formatMessage({
      defaultMessage: 'Energia termica autoprodotta',
      description: 'homepage modalsExplanations 103 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Quantitativo di energia termica generata internamente al
        sistema stesso.`,
        description: 'homepage modalsExplanations 103 body',
      },
      { ...htmlParserOptions }
    ),
  },
  104: {
    title: intl.formatMessage({
      defaultMessage: 'Fabbisogno idrico',
      description: 'homepage modalsExplanations 104 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Volume di acqua necessario al soddisfacimento del bisogno idrico
        dell’organizzazione.`,
        description: 'homepage modalsExplanations 104 body',
      },
      { ...htmlParserOptions }
    ),
  },
});

export const energyModalsNotes = (
  intl: IntlShape,
  htmlParserOptions?: object
) => ({
  question_11: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto  tra: l'energia elettrica autoprodotta da fonti rinnovabili (ad es fotovoltaico e/o eolico) e l'energia totale consumata.`,
      description: 'homepage notes question_11 title',
    },
    { ...htmlParserOptions }
  ),
  question_12: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra: l'energia termica autoprodotta da fonti rinnovabili (ad es. solare termico ) e l'energia totale consumata.`,
      description: 'homepage notes question_12 title',
    },
    { ...htmlParserOptions }
  ),
  question_13: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra: l'energia elettrica acquistata da fonti rinnovabili (ad es.energia solare, solare termico) e l'energia elettrica totale acquistata.<br></br>
      Se ad esempio l'azienda A acquista energia per un totale di 500.000 kWh e di questi 50.000 kWh provengono da fonti rinnovabili (es fotovoltaico), il valore da inserire per rispondere al quesito sarà (50.000/500.000)*100 = 10%.`,
      description: 'homepage notes question_13 title',
    },
    { ...htmlParserOptions }
  ),
  question_14: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra: l'energia termica acquistata da fonti rinnovabili (ad es. solare termico) e l'energia termica totale acquistata.`,
      description: 'homepage notes question_14 title',
    },
    { ...htmlParserOptions }
  ),
  question_15: intl.formatMessage(
    {
      defaultMessage: `Questa domanda richiede di indicare la fascia percentuale in cui ricade il rapporto tra la quantità di acqua che proviene da operazioni di recupero o riciclo (m3) e la quantità totale di acqua consumata (m3). Il recupero o il riciclo dell’acqua consiste nel riutilizzare l’acqua proveniente da fonti diverse da quelle naturali, come le acque reflue o le acque piovane. Il rapporto si esprime in percentuale, cioè il numero di volte che la quantità di acqua riciclata si contiene nella quantità totale di acqua consumata moltiplicato per 100. Per esempio, se si consumano 100 m3 e 20 m3 provengono da recupero o riciclo, il rapporto è 20/100 x 100 = 20%. Questo significa che il 20% dell’acqua consumata è stata riciclata.`,
      description: 'homepage notes question_15 title',
    },
    { ...htmlParserOptions }
  ),
});
