import { Button, Grid, Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { DynamicFieldForm } from '../DynamicFieldForm/DynamicFieldForm';
import { IDynamicFieldFormProps } from '../DynamicFieldForm/DynamicFieldForm.types';
import { AddIcon, CloseIcon } from 'assets/icons';
import { useTranslations } from 'hooks';

const DynamicFieldArray = ({
  fieldData,
  context = '',
  key,
}: IDynamicFieldFormProps & {
  context?: '' | 'academy' | 'master';
  key: string | number;
}) => {
  //hooks
  const theme = useTheme();
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldData.name,
  });
  const i18n = useTranslations();
  const label = context
    ? i18n.general.webform.academy
    : i18n.general.webform.common;

  const [defaultField, setDefaultField] = useState<{ [key: string]: string }>(
    {}
  );

  useEffect(() => {
    if (fieldData && fieldData.children?.length) {
      const field: { [key: string]: string } = {};
      fieldData.children.map(c => {
        field[`${c.name}`] = '';
      });
      setDefaultField(field);
      if (!fields?.length) append(field);
    }
  }, [fieldData]);

  const renderDynamicFields = (
    field: { [key: string]: string },
    idx: number
  ) => (
    <React.Fragment key={field.id}>
      <Grid item xs={12}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography variant={'body2'} sx={{ color: theme.palette.grey[500] }}>
            {label.fieldArrayTitle}
          </Typography>
          <CloseIcon
            style={{ width: 20, cursor: 'pointer' }}
            onClick={() => remove(idx)}
          />
        </Stack>
      </Grid>
      {fieldData.children?.map(child => (
        <DynamicFieldForm
          fieldData={{
            ...child,
            name: `${fieldData.name}[${idx}].${child.name}`,
          }}
          key={`${fieldData.name}[${idx}].${child.name}`}
        />
      ))}
    </React.Fragment>
  );

  return (
    <React.Fragment key={key}>
      {fields.map((f, idx) => renderDynamicFields(f, idx))}
      {fieldData.isMultiple ? (
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              Object.entries(defaultField).length && append(defaultField)
            }
            size={'small'}
            sx={{ width: '176px' }}
          >
            <Stack direction={'row'} alignItems={'center'}>
              <AddIcon style={{ marginBottom: 3 }} />
              {label.fieldArrayAdd}
            </Stack>
          </Button>
        </Grid>
      ) : null}
    </React.Fragment>
  );
};

export { DynamicFieldArray };
