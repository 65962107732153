import { Badge } from '@mui/material';
import { useNavigate } from 'react-router';
import { Iconify } from 'components';
import { IconButtonAnimate } from 'components/animate';
import * as PATHS from 'constants/paths';
import { ICartItem, useCartStore } from 'stores/cart';

const CartHeaderIcon = () => {
  const navigate = useNavigate();
  const storeItems = useCartStore.getState().items.length;
  const storedCart = () => {
    const cart = localStorage.getItem('cart');
    if (cart) return JSON.parse(cart) as ICartItem[];
    else return [];
  };
  const cartItemsAmount = storeItems ? storeItems : storedCart.length;
  return (
    <IconButtonAnimate
      // color={open ? 'primary' : 'default'}
      onClick={() => navigate(PATHS.CART)}
      sx={{ width: 40, height: 40, ml: -2 }}
    >
      <Badge badgeContent={cartItemsAmount} color="error">
        <Iconify icon="mdi:cart-outline" width={20} height={20} />
      </Badge>
    </IconButtonAnimate>
  );
};

export default CartHeaderIcon;
