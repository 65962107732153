import { Popover } from '@mui/material';
import { ArrowStyle } from './MenuPopover.style';
import { IMenuPopoverProps } from './MenuPopover.types';
const MenuPopover = ({
  children,
  arrow = 'top-right',
  disabledArrow,
  sx,
  ...other
}: IMenuPopoverProps) => {
  return (
    <Popover
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          p: 1,
          width: 200,
          overflow: 'inherit',
          ...sx,
        },
      }}
      {...other}
    >
      {!disabledArrow && <ArrowStyle arrow={arrow} />}

      {children}
    </Popover>
  );
};

export { MenuPopover };
