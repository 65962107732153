import * as PATHS from 'constants/paths';
import { useGlobalSelector } from 'stores';
import { ICustomi18n } from 'types/intl';

export const getCustomBreadcrumbs = (
  i18n: ICustomi18n
): { path: string; breadcrumb: string | null }[] => [
  { path: PATHS.DASHBOARD, breadcrumb: 'Area Riservata' },
  {
    path: PATHS.CERTIFICATION_DYNAMIC_ID,
    breadcrumb: i18n.internazionalization.certificationsTab.title,
  },
  {
    path: PATHS.FINANCE_LIST,
    breadcrumb: i18n.finance.list.title,
  },
  {
    path: PATHS.INTELLECTUAL_LIST,
    breadcrumb: i18n.intellectual.list.title,
  },
  {
    path: PATHS.INTELLECTUAL_ON_DEMAND_LIST,
    breadcrumb: i18n.intellectual.onDemandList.title,
  },
  {
    path: PATHS.CIRCULARITYTOOL,
    breadcrumb: i18n.tool.homepage.title,
  },
  {
    path: PATHS.CIRCULARITYTOOL_NEW_SURVEY,
    breadcrumb: () =>
      useGlobalSelector().dynamicBreadcrumbs?.circularityToolContext,
  },
  { path: PATHS.FINANCE_DETAIL, breadcrumb: null },
  { path: PATHS.FINANCE_WEBINAR_DETAIL, breadcrumb: null },
  { path: PATHS.INTELLECTUAL_DETAIL, breadcrumb: null },
  {
    path: PATHS.INTELLECTUAL_ON_DEMAND_DETAIL,
    breadcrumb: () =>
      useGlobalSelector().dynamicBreadcrumbs?.intellectualPropertyFocusName,
  },
  {
    path: PATHS.CONTENT_MASTER,
    breadcrumb: () => useGlobalSelector().dynamicBreadcrumbs?.masterName,
  },
  {
    path: PATHS.ACADEMYFLA_CATEGORY_ID,
    breadcrumb: () => useGlobalSelector().dynamicBreadcrumbs?.categoryId,
  },
  {
    path: PATHS.ACADEMYFLA_ID,
    breadcrumb: () => useGlobalSelector().dynamicBreadcrumbs?.courseId,
  },
  {
    path: PATHS.ACADEMYFLA_ID_FINANCE_REQ,
    breadcrumb: () => useGlobalSelector().dynamicBreadcrumbs?.financeReq,
  },
  {
    path: PATHS.ACADEMYFLA_ID_MORE_INFO,
    breadcrumb: () => useGlobalSelector().dynamicBreadcrumbs?.moreInfoReq,
  },
  {
    path: PATHS.ACADEMYARCHITECTS_ID,
    breadcrumb: () => useGlobalSelector().dynamicBreadcrumbs?.courseId,
  },
  {
    path: PATHS.STUDYCENTER_PRODUCT_CATEGORY,
    breadcrumb: () => useGlobalSelector().dynamicBreadcrumbs?.studyCategoryId,
  },
  {
    path: PATHS.STUDYCENTER_DETAIL,
    breadcrumb: () => useGlobalSelector().dynamicBreadcrumbs?.studyContentId,
  },
  {
    path: PATHS.MATERIALDETAILS,
    breadcrumb: () => useGlobalSelector().dynamicBreadcrumbs?.materialId,
  },
  {
    path: PATHS.MATERIAL_PRODUCER_DETAILS,
    breadcrumb: () => useGlobalSelector().dynamicBreadcrumbs?.materialCompanyId,
  },
  {
    path: PATHS.INTELLECTUAL_CONVENTION,
    breadcrumb: () =>
      useGlobalSelector().dynamicBreadcrumbs?.intellectualPropertyName,
  },
  {
    path: PATHS.INTELLECTUAL_COURSE,
    breadcrumb: () =>
      useGlobalSelector().dynamicBreadcrumbs?.intellectualPropertyCourseName,
  },
  {
    path: PATHS.GENERALSURVEY_NEW,
    breadcrumb: () => useGlobalSelector().dynamicBreadcrumbs?.generalSurveyName,
  },
  { path: PATHS.ROOT, breadcrumb: null },
  { path: PATHS.CERTIFICATION_DYNAMIC_BUNDLE, breadcrumb: null },
  {
    path: PATHS.CONTENT_DETAIL_ID,
    breadcrumb: null,
  },
];

export const excludePaths = [PATHS.HOME];
