import { BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IPhoneProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function PhoneIcon({ color = '#7B8A72', style }: IPhoneProps) {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M20.4191 16H23.1416C23.1416 12.32 20.0924 9.33333 16.3353 9.33333V12C18.595 12 20.4191 13.7867 20.4191 16ZM25.8641 16H28.5866C28.5866 9.37333 23.1007 4 16.3353 4V6.66667C21.6034 6.66667 25.8641 10.84 25.8641 16ZM27.2253 20.6667C25.5238 20.6667 23.8903 20.4 22.3657 19.9067C22.2295 19.8667 22.0798 19.84 21.9437 19.84C21.5897 19.84 21.2494 19.9733 20.9772 20.2267L17.9824 23.16C14.1301 21.24 10.9719 18.16 9.01173 14.3733L12.0065 11.4267C12.3876 11.08 12.4965 10.56 12.3468 10.0933C11.8431 8.6 11.5709 7 11.5709 5.33333C11.5709 4.6 10.9583 4 10.2096 4H5.44524C4.69655 4 4.08398 4.6 4.08398 5.33333C4.08398 17.8533 14.4431 28 27.2253 28C27.974 28 28.5866 27.4 28.5866 26.6667V22C28.5866 21.2667 27.974 20.6667 27.2253 20.6667ZM6.84734 6.66667H8.88922C8.98451 7.84 9.1887 9 9.50179 10.1067L7.86828 11.72C7.32378 10.1067 6.96985 8.42667 6.84734 6.66667ZM25.8641 25.2933C24.0672 25.1733 22.3248 24.8267 20.6913 24.28L22.3248 22.68C23.4819 23 24.6662 23.2 25.8641 23.28V25.2933Z"
        fill={color}
      />
    </svg>
  );
}

export default PhoneIcon;
