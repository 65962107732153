import { Box, BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface ITreesIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function TreesIcon({ color = '#7b8a72', style, ...other }: ITreesIconProps) {
  return (
    <Box {...other}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 88.681 75"
        style={style}
      >
        <path
          id="Tracciato_480"
          data-name="Tracciato 480"
          d="M89,60.2,74.738,37.7h6.873L55.746.2,44.66,16.29,33.575.2,7.71,37.7h6.873L.32,60.2H26.185v15h14.78v-15h7.39v15h14.78v-15ZM55.746,13.29,67.422,30.2H61.214L75.477,52.7H62.1l-9.5-15h6.836L49.169,22.815ZM13.844,52.7,28.107,30.2H21.9L33.575,13.29,45.252,30.2H39.044L53.307,52.7Z"
          transform="translate(-0.32 -0.203)"
          fill={color}
        />
      </svg>
    </Box>
  );
}

export default TreesIcon;
