import { Stack, styled } from '@mui/material';
import { IContainerProps } from './GeneralContactsBanner.types';

export const Container = styled(Stack)(({ theme, color }: IContainerProps) => ({
  width: '100%',
  minHeight: 169,
  borderRadius: 20,
  padding: '27px',
  backgroundColor:
    color !== 'info' ? theme?.palette[color].lighter : theme?.palette.info.main,
  ...(theme && {
    [theme.breakpoints.down('sm')]: {
      minHeight: 180,
    },
  }),
}));
