import { Box, BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IGlobeIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function GlobeIcon({ color = '#7B8A72', style, ...other }: IGlobeIconProps) {
  return (
    <Box {...other}>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill={color}
        style={style}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M13.986 0.666992C6.62602 0.666992 0.666016 6.64033 0.666016 14.0003C0.666016 21.3603 6.62602 27.3337 13.986 27.3337C21.3594 27.3337 27.3327 21.3603 27.3327 14.0003C27.3327 6.64033 21.3594 0.666992 13.986 0.666992ZM23.226 8.66699H19.2927C18.866 7.00033 18.2527 5.40033 17.4527 3.92033C19.906 4.76033 21.946 6.46699 23.226 8.66699ZM13.9993 3.38699C15.106 4.98699 15.9727 6.76033 16.546 8.66699H11.4527C12.026 6.76033 12.8927 4.98699 13.9993 3.38699ZM3.67935 16.667C3.46602 15.8137 3.33268 14.9203 3.33268 14.0003C3.33268 13.0803 3.46602 12.187 3.67935 11.3337H8.18602C8.07935 12.2137 7.99935 13.0937 7.99935 14.0003C7.99935 14.907 8.07935 15.787 8.18602 16.667H3.67935ZM4.77268 19.3337H8.70602C9.13268 21.0003 9.74602 22.6003 10.546 24.0803C8.09268 23.2403 6.05268 21.547 4.77268 19.3337ZM8.70602 8.66699H4.77268C6.05268 6.45366 8.09268 4.76033 10.546 3.92033C9.74602 5.40033 9.13268 7.00033 8.70602 8.66699ZM13.9993 24.6137C12.8927 23.0137 12.026 21.2403 11.4527 19.3337H16.546C15.9727 21.2403 15.106 23.0137 13.9993 24.6137ZM17.1194 16.667H10.8793C10.7593 15.787 10.666 14.907 10.666 14.0003C10.666 13.0937 10.7593 12.2003 10.8793 11.3337H17.1194C17.2393 12.2003 17.3327 13.0937 17.3327 14.0003C17.3327 14.907 17.2393 15.787 17.1194 16.667ZM17.4527 24.0803C18.2527 22.6003 18.866 21.0003 19.2927 19.3337H23.226C21.946 21.5337 19.906 23.2403 17.4527 24.0803ZM19.8127 16.667C19.9193 15.787 19.9993 14.907 19.9993 14.0003C19.9993 13.0937 19.9193 12.2137 19.8127 11.3337H24.3193C24.5327 12.187 24.666 13.0803 24.666 14.0003C24.666 14.9203 24.5327 15.8137 24.3193 16.667H19.8127Z" />
      </svg>
    </Box>
  );
}

export default GlobeIcon;
