import { IntlShape } from 'react-intl';
import { modalsExplanations, modalsNotes } from './toolsModals';
import { modalsMapping } from './utils/tools';
import { feedbackBodies } from './utils/toolsData/FeedbackBodies';
import { improveBodies } from './utils/toolsData/ImproveBodies';
import { SurveyCategoriesEnum } from 'types/stores';

const htmlParserOptions = {
  br: () => <br />,
  b: (str: string) => <b>{str}</b>,
  ol: (str: string) => <ol>{str}</ol>,
  ul: (str: string) => <ul>{str}</ul>,
  li: (str: string) => <li>{str}</li>,
  raccomandedLabel: (str: string) => (
    <span
      style={{
        color: 'white',
        backgroundColor: '#7B8A72',
        borderRadius: '50px',
        padding: '1px 8px',
        marginLeft: '8px',
      }}
    >
      {str}
    </span>
  ),
  example: (str: string) => (
    <span
      style={{
        color: '#7B8A72',
        textDecoration: 'underline',
        fontSize: 14,
        fontWeight: 700,
      }}
    >
      {str}
    </span>
  ),
};

export const toolTranslations = (intl: IntlShape) => ({
  tool: {
    modals: {
      ...modalsMapping(intl),
      notes: {
        show: intl.formatMessage({
          defaultMessage: 'Vedi note e spiegazioni',
          description: 'homepage notes show title',
        }),
        ...modalsNotes(intl, htmlParserOptions),
      },
    },
    modalsExplanations: {
      ...modalsExplanations(intl, htmlParserOptions),
    },
    feedbackBody: { ...feedbackBodies(intl, htmlParserOptions) },
    improveBody: { ...improveBodies(intl, htmlParserOptions) },
    homepage: {
      contactText: intl.formatMessage({
        defaultMessage: 'Compila il form per richiedere più informazioni.',
        description: 'homepage contactText',
      }),
      title: intl.formatMessage({
        defaultMessage: 'TECLA (Tool per l’Economia Circolare nel LegnoArredo)',
        description: 'tool homepage title label',
      }),
      subtitle: intl.formatMessage(
        {
          defaultMessage:
            'Misura la circolarità dei processi della tua organizzazione grazie alla specifica tecnica <b>UNI/TS 11820</b>:  “Misurazione della circolarità. Metodi ed indicatori per la misurazione dei processi circolari nelle organizzazioni”.',
          description: 'tool homepage subtitle label',
        },
        { ...htmlParserOptions }
      ),
      header: {
        goToHistory: intl.formatMessage({
          defaultMessage: 'Vai allo storico',
          description: 'tool homepage header goToHistory label',
        }),
        newSurvey: intl.formatMessage({
          defaultMessage: 'Nuovo sondaggio',
          description: 'tool homepage header newSurvey label',
        }),
        newSurveyBody: intl.formatMessage({
          defaultMessage:
            'Puoi iniziare un nuovo sondaggio in qualsiasi momento.',
          description: 'tool homepage header newSurveyBody label',
        }),
        newSurveyBtn: intl.formatMessage({
          defaultMessage: 'Nuovo',
          description: 'tool homepage header newSurveyBtn label',
        }),
        newSurveyGuide: intl.formatMessage({
          defaultMessage: 'Guida alla compilazione',
          description: 'tool homepage header newSurveyGuide label',
        }),
        lastSurvey: intl.formatMessage({
          defaultMessage: 'Ultimo sondaggio completato',
          description: 'tool homepage header lastSurvey label',
        }),
        noLastSurvey: intl.formatMessage({
          defaultMessage: 'Non sono presenti sondaggi',
          description: 'tool homepage header noLastSurvey label',
        }),
        currentSurvey: intl.formatMessage({
          defaultMessage: 'Sondaggio in corso',
          description: 'tool homepage header currentSurvey label',
        }),
        noCurrentSurvey: intl.formatMessage({
          defaultMessage: 'Non sono presenti bozze di sondaggi',
          description: 'tool homepage header noCurrentSurvey label',
        }),
      },
      infoSection: {
        title: intl.formatMessage({
          defaultMessage: 'Cos’è la specifica tecnica UNI/TS 11820?',
          description: 'tool homepage infoSection title label',
        }),
        centralBody: intl.formatMessage(
          {
            defaultMessage:
              'Questa specifica tecnica, entrata in vigore dal 30 novembre 2022, definisce un set di indicatori di circolarità al fine di valutare, attraverso un sistema di rating, il livello di circolarità di una organizzazione.<br></br><br></br> Questo sistema non prevede livelli minimi di circolarità, ma restituisce una valutazione sul livello raggiunto.<br></br><br></br>Gli indicatori di circolarità sono suddivisi in <b>6 categorie</b>:<br></br><ol><li>Risorse materiche e componenti(Materia)</li><li>Risorse energetiche e idriche (Energia)</li><li>Rifiuti ed emissioni (Rifiuti)</li><li>Logistica (Logistica)</li><li>Prodotto/Servizio (Prodotto/Servizio)</li><li>Risorse umane, asset, policy e sostenibilità (HR/Policy)</li></ol>',
            description: 'tool homepage infoSection centralBody label',
          },
          { ...htmlParserOptions }
        ),
        subtitle: intl.formatMessage({
          defaultMessage: 'Perchè utilizzare TECLA?',
          description: 'tool homepage subtitle label',
        }),
        simpleBody: intl.formatMessage({
          defaultMessage:
            'Attraverso la compilazione di un sondaggio, ti aiutiamo ad individuare il tuo livello di circolarità e ad identificare il tuo profilo con il percorso più idoneo di miglioramento verso la transizione di economia circolare. Al completamento del sondaggio, TECLA ti restituirà un valore numerico percentuale che attesta il livello di circolarità della tua organizzazione, in accordo con la UNI/TS 11820, mediante una attività di valutazione di prima parte, ovvero un’autovalutazione. In aggiunta per ciascuna categoria di indicatore di circolarità, sarà assegnato uno score con relativo feedback e possibili soluzioni di miglioramento.',
          description: 'tool homepage simpleBody label',
        }),
      },
      infoSectionPrivacy: {
        subtitle: intl.formatMessage({
          defaultMessage: 'Trattamento dei dati ',
          description: 'tool homepage infoSectionPrivacy subtitle label',
        }),
        simpleBody: intl.formatMessage({
          defaultMessage:
            'I dati utilizzati per il calcolo del livello di circolarità sono autodichiarati e approssimati per fasce percentuali. Il valore numerico percentuale restituito da TECLA è ottenuto applicando la metodologia proposta dalla UNI/TS 11820. Il livello di prestazione alla data di compilazione (ovvero base-medio-alto-eccellente) è determinato dalla media di settore con rivalutazione annuale. I dati inseriti vengono utilizzati da FederlegnoArredo unicamente in forma aggregata per rapporti di settore. I dati sulle frequenze di utilizzo dei diversi indicatori verranno condivisi con UNI per informazioni sull’utilizzo della norma.',
          description: 'tool homepage infoSectionPrivacy simpleBody label',
        }),
      },
      guideSection: {
        title: intl.formatMessage({
          defaultMessage: 'Guida alla compilazione',
          description: 'tool homepage guideSection title label',
        }),
        body: intl.formatMessage({
          defaultMessage:
            'Il sondaggio è composto da una sezione introduttiva e una legata alle categorie specifiche. TECLA non richiede l’inserimento di dati numerici puntuali ma stime in fasce percentuali degli indicatori richiesti, stime per le quali una buona conoscenza dell’azienda e dei suoi processi permette al compilatore una valutazione molto rapida. Prenditi il tempo necessario per compilarlo; potrai sempre salvare una bozza e completare il sondaggio quando preferisci.',
          description: 'tool homepage guideSection body label',
        }),
        infoSubtitle: intl.formatMessage({
          defaultMessage:
            'Potrai ritrovare e consultare l’ultimo feedback ottenuto nello storico. Potrai compilare un nuovo questionario in qualsiasi momento.',
          description: 'tool homepage guideSection infoSubtitle label',
        }),
        typeCard: {
          title: intl.formatMessage({
            defaultMessage: 'Tipologia di risposte',
            description: 'tool homepage guideSection typeCard title label',
          }),
          body: intl.formatMessage({
            defaultMessage:
              'Tutte le risposte sono a scelta singola e non obbligatorie. La non risposta viene interpretata come non conoscenza del dato in questione e pertanto valutata con il punteggio minimo.',
            description: 'tool homepage guideSection typeCard body label',
          }),
        },
        hintCard: {
          title: intl.formatMessage({
            defaultMessage: 'Suggerimenti',
            description: 'tool homepage guideSection hintCard title label',
          }),
          body: intl.formatMessage({
            defaultMessage:
              'Se una parola all’interno della domanda si presenta colorata e sottolineata, cliccando sulla parola riceverai maggiori informazioni sul significato del termine.',
            description: 'tool homepage guideSection hintCard body label',
          }),
          example: intl.formatMessage(
            {
              defaultMessage: '1. Lorem <example>ipsum</example> lorem?',
              description: 'tool homepage guideSection hintCard example label',
            },
            { ...htmlParserOptions }
          ),
        },
      },
      newSurveyModal: {
        title: intl.formatMessage({
          defaultMessage: 'Attenzione',
          description: 'tool homepage newSurveyModal title label',
        }),
        body: intl.formatMessage({
          defaultMessage:
            'Stai iniziando un nuovo sondaggio. Il sistema mantiene traccia dell’ultimo sondaggio terminato. Prima di iniziare uno nuovo ti consigliamo di scaricare e salvare l’ultimo feedback ottenuto dallo storico.',
          description: 'tool homepage newSurveyModal body label',
        }),
        continueBtn: intl.formatMessage({
          defaultMessage: 'Continua',
          description: 'tool homepage newSurveyModal continueBtn label',
        }),
      },
    },
    newSurvey: {
      messagges: {
        saveProgress: {
          success: intl.formatMessage({
            defaultMessage: 'Progressi salvati correttamente',
            description: 'tool newSurvey messagges saveProgress success',
          }),
          error: intl.formatMessage({
            defaultMessage:
              'Errore nel salvataggio dei progressi. Riprovare più tardi',
            description: 'tool newSurvey messagges saveProgress error',
          }),
        },
        closeSurvey: {
          success: intl.formatMessage({
            defaultMessage: 'Sondaggio terminato correttamente',
            description: 'tool newSurvey messagges closeSurvey success',
          }),
          error: intl.formatMessage({
            defaultMessage:
              'Errore nel salvataggio del sondaggio. Riprovare più tardi',
            description: 'tool newSurvey messagges closeSurvey error',
          }),
        },
      },
      title: intl.formatMessage({
        defaultMessage: 'Nuovo sondaggio',
        description: 'tool newSurvey title label',
      }),
      welcome: {
        title: intl.formatMessage({
          defaultMessage: 'Benvenuto al Tool di circolarità.',
          description: 'tool newSurvey welcome title label',
        }),
        subtitle: intl.formatMessage({
          defaultMessage:
            'Puoi scegliere 2 percorsi per ottenere il livello di circolarità della tua organizzazione: Puoi optare per la compilazione estensiva del tool con tutti gli indicatori della specifica tecnica o puoi scegliere di personalizzare il tuo sondaggio rispondendo ad alcune domande preliminari che ci aiuteranno ad offrirti un sondaggio semplificato e pensato per te. Entrambi vengono suddivisi per le 6 categorie della UNI/TS 11820',
          description: 'tool newSurvey welcome subtitle label',
        }),
        card: {
          title: intl.formatMessage({
            defaultMessage:
              'Scegli il tipo di sondaggio che preferisci compilare:',
            description: 'tool newSurvey welcome card title label',
          }),
          options: {
            complete: intl.formatMessage(
              {
                defaultMessage:
                  '<b>Sondaggio completo</b> (completo di tutti gli indicatori della UNI/TS 11820 per un totale di 69 domande)',
                description:
                  'tool newSurvey welcome card options complete label',
              },
              { ...htmlParserOptions }
            ),
            customize: intl.formatMessage(
              {
                defaultMessage:
                  '<b>Sondaggio personalizzato</b> (prevede l’autocompilazione di alcune famiglie di indicatori in base alle risposte date alle domande preliminari)<raccomandedLabel>consigliato</raccomandedLabel>',
                description:
                  'tool newSurvey welcome card options customize label',
              },
              { ...htmlParserOptions }
            ),
          },
        },
      },
      customize: {
        modal: {
          title: intl.formatMessage({
            defaultMessage: 'Sei pronto per iniziare!',
            description: 'tool customize modal title label',
          }),
          body: intl.formatMessage({
            defaultMessage:
              'Sulla base delle tue risposte il sondaggio è ora composto da ',
            description: 'tool customize modal body label',
          }),
          continueBtn: intl.formatMessage({
            defaultMessage: 'Continua',
            description: 'tool customize modal continueBtn label',
          }),
          backBtn: intl.formatMessage({
            defaultMessage: 'Torna indietro',
            description: 'tool customize modal backBtn label',
          }),
        },
        title: intl.formatMessage({
          defaultMessage: 'Personalizza il tuo sondaggio.',
          description: 'tool newSurvey customize title label',
        }),
        subtitle: intl.formatMessage({
          defaultMessage:
            'Inizia con alcune domande preliminari che saranno propedeutiche ad una compilazione e restituzione mirata del livello di circolarità della tua organizzazione.',
          description: 'tool newSurvey customize subtitle label',
        }),
        options: {
          yes: intl.formatMessage({
            defaultMessage: 'Sì',
            description: 'tool newSurvey customize options yes label',
          }),
          no: intl.formatMessage({
            defaultMessage: 'No',
            description: 'tool newSurvey customize options no label',
          }),
        },
        backBtn: intl.formatMessage({
          defaultMessage: 'Torna indietro',
          description: 'tool newSurvey customize backBtn label',
        }),
      },
      survey: {
        title: intl.formatMessage({
          defaultMessage: 'Sondaggio completo UNI/TS 11820',
          description: 'tool newSurvey survey title label',
        }),
        customizeTitle: intl.formatMessage({
          defaultMessage: 'Sondaggio personalizzato UNI/TS 11820',
          description: 'tool newSurvey survey title label',
        }),
        subtitle: intl.formatMessage(
          {
            defaultMessage:
              'Compila la seconda parte del sondaggio per ottenere il tuo livello di circolarità. Puoi navigare tra le categorie senza necessariamente seguire un ordine. <b>È consigliabile compilare tutte le domande del sondaggio per avere una restituzione adeguata.</b> Una domanda senza risposta equivale al punteggio minimo. Ricordati di salvare se vuoi riprendere il sondaggio più tardi.',
            description: 'tool newSurvey survey subtitle label',
          },
          { ...htmlParserOptions }
        ),
        optionsLabel: intl.formatMessage({
          defaultMessage: 'Seleziona il valore corrispondente.',
          description: 'tool newSurvey survey optionsLabel label',
        }),
        buttons: {
          save: intl.formatMessage({
            defaultMessage: 'Salva progress',
            description: 'tool newSurvey survey buttons save label',
          }),
          getFeedback: intl.formatMessage({
            defaultMessage: 'Ottieni feedback',
            description: 'tool newSurvey survey buttons getFeedback label',
          }),
        },
        tabs: {
          materia: intl.formatMessage({
            defaultMessage: 'Materia',
            description: 'tool newSurvey survey tabs materia label',
          }),
          energy: intl.formatMessage({
            defaultMessage: 'Energia',
            description: 'tool newSurvey survey tabs energy label',
          }),
          waste: intl.formatMessage({
            defaultMessage: 'Rifiuti',
            description: 'tool newSurvey survey tabs waste label',
          }),
          logistics: intl.formatMessage({
            defaultMessage: 'Logistica',
            description: 'tool newSurvey survey tabs logistics label',
          }),
          products: intl.formatMessage({
            defaultMessage: 'Prodotti/Servizi',
            description: 'tool newSurvey survey tabs products label',
          }),
          hr: intl.formatMessage({
            defaultMessage: 'HR/Policy',
            description: 'tool newSurvey survey tabs hr label',
          }),
        },
      },
      feedback: {
        modal: {
          title: intl.formatMessage({
            defaultMessage: 'Comunicare il tuo livello di circolarità',
            description: 'tool newSurvey feedback modal title label',
          }),
          body: intl.formatMessage(
            {
              defaultMessage: `<br></br>Il risultato di circolarità proposto da TECLA è un valore numerico percentuale approssimato che attesta il livello di circolarità di un'organizzazione. Ciascuna organizzazione una volta calcolato tale livello può scegliere di approfondire la propria analisi rispetto a quanto previsto dalla specifica tecnica, mediante un'attività di valutazione di:<br></br><br></br><ul>
              <li>Prima parte, ovvero un'auto-valutazione a cura della stessa;</li>
              <li>Seconda parte, ovvero una valutazione a cura del cliente dell'organizzazione interessato a tale livello di circolarità;</li>
              <li>Terza parte, ovvero una valutazione a cura di un organismo indipendente, accreditato ai sensi della UNI CEI EN ISO/IEC 17029 e della UNI EN ISO 14065, trattandosi della verifica di un'asserzione di circolarità.</li></ul><br></br>
              Per asserzione di circolarità si intende la seguente espressione:<br></br><br></br>
              <b>Livello di circolarità dell'organizzazione xxxx relativo al perimetro di valutazione* yyyy (codice ATECO A.BB) misurato nell'anno ZZZZ secondo la UNI/TS 11820:2022 pari a pp%</b>
              <br></br><br></br>Una volta utilizzata la UNI/TS 11820:2022, qualunque organizzazione interessata a comunicare al mercato il proprio risultato può ottenere la certificazione della propria asserzione (o claim) di circolarità avvalendosi di uno degli organismi di certificazione presenti sul mercato e accreditati dall’ente unico di accreditamento in Italia ACCREDIA. L’accreditamento dell’organismo di certificazione deve essere emesso in conformità alla norma UNI CEI EN ISO/IEC 17029 e deve essere specifico per rilasciare attestazioni di conformità alla UNI/TS 11820. La certificazione così ottenuta, ai sensi dell’appendice A della UNI/TS 11820 è accompagnata dal “Marchio UNI Verified Claim” (requisito di certificazione), ulteriore garanzia che attesta la coerenza del claim con quanto previsto dallo standard UNI. La gestione della concessione del marchio UNI è affidata agli organismi di certificazione accreditati da Accredia, rappresentando così l’intervento della cosiddetta “Infrastruttura per la Qualità” (normazione, certificazione, accreditamento + metrologia). Il marchio UNI darà pertanto un’evidenza di conformità immediata e confrontabile con le altre organizzazioni certificate.`,
              description: 'tool newSurvey feedback modal body label',
            },
            { ...htmlParserOptions }
          ),
          subBody: intl.formatMessage({
            defaultMessage: `*Perimetro di valutazione: limiti, sia spaziali che temporali, che definiscono quali processi debbano essere inclusi nella misurazione della circolarità, anche al fine di includere o escludere fasi non direttamente dipendenti dall'organizzazione o fuori dagli obiettivi della misurazione. Il perimetro di valutazione deve risultare coerente con l'obiettivo della misurazione`,
            description: 'tool newSurvey feedback modal subBody label',
          }),
        },
        title: intl.formatMessage({
          defaultMessage: 'Feedback',
          description: 'tool newSurvey feedback title label',
        }),
        endSurvey: intl.formatMessage({
          defaultMessage: 'Termina sondaggio',
          description: 'tool newSurvey feedback endSurvey label',
        }),
        newSurvey: intl.formatMessage({
          defaultMessage: 'Nuovo sondaggio',
          description: 'tool newSurvey feedback newSurvey label',
        }),
        editSurvey: intl.formatMessage({
          defaultMessage: 'Modifica sondaggio',
          description: 'tool newSurvey feedback editSurvey label',
        }),
        downloadFeedBack: intl.formatMessage({
          defaultMessage: 'Scarica feedback',
          description: 'tool newSurvey feedback downloadFeedBack label',
        }),
        categoryLabels: {
          [SurveyCategoriesEnum.MATERIA]: intl.formatMessage({
            defaultMessage: 'Risorse materiche e componenti',
            description: 'tool newSurvey feedback categoryLabels materia label',
          }),
          [SurveyCategoriesEnum.ENERGY]: intl.formatMessage({
            defaultMessage: 'Risorse energetiche e idriche',
            description: 'tool newSurvey feedback categoryLabels energy label',
          }),
          [SurveyCategoriesEnum.WASTE]: intl.formatMessage({
            defaultMessage: 'Rifiuti ed emissioni',
            description: 'tool newSurvey feedback categoryLabels waste label',
          }),
          [SurveyCategoriesEnum.LOGISTICS]: intl.formatMessage({
            defaultMessage: 'Logistica',
            description:
              'tool newSurvey feedback categoryLabels logistics label',
          }),
          [SurveyCategoriesEnum.PRODUCTS]: intl.formatMessage({
            defaultMessage: 'Prodotto/Servizio',
            description:
              'tool newSurvey feedback categoryLabels products label',
          }),
          [SurveyCategoriesEnum.HR]: intl.formatMessage({
            defaultMessage: 'Risorse Umane, asset, policy e sostenibilità',
            description: 'tool newSurvey feedback categoryLabels materia label',
          }),
        },
        header: {
          titleFirstPart: intl.formatMessage({
            defaultMessage: 'Il tuo livello di circolarità è del',
            description:
              'tool newSurvey feedback editSurvey header titleFirstPart label',
          }),
          titleSecondPart: intl.formatMessage({
            defaultMessage: 'corrispondente ad un livello',
            description:
              'tool newSurvey feedback editSurvey header titleSecondPart label',
          }),
          levels: {
            base: intl.formatMessage({
              defaultMessage: 'Base',
              description:
                'tool newSurvey feedback editSurvey header base label',
            }),
            middle: intl.formatMessage({
              defaultMessage: 'Medio',
              description:
                'tool newSurvey feedback editSurvey header middle label',
            }),
            high: intl.formatMessage({
              defaultMessage: 'Alto',
              description:
                'tool newSurvey feedback editSurvey header high label',
            }),
            excelent: intl.formatMessage({
              defaultMessage: 'Eccellente',
              description:
                'tool newSurvey feedback editSurvey header excelent label',
            }),
          },
        },
        comunicateLabel: intl.formatMessage({
          defaultMessage:
            'Scopri come comunicare il tuo livello di circolarità',
          description: 'tool newSurvey feedback comunicateLabel label',
        }),
        infoLabel: intl.formatMessage({
          defaultMessage: 'Tool di circolarità completato il',
          description: 'tool newSurvey feedback infoLabel label',
        }),
        charts: {
          radarTitle: intl.formatMessage({
            defaultMessage: 'Valutazione complessiva',
            description: 'tool newSurvey feedback charts radarTitle label',
          }),
          radarsubTitle: intl.formatMessage({
            defaultMessage: 'Scopri come migliorare',
            description: 'tool newSurvey feedback charts radarsubTitle label',
          }),
          lineTitle: intl.formatMessage({
            defaultMessage: 'Risultati specifici per categorie',
            description: 'tool newSurvey feedback charts lineTitle label',
          }),
        },
        specificTitle: intl.formatMessage({
          defaultMessage: 'Feedback specifici',
          description: 'tool newSurvey feedback specificTitle label',
        }),
        improveTitle: intl.formatMessage({
          defaultMessage: 'Scopri come migliorare',
          description: 'tool newSurvey feedback improveTitle label',
        }),
      },
    },
  },
});
