import { IntlShape } from 'react-intl';

export const studyCenterTranslations = (intl: IntlShape) => ({
  studyCenter: {
    contact: {
      footerText: intl.formatMessage({
        defaultMessage:
          'Compila il form per richiedere i prodotti elaborati dal Centro Studi.',
        description: 'studyCenter contact footerText',
      }),
    },
    categoriesList: {
      title: intl.formatMessage({
        defaultMessage: 'Prodotti',
        description: 'studyCenter categoriesList title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage: 'Esplora i prodotti elaborati dal Centro Studi FLA.',
        description: 'studyCenter categoriesList subtitle',
      }),
    },
  },
});
