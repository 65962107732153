import { Typography } from '@mui/material';
import { CustomInterestCard } from './InterestCard.style';
import {
  SideBarAcademyIcon,
  SideBarCertificationsIcon,
  SideBarChartIcon,
  SideBarCircolarityIcon,
  SideBarCopyrightIcon,
  SideBarFinanceIcon,
  SideBarLibraryIcon,
  SideBarSportelloIcon,
  SideBarSurveyIcon,
  SideBarTreesIcon,
} from 'assets/icons';

import { IInterest } from 'types/stores';

const getIcon = (color: '#212B36' | 'rgba(33,43,54, 0.5)') => ({
  formazione: <SideBarAcademyIcon color={color} />,
  centro_studi: <SideBarChartIcon color={color} />,
  finanziamenti_fisco: <SideBarFinanceIcon color={color} />,
  proprieta_intellettuale: <SideBarCopyrightIcon color={color} />,
  libreria_materiali: <SideBarLibraryIcon color={color} />,
  TECLA: <SideBarCircolarityIcon color={color} />,
  survey: <SideBarSurveyIcon color={color} />,
  bosco_design: <SideBarTreesIcon color={color} />,
  sportello_doganale: <SideBarSportelloIcon color={color} />,
  portale_certificazioni: <SideBarCertificationsIcon color={color} />,
});

const InterestCard = ({
  interest,
  onSetInterests,
  onRemoveInterests,
  cardsSelected,
}: {
  interest: IInterest;
  onSetInterests: (interest: IInterest) => void;
  onRemoveInterests: (interest: IInterest) => void;
  cardsSelected: IInterest[];
}) => {
  const isSelected = !!cardsSelected.find(
    card => card.drupalId === interest.drupalId
  );

  const handleSelect = () => {
    if (isSelected) onRemoveInterests(interest);
    else onSetInterests(interest);
  };

  return (
    <CustomInterestCard isSelected={isSelected} onClick={handleSelect}>
      {
        getIcon(isSelected ? '#212B36' : 'rgba(33,43,54, 0.5)')[
          interest.iconType as keyof typeof getIcon
        ]
      }
      <Typography>
        {interest.interestType?.split('\n').map(text => (
          <>
            {text}
            <br />
          </>
        ))}
      </Typography>
    </CustomInterestCard>
  );
};

export default InterestCard;
