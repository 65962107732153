import { IntlShape } from 'react-intl';

const htmlParserOptions = {
  br: () => <br />,
  b: (str: string) => <b>{str}</b>,
  u: (str: string) => <u>{str}</u>,
  aMaterially: (str: string) => (
    <a
      href="https://www.materially.eu/it/"
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: 'none', fontWeight: 'bold', color: '#637381' }}
    >
      {str}
    </a>
  ),
};

export const sustainabilityTranslations = (intl: IntlShape) => ({
  sustainability: {
    materialLibrary: {
      subtitle: intl.formatMessage(
        {
          defaultMessage:
            'Database di informazioni sui materiali per il settore Legno-Arredo. Realizzato in collaborazione con <aMaterially>Materially</aMaterially>.',
          description: 'MaterialLibrary Page subtitle',
        },
        { ...htmlParserOptions }
      ),
    },
    materialLibraryDetails: {
      buttons: {
        goToSite: intl.formatMessage({
          defaultMessage: 'Vai al sito',
          description: 'MaterialLibraryDetails Page goToSite button label',
        }),
        dataSheet: intl.formatMessage({
          defaultMessage: 'Scheda Tecnica',
          description: 'MaterialLibraryDetails Page dataSheet button label',
        }),
        catalog: intl.formatMessage({
          defaultMessage: 'Catalogo',
          description: 'MaterialLibraryDetails Page catalog button label',
        }),
        showCompanyCard: intl.formatMessage({
          defaultMessage: 'Vedi scheda produttore',
          description:
            'MaterialLibraryDetails Page showCompanyCard button label',
        }),
        download: intl.formatMessage({
          defaultMessage: 'Scarica scheda',
          description: 'MaterialLibraryDetails Page download button label',
        }),
      },
      labels: {
        processing: intl.formatMessage({
          defaultMessage: 'Modalità di lavorazione/applicazione',
          description: 'MaterialLibraryDetails Page labels processing',
        }),
        composition: intl.formatMessage({
          defaultMessage: 'Composizione',
          description: 'MaterialLibraryDetails Page labels composition',
        }),
        rawMaterialsOrigin: intl.formatMessage({
          defaultMessage: 'Origine delle materie prime',
          description: 'MaterialLibraryDetails Page labels rawMaterialsOrigin',
        }),
        field_other_data: intl.formatMessage({
          defaultMessage: 'Altre caratteristiche di rilievo',
          description: 'MaterialLibraryDetails Page labels other',
        }),
        productionSite: intl.formatMessage({
          defaultMessage: 'Luogo di produzione del materiale',
          description: 'MaterialLibraryDetails Page labels productionSite',
        }),
        admissionCriteria: intl.formatMessage({
          defaultMessage: 'Certificazioni',
          description: 'MaterialLibraryDetails Page labels admissionCriteria',
        }),
        sustainability: intl.formatMessage({
          defaultMessage: 'Caratteristiche di  Sostenibilità',
          description: 'MaterialLibraryDetails Page labels sustainability',
        }),
        field_cam_features: intl.formatMessage({
          defaultMessage: 'CAM ARREDO 2022',
          description: 'MaterialLibraryDetails Page labels field_cam_features',
        }),
        field_carbon_footprint_features: intl.formatMessage({
          defaultMessage: 'CARBON FOOTPRINT',
          description:
            'MaterialLibraryDetails Page labels field_carbon_footprint_features',
        }),
        field_material_circularity_features: intl.formatMessage({
          defaultMessage: 'MATERIA CIRCOLARE',
          description:
            'MaterialLibraryDetails Page labels field_material_circularity_features',
        }),
        field_toxity_reduction_features: intl.formatMessage({
          defaultMessage: 'riduzione tossicità',
          description:
            'MaterialLibraryDetails Page labels field_toxity_reduction_features',
        }),
        field_sustainability_process_features: intl.formatMessage({
          defaultMessage: 'PROCESSI SOSTENIBILI',
          description:
            'MaterialLibraryDetails Page labels field_sustainability_process_features',
        }),
      },
    },
    generalSurvey: {
      title: intl.formatMessage({
        defaultMessage: 'Survey Generale',
        description: 'GeneralSurvey Page title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          "Il questionario ha l'obiettivo di mappare le performance delle imprese associate rispetto ai 10 punti del Decalogo.",
        description: 'GeneralSurvey Page subtitle',
      }),
      tabs: {
        personalSurvey: {
          label: intl.formatMessage({
            defaultMessage: 'Storico survey',
            description: 'General Survey Page personal surveys tab label',
          }),
          subtitleOne: intl.formatMessage({
            defaultMessage:
              'In questa sezione puoi trovare il riepilogo dei sondaggi in corso e di quelli completati negli anni precedenti dalla tua organizzazione.',
            description: 'GeneralSurvey Page personal surveys tab subtitleOne',
          }),
          subtitleTwo: intl.formatMessage({
            defaultMessage:
              'Per le survey in corso, è possibile riprendere la compilazione prima di inviarle definitivamente. Ricorda che ogni azienda può avere una sola survey attiva, quindi assicurati di coordinarti con l’utente che sta attualmente compilando il questionario per evitare di sovrascrivere le risposte.',
            description: 'GeneralSurvey Page personal surveys tab subtitleTwo',
          }),
          subtitleThree: intl.formatMessage({
            defaultMessage:
              'Potrai scaricare il report della Federazione non appena sarà disponibile.',
            description:
              'GeneralSurvey Page personal surveys tab subtitleThree',
          }),
          titlePaper: intl.formatMessage({
            defaultMessage: 'Le survey annuali',
            description: 'General Survey Page personal surveys tab title paper',
          }),
          table: {
            year: intl.formatMessage({
              defaultMessage: 'Anno',
              description:
                'General Survey Page personal surveys tab table column',
            }),
            openCollectionDate: intl.formatMessage({
              defaultMessage: 'Data di apertura raccolta',
              description:
                'General Survey Page personal surveys tab table column',
            }),
            status: intl.formatMessage({
              defaultMessage: 'Stato',
              description:
                'General Survey Page personal surveys tab table column',
            }),
            lastSave: intl.formatMessage({
              defaultMessage: 'Ultimo salvataggio',
              description:
                'General Survey Page personal surveys tab table column',
            }),
            complete: intl.formatMessage({
              defaultMessage: 'Completata da',
              description:
                'General Survey Page personal surveys tab table column',
            }),
            sendDate: intl.formatMessage({
              defaultMessage: 'Data di invio',
              description:
                'General Survey Page personal surveys tab table column',
            }),
          },
        },
        surveyOptions: {
          fillingGuidePaper: {
            title: intl.formatMessage({
              defaultMessage: 'Guida alla compilazione',
              description:
                'GeneralSurvey Page SurveyOptions tab filling guide paper title',
            }),
            nextText: intl.formatMessage(
              {
                defaultMessage:
                  'Potrai salvare <b>cliccando avanti</b> ad ogni step e riprenderla in un secondo momento. ',
                description:
                  'GeneralSurvey Page SurveyOptions filling guide next text',
              },
              { ...htmlParserOptions }
            ),
            prevText: intl.formatMessage(
              {
                defaultMessage:
                  'Potrai tornare alla sezione precedente <b>cliccando indietro</b> ad ogni step, per modificare delle risposte.',
                description:
                  'GeneralSurvey Page SurveyOptions filling guide prev text',
              },
              { ...htmlParserOptions }
            ),
            typologyAnswers: {
              title: intl.formatMessage({
                defaultMessage: 'Tipoligia Risposte',
                description:
                  'GeneralSurvey Page SurveyOptions filling guide typologyAnswer title',
              }),
              multipleChoice: intl.formatMessage({
                defaultMessage: 'Risposte a scelta multipla',
                description:
                  'GeneralSurvey Page SurveyOptions filling guide typologyAnswer multiple title',
              }),
              singleChoice: intl.formatMessage({
                defaultMessage: 'Risposte a scelta singola',
                description:
                  'GeneralSurvey Page SurveyOptions filling guide typologyAnswer single title',
              }),
              textAnswer: intl.formatMessage({
                defaultMessage: 'Risposte con inserimento di testo',
                description:
                  'GeneralSurvey Page SurveyOptions filling guide typologyAnswer text title',
              }),
            },
            label: {
              nextButtonLabel: intl.formatMessage({
                defaultMessage: 'Avanti',
                description:
                  'GeneralSurvey Page SurveyOptions tab filling guide next button label',
              }),
              prevButtonLabel: intl.formatMessage({
                defaultMessage: 'Indietro',
                description:
                  'GeneralSurvey Page SurveyOptions tab filling guide prev button label',
              }),
              textfieldLabel: intl.formatMessage({
                defaultMessage: 'Test',
                description:
                  'GeneralSurvey Page SurveyOptions tab filling guide textfield label',
              }),
            },
          },
          secondPaper: {
            title: intl.formatMessage(
              {
                defaultMessage:
                  'In questa sezione puoi avviare la compilazione del nuovo questionario.',
                description: 'GeneralSurvey Page SurveyOptions title tab',
              },
              { ...htmlParserOptions }
            ),
            ol: {
              title: intl.formatMessage({
                defaultMessage:
                  'Il seguente questionario fa parte del progetto FLA Plus ed ha l’obiettivo di mappare le performance delle imprese associate rispetto ai 10 punti del Decalogo (per saperne di più visita: https://fla-plus.it/).',
                description:
                  'GeneralSurvey Page SurveyOptions order list title',
              }),
              subtitle: intl.formatMessage({
                defaultMessage:
                  'La compilazione permetterà di costruire indicatori discreti, legati alla singola domanda, e di sintesi rispetto alle varie sezioni della Survey.',
                description:
                  'GeneralSurvey Page SurveyOptions order list subtitle',
              }),
              li: {
                one: intl.formatMessage(
                  {
                    defaultMessage:
                      '<b>A. Dati Generali</b> (compilare i campi richiesti)',
                    description:
                      'GeneralSurvey Page SurveyOptions order list first li',
                  },
                  { ...htmlParserOptions }
                ),
                two: intl.formatMessage(
                  {
                    defaultMessage:
                      '<b>B. Strategie corporate e organizzazione</b> (6 domande)',
                    description:
                      'GeneralSurvey Page SurveyOptions order list second li',
                  },
                  { ...htmlParserOptions }
                ),
                three: intl.formatMessage(
                  {
                    defaultMessage:
                      '<b>C. Input, processo, output</b> (22 domande)',
                    description:
                      'GeneralSurvey Page SurveyOptions order list third li',
                  },
                  { ...htmlParserOptions }
                ),
                four: intl.formatMessage(
                  {
                    defaultMessage:
                      '<b>D. Drivers della sostenibilità e corcolarità</b> (3 domande)',
                    description:
                      'GeneralSurvey Page SurveyOptions order list fourth li',
                  },
                  { ...htmlParserOptions }
                ),
                five: intl.formatMessage(
                  {
                    defaultMessage:
                      '<b>E. Best Practices</b> (domande aperte con caricamento di file)',
                    description:
                      'GeneralSurvey Page SurveyOptions order list fifth li',
                  },
                  { ...htmlParserOptions }
                ),
                six: intl.formatMessage(
                  {
                    defaultMessage:
                      '<b>F. Conseguenze della crisi del coronavirus:</b> consumatori, imprese, federazione (3 domande)',
                    description:
                      'GeneralSurvey Page SurveyOptions order list sixth li',
                  },
                  { ...htmlParserOptions }
                ),
              },
            },
            suggestionTitle: intl.formatMessage({
              defaultMessage:
                'Ti ricordiamo che una volta inviata non potrai modificarla',
              description: 'GeneralSurvey Page SurveyOptions suggestion title',
            }),
            suggestionText: intl.formatMessage({
              defaultMessage:
                "Ti suggeriamo di avere a portata di mano i dati relativi alla quantità di rifiuti prodotti, l'energia consumata e le certificazioni di sistema e ambientali di prodotto.",
              description: 'GeneralSurvey Page SurveyOptions suggestion text',
            }),
            buttonLabel: intl.formatMessage({
              defaultMessage: 'Inizia Survey',
              description: 'GeneralSurvey Page SurveyOptions button label',
            }),
          },
        },
        survey: {
          label: intl.formatMessage({
            defaultMessage: 'Survey',
            description: 'General Survey Page survey tab label',
          }),
        },
      },
    },
    designWoods: {
      subtitle: intl.formatMessage({
        defaultMessage:
          'Il Bosco del Design è un progetto nato nel 2019 come programma di riforestazione nell’ambito delle attività forestali di Rete Clima, in collaborazione col Comune di Milano. Assarredo e FederlegnoArredo collaborano per portare i temi della responsabilità ambientale e sociale al centro delle strategie di sviluppo delle aziende del settore.',
        description: 'DesignWoods Page subtitle',
      }),
      cards: {
        tressPlanted: intl.formatMessage({
          defaultMessage: 'Alberi Piantati',
          description: 'DesignWoods Page treesPlanted card label',
        }),
        co2: intl.formatMessage({
          defaultMessage: 'CO2',
          description: 'DesignWoods Page co2 card label',
        }),
        projects: intl.formatMessage({
          defaultMessage: 'Progetti',
          description: 'DesignWoods Page projects card label',
        }),
        countriesInvolved: intl.formatMessage({
          defaultMessage: 'Paesi Coinvolti',
          description: 'DesignWoods Page countriesInvolved card label',
        }),
      },
      whySection: {
        title: intl.formatMessage({
          defaultMessage: 'Perché le Aziende piantano alberi in Italia?',
          description: 'DesignWoods Page whySection title',
        }),
        button: intl.formatMessage({
          defaultMessage: 'Partecipa alla campagna',
          description: 'DesignWoods Page whySection button',
        }),
        text: intl.formatMessage(
          {
            defaultMessage:
              ' Tolte le piante sradicate, sistemate le prime ceppaie, riparati i primi steccati, questo luogo magico prende di nuovo forma, ritorna a nascere a vita nuova, ed ecco l’opportunità che prende forma. <br></br> Alla fine, alchimisti non sono solamente quelli che trasformano i metalli in oro, ma anche tutti coloro che con i loro sogni e le loro idee concorrono a cambiare qualcosa e renderlo migliore.',
            description: 'DesignWoods Page whySection text',
          },
          { ...htmlParserOptions }
        ),
        author: intl.formatMessage(
          {
            defaultMessage:
              '<b>Gilberto Fossen</b> <br></br> Guardia Forestale del Comune di Taibon Agordino',
            description: 'DesignWoods Page whySection author',
          },
          { ...htmlParserOptions }
        ),
      },
      firstColSection: {
        title: intl.formatMessage({
          defaultMessage: 'Vaia - Taibon Agordino',
          description: 'DesignWoods Page firstCol section title',
        }),
        text: intl.formatMessage(
          {
            defaultMessage:
              "Si tratta di una occasione per dare concretezza al proprio impegno ambientale dentro la filiera legno-arredo, quale opportunità di aumento del capitale forestale, quale strategia di sviluppo della superficie forestale nazionale. <br></br><br></br><b> Gli alberi al centro del mondo del legno e dell'arredo, da oggi anche grazie al Bosco del Design a Milano.</b><br></br><br></br>Gli interventi di riforestazione delle aree interessate dalla tempesta Vaia, si sono svolti nella primavera 2021 presso Taibon Agordino (BL) grazie anche al fondamentale contributo di Assarredo.",
            description: 'DesignWoods Page firstCol section text',
          },
          { ...htmlParserOptions }
        ),
        button: intl.formatMessage({
          defaultMessage: 'Scopri di più',
          description: 'DesignWoods Page firstCol section button label',
        }),
      },
      secondColSection: {
        title: intl.formatMessage({
          defaultMessage: 'Cormano',
          description: 'DesignWoods Page secondCol section title',
        }),
        text: intl.formatMessage({
          defaultMessage:
            'Nel novembre 2021 sono proseguiti gli interventi forestali finanziati da Assarredo, con la piantagione di 50 alberi in una zona fortemente degradata nel comune di Cormano (MI).',
          description: 'DesignWoods Page secondCol secion text',
        }),
        button: intl.formatMessage({
          defaultMessage: 'Scopri di più',
          description: 'DesignWoods Page secondCol section button label',
        }),
      },
      siteSection: {
        title: intl.formatMessage({
          defaultMessage: 'Reteclima.it',
          description: 'DesignWoods Page siteSection title',
        }),
        text: intl.formatMessage({
          defaultMessage: 'Per maggiori informazioni visita il ',
          description: 'DesignWoods Page siteSection text',
        }),
        link: intl.formatMessage({
          defaultMessage: 'sito.',
          description: 'DesignWoods Page siteSection link label',
        }),
      },
      collaboration: intl.formatMessage({
        defaultMessage:
          'In collaborazione con Rete Clima, ASSARREDO, Associazione di FLA, ha realizzato il Bosco del Design.',
        description: 'DesignWoods Page collaboration text',
      }),
    },
  },
});
