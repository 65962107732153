import { IntlShape } from 'react-intl';

const linkOptions = {
  symbiosis: (str: string) => (
    <a
      style={{ color: '#7B8A72', wordBreak: 'break-all' }}
      href={'http://www.industrialsymbiosis.it/piattaforma'}
      target="_blank"
      rel="noreferrer"
    >
      {str}
    </a>
  ),
  enea: (str: string) => (
    <a
      style={{ color: '#7B8A72', wordBreak: 'break-all' }}
      href={'https://risorse.sostenibilita.enea.it/projects'}
      target="_blank"
      rel="noreferrer"
    >
      {str}
    </a>
  ),
  gse: (str: string) => (
    <a
      style={{ color: '#7B8A72', wordBreak: 'break-all' }}
      href={
        'https://www.gse.it/servizi-per-te/autoconsumo/gruppi-di-autoconsumatori-e-comunita-di-energia-rinnovabile'
      }
      target="_blank"
      rel="noreferrer"
    >
      {str}
    </a>
  ),
  mite: (str: string) => (
    <a
      style={{ color: '#7B8A72', wordBreak: 'break-all' }}
      href={
        'https://gpp.mite.gov.it/PDF/GURI%20183%2006.08.22%20-%20Allegato%20Edilizia.pdf'
      }
      target="_blank"
      rel="noreferrer"
    >
      {str}
    </a>
  ),
  env: (str: string) => (
    <a
      style={{ color: '#7B8A72', wordBreak: 'break-all' }}
      href={
        'https://environment.ec.europa.eu/publications/proposal-ecodesign-sustainable-products-regulation_en'
      }
      target="_blank"
      rel="noreferrer"
    >
      {str}
    </a>
  ),
  circularity: (str: string) => (
    <a
      style={{ color: '#7B8A72', wordBreak: 'break-all' }}
      href={
        'https://circulareconomy.europa.eu/platform/sites/default/files/michele-galatola-european-commission.pdf'
      }
      target="_blank"
      rel="noreferrer"
    >
      {str}
    </a>
  ),
  project: (str: string) => (
    <a
      style={{ color: '#7B8A72', wordBreak: 'break-all' }}
      href={'https://cirpassproject.eu/'}
      target="_blank"
      rel="noreferrer"
    >
      {str}
    </a>
  ),
  omarmail: (str: string) => (
    <a
      style={{ color: '#7B8A72', wordBreak: 'break-all' }}
      href={'mailto:omar.degoli@federlegnoarredo.it'}
      target="_blank"
      rel="noreferrer"
    >
      {str}
    </a>
  ),
  p: (str: string) => <p>{str}</p>,
};

export const improveBodies = (intl: IntlShape, htmlParserOptions?: object) => ({
  ['question_A']: {
    title: intl.formatMessage(
      {
        defaultMessage: `Modelli e meccanismi di simbiosi industriale`,
        description: 'tool improveBody question_A title',
      },
      { ...htmlParserOptions }
    ),
    body: intl.formatMessage(
      {
        defaultMessage: `La simbiosi industriale, attraverso il trasferimento di risorse di scarto in esubero o sottoutilizzate da un'industria ad un'altra, consente di conseguire benefici economici e ambientali derivanti dal mancato smaltimento dei rifiuti e dal consumo evitato di risorse primarie. Complessivamente, consente quindi di ottenere soluzioni di tipo win-win in cui tutti gli attori coinvolti possono trarre vantaggio dalle reciproche interazioni. La simbiosi industriale è anche uno strumento utile di pianificazione territoriale per la valorizzazione locale delle risorse, indubbio fattore di eco-innovazione e di arricchimento per il territorio. In un’ottica olistica di gestione delle aree industriali diventa, inoltre, uno strumento imprescindibile per garantire un uso più efficiente delle risorse e per innescare le condizioni di competitività territoriale ed economica.<br></br><br></br> 
        Un altro importante trend che lavora sulla sinergia tra imprese è quello delle comunità energetiche, gruppi di persone e/o di aziende che si uniscono per produrre e gestire insieme la propria energia. Possono utilizzare fonti di energia rinnovabile come solare, eolica, idroelettrica o biomasse per soddisfare le proprie esigenze energetiche, ridurre la dipendenza dai fornitori di energia convenzionali e migliorare l'efficienza energetica. Possono anche condividere infrastrutture, conoscenze ed esperienze per ottenere risultati più economici ed efficienti. Questo tipo di modello promuove la partecipazione attiva della comunità locale nella produzione e gestione dell'energia, migliorando la resilienza energetica e contribuendo alla transizione verso un futuro più sostenibile.<br></br><br></br> 
        Sia per la simbiosi industriale che per le comunità energetiche, l'obiettivo è promuovere la sostenibilità e l'innovazione, ottimizzare l'uso delle risorse e creare valore per le comunità e l'ambiente.<br></br><br></br><p><b>Riferimenti</b>:</p>
        <p>Piattaforma Industrial Symbiosis: <symbiosis>http://www.industrialsymbiosis.it/piattaforma</symbiosis></p> 
        <p>Elenco dei progetti del Laboratorio Valorizzazione delle Risorse nei Sistemi Produttivi e Territoriali di ENEA: <enea>https://risorse.sostenibilita.enea.it/projects</enea></p>
        <p>Pagina GSE sulle comunità energetiche: <gse>https://www.gse.it/servizi-per-te/autoconsumo/gruppi-di-autoconsumatori-e-comunita-di-energia-rinnovabile</gse></p>
        `,
        description: 'tool feedbackbody question_A body',
      },
      { ...htmlParserOptions, ...linkOptions }
    ),
  },
  ['question_B']: {
    title: intl.formatMessage(
      {
        defaultMessage: `Materie prime e componenti`,
        description: 'tool improveBody question_B title',
      },
      { ...htmlParserOptions }
    ),
    body: intl.formatMessage(
      {
        defaultMessage: `In generale, per chi ambisce ad entrare in mercati evoluti, catene di fornitura complesse, progetti che seguono specifici protocolli ambientali, i criteri ESG impongono di monitorare non solo i propri impatti ma anche quelli della propria catena di fornitura. Il controllo dei fornitori è importante per chi lavora con la pubblica amministrazione e deve conformarsi ai Criteri Ambientali Minimi (CAM). La pubblica amministrazione, infatti, richiede spesso che i fornitori soddisfino standard ambientali e di sostenibilità elevati, e il controllo dei fornitori è un modo per garantire che questi requisiti vengano soddisfatti.<br></br><br></br>   
        Il criterio "Do No Significant Harm" (DNSH) richiede che le aziende evitino di causare danni significativi all'ambiente e alla società durante le loro attività e nella loro catena di fornitura, ed è stato incorporato nella tassonomia per la sostenibilità (una legislazione europea che punta a dare criteri per distinguere le attività “sostenibili” dalle altre, in modo da poter indirizzare investimenti e incentivi sulle prime) ed è stato anche menzionato nel Piano Nazionale di Ripresa e Resilienza (PNRR). Questo ha fatto sì che l’onere del rispetto di questo criterio si trasferisse poi sulle aziende che beneficiano di contributi finanziati dal PNRR e sui loro fornitori. Nella filiera dell’arredo, questo è stato il caso, ad esempio, dei contributi per la partecipazione a fiere per le quali sono stati richiesti criteri specifici per gli allestimenti, richieste che le aziende beneficiarie degli incentivi hanno riversato sugli allestitori.<br></br><br></br>   
        Metodologicamente, si suggerisce di definire un proprio disciplinare d’acquisto con requisiti minimi e informazioni omogenee da richiedere ai fornitori. Valutare la possibilità di realizzare autodichiarazioni (ad esempio etichette di secondo tipo secondo la UNI EN ISO 14021) per comunicare al mercato le prestazioni ambientali dei prodotti acquistati, sempre assicurandosi che le asserzioni siano accurate, pertinenti e non fuorvianti, e che siano supportate da prove documentali.<br></br><br></br> 
        Mappare la distanza dai propri fornitori, (100 km è la distanza usata nella UNI/TS 11820), permette inoltre di conoscere la propria possibilità di essere conformi a requisiti  che possono essere richiesti a livello di Pubblica Amministrazione (i criteri ambientali minimi per gli edifici favoriscono con una premialità i materiali approvvigionati entro 150 km dal cantiere)  o anche da specifici protocolli ambientali come il LEED che incorpora questo principio come Location Valuation Factor, meccanismo premiante che usa come discrimine una distanza di 160km.<br></br><br></br> 
        Ma non è solo la distanza di approvvigionamento a contare. La ESPR, una regolamentazione europea in discussione sulla sostenibilità dei prodotti, prevede uno strumento chiamato Passaporto Digitale di Prodotto nel quale dovranno essere tracciate diverse informazioni per le quali è fondamentale stabilire un flusso di informazioni lungo la catena di fornitura.<br></br><br></br><p><b>Riferimenti</b>:</p>
        <p>Criteri ambientali minimi per l’edilizia: <mite>https://gpp.mite.gov.it/PDF/GURI%183%06.08.22%-%Allegato%Edilizia.pdf</mite></p>
        <p>Criterio 3.2.5 Linea guida LEED -WELL di FederlegnoArredo</p>
        <p>ESPR – Pagina di presentazione dell’iniziativa: <env>https://environment.ec.europa.eu/publications/proposal-ecodesign-sustainable-products-regulation_en</env></p> 
        <p>Presentazione Commissione Europea su DPP: <circularity>https://circulareconomy.europa.eu/platform/sites/default/files/michele-galatola-european-commission.pdf</circularity></p>
        <p>CirPass Project – progetto finanziato sul tema DPP: <project>https://cirpassproject.eu/</project> </p><br></br>
        
        <p>Per maggiori informazioni chiedi al tuo segretario di associazione o all’<omarmail>Ufficio Ambiente e Economia Circolare</omarmail></p>
        `,
        description: 'tool improveBody question_B body',
      },
      { ...htmlParserOptions, ...linkOptions }
    ),
  },
  ['question_C']: {
    title: intl.formatMessage(
      {
        defaultMessage: `Progettazione circolare`,
        description: 'tool improveBody question_C title',
      },
      { ...htmlParserOptions }
    ),
    body: intl.formatMessage(
      {
        defaultMessage: `Gli strumenti per l’eco-progettazione, come quelli per la Life Cycle Assessment (LCA), sono sempre più sviluppati per integrarsi in modo semplice con il processo progettuale (ad esempio estensioni per o interoperabilità con strumenti di modellazione 3D e per la gestione del progetto) e con i sistemi aziendali (ad esempio strumenti che usano i dati già presenti nell’ERP).<br></br><br></br>
        In un prossimo futuro tali impatti verranno richiesti dalle aziende più strutturate ai propri fornitori, in virtù dei nuovi regolamenti europei e internazionali su Tassonomia e Disclosure.<br></br><br></br>
        Ti segnaliamo che nei nuovi CAM (Criteri Ambientali Minimi) Arredi per gli acquisti verdi della pubblica amministrazione, il Criterio 4.1.1 Ecoprogettazione: l’arredo deve essere provvisto di un bilancio materico che evidenzia le caratteristiche ambientali dei materiali utilizzati per la fabbricazione dell’arredo e la destinazione finale dei relativi componenti.<br></br><br></br>
        Si segnalano inoltre due normative per l’eco-progettazione: la UNI ISO 14062:2007 Integrazione degli aspetti ambientali nella progettazione e nello sviluppo del prodotto e la UNI EN ISO 14006:2020 Sistemi di gestione ambientale- Linee guida per l’integrazione dell’eco-design.<br></br><br></br>
        Per i prodotti dell’arredo sempre più attenzione viene data al disassemblaggio dei prodotti al fine di recuperare i vari materiali che lo compongono (ad es. il recente progetto di norma in fase di pubblicazione prEN 17902 Furniture - Circularity - Requirement and evaluation methods for dis-reassembly. Altri standard relativi a principi di ecodesign coperti dalla emandanda regolamentazione sui prodotti sostenibili ESPR saranno sviluppati prossimamente dal CEN/TC 207).<br></br><br></br><p><b>Riferimenti</b>:</p>
        <p>CAM Arredi <arredi>https://gpp.mite.gov.it/PDF/GURI%20184%2008.08.22%20-%20Allegato%20Arredi.pdf</arredi></p>
        <p>CAM Edilizia <edilizia>https://gpp.mite.gov.it/PDF/GURI%20183%2006.08.22%20-%20Allegato%20Edilizia.pdf</edilizia></p>
        <p>CAM Arredo Urbano: <mase>https://www.mase.gov.it/sites/default/files/archivio/allegati/GPP/2017/allegato_arredo_urbanopdf.pdf</mase></p>
        <p>Innovawood - Sul sito di innovawood <innova>http://www.innovawood.com/Projects/tabid/257/Default.aspx</innova> ci sono diversi progetti di ricerca e piattaforme interessanti per settore legno;</p>
        <p>BASAJAUN è un importante progetto europeo di innovazione sulla sostenibilità dell'edilizia in legno. Diversi risultati di questo progetto sono disponibili come relazioni su come costruire con il legno, valutazione LCA, riciclabilità ed eco-progettazione di nuovi prodotti e sistemi per l'edilizia. <basa>https://basajaun-horizon.eu</basa></p><br></br>
        
        <p>Per maggiori informazioni chiedi al tuo segretario di associazione o all’<omarmail>Ufficio Ambiente e Economia Circolare</omarmail></p>
        `,
        description: 'tool improveBody question_C body',
      },
      {
        ...htmlParserOptions,
        ...linkOptions,
        arredi: (str: string) => (
          <a
            style={{ color: '#7B8A72', wordBreak: 'break-all' }}
            href={
              'https://gpp.mite.gov.it/PDF/GURI%20184%2008.08.22%20-%20Allegato%20Arredi.pdf'
            }
            target="_blank"
            rel="noreferrer"
          >
            {str}
          </a>
        ),
        edilizia: (str: string) => (
          <a
            style={{ color: '#7B8A72', wordBreak: 'break-all' }}
            href={
              'https://gpp.mite.gov.it/PDF/GURI%20183%2006.08.22%20-%20Allegato%20Edilizia.pdf'
            }
            target="_blank"
            rel="noreferrer"
          >
            {str}
          </a>
        ),
        mase: (str: string) => (
          <a
            style={{ color: '#7B8A72', wordBreak: 'break-all' }}
            href={
              'https://www.mase.gov.it/sites/default/files/archivio/allegati/GPP/2017/allegato_arredo_urbanopdf.pdf'
            }
            target="_blank"
            rel="noreferrer"
          >
            {str}
          </a>
        ),
        innova: (str: string) => (
          <a
            style={{ color: '#7B8A72', wordBreak: 'break-all' }}
            href={'http://www.innovawood.com/Projects/tabid/257/Default.aspx'}
            target="_blank"
            rel="noreferrer"
          >
            {str}
          </a>
        ),
        basa: (str: string) => (
          <a
            style={{ color: '#7B8A72', wordBreak: 'break-all' }}
            href={'https://basajaun-horizon.eu'}
            target="_blank"
            rel="noreferrer"
          >
            {str}
          </a>
        ),
        p: (str: string) => <p>{str}</p>,
      }
    ),
  },
  ['question_D']: {
    title: intl.formatMessage(
      {
        defaultMessage: `Energia Termica o Elettrica`,
        description: 'tool improveBody question_D title',
      },
      { ...htmlParserOptions }
    ),
    body: intl.formatMessage(
      {
        defaultMessage: `Per le aziende, l’installazione di un impianto per l’autoproduzione di energia elettrica è garanzia di risparmio sulla bolletta elettrica, possibilità di trarre profitto dalla cessione delle eccedenze e indipendenza dal punto di vista energetico, il che riduce il pericolo di interruzioni della produzione causate da distacchi non programmati della fornitura elettrica e mette al riparo dai rischi finanziari legati alle fluttuazioni del prezzo dell’energia. Oltre a ciò, le aziende possono accedere a una serie di incentivi fiscali e finanziamenti. Inoltre l’installazione di un impianto per produrre energia da fonti rinnovabili ripaga l’azienda sia in termini di sostenibilità che di green corporate reputation.<br></br><br></br>
        Dal lato consumi, inoltre è molto importante valutare le possibilità di efficientare tutti i consumi di energia di una azienda, quelli relativi agli immobili, ai consumi per necessità produttive, all’illuminazione.<br></br><br></br> 
        Un altro suggerimento, è l'utilizzo delle comunità energetiche per alimentare la propria attività industriale in modo sostenibile e ridurre i costi energetici. Ci sono diverse strategie che le aziende possono adottare, come ad esempio:<br></br><br></br><ul>
        <li>Installazione di impianti di produzione di energia rinnovabile, come pannelli solari o turbine eoliche, all'interno della comunità energetica.</li>
        <li>Partecipazione a progetti di energia rinnovabile condivisa, in cui l'energia viene prodotta e distribuita all'interno della comunità.</li></ul><br></br>
        <p>In questo modo, le aziende del settore legno arredo possono contribuire alla creazione di un futuro più sostenibile e ridurre i costi associati all'energia.</p><br></br><p><b>Riferimenti</b>:</p>
        <p>Pagina GSE sulle comunità energetiche: <gse>https://www.gse.it/servizi-per-te/autoconsumo/gruppi-di-autoconsumatori-e-comunita-di-energia-rinnovabile</gse></p>
        <p>FederlegnoArredo sta effettuando dei “test”  su alcune aziende campione per finalizzare una partnership con un importante player energetico.</p>
        <p>Per maggiori informazioni chiedi al tuo segretario di associazione o all’<omarmail>Ufficio Ambiente e Economia Circolare</omarmail></p>
        `,
        description: 'tool improveBody question_D body',
      },
      {
        ...htmlParserOptions,
        ...linkOptions,
        gse: (str: string) => (
          <a
            style={{ color: '#7B8A72', wordBreak: 'break-all' }}
            href={
              'https://www.gse.it/servizi-per-te/autoconsumo/gruppi-di-autoconsumatori-e-comunita-di-energia-rinnovabile'
            }
            target="_blank"
            rel="noreferrer"
          >
            {str}
          </a>
        ),
      }
    ),
  },
  ['question_E']: {
    title: intl.formatMessage(
      {
        defaultMessage: `Carbon Footprint`,
        description: 'tool improveBody question_E title',
      },
      { ...htmlParserOptions }
    ),
    body: intl.formatMessage(
      {
        defaultMessage: `La Carbon Footprint (tradotta in Impronta di Carbonio) è un parametro che viene utilizzato per stimare le emissioni gas serra causate da un prodotto, da un servizio, da un'organizzazione, da un evento o da un individuo, espresse generalmente in tonnellate di CO2 equivalente (ovvero prendendo come riferimento per tutti i gas serra l'effetto associato alla CO2, assunto pari a 1).<br></br>
        È possibile calcolare la Carbon Foot Print di organizzazione. Gli standard di riferimento sono:<br></br><br></br><ul>
        <li>Greenhouse Gas Protocol (GHG Protocol): Standard e linee guida per contabilizzazione e rendicontazione dei gas serra delle organizzazioni (World Resource Institute, WBCSD);</li>
        <li>UNI EN ISO 14064: Standard per quantificare e rendicontare a livello di organizzazione le emissioni di gas serra e le loro rimozioni (International Standard Organization).</li></ul><br></br>
        <p>Per maggiori informazioni è accessibile un database gratuito (banca dati italiana dei fattori di emissione <arcadia>https://www.arcadia.enea.it/la-banca-dati.html</arcadia>) realizzata da ENEA in collaborazione con esperti di settore per diverse filiere produttive:</p><br></br> 
        <p>Si segnala che ci sono diversi software disponibili per il calcolo delle emissioni di carbonio (Carbon Footprint) e la quantificazione della propria impronta di carbonio. Alcune delle più comuni opzioni sono:</p><br></br><ul>
        <li>Carbon Footprint Calculator: un software gratuito che permette agli utenti di quantificare le loro emissioni di carbonio e di identificare le opportunità di riduzione <carbon>https://www.carbonfootprint.com/</carbon>;</li>
        <li>GHG Protocol: al seguente link <protocol>https://ghgprotocol.org/</protocol> oltre lo standard globale per la quantificazione e la gestione delle emissioni di gas serra, è incluso un software per il calcolo delle emissioni di carbonio.</li></ul><br></br>
        <p>Scegliere il software più adatto dipende dalle esigenze specifiche di ciascuna azienda e dalla sua capacità di gestire i dati e le informazioni.</p><br></br>
        <p>Un altro strumento che dà informazioni sull’impronta di carbonio di un prodotto è L'EPD (Environmental Product Declaration, in italiano Dichiarazione Ambientale di Prodotto), è un documento che descrive in modo trasparente, oggettivo e comparabile l'impatto ambientale di un determinato prodotto o servizio attraverso diverse categorie di impatto. Oltre alla misurazione dell’impronta di carbonio può essere utilizzata anche per la dimostrazione di altre prestazioni ambientali.</p><br></br>
        <p>EPD è sviluppata in applicazione della norma UNI EN ISO 14025:2010 (Etichetta Ecologica di Tipo III); le informazioni ambientali riportate si basano sull'analisi del ciclo di vita, Life Cycle Assessment (LCA), in accordo con le norme della serie ISO 14040.</p><br></br>
        <p>Un EPD per un prodotto del legno-arredo può avere diverse applicazioni, tra cui:</p><br></br><ul>
        <li>Comunicare la sostenibilità ambientale del prodotto: l'EPD fornisce informazioni trasparenti e affidabili sulle emissioni di gas serra, l'utilizzo delle risorse naturali e altri impatti ambientali associati al prodotto.</li>
        <li>Migliorare la prestazione ambientale del prodotto: l'EPD fornisce un'analisi dettagliata del ciclo di vita del prodotto e identifica le opportunità per migliorare la sua sostenibilità ambientale.</li>
        <li>Soddisfare le esigenze dei clienti: molti clienti, soprattutto nei settori pubblico e privato, richiedono informazioni dettagliate sulla sostenibilità ambientale dei prodotti che acquistano. Una EPD può soddisfare queste richieste e aumentare la fiducia dei clienti nei prodotti dell'azienda.</li>
        <li>Conformità ai requisiti normativi: in alcuni paesi, come in Francia per i prodotti dell'edilizia, sono previsti requisiti normativi per la comunicazione dell'impatto ambientale dei prodotti. Un EPD può aiutare l'azienda a soddisfare questi requisiti e a mantenere la conformità alle norme.</li>
        <li>Competere sul mercato: Con una maggiore attenzione ai temi ambientali e una crescente domanda di prodotti sostenibili, un EPD può aiutare l'azienda a competere sul mercato e a differenziarsi dalla concorrenza.</li></ul><br></br><p><b>Riferimenti</b>:</p>
        <p>Per l’EPD , dichiarazione ambientale di prodotto di TIPO III secondo la UNI EN ISO 14025: <environdec>https://www.environdec.com/home</environdec>.</p>
        <p>È possibile sviluppare anche delle EPD di gruppo, come Assobagno ha fatto per le cabine doccia: <assobagno>https://www.federlegnoarredo.it/it/associazioni/assobagno/attivita-e-servizi-per-i-soci/assobagno-il-gruppo-cabine-doccia-ottiene-la-certificazione-epd-per-il-comparto</assobagno></p>
        <p>Per maggiori informazioni chiedi al tuo segretario di associazione o all’<omarmail>Ufficio Ambiente e Economia Circolare</omarmail></p>`,
        description: 'tool improveBody question_E body',
      },
      {
        ...htmlParserOptions,
        ...linkOptions,
        carbon: (str: string) => (
          <a
            style={{ color: '#7B8A72', wordBreak: 'break-all' }}
            href={'https://www.carbonfootprint.com/'}
            target="_blank"
            rel="noreferrer"
          >
            {str}
          </a>
        ),
        arcadia: (str: string) => (
          <a
            style={{ color: '#7B8A72', wordBreak: 'break-all' }}
            href={'https://www.arcadia.enea.it/la-banca-dati.html'}
            target="_blank"
            rel="noreferrer"
          >
            {str}
          </a>
        ),
        protocol: (str: string) => (
          <a
            style={{ color: '#7B8A72', wordBreak: 'break-all' }}
            href={'https://ghgprotocol.org/'}
            target="_blank"
            rel="noreferrer"
          >
            {str}
          </a>
        ),
        environdec: (str: string) => (
          <a
            style={{ color: '#7B8A72', wordBreak: 'break-all' }}
            href={'https://www.environdec.com/home'}
            target="_blank"
            rel="noreferrer"
          >
            {str}
          </a>
        ),
        assobagno: (str: string) => (
          <a
            style={{ color: '#7B8A72', wordBreak: 'break-all' }}
            href={
              'https://www.federlegnoarredo.it/it/associazioni/assobagno/attivita-e-servizi-per-i-soci/assobagno-il-gruppo-cabine-doccia-ottiene-la-certificazione-epd-per-il-comparto'
            }
            target="_blank"
            rel="noreferrer"
          >
            {str}
          </a>
        ),
      }
    ),
  },
  ['question_F']: {
    title: intl.formatMessage(
      {
        defaultMessage: `Mobilità dei dipendenti`,
        description: 'tool improveBody question_F title',
      },
      { ...htmlParserOptions }
    ),
    body: intl.formatMessage(
      {
        defaultMessage: `L’istituzione del Mobility Manager e la predisposizione del Piano di Spostamento Casa-Lavoro (PSCL) annuale è prevista a livello normativo per tutte le aziende con più di 100 dipendenti situate in città metropolitane, città capoluogo di provincia e zone urbane con oltre 50.000 abitanti.<br></br><br></br> 
        L’attuazione di queste pratiche consente alle organizzazioni di fare innovazione, ridurre i costi, migliorare l’engagement dei lavoratori e di integrare azioni di Sostenibilità ambientale tra le pratiche delle imprese in riferimento ai 17 Obiettivi di Sostenibilità (SDGs) Agenda 2030 ONU, in particolare l’Obiettivo 3 (Salute e Benessere), 9 (Imprese, Innovazione e Infrastrutture), 11 (Città e Comunità Sostenibili) e 13 (Lotta contro il Cambiamento Climatico).<br></br><br></br>
        Anche in assenza di un mobility manager può essere utile valutare possibili miglioramenti nella gestione della mobilità aziendale<br></br><br></br><p><b>Riferimenti</b>:</p> <p>Decreto 12 maggio 2021 <gazzetta>https://www.gazzettaufficiale.it/eli/id/2021/05/26/21A03111/sg</gazzetta></p>`,
        description: 'tool improveBody question_F body',
      },
      {
        ...htmlParserOptions,
        ...linkOptions,
        gazzetta: (str: string) => (
          <a
            style={{ color: '#7B8A72', wordBreak: 'break-all' }}
            href={
              'https://www.gazzettaufficiale.it/eli/id/2021/05/26/21A03111/sg'
            }
            target="_blank"
            rel="noreferrer"
          >
            {str}
          </a>
        ),
      }
    ),
  },
  ['question_G']: {
    title: intl.formatMessage(
      {
        defaultMessage: `Riparazione dei prodotti`,
        description: 'tool improveBody question_G title',
      },
      { ...htmlParserOptions }
    ),
    body: intl.formatMessage(
      {
        defaultMessage: `Ripensando i modelli d'impresa, i processi di produzione e i prodotti stessi, rendendoli più durevoli o ottimizzandone l'uso, e partecipando ad un mercato ben funzionante delle materie prime secondarie, le imprese possono ridurre significativamente i costi che devono sostenere per i materiali, l'energia e la gestione dei rifiuti, oltre a migliorare la propria resilienza. Le organizzazioni che si occupano di economia sociale hanno fatto da pioniere nel plasmare ed espandere le attività dell'economia circolare, ad esempio nei settori del riciclaggio di prodotti elettronici e tessili, dei beni di consumo riutilizzabili e delle attività di riparazione e rifabbricazione.<br></br><br></br> 
        Offrire servizi post vendita che allungano la vita del prodotto aiuta anche l’azienda a mantenere il contatto con il cliente anche oltre il momento dell’acquisto, generando quindi altre possibilità di vendita o offerta di servizi.
        Per i prodotti dell’arredo sempre più attenzione viene data al disassemblaggio dei prodotti al fine di recuperare i vari materiali che lo compongono (ad es. il recente progetto di norma in fase di pubblicazione prEN 17902 Furniture - Circularity - Requirement and evaluation methods for dis-reassembly).<br></br><br></br>  
        Questo ed altri aspetti verranno considerati nei prossimi anni anche all’interno della regolamentazione sui prodotti sostenibili che l'UE sta sviluppando e che si applicherà progressivamente a tutti i prodotti. Tra i primi settori ad essere interessati ci saranno i mobili e i materassi. Le prime regolamentazioni settoriali sono attese verso la fine del 2025.<br></br><br></br> 
        
        A marzo 2023 la Commissione Europea ha inoltre presentato una proposta di direttiva sulle attività di riparazione: <commission>https://commission.europa.eu/document/afb20917-5a6c-4d87-9d89-666b2b775aa1_en</commission><br></br><br></br>  
        
        Per maggiori informazioni chiedi al tuo segretario di associazione o all’<omarmail>Ufficio Ambiente e Economia Circolare</omarmail>`,
        description: 'tool improveBody question_G body',
      },
      {
        ...htmlParserOptions,
        ...linkOptions,
        commission: (str: string) => (
          <a
            style={{ color: '#7B8A72', wordBreak: 'break-all' }}
            href={
              'https://commission.europa.eu/document/afb20917-5a6c-4d87-9d89-666b2b775aa1_en'
            }
            target="_blank"
            rel="noreferrer"
          >
            {str}
          </a>
        ),
      }
    ),
  },
  ['question_H']: {
    title: intl.formatMessage(
      {
        defaultMessage: `Formazione del personale`,
        description: 'tool improveBody question_H title',
      },
      { ...htmlParserOptions }
    ),
    body: intl.formatMessage(
      {
        defaultMessage: `Una formazione aziendale diffusa e continua sui temi dell'economia circolare, permette anche di evitare un'erronea comunicazione che può portare anche inconsapevolmente al "greenwashing". La formazione effettuata sui temi della sostenibilità è anche valutata negli strumenti per la rendicontazione della sostenibilità, ed è un'azione fondamentale per far crescere nell’azienda una cultura di impresa orientata alla sostenibilità.<br></br>
        FederlegnoArredo ha partecipato a diversi progetti europei sul tema delle competenze necessarie per affrontare la transizione ecologica nelle aziende della filiera.<br></br><br></br>
        Il Report "SAWYER Holistic approach for the identification of Skills and Safety needs towards a growing sustainability & circularity of furniture sector", disponibile anche in italiano, mostra l’impatto della Twin Transition su 11 profili professionali specifici per la filiera legno-arredo. È scaricabile gratuitamente qui: <circularfurniture>https://circularfurniture-sawyer.eu/downloads/</circularfurniture><br></br>
        Un percorso formativo completo sui principali temi della sostenibilità ambientale è sviluppato dal progetto ALLVIEW ed è disponibile gratuitamente nella piattaforma e-learning di progetto, anche nella traduzione in italiano <allview>https://allview.learning-platform.eu/</allview>. È composto da 4 Moduli:<br></br><br></br><ul> 
        <li>Introduzione all’Economia Circolare;</li>
        <li>Gestione dei rifiuti e modelli di riciclo;</li>
        <li>Ecodesign;</li>
        <li>Modelli di business circolari.</li></ul><br></br>
        <p>I Progetti DITRAMA e WOODIGITAL hanno sviluppato moduli formativi digitali, multimediali e gratuiti (MOOC) per affrontare la transizione ecologica e digitale nel settore arredo, con interventi di esperti di tutta Europa. Sono scaricabili gratuitamente dai siti di progetto:</p>
        <p><ditrama>DITRAMA</ditrama> – corso formativo disponibile in inglese con sottotitoli in italiano</p>
        <p><woodigital>WoodDIGITAL</woodigital> – corso di formazione multilingue, italiano compreso</p>
        <p>È in corso di sviluppo il progetto WoodCIRCLE che svilupperà un percorso formativo di base sui fondamenti della transizione ecologica, dedicato soprattutto a giovani e maestranze del settore.</p>
        <p>È già disponibile il primo report di progetto, sullo scenario e sui bisogni del settore nel merito della transizione ecologica, scaricabile gratuitamente (in inglese) a questo link <woodcircle>https://www.woodcircle.net/s-projects-side-by-side</woodcircle></p><br></br> 
        <p><b>Per approfondimenti</b>:</p>
        <p>Per le tue esigenze di formazione, l’ufficio Formazione di FLA propone inoltre corsi interaziendali e percorsi aziendali ad hoc sulle esigenze della tua azienda, anche finanziabili tramite Fondimpresa.</p>
        <p>Se ti interessa un percorso informativo iniziale sulla rendicontazione della sostenibilità, rivedi i webinar del ciclo “rendicontare la sostenibilità” realizzati dall’<giuliamail>Ufficio formazione</giuliamail> e dall’<omarmail>Ufficio Ambiente e economia circolare di FLA</omarmail></p>
        `,
        description: 'tool improveBody question_H body',
      },
      {
        ...htmlParserOptions,
        ...linkOptions,
        circularfurniture: (str: string) => (
          <a
            style={{ color: '#7B8A72', wordBreak: 'break-all' }}
            href={'https://circularfurniture-sawyer.eu/downloads/'}
            target="_blank"
            rel="noreferrer"
          >
            {str}
          </a>
        ),
        allview: (str: string) => (
          <a
            style={{ color: '#7B8A72', wordBreak: 'break-all' }}
            href={'https://allview.learning-platform.eu/'}
            target="_blank"
            rel="noreferrer"
          >
            {str}
          </a>
        ),
        ditrama: (str: string) => (
          <a
            style={{ color: '#7B8A72', wordBreak: 'break-all' }}
            href={'http://www.ditrama.eu/'}
            target="_blank"
            rel="noreferrer"
          >
            {str}
          </a>
        ),
        woodigital: (str: string) => (
          <a
            style={{ color: '#7B8A72', wordBreak: 'break-all' }}
            href={'https://course.woodigital.eu/'}
            target="_blank"
            rel="noreferrer"
          >
            {str}
          </a>
        ),
        woodcircle: (str: string) => (
          <a
            style={{ color: '#7B8A72', wordBreak: 'break-all' }}
            href={'https://www.woodcircle.net/s-projects-side-by-side'}
            target="_blank"
            rel="noreferrer"
          >
            {str}
          </a>
        ),
        giuliamail: (str: string) => (
          <a
            style={{ color: '#7B8A72', wordBreak: 'break-all' }}
            href={'mailto:giulia.pedraccini@federlegnoarredo.it'}
            target="_blank"
            rel="noreferrer"
          >
            {str}
          </a>
        ),
      }
    ),
  },
});
