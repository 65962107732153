// @mui

import { CSSProperties } from 'react';

// ----------------------------------------------------------------------

function SideBarBagIcon({
  color = '#919EAB',
  style,
  styleIcon,
}: {
  color?: string;
  style?: CSSProperties;
  styleIcon?: CSSProperties;
}) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M4.79999 9.50032L8.39999 4.70032H22.8L26.4 9.50032M4.79999 9.50032V26.3003C4.79999 26.9368 5.05284 27.5473 5.50293 27.9974C5.95302 28.4475 6.56347 28.7003 7.19999 28.7003H24C24.6365 28.7003 25.247 28.4475 25.697 27.9974C26.1471 27.5473 26.4 26.9368 26.4 26.3003V9.50032M4.79999 9.50032H26.4M20.4 14.3003C20.4 15.5734 19.8943 16.7943 18.9941 17.6944C18.0939 18.5946 16.873 19.1003 15.6 19.1003C14.3269 19.1003 13.106 18.5946 12.2059 17.6944C11.3057 16.7943 10.8 15.5734 10.8 14.3003"
        stroke={color}
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={styleIcon}
      />
    </svg>
  );
}

export default SideBarBagIcon;
