/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IMaterial {
  type: string;
  id: string;
  title: string;
  field_cam_sticker: boolean;
  field_carbon_footprint_sticker: boolean;
  field_circularity_sticker: boolean;
  field_sustainability_sticker: boolean;
  field_toxity_sticker: boolean;
  drupal_internal__nid: number;
  drupal_internal__vid: number;
  langcode: Langcode;
  revision_timestamp: Date;
  revision_log: null;
  status: boolean;
  created: Date;
  changed: Date;
  promote: boolean;
  sticky: boolean;
  default_langcode: boolean;
  revision_translation_affected: boolean;
  path: Path;
  content_translation_source: string;
  content_translation_outdated: boolean;
  field_biodegradability: any[];
  field_bio_based_rate: number;
  field_bio_based_validator: null;
  field_carbon_footprint: null;
  field_catalogue_link: FieldDatasheetLinkClass;
  field_composition: FieldCompositionClass;
  field_compostability: any[];
  field_datasheet_link: FieldDatasheetLinkClass;
  field_description: FieldCompositionClass;
  field_formaldehyde: null;
  field_lca: boolean;
  field_lca_type: null;
  field_other_data: FieldCompositionClass;
  field_other_toxic_substances: null;
  field_processing: FieldCompositionClass;
  field_recyclability: string[];
  field_recycled_content: string[];
  field_recycled_content_rate: number;
  field_recycled_content_validator: null;
  field_renewable_energy_usage: string[];
  field_renewable_sources: boolean;
  field_renewable_sources_origin: string[];
  field_renewable_sources_rate: null;
  field_sustainability: boolean;
  field_sustainability_description: null;
  field_toxicity_reduction: null;
  field_volatile_organic_compounds: null;
  field_water_management: boolean;
  field_water_management_rate: number;
  field_website: FieldDatasheetLinkClass;
  field_material_circularity_features: string[];
  field_sustainability_process_features: string[];
  field_circularity_sticker_summary: string[];
  field_toxity_reduction_features: any[];
  field_cam_features: string[];
  field_cam_sticker_summary: string[];
  field_carbon_footprint_features: any[];
  field_sustainability_sticker_summary: string[];
  links: FieldAdmissionCriterionLinks;
  field_admission_criteria: FieldAdmissionCriterion[];
  field_categories: FieldCategory[];
  field_company: FieldCompany;
  field_images: FieldImage[];
  field_material_types: FieldAdmissionCriterion[];
  field_sustainabilities: any[];
  node_type: NodeType;
  revision_uid: RevisionUid;
  uid: RevisionUid;
  field_cam_furniture: RevisionUid[];
  field_material_circularity: any[];
  field_production_site: FieldCategory[];
  field_raw_materials_origin: FieldCategory[];
  relationshipNames: string[];
}

export interface FieldAdmissionCriterion {
  type: string;
  id: string;
  drupal_internal__tid: number;
  drupal_internal__revision_id: number;
  langcode: Langcode;
  revision_created: Date;
  revision_log_message: null;
  status: boolean;
  name: string;
  description: null;
  weight: number;
  changed: Date;
  default_langcode: boolean;
  revision_translation_affected: boolean;
  path: Path;
  links: FieldAdmissionCriterionLinks;
  resourceIdObjMeta: FieldAdmissionCriterionResourceIDObjMeta;
  vid: NodeType;
  revision_user: null;
  parent: Parent[];
  relationshipNames: RelationshipName[];
}

export type Langcode = 'it';

export interface FieldAdmissionCriterionLinks {
  describedby: Describedby;
  self: Describedby;
}

export interface Describedby {
  href: string;
}

export interface Parent {
  type: string;
  id: string;
  resourceIdObjMeta: PurpleResourceIDObjMeta;
}

export interface PurpleResourceIDObjMeta {
  links: ResourceIDObjMetaLinks;
}

export interface ResourceIDObjMetaLinks {
  help: Help;
}

export interface Help {
  href: string;
  meta: Meta;
}

export interface Meta {
  about: string;
}

export interface Path {
  alias: null | string;
  pid: number | null;
  langcode: Langcode;
}

export type RelationshipName = 'vid' | 'revision_user' | 'parent';

export interface FieldAdmissionCriterionResourceIDObjMeta {
  drupal_internal__target_id: number;
}

export interface NodeType {
  type: NodeTypeType;
  id: string;
  resourceIdObjMeta: NodeTypeResourceIDObjMeta;
}

export interface NodeTypeResourceIDObjMeta {
  drupal_internal__target_id: string;
}

export type NodeTypeType =
  | 'taxonomy_vocabulary--taxonomy_vocabulary'
  | 'node_type--node_type'
  | 'media_type--media_type';

export interface RevisionUid {
  type: RevisionUidType;
  id: string;
  resourceIdObjMeta: FieldAdmissionCriterionResourceIDObjMeta;
}

export type RevisionUidType =
  | 'taxonomy_term--cam'
  | 'user--user'
  | 'taxonomy_term--geographical_areas';

export interface FieldCategory {
  type: string;
  id: string;
  drupal_internal__tid: number;
  drupal_internal__revision_id: number;
  langcode: Langcode;
  revision_created: Date;
  revision_log_message: null;
  status: boolean;
  name: string;
  description: null;
  weight: number;
  changed: Date;
  default_langcode: boolean;
  revision_translation_affected: boolean;
  path: Path;
  links: FieldAdmissionCriterionLinks;
  resourceIdObjMeta: FieldAdmissionCriterionResourceIDObjMeta;
  vid: NodeType;
  revision_user: null;
  parent: FieldAdmissionCriterion[];
  relationshipNames: RelationshipName[];
}

export interface FieldCompany {
  type: string;
  id: string;
  drupal_internal__nid: number;
  drupal_internal__vid: number;
  langcode: Langcode;
  revision_timestamp: Date;
  revision_log: null;
  status: boolean;
  title: string;
  created: Date;
  changed: Date;
  promote: boolean;
  sticky: boolean;
  default_langcode: boolean;
  revision_translation_affected: boolean;
  path: Path;
  content_translation_source: string;
  content_translation_outdated: boolean;
  field_address: FieldAddress;
  field_company_certifications: FieldCompositionClass;
  field_contact_email: string;
  field_contact_phone: string;
  field_email: string;
  field_name: string;
  field_phone: string;
  field_startup: boolean;
  field_surname: string;
  field_website: FieldDatasheetLinkClass;
  links: FieldAdmissionCriterionLinks;
  resourceIdObjMeta: FieldAdmissionCriterionResourceIDObjMeta;
  node_type: NodeType;
  revision_uid: RevisionUid;
  uid: RevisionUid;
  relationshipNames: string[];
}

export interface FieldAddress {
  langcode: null;
  country_code: string;
  administrative_area: string;
  locality: string;
  postal_code: string;
  address_line1: string;
}

export interface FieldCompositionClass {
  value: string;
  format: string;
  processed: string;
}

export interface FieldDatasheetLinkClass {
  uri: string;
  title: string;
  options: any[];
}

export interface FieldImage {
  type: string;
  id: string;
  drupal_internal__mid: number;
  drupal_internal__vid: number;
  langcode: Langcode;
  revision_created: Date;
  revision_log_message: null;
  status: boolean;
  name: string;
  created: Date;
  changed: Date;
  default_langcode: boolean;
  revision_translation_affected: boolean;
  path: Path;
  links: FieldAdmissionCriterionLinks;
  resourceIdObjMeta: FieldAdmissionCriterionResourceIDObjMeta;
  bundle: NodeType;
  revision_user: null;
  uid: RevisionUid;
  thumbnail: FieldMediaImage;
  field_media_image: FieldMediaImage;
  relationshipNames: string[];
}

export interface FieldMediaImage {
  type: string;
  id: string;
  drupal_internal__fid: number;
  langcode: Langcode;
  filename: string;
  uri: URI;
  filemime: string;
  filesize: number;
  status: boolean;
  created: Date;
  changed: Date;
  image_style_uri: ImageStyleURI;
  links: FieldAdmissionCriterionLinks;
  resourceIdObjMeta: FieldMediaImageResourceIDObjMeta;
  uid: RevisionUid;
  relationshipNames: string[];
}

export interface ImageStyleURI {
  large: string;
  media_library: string;
  medium: string;
  thumbnail: string;
  wide: string;
}

export interface FieldMediaImageResourceIDObjMeta {
  alt: string;
  title: null | string;
  width: number;
  height: number;
  drupal_internal__target_id: number;
}

export interface URI {
  value: string;
  url: string;
}

export interface IMaterialGeneralFilter {
  type: string;
  id: string;
  name: string;
  weight: number;
  links: IMaterialCategoryLinks;
  parent: IMaterialCategoryParent[];
  relationshipNames: string[];
}

export interface IMaterialCategoryLinks {
  describedby: Describedby;
  self: Describedby;
}

export interface Describedby {
  href: string;
}

export interface IMaterialCategoryParent {
  type: string;
  id: string;
  name: string;
  weight: number;
  links: IMaterialCategoryLinks;
  resourceIdObjMeta: FluffyResourceIDObjMeta;
  parent: ParentParent[];
  relationshipNames: string[];
}

export interface ParentParent {
  type: string;
  id: string;
  resourceIdObjMeta: PurpleResourceIDObjMeta;
}

export interface PurpleResourceIDObjMeta {
  links: ResourceIDObjMetaLinks;
}

export interface ResourceIDObjMetaLinks {
  help: Help;
}

export interface Help {
  href: string;
  meta: Meta;
}

export interface Meta {
  about: string;
}

export interface FluffyResourceIDObjMeta {
  drupal_internal__target_id: number;
}

export enum MaterialSustainabilitiesEnum {
  PROC = 'field_sustainability_process_features',
  CIRC = 'field_material_circularity_features',
  CAM = 'field_cam_features',
  TOX = 'field_toxity_reduction_features',
  LCA = 'field_carbon_footprint_features',
}

export interface IMaterialDetailProps {
  id: string;
  name: string;
  companyName?: string;
  images?: string[];
  description?: string;
  processing?: string;
  composition?: string;
  rawMaterialsOrigin?: string;
  productionSite?: string;
  admissionCriteria?: string;
  sustainabilitityFeatures?: SustObject[];
  other?: string;
  website?: string;
  datasheet?: string;
  catalogue?: string;
  summaries?: {
    cam?: string[];
    circ?: string[];
    proc?: string[];
  };
  field_renewable_sources: boolean;
  field_renewable_energy_usage?: string;
  field_renewable_sources_origin?: string;
}
export interface SustObject {
  key: `${MaterialSustainabilitiesEnum}`;
  features?: string[];
}

export interface IMaterialCompany {
  type: string;
  id: string;
  drupal_internal__nid: number;
  drupal_internal__vid: number;
  langcode: string;
  revision_timestamp: Date;
  revision_log: null;
  status: boolean;
  title: string;
  created: Date;
  changed: Date;
  promote: boolean;
  sticky: boolean;
  default_langcode: boolean;
  revision_translation_affected: boolean;
  path: Path;
  content_translation_source: string;
  content_translation_outdated: boolean;
  field_address: FieldAddress;
  field_company_certifications: FieldCompanyCertifications;
  field_contact_email: string;
  field_contact_phone: string;
  field_email: string;
  field_name: string;
  field_phone: string;
  field_startup: boolean;
  field_surname: string;
  field_website: FieldWebsite;
  links: Links;
  node_type: NodeType;
  revision_uid: Uid;
  uid: Uid;
  relationshipNames: string[];
}

export interface FieldAddress {
  langcode: null;
  country_code: string;
  administrative_area: string;
  locality: string;
  postal_code: string;
  address_line1: string;
}

export interface FieldCompanyCertifications {
  value: string;
  format: string;
  processed: string;
}

export interface FieldWebsite {
  uri: string;
  title: string;
  options: any[];
}

export interface Links {
  describedby: Describedby;
  self: Describedby;
}

export interface Describedby {
  href: string;
}

export interface NodeTypeResourceIDObjMeta {
  drupal_internal__target_id: string;
}

export interface Uid {
  type: string;
  id: string;
  resourceIdObjMeta: RevisionUidResourceIDObjMeta;
}

export interface RevisionUidResourceIDObjMeta {
  drupal_internal__target_id: number;
}
