import { IntlShape } from 'react-intl';
import { modalsData } from '../toolsModals';
import { useToolStore } from 'stores/tool';

const StyledSpan = ({ id, label }: { id: string; label: string }) => (
  <span
    style={{
      color: '#7B8A72',
      textDecoration: 'underline',
      cursor: 'pointer',
      fontSize: 16,
      fontWeight: 700,
    }}
    onClick={() =>
      useToolStore.setState(prev => ({ ...prev, clickedTextId: id }))
    }
  >
    {label}
  </span>
);

/**
 * This Fn generate this: 
 * [{
        "label": "1. Lorem "
    },
    {
        "id": 1,
        "label": "ipsum"
    },
    {
        "label": "lorem"
    }]
    
 * from this '1. Lorem <click id={1}>ipsum</click> lorem'
 */
const splitClickableItems = (
  inputString: string
): { id?: string; label: string }[] => {
  // const regex = /(<\/?\w+>[^<>]*<\/?\w+>)|\s+/g;
  const regex = /(<click id={(\d+)}>[^<>]*<\/click>)|\s+/g;
  const matches =
    inputString.match(regex)?.filter(el => !!el && !!el.trim()) || [];
  const splitted = inputString.split(regex);
  const result = splitted.filter((str, idx) =>
    Number.isInteger(+str)
      ? !matches.includes(inputString.split(regex)[idx - 1])
      : str && str.trim() !== ''
  );
  const concat: { id?: string; label: string }[] = [];
  result.map((str, idx) => {
    if (idx === 0) return (concat[idx] = { id: undefined, label: str });
    if (!matches.find(el => str === el)) {
      if (concat[concat.length - 1].id)
        concat.push({ id: undefined, label: str });
      else
        concat[concat.length - 1].label = `${
          concat[concat.length - 1].label
        } ${str}`;
    } else {
      const id = str.match(/<click id={(\d+)}>/)?.[1];
      const onlyStrinRegex = /<click id={\d+}>(.*?)<\/click>/;
      const onlyStringMatch = str.match(onlyStrinRegex);
      if (onlyStringMatch) {
        const extractedText = onlyStringMatch[1];
        concat.push({
          id: id ? `${id}` : undefined,
          label: extractedText,
        });
      }
    }
  });
  return concat;
};

const renderTranspiledString = ({
  intl,
  id,
  label,
}: {
  intl: IntlShape;
  id?: string;
  label: string;
}) => {
  const transpiled = id
    ? StyledSpan({
        id: id,
        label: intl.formatMessage(
          {
            defaultMessage: '{label}',
            description: 'tool modals {id} {label} label',
          },
          { label }
        ),
      })
    : intl.formatMessage(
        {
          defaultMessage: '{label}',
          description: 'tool modals  {label} label',
        },
        {
          label,
        }
      );
  return transpiled;
};

export const modalsMapping = (intl: IntlShape) => {
  const input: {
    [key: string]: React.ReactNode;
  } = {};
  Object.entries(modalsData).map(([key, value]) => {
    const splitted = splitClickableItems(value);
    input[key] = (
      <span>
        {splitted?.map(element =>
          renderTranspiledString({
            intl,
            id: element.id,
            label: element.label,
          })
        )}
      </span>
    );
  });
  return input;
};
