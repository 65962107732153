import { useCallback } from 'react';
import { create } from 'zustand';
import {
  OrderByValueMaterialEnum,
  OrderMaterialEnum,
} from 'pages/Sustainability/MaterialLibrary/index.types';
import { IMaterialFiltersStore } from 'types/stores';

export const cardListPageLimit = 8;

const useStore = create<IMaterialFiltersStore>(
  (): IMaterialFiltersStore => ({
    values: {
      sortingOrder: OrderMaterialEnum.ASC,
      sortingFieldName: OrderByValueMaterialEnum.TITLE,
      pageOffset: 0,
      limit: cardListPageLimit,
      field_categories: [],
      field_subcategories: [],
      field_material_types: [],
      field_admission_criteria: [],
      field_sustainabilities: [],
    },
    hasPrevFilters: false,
    pageSelectedTrigger: false,
  })
);

const useMateriaFiltersSelector = () =>
  useStore(useCallback(state => state, []));

export { useMateriaFiltersSelector, useStore as useMaterialStore };
