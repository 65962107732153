import { Typography, useTheme } from '@mui/material';
import { ArrowButton, ArrowContainer } from './CarouselArrows.style';
import { ICarouselArrowsProps } from './CarouselArrows.type';
import { Iconify } from 'components';

const CarouselArrows = ({
  next,
  previous,
  currentSlide,
  numberOfSlide,
}: ICarouselArrowsProps) => {
  //hooks
  const theme = useTheme();
  return (
    <ArrowContainer>
      <ArrowButton onClick={previous.bind(this)}>
        <Iconify icon="ph:caret-left-fill" style={{ color: 'white' }} />
      </ArrowButton>
      <Typography variant="caption" sx={{ color: theme.palette.grey[0] }}>
        {currentSlide + 1} / {numberOfSlide}
      </Typography>
      <ArrowButton onClick={next}>
        <Iconify icon="ph:caret-right-fill" style={{ color: 'white' }} />
      </ArrowButton>
    </ArrowContainer>
  );
};

export { CarouselArrows };
