import { Theme } from '@mui/material';

export interface IGlossaryWord {
  name: string;
  field_name_en: string;
  field_name_fr: string;
  field_name_es: string;
  field_name_de: string;
  field_name_ru: string;
  field_name_zh: string;
}

export enum SelectionSizes {
  LARGE,
  MEDIUM,
  SMALL,
}

export interface ILetterProps {
  active: boolean;
  index: number;
  hoverIndex?: number;
}

export interface IStyledDrawer {
  theme?: Theme;
  openNav: boolean | undefined;
  expandDrawer: boolean;
  typing: boolean;
}

export interface IMainContainerProps {
  expandDrawer: boolean;
  typing: boolean;
}
