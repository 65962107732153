import { styled } from '@mui/material';

export const CustomInterestCard = styled('div')(
  ({ isSelected }: { isSelected: boolean }) => ({
    width: '120px',
    height: '120px',
    textAlign: 'center',
    borderRadius: '5px',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',
    cursor: 'pointer',
    backgroundColor: isSelected ? '#EFEEEA' : 'white',
    color: isSelected ? '#0A2233' : '#919EAB',
    ...(!isSelected && {
      border: '1px solid rgba(145, 158, 171, 0.30)',
    }),
    [`@media (min-width: 900px)`]: {
      width: '150px',
      height: '150px',
    },
    [`@media (min-width: 1200px)`]: {
      width: '180px',
      height: '180px',
    },
  })
);
