import { Box, BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface ICommunityIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function CommunityIcon({
  color = '#637381',
  style,
  ...other
}: ICommunityIconProps) {
  return (
    <Box {...other}>
      <svg
        width="18"
        height="12"
        viewBox="0 0 18 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <path
          d="M6.49935 7.45964C4.54935 7.45964 0.666016 8.43464 0.666016 10.3763V11.8346H12.3327V10.3763C12.3327 8.43464 8.44935 7.45964 6.49935 7.45964ZM2.61602 10.168C3.31602 9.68464 5.00768 9.1263 6.49935 9.1263C7.99102 9.1263 9.68268 9.68464 10.3827 10.168H2.61602ZM6.49935 6.0013C8.10768 6.0013 9.41602 4.69297 9.41602 3.08464C9.41602 1.4763 8.10768 0.167969 6.49935 0.167969C4.89102 0.167969 3.58268 1.4763 3.58268 3.08464C3.58268 4.69297 4.89102 6.0013 6.49935 6.0013ZM6.49935 1.83464C7.19102 1.83464 7.74935 2.39297 7.74935 3.08464C7.74935 3.7763 7.19102 4.33464 6.49935 4.33464C5.80768 4.33464 5.24935 3.7763 5.24935 3.08464C5.24935 2.39297 5.80768 1.83464 6.49935 1.83464ZM12.366 7.50964C13.3327 8.20964 13.9993 9.14297 13.9993 10.3763V11.8346H17.3327V10.3763C17.3327 8.69297 14.416 7.73464 12.366 7.50964ZM11.4993 6.0013C13.1077 6.0013 14.416 4.69297 14.416 3.08464C14.416 1.4763 13.1077 0.167969 11.4993 0.167969C11.0493 0.167969 10.6327 0.276302 10.2493 0.459635C10.7743 1.2013 11.0827 2.10964 11.0827 3.08464C11.0827 4.05964 10.7743 4.96797 10.2493 5.70963C10.6327 5.89297 11.0493 6.0013 11.4993 6.0013Z"
          fill={color}
        />
      </svg>
    </Box>
  );
}

export default CommunityIcon;
