import { Box, BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface ICalendarIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function CalendarIcon({
  color = '#5db0b4',
  style,
  ...other
}: ICalendarIconProps) {
  return (
    <Box {...other}>
      <svg
        width="20"
        height="22"
        viewBox="0 0 20 22"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <path
          d="M18 2H17V0H15V2H5V0H3V2H2C0.9 2 0 2.9 0 4V20C0 21.1 0.9 22 2 22H18C19.1 22 20 21.1 20 20V4C20 2.9 19.1 2 18 2ZM18 20H2V9H18V20ZM18 7H2V4H18V7Z"
          fill="#919EAB"
        />
      </svg>
    </Box>
  );
}

export default CalendarIcon;
