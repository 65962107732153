import { IntlShape } from 'react-intl';
import { ContentTypeEnum } from 'types/content';

const htmlParserOptions = {
  br: () => <br />,
  b: (str: string) => <b>{str}</b>,
  privacy: (str: string) => (
    <a href={'https://www.google.com'} target="_blank" rel="noreferrer">
      {str}
    </a>
  ),
};

export const contentTranslations = (intl: IntlShape) => ({
  content: {
    [ContentTypeEnum.WEBINAR]: {
      title: intl.formatMessage({
        defaultMessage: 'Webinar',
        description: 'content webinar title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage: 'Webinar proposti da un consulente esperto in dogane.',
        description: 'content webinar subtitle',
      }),
    },
    [ContentTypeEnum.TUTORIAL]: {
      title: intl.formatMessage({
        defaultMessage: 'Video Tutorial',
        description: 'content tutorial title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'Video tutorial per aiutarti a navigare le banche dati.',
        description: 'content tutorial subtitle',
      }),
    },
    [ContentTypeEnum.DOCUMENTS]: {
      title: intl.formatMessage({
        defaultMessage: 'Schede Esportatori',
        description: 'content documenti title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'Schede pratiche utili per affrontare al meglio i mercati internazionali.',
        description: 'content documenti subtitle',
      }),
      download: intl.formatMessage({
        defaultMessage: 'Scarica documento',
        description: 'content buttons download documents',
      }),
    },
    [ContentTypeEnum.DEEPENINGS]: {
      title: intl.formatMessage({
        defaultMessage: 'Approfondimenti',
        description: 'content approfondimenti title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          "Questa sezione contiene documenti che approfondiscono, nel dettaglio, le conseguenze per l'arredo di specifiche conformità, certificazioni, schemi di rating e criteri vari.",
        description: 'content approfondimenti subtitle',
      }),
      download: intl.formatMessage({
        defaultMessage: 'Scarica la guida completa',
        description: 'content buttons downloadGuide',
      }),
    },
    [ContentTypeEnum.CONVENTIONS]: {
      title: intl.formatMessage({
        defaultMessage: 'Convenzioni',
        description: 'content convenzioni title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage: 'Consulta gli enti certificatori convenzionati.',
        description: 'content convenzioni subtitle',
      }),
      download: intl.formatMessage({
        defaultMessage: 'Scarica documento',
        description: 'content buttons download conventions',
      }),
    },
    [ContentTypeEnum.STUDY_CENTER_PRODUCT]: {
      title: '',
      subtitle: '',
      download: intl.formatMessage({
        defaultMessage: 'Scarica documento completo',
        description: 'content buttons download study center',
      }),
    },
    [ContentTypeEnum.INTELLECTUAL_PROPERTY_CONVENTION]: {
      title: '',
      subtitle: '',
      download: intl.formatMessage({
        defaultMessage: 'Scarica documento',
        description: 'content buttons download intellectual property',
      }),
    },
    [ContentTypeEnum.FINANCE_WEBINAR]: {
      title: '',
      subtitle: '',
    },
    [ContentTypeEnum.INTELLECTUAL_PROPERTY_DEEPENING]: {
      title: intl.formatMessage({
        defaultMessage: 'Altri approfondimenti',
        description: 'content intellectual property title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage: 'Approfondimenti Proprietà Intellettuale',
        description: 'content intellectual property subtitle',
      }),
    },
    [ContentTypeEnum.ACADEMY_DEEPENING]: {
      title: intl.formatMessage({
        defaultMessage: 'Approfondimenti',
        description: 'content academy title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage: 'Approfondimenti Academy',
        description: 'content academy subtitle',
      }),
    },
    [ContentTypeEnum.INTELLECTUAL_PROPERTY_CONTRACTUAL_FORMAT]: {
      title: intl.formatMessage({
        defaultMessage: 'Format Contrattuali',
        description: 'contractual format intellectual property title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage: 'Format Contrattuali Proprietà Intellettuale',
        description: 'contractual format intellectual property subtitle',
      }),
    },
  },
  contentGeneral: {
    contact: intl.formatMessage({
      defaultMessage: 'Contatto',
      description: 'content general contact',
    }),
    other: intl.formatMessage({
      defaultMessage: 'Guarda le schede disponibili',
      description: 'content general other',
    }),
    otherConventions: intl.formatMessage({
      defaultMessage: 'Guarda gli enti certificatori convenzionati.',
      description: 'content general otherContent',
    }),
    otherWebinar: intl.formatMessage({
      defaultMessage: 'Guarda gli altri webinar disponibili',
      description: 'content general otherWebinar',
    }),
    otherStudyCenter: intl.formatMessage({
      defaultMessage: 'Prodotti suggeriti per te',
      description: 'content general otherStudyCenter',
    }),
    otherTutorial: intl.formatMessage({
      defaultMessage: 'Guarda gli altri video tutorial disponibili',
      description: 'content general otherTutorial',
    }),
    otherDeepings: intl.formatMessage({
      defaultMessage: 'Guarda altri approfondimenti',
      description: 'content general otherDeepings',
    }),
    otherIntellectualDeepings: intl.formatMessage({
      defaultMessage:
        'Guarda altri approfondimenti che potrebbero interessarti',
      description: 'content general otherIntellectualDeepings',
    }),
    otherAcademyDeepings: intl.formatMessage({
      defaultMessage:
        'Guarda altri approfondimenti che potrebbero interessarti',
      description: 'content general otherAcademyDeepings',
    }),
    seeOther: intl.formatMessage({
      defaultMessage: 'Vedi altro',
      description: 'content general seeOther',
    }),
    contactFormTitle: intl.formatMessage({
      defaultMessage: 'Richiedi i nostri prodotti e servizi a pagamento',
      description: 'content general contactFormTitle',
    }),
    otherContractualFormat: intl.formatMessage({
      defaultMessage: 'Guarda altri format contrattuali',
      description: 'content general otherContractualForm',
    }),
    cards: {
      master: {
        title: intl.formatMessage({
          defaultMessage: 'Master dogane',
          description: 'content general cards master title',
        }),
        body: intl.formatMessage(
          {
            defaultMessage:
              'A disposizione degli associati il percorso formativo su dogane e contrattualistica tenuto da esperti del settore.',
            description: 'content general cards master body',
          },
          { ...htmlParserOptions }
        ),
        btn: intl.formatMessage({
          defaultMessage: 'Vedi il programma del corso',
          description: 'content general cards master btn',
        }),
      },
      voucher: {
        title: intl.formatMessage({
          defaultMessage: 'Tagliando doganale',
          description: 'content general cards voucher title',
        }),
        body: intl.formatMessage(
          {
            defaultMessage:
              'Pensato per formare dipendenti e collaboratori delle aziende associate, mettiamo a disposizione un percorso di 10 ore tenuto da uno dei nostri consulenti specializzati.',
            description: 'content general cards voucher body',
          },
          { ...htmlParserOptions }
        ),
        btn: intl.formatMessage({
          defaultMessage: 'Richiedi',
          description: 'content general cards voucher btn',
        }),
      },
      expert: {
        title: intl.formatMessage({
          defaultMessage: "Parlane con l'esperto",
          description: 'content general cards expert title',
        }),
        body: intl.formatMessage(
          {
            defaultMessage:
              'Ogni azienda potrà scegliere uno o più moduli formativi della durata massima di 4,5 ore ciascuno tra i seguenti temi: classificazione doganale dei prodotti, adempimenti doganali, Iva e incoterms, sanzioni internazionali, focus importazioni.',
            description: 'content general cards expert body',
          },
          { ...htmlParserOptions }
        ),
        btn: intl.formatMessage({
          defaultMessage: 'Scopri di più',
          description: 'content general cards expert btn',
        }),
      },
    },
  },
});
