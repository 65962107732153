import { Button, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Container, StyledPaper } from './AddOrUpdateInfoProfile.style';
import { IFormProps } from './AddOrUpdateInfoProfile.types';
import { RHFUploader } from 'components';
import { RHFTextField } from 'components';
import {
  useTranslations,
  useResponsive,
  useUpload,
  useJsonApi,
  useSnackbar,
} from 'hooks';
import { useAuth } from 'hooks/api';
import { FormProvider } from 'providers';
import { useAuthSelector, useGlobalStore } from 'stores';
import { IUserToken } from 'types/user';

const defaultValues: IFormProps = {
  email: '',
  field_name: '',
  field_surname: '',
  field_telephone: '',
  field_company: '',
  field_role: '',
  user_picture: [],
};

const AddOrUpdateInfoProfile = () => {
  //hooks
  const theme = useTheme();
  const methods = useForm<IFormProps>({ defaultValues });
  const { showLoader, hideLoader } = useGlobalStore();
  const { updateUserImage, editUser } = useJsonApi({ showLoader, hideLoader });
  const { errorSnackbar, successSnackbar } = useSnackbar();
  const { handleDrop, handleRemove } = useUpload<IFormProps>({
    formContext: methods,
    formFieldName: 'user_picture',
    multiple: false,
  });
  const { handleSubmit, reset } = methods;
  const i18n = useTranslations();
  const isMobile = useResponsive('down', 'md');
  const { user, storeUser } = useAuthSelector();
  const { refreshTokens } = useAuth();

  useEffect(() => {
    if (user && user.email) {
      reset({
        ...user,
        user_picture: user.user_picture
          ? [`${process.env.REACT_APP_HOST}${user?.user_picture}`]
          : [],
      });
    }
  }, [user]);

  const onSubmit = async (data: IFormProps) => {
    showLoader();
    const {
      field_name,
      field_surname,
      field_telephone,
      field_company,
      field_role,
    } = data;
    let newImg = '';
    const profileImage = data.user_picture?.[0];
    const firstRefreshedToken = await refreshTokens();
    const response = await editUser({
      useLoader: false,
      id: user?.uuid as string,
      body: {
        data: {
          type: 'user--user',
          id: user?.uuid,
          attributes: {
            field_name,
            field_surname,
            field_telephone,
            field_company,
            field_role,
          },
        },
      },
      accessToken: firstRefreshedToken?.access_token as string,
    });
    const secondRefreshedToken = await refreshTokens(
      firstRefreshedToken as IUserToken
    );
    if (profileImage && typeof profileImage !== 'string') {
      const updatedImg = await updateUserImage({
        useLoader: false,
        userUid: user?.uuid as string,
        filename: profileImage.name,
        file: profileImage,
        accessToken: secondRefreshedToken?.access_token as string,
      });
      if (updatedImg) {
        newImg = updatedImg;
      }
    }
    await refreshTokens(secondRefreshedToken as IUserToken);
    if (response) {
      user &&
        storeUser({
          ...user,
          ...(newImg && {
            user_picture: newImg,
          }),
          field_name,
          field_surname,
          field_telephone,
          field_company,
          field_role,
        });
      successSnackbar(i18n.general.snackbar.success);
    } else {
      errorSnackbar(i18n.general.errors.generic);
    }
    hideLoader();
  };

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: '100%' }}
    >
      <Container>
        <StyledPaper width={!isMobile ? '400px' : '100%'} height="394px" center>
          <Stack
            sx={{
              height: '250px',
              width: '100%',
            }}
            justifyContent="space-between"
          >
            <RHFUploader
              name={'user_picture'}
              accept={{
                'image/*': [],
              }}
              helperText={
                <Typography
                  variant="body1"
                  sx={{ textAlign: 'center', color: theme.palette.grey[600] }}
                >
                  Allowed *.jpeg, *.jpg, *.png, *.gif
                  <br /> max size of 3.1 MB
                </Typography>
              }
              onDrop={acceptedFiles => handleDrop({ acceptedFiles })}
              onRemove={file => handleRemove({ file })}
              isMultiple={false}
            />
          </Stack>
        </StyledPaper>
        <StyledPaper flex={1} height="auto" padding="52px 35px 25px 35px">
          <Stack gap={!isMobile ? '25px' : '10px'} sx={{ width: '100%' }}>
            <Stack
              flexDirection={!isMobile ? 'row' : 'column'}
              gap="10px"
              sx={{ width: '100%' }}
            >
              <RHFTextField
                name="field_name"
                label={i18n.common.fields.firstName}
                required
                rules={{
                  required: i18n.general.errors.requiredField,
                }}
              />
              <RHFTextField
                name="field_surname"
                label={i18n.common.fields.lastName}
                required
                rules={{
                  required: i18n.general.errors.requiredField,
                }}
              />
            </Stack>
            <Stack
              flexDirection={!isMobile ? 'row' : 'column'}
              gap="10px"
              sx={{ width: '100%' }}
            >
              <RHFTextField
                name="email"
                label={i18n.common.fields.email}
                required
                rules={{
                  required: i18n.general.errors.requiredField,
                }}
                disabled={true}
              />
              <RHFTextField
                name="field_telephone"
                label={i18n.common.fields.phone}
                required
                rules={{
                  required: i18n.general.errors.requiredField,
                }}
              />
            </Stack>
            <Stack
              flexDirection={!isMobile ? 'row' : 'column'}
              gap="10px"
              sx={{ width: '100%' }}
            >
              <RHFTextField
                name="field_company"
                label={i18n.common.fields.association}
                required
                rules={{
                  required: i18n.general.errors.requiredField,
                }}
                disabled={true}
              />
              <RHFTextField
                name="field_role"
                label={i18n.common.fields.role}
                required
                rules={{
                  required: i18n.general.errors.requiredField,
                }}
              />
            </Stack>
            <Stack
              alignItems="flex-end"
              justifyContent="flex-end"
              sx={{ width: '100%', paddingTop: !isMobile ? '200px' : '50px' }}
            >
              <Button
                variant="contained"
                color="primary"
                size="small"
                fullWidth={isMobile}
                type="submit"
              >
                {i18n.common.buttons.saveChanges}
              </Button>
            </Stack>
          </Stack>
        </StyledPaper>
      </Container>
    </FormProvider>
  );
};
export { AddOrUpdateInfoProfile };
