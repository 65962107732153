import { Typography } from '@mui/material';
import { useTranslations } from 'hooks';

const DevModeBadge = () => {
  const i18n = useTranslations().general;
  return (
    <div
      style={{
        zIndex: 6000,
        position: 'fixed',
        background: 'red',
        transform: 'rotate(-36deg)',
        top: 40,
        left: -40,
        width: 220,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{
          color: 'white',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
        textTransform={'uppercase'}
        variant="subtitle2"
      >
        {i18n.testEnv}
      </Typography>
    </div>
  );
};
export { DevModeBadge };
