/* eslint-disable @typescript-eslint/no-explicit-any */
import { SyntheticEvent, useCallback } from 'react';
import { Path } from 'react-hook-form';
import {
  IHandleDropProps,
  IHandleRemoveProps,
  IUseUploadProps,
} from './useUpload.types';

const useUpload = <T>({
  formContext,
  formFieldName: defaultFieldName,
  multiple: defaultMultiple = true,
}: IUseUploadProps<T>) => {
  const { setValue, getValues } = formContext;

  const getTargetFieldName = (currentFormFieldName?: Path<T>) =>
    (currentFormFieldName || defaultFieldName) as Path<T>;

  const getMultiple = (multiple?: boolean) =>
    (multiple || defaultMultiple) as boolean;

  const handleDrop = useCallback(
    ({ acceptedFiles, formFieldName, multiple }: IHandleDropProps<T>) => {
      const targetFieldName = getTargetFieldName(formFieldName);
      const images = getValues(targetFieldName);
      const isMultiple = getMultiple(multiple);

      setValue(targetFieldName, [
        ...(isMultiple ? (images as File[]) : []),
        ...acceptedFiles.map((file: File) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ] as any);
    },
    []
  );

  const handleRemove = ({ file, formFieldName }: IHandleRemoveProps<T>) => {
    const targetFieldName = getTargetFieldName(formFieldName);
    const currentImages = getValues(targetFieldName) as File[];
    const filteredItems =
      currentImages?.filter((_file: File) => _file !== file) || [];
    setValue(targetFieldName, filteredItems as any);
  };

  const handleRemoveAll = (_e?: SyntheticEvent, formFieldName?: Path<T>) => {
    const targetFieldName = getTargetFieldName(formFieldName);
    setValue(targetFieldName, [] as any);
  };

  return { handleDrop, handleRemove, handleRemoveAll };
};

export { useUpload };
