const CloseCircularIcon = ({
  fill = 'white',
  style,
  ...other
}: {
  fill?: string;
  style: React.CSSProperties;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      {...other}
    >
      <g opacity="0.7">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C10.9891 1.5 12.8968 2.29018 14.3033 3.6967C15.7098 5.10322 16.5 7.01088 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9ZM11.2544 10.5C11.2544 10.3 11.1745 10.1083 11.0325 9.96751L10.0575 9.00002L11.0325 8.03251C11.3266 7.73842 11.3266 7.26161 11.0325 6.96751C10.7384 6.67342 10.2616 6.67342 9.96751 6.96751L9.00002 7.94251L8.03252 6.96751C7.73842 6.67342 7.26161 6.67342 6.96752 6.96751C6.67342 7.26161 6.67342 7.73842 6.96752 8.03251L7.94252 9.00002L6.96752 9.96751C6.82553 10.1083 6.74567 10.3 6.74567 10.5C6.74567 10.7 6.82553 10.8917 6.96752 11.0325C7.10834 11.1745 7.30004 11.2544 7.50002 11.2544C7.69999 11.2544 7.89169 11.1745 8.03252 11.0325L9.00002 10.0575L9.96751 11.0325C10.1083 11.1745 10.3 11.2544 10.5 11.2544C10.7 11.2544 10.8917 11.1745 11.0325 11.0325C11.1745 10.8917 11.2544 10.7 11.2544 10.5Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default CloseCircularIcon;
