import imagePlaceHolder from 'assets/images/login/frontImage.png';
import { IMediaCourse, IMediaCourseDetailProps } from 'types/mediaCourse';

export const standardMediaCoursesIncludes = [
  'field_files.field_media_file',
  'field_course_info',
  'field_image.field_media_image',
  'field_course_info',
];

export const parseMediaCourse = (
  data: IMediaCourse
): IMediaCourseDetailProps => {
  const basicInfo: IMediaCourseDetailProps = {
    id: data.id,
    title: data.title,
    image:
      data.field_image?.field_media_image?.image_style_uri?.wide ||
      imagePlaceHolder,
    weight: data.field_weight || 0,
    year: data.field_course_info.field_year,
    subtitle: data.field_course_info.field_text || '',
    count: data.count || 0,
  };
  const body = data.field_description?.value || '';
  return {
    ...basicInfo,
    body,
    files: data.field_files || [],
    courseName: data.field_course_info.name || '',
    focus: data.field_course_focus || '',
    videoSrc: data.field_video?.uri || '',
  };
};

export const ordinalMediaCoursesMapping: { [key: number]: string } = {
  1: 'Primo incontro',
  2: 'Secondo incontro',
  3: 'Terzo incontro',
  4: 'Quarto incontro',
  5: 'Quinto incontro',
  6: 'Sesto incontro',
  7: 'Settimo incontro',
  8: 'Ottavo incontro',
  9: 'Nono incontro',
  10: 'Decimo incontro',
  11: 'Undicesimo incontro',
  12: 'Dodicesimo incontro',
  13: 'Tredicesimo incontro',
  14: 'Quattordicesimo incontro',
  15: 'Quindicesimo incontro',
  16: 'Sedicesimo incontro',
  17: 'Diciasettesimo incontro',
  18: 'Diciottesimo incontro',
  19: 'Diciannovesimo incontro',
  20: 'Ventesimo incontro',
};

export const getOrdinal = (weight: number) =>
  ordinalMediaCoursesMapping[weight] || `Incontro ${weight}`;
