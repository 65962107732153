import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import { IFinalBalanceCardProps } from './FinalBalance.type';
import { Container } from './FinalBalanceCard.style';
import { BarChartIcon, RightArrowIcon } from 'assets/icons';
import PositiveOrNegativeIcon from 'assets/icons/PositiveOrNegativeArrow';
import { useResponsive, useTranslations } from 'hooks';

const FinalBalanceCard = ({
  title = 'Title Here',
  amount,
  percentage,
  withLink = true,
}: IFinalBalanceCardProps) => {
  //hooks
  const theme = useTheme();
  const i18n = useTranslations();
  const translation = i18n.trainingAndConsulting.studyCenter;
  const isSmDown = useResponsive('down', 'sm');

  return (
    <Container>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        // sx={{ width: '100%' }}
      >
        <Stack flexDirection="row" gap="15px" alignItems="center">
          <BarChartIcon color={'#5DB0B4'} />
          <Typography variant="subtitle2">{title}</Typography>
        </Stack>

        {withLink ? (
          <IconButton sx={{ height: '40px', width: '40px' }}>
            <RightArrowIcon style={{ marginBottom: '2.5px' }} />
          </IconButton>
        ) : null}
      </Stack>
      <Typography
        variant="h3"
        sx={{
          color: theme.palette.primary.main,
          mt: isSmDown ? 0 : 2,
          mb: isSmDown ? 0 : -2,
        }}
      >
        {amount}
      </Typography>
      <Stack flexDirection="row" alignItems="center" gap="5px">
        {percentage !== 0 ? (
          <PositiveOrNegativeIcon
            isPositive={(percentage && percentage > 0) || false}
          />
        ) : (
          <PositiveOrNegativeIcon isPositive={true} />
        )}
        <Typography
          variant="body2"
          sx={{ color: theme.palette.grey[600], lineHeight: 0 }}
        >
          <span style={{ fontWeight: 700, color: 'black' }}>
            {percentage && <>{`${percentage > 0 ? '+' : ''}${percentage}`}%</>}
          </span>{' '}
          {translation.kpi.kpiPercentageText}
        </Typography>
      </Stack>
    </Container>
  );
};

export { FinalBalanceCard };
