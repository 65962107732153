import { Theme, styled } from '@mui/material';

export const HeaderContainer = styled('div')(
  ({ theme, goColumn = false }: { theme?: Theme; goColumn: boolean }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...(goColumn &&
      theme && {
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      }),
  })
);

export const Body = styled('div')(
  ({ isCertificationCard = false }: { isCertificationCard?: boolean }) => ({
    ...(!isCertificationCard
      ? {
          height: '150px',
        }
      : {
          minHeight: '150px',
        }),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginTop: !isCertificationCard ? '5px' : '10px',
    marginBottom: !isCertificationCard ? '15px' : '40px',
  })
);

export const ChipsContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  gap: '10px',
  marginBottom: 16,
});

export const ClockContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '10px',
  marginBottom: 32,
});

export const NextFab = styled('div')(({ bg = '#4D5A49' }: { bg?: string }) => ({
  backgroundColor: bg,
  color: 'white',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 16px',
  gap: '8px',
  borderRadius: 50,
}));
