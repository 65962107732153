import { IntlShape } from 'react-intl';
export const materialTranslations = (intl: IntlShape) => ({
  material: {
    form: {
      drawer: {
        title: intl.formatMessage({
          defaultMessage: 'Filtri',
          description: 'form drawer title',
        }),
        category: intl.formatMessage({
          defaultMessage: 'Categoria',
          description: 'form drawer category',
        }),
        subcategory: intl.formatMessage({
          defaultMessage: 'Sottocategoria',
          description: 'form drawer subcategory',
        }),
        materialType: intl.formatMessage({
          defaultMessage: 'Tipo di materiale',
          description: 'form drawer materialType',
        }),
        certification: intl.formatMessage({
          defaultMessage: 'Certificazioni',
          description: 'form drawer certification',
        }),
        sustanibility: intl.formatMessage({
          defaultMessage: 'Caratteristiche di sostenibilità',
          description: 'form drawer sustanibility',
        }),
      },
      textfield: {
        label: intl.formatMessage({
          defaultMessage: 'Ricerca Materiali',
          description: 'form textfield label',
        }),
        placeholder: intl.formatMessage({
          defaultMessage: 'Scrivi una keyword',
          description: 'form textfield placeholder',
        }),
      },
      btn: {
        filter: intl.formatMessage({
          defaultMessage: 'Filtri avanzati',
          description: 'form btn filter',
        }),
        clearAll: intl.formatMessage({
          defaultMessage: 'Cancella filtri',
          description: 'form btn clearAll',
        }),
        apply: intl.formatMessage({
          defaultMessage: 'Applica',
          description: 'form btn apply',
        }),
      },
      select: {
        letter: {
          asc: intl.formatMessage({
            defaultMessage: 'Alfabeticamente dalla (A-Z)',
            description: 'form select letter asc',
          }),
          desc: intl.formatMessage({
            defaultMessage: 'Alfabeticamente dalla (Z-A)',
            description: 'form select letter desc',
          }),
          owner: intl.formatMessage({
            defaultMessage: 'Ordina per produttore',
            description: 'form select letter owner',
          }),
          name: intl.formatMessage({
            defaultMessage: 'Ordina per materiale',
            description: 'form select letter name',
          }),
        },
      },
      results: {
        title: intl.formatMessage({
          defaultMessage: 'Materiali',
          description: 'form results title',
        }),
        label: intl.formatMessage({
          defaultMessage: 'risultati',
          description: 'form results label',
        }),
        ofLabel: intl.formatMessage({
          defaultMessage: 'di',
          description: 'form results ofLabel',
        }),
      },
    },
    company: {
      field_address: intl.formatMessage({
        defaultMessage: 'Indirizzo',
        description: 'company address',
      }),
      field_phone: intl.formatMessage({
        defaultMessage: 'Telefono',
        description: 'company field_phone',
      }),
      field_email: intl.formatMessage({
        defaultMessage: 'Email',
        description: 'company field_email',
      }),
      field_website: intl.formatMessage({
        defaultMessage: 'Sito Web',
        description: 'company field_website',
      }),
      field_company_certifications: intl.formatMessage({
        defaultMessage: 'Certificazioni',
        description: 'company field_company_certifications',
      }),
      field_startup: intl.formatMessage({
        defaultMessage: 'Start-up',
        description: 'company field_startup',
      }),
      field_contact_email: intl.formatMessage({
        defaultMessage: 'Contatto',
        description: 'company field_contact_email',
      }),
    },
    tooltips: {
      cam: {
        title: intl.formatMessage({
          defaultMessage: 'CAM Arredo 2022',
          description: 'material tooltips cam title',
        }),
        label: intl.formatMessage({
          defaultMessage:
            'Materiali che possono contribuire al punteggio secondo i criteri CAM Arredo 2022.',
          description: 'material tooltips cam label',
        }),
      },
      lca: {
        title: intl.formatMessage({
          defaultMessage: 'Carbon Footprint',
          description: 'material tooltips lca title',
        }),
        label: intl.formatMessage({
          defaultMessage: 'Materiali con calcolo della carbon footprint.',
          description: 'material tooltips lca label',
        }),
      },
      circ: {
        title: intl.formatMessage({
          defaultMessage: 'Materia Circolare',
          description: 'material tooltips circ title',
        }),
        label: intl.formatMessage({
          defaultMessage:
            'Materiali con contenuto riciclato e/o da fonte rinnovabile, riciclabili, biodegradabili, compostabili.',
          description: 'material tooltips circ label',
        }),
      },
      tox: {
        title: intl.formatMessage({
          defaultMessage: 'Riduzione Tossicità',
          description: 'material tooltips tox title',
        }),
        label: intl.formatMessage({
          defaultMessage:
            'Materiali che riducono al minimo i contenuti e/o le emissioni di sostanze chimiche di preoccupazione.',
          description: 'material tooltips tox label',
        }),
      },
      proc: {
        title: intl.formatMessage({
          defaultMessage: 'Processi Sostenibili',
          description: 'material tooltips proc title',
        }),
        label: intl.formatMessage({
          defaultMessage:
            'Modelli di produzione o applicazione più sostenibili.',
          description: 'material tooltips tox label',
        }),
      },
    },
  },
});
