import { CSSProperties } from 'react';

function SuccessRoundIcon({
  fill = '#2F9D50',
  style,
}: {
  fill?: string;
  style?: CSSProperties;
}) {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M35 0C28.0777 0 21.3108 2.05271 15.5551 5.89856C9.79933 9.74441 5.3133 15.2107 2.66423 21.6061C0.0151649 28.0015 -0.677952 35.0388 0.672531 41.8282C2.02301 48.6175 5.35644 54.8539 10.2513 59.7487C15.1461 64.6436 21.3825 67.977 28.1719 69.3275C34.9612 70.678 41.9985 69.9848 48.3939 67.3358C54.7893 64.6867 60.2556 60.2007 64.1014 54.4449C67.9473 48.6892 70 41.9223 70 35C70 25.7174 66.3125 16.815 59.7487 10.2513C53.185 3.68749 44.2826 0 35 0ZM30 48.977L17.5 36.477L21.4765 32.5L30 41.023L48.525 22.5L52.5143 26.4647L30 48.977Z"
        fill={fill}
      />
    </svg>
  );
}

export default SuccessRoundIcon;
