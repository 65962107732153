import { styled } from '@mui/material';

export const CarouselContainer = styled('div')({
  position: 'relative',
  maxWidth: 455,
});

export const ItemContainer = styled('div')(({ image }: { image: string }) => ({
  height: 442,
  maxWidth: 455,
  backgroundImage: `url(${image})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  position: 'relative',
  borderRadius: '15px',
}));
