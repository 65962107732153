import { Box, BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface ILibraryIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function LibraryIcon({
  color = '#7b8a72',
  style,
  ...other
}: ILibraryIconProps) {
  return (
    <Box {...other}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 62.832 75"
        style={style}
      >
        <path
          id="Tracciato_478"
          data-name="Tracciato 478"
          d="M36.723,29.884A13.921,13.921,0,1,0,22.76,15.963,13.938,13.938,0,0,0,36.723,29.884ZM36.723,9a6.961,6.961,0,1,1-6.981,6.961A6.991,6.991,0,0,1,36.723,9Zm0,29.756A45.986,45.986,0,0,0,5.307,26.4V64.687A45.986,45.986,0,0,1,36.723,77.042,46.149,46.149,0,0,1,68.139,64.687V26.4A45.987,45.987,0,0,0,36.723,38.759Zm24.435,19.42a53.241,53.241,0,0,0-24.435,9.814,53.143,53.143,0,0,0-24.434-9.849V33.956A39.067,39.067,0,0,1,31.976,43.8l4.747,4.455,4.747-4.42a39.066,39.066,0,0,1,19.687-9.849Z"
          transform="translate(-5.307 -2.042)"
          fill={color}
        />
      </svg>
    </Box>
  );
}

export default LibraryIcon;
