import { Box, BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface ICO2IconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function CO2Icon({ color = '#7B8A72', style, ...other }: ICO2IconProps) {
  return (
    <Box {...other}>
      <svg
        width="35"
        height="24"
        viewBox="0 0 35 24"
        fill={color}
        style={style}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0.000832018 15.9884C0.1689 15.3369 0.265414 14.6571 0.517515 14.0406C1.56835 11.4705 3.48199 9.94126 6.21933 9.46369C6.42567 9.42791 6.63617 9.41293 6.84501 9.39713C7.05385 9.38132 7.26268 9.37716 7.51811 9.36551C7.53974 6.39604 8.63551 3.91746 10.9319 2.03377C12.561 0.69838 14.4538 0.0360942 16.5655 0.00114948C20.4876 -0.0629159 24.4164 2.55461 25.5296 6.94683C26.2244 7.05583 26.9283 7.09743 27.5922 7.28214C30.966 8.22065 33.0885 10.423 33.8332 13.8409C34.8183 18.3621 31.7723 22.9166 27.2294 23.8177C26.6154 23.9391 25.9764 23.9816 25.3491 23.9832C19.3927 23.9949 13.4363 23.9658 7.48067 23.9999C4.16175 24.019 1.33872 21.9356 0.376072 18.9612C0.203844 18.4287 0.123139 17.8671 0 17.3188C0 16.8753 0 16.431 0 15.9875L0.000832018 15.9884ZM9.85858 11.7875C9.75874 11.77 9.70715 11.7609 9.65557 11.7517C8.92672 11.6278 8.2012 11.4331 7.46819 11.3931C3.81064 11.1959 1.04917 14.9525 2.28389 18.4079C3.08096 20.6377 5.02456 21.9905 7.45904 21.9922C13.4254 21.9964 19.3918 21.9947 25.3574 21.9905C25.7435 21.9905 26.1312 21.9631 26.5139 21.9132C30.4236 21.4031 33.0028 17.1532 31.6741 13.4199C30.6366 10.5045 27.96 8.78226 24.874 9.04518C24.537 9.07347 24.2009 9.10509 23.8789 9.13338C23.7732 8.52351 23.7158 7.94525 23.5702 7.3903C22.6209 3.78766 19.3036 1.57865 15.617 2.07454C11.7855 2.58956 8.99661 6.3378 9.59899 10.1609C9.68302 10.6926 9.76872 11.2242 9.85858 11.7867V11.7875Z" />
        <path d="M21.0333 11.0684C23.15 11.0626 24.7966 12.7723 24.829 14.9589C24.8606 17.083 23.1017 18.8436 21.015 18.8394C18.9333 18.8353 17.226 17.0714 17.2285 14.9264C17.231 12.8164 18.9458 11.0775 21.0333 11.0684ZM19.0065 14.8524C19.0506 15.1469 19.0723 15.4465 19.1438 15.7343C19.3502 16.5597 19.9908 17.1404 20.7713 17.2386C21.6016 17.3435 22.3354 16.9774 22.7223 16.2136C23.1433 15.3824 23.1575 14.5204 22.7348 13.6884C22.184 12.6034 20.7737 12.3031 19.8444 13.0586C19.2819 13.5153 19.0698 14.1443 19.0057 14.8524H19.0065Z" />
        <path d="M16.7799 12.7393C16.314 13.0655 15.8813 13.3675 15.4312 13.682C15.0734 13.0072 14.5251 12.6794 13.788 12.6561C13.0067 12.6311 12.4226 12.9565 12.0274 13.6262C11.5141 14.4957 11.6189 15.8045 12.2487 16.5699C13.0941 17.5983 14.8147 17.4477 15.4329 16.2196C15.6675 16.3553 15.9013 16.49 16.1343 16.6265C16.3705 16.7646 16.606 16.9052 16.8539 17.0517C16.5228 17.8046 15.9362 18.2265 15.2348 18.5202C13.1889 19.3763 10.9866 18.4261 10.1762 16.3386C9.38243 14.2927 10.3725 12.0387 12.3935 11.2933C13.8587 10.7524 15.5685 11.171 16.4779 12.2958C16.581 12.4231 16.6634 12.5671 16.7799 12.7393Z" />
        <path d="M25.3193 18.2522C25.1812 18.0924 25.0306 17.9194 24.8833 17.7488C25.321 17.2238 26.4392 17.0674 26.9659 17.4393C27.3844 17.7347 27.4277 18.3354 27.0358 18.7123C26.871 18.8704 26.6614 18.9827 26.4692 19.1116C26.3369 19.2007 26.2004 19.2847 26.0889 19.4403H27.3445V20.15H24.865C24.8359 19.6849 24.9757 19.298 25.3659 19.0334C25.6397 18.8471 25.9417 18.7031 26.2063 18.5059C26.3053 18.4319 26.4126 18.2247 26.3743 18.1457C26.3244 18.0417 26.1206 17.9294 26.0099 17.9526C25.7836 18.0001 25.5756 18.1349 25.3177 18.253L25.3193 18.2522Z" />
      </svg>
    </Box>
  );
}

export default CO2Icon;
