import { Card, Typography, styled, Chip } from '@mui/material';

export const StyledPaper = styled(Card)({
  width: '100%',
  height: '100%',
});

export const ElipsedTypography = styled(Typography)(
  ({ maxLine = 2 }: { maxLine: number }) => ({
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    whiteSpace: 'pre-wrap',
    WebkitLineClamp: maxLine,
    WebkitBoxOrient: 'vertical',
  })
);

export const StyledImage = styled('div')(({ src }: { src: string }) => ({
  height: 191,
  width: '100%',
  backgroundImage: `url(${src})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  position: 'relative',
}));

export const StyledLabel = styled('div')({
  padding: '2px 15px',
  backgroundColor: '#FFF3D6',
  borderRadius: '4px',
  position: 'absolute',
  top: '12px',
  right: '11px',
  fontSize: '14px',
  fontWeight: '400',
  color: '#CC9200',
  textTransform: 'capitalize',
  display: 'flex',
  alignItems: 'center',
});

export const StyledChip = styled(Chip)(({ bgColor }: { bgColor: string }) => ({
  backgroundColor: bgColor,
  color: '#FFF',
}));
