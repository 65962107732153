import { Button, Stack, Typography } from '@mui/material';

import { useTheme } from '@mui/material';
import { IDocumentListProps } from './DocumentList.types';
import { CardImage } from 'components/CardImage/CardImage';
import { useResponsive } from 'hooks';
const DocumentList = ({
  marginTop = 50,
  title,
  titleVariant = 'subtitle1',
  subtitle,
  subtitleVariant = 'body2',
  list,
  button,
  buttonAction,
  onCardClick,
  style = {},
}: IDocumentListProps) => {
  const theme = useTheme();
  const isMobile = useResponsive('down', 'sm');
  //hooks
  return (
    <div style={{ ...style }}>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems={isMobile ? 'center' : 'flex-end'}
        sx={{ marginTop: `${marginTop}px` }}
      >
        <div style={{ ...(isMobile && !!button && { width: '54%' }) }}>
          {title ? (
            <Typography
              variant={titleVariant}
              style={{
                ...(isMobile && {
                  fontSize: 18,
                }),
              }}
            >
              {title}
            </Typography>
          ) : null}
          {subtitle ? (
            <Typography
              variant={subtitleVariant}
              sx={{ color: theme.palette.grey[600] }}
            >
              {subtitle}
            </Typography>
          ) : null}
        </div>
        {button ? (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => buttonAction?.(list[0].contentType)}
          >
            {button}
          </Button>
        ) : null}
      </Stack>
      <Stack
        flexDirection="row"
        gap="20px"
        sx={{ marginTop: '20px' }}
        flexWrap="wrap"
      >
        {list?.map(c => (
          <CardImage key={c.id} {...c} onClick={id => onCardClick(id, c)} />
        ))}
      </Stack>
    </div>
  );
};

export { DocumentList };
