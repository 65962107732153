import imagePlaceHolder from 'assets/images/login/frontImage.png';
import {
  ICourseDetailProps,
  ICourseBasicProps,
} from 'templates/CourseDetail/CourseDetail.types';
import { ICourse } from 'types/academy';
import { ICustomi18n } from 'types/intl';

export const standardCoursesIncludes = [
  'field_file.field_media_file',
  'field_course_category',
  'field_teachers.field_image.field_media_image',
  'field_image.field_media_image',
  'field_contact',
];

export const parseCourse = ({
  data,
  i18n,
}: {
  data: ICourse;
  i18n: ICustomi18n;
}): ICourseDetailProps => {
  const basicInfo: ICourseBasicProps = {
    id: data.id,
    dates: '',
    label: i18n.courseLabel,
    title: data.title,
    image:
      data.field_image?.field_media_image?.image_style_uri?.wide ||
      imagePlaceHolder,
  };
  const body = {
    ...(data.field_objectives?.value && {
      objectives: data.field_objectives?.value || '',
    }),
    ...(data.field_prerequisites?.value && {
      prerequisites: data.field_prerequisites?.value || '',
    }),
    ...(data.field_topics?.value && {
      topics: data.field_topics?.value || '',
    }),
    ...(data.body?.value && {
      additionalInfo: data.body?.value || '',
    }),
  };
  const info = {
    ...(data.field_dates && {
      dates: data.field_dates || data.field_dates[0] || '',
    }),
    ...(data.field_hours && {
      hour: data.field_hours || '',
    }),
    ...(data.field_duration && {
      duration: data.field_duration || '',
    }),
    ...(data.field_cost && {
      cost: data.field_cost || '',
    }),
    ...(data.field_recipients && {
      recipients: data.field_recipients || '',
    }),
    ...(data.field_cfp && {
      cfp: data.field_cfp || '',
    }),
  };

  const address = data.field_contact?.id && {
    name: data.field_contact.name || '',
    email: data.field_contact.field_email || '',
    phoneNumber: data.field_contact.field_phone || '',
  };

  const teachers = data.field_teachers?.length
    ? data.field_teachers.map(t => ({
        name: t.name,
        linkedin: t.field_linkedin?.uri || '',
        img: t.field_image?.[0]?.field_media_image?.image_style_uri?.wide,
      }))
    : [];

  const fileLink = data.field_file || undefined;
  return {
    ...basicInfo,
    ...(data?.field_subtitle && {
      subtitle: data?.field_subtitle || '',
    }),
    body,
    info,
    teachers,
    price: data.field_price || '',
    ...(address && {
      contacts: address,
    }),
    ...(data.field_course_category?.name && {
      category: data.field_course_category.name,
    }),
    color: 'info',
    ...(fileLink && {
      file: fileLink,
    }),
    ...(data.field_link?.uri && {
      externalLink: data.field_link.uri,
    }),
  };
};
