import { IntlShape } from 'react-intl';
import { SurveyCategoriesEnum } from 'types/stores';

const linkOptions = {
  industrialsymbiosis: (str: string) => (
    <a
      style={{ color: '#7B8A72', wordBreak: 'break-all' }}
      href={'http://www.industrialsymbiosis.it/'}
      target="_blank"
      rel="noreferrer"
    >
      {str}
    </a>
  ),
  elencosottoprodotti: (str: string) => (
    <a
      style={{ color: '#7B8A72', wordBreak: 'break-all' }}
      href={'https://www.elencosottoprodotti.it/'}
      target="_blank"
      rel="noreferrer"
    >
      {str}
    </a>
  ),
  risorse: (str: string) => (
    <a
      style={{ color: '#7B8A72', wordBreak: 'break-all' }}
      href={'https://risorse.sostenibilita.enea.it/projects'}
      target="_blank"
      rel="noreferrer"
    >
      {str}
    </a>
  ),
  sfrido: (str: string) => (
    <a
      style={{ color: '#7B8A72', wordBreak: 'break-all' }}
      href={'https://www.sfridoo.com/'}
      target="_blank"
      rel="noreferrer"
    >
      {str}
    </a>
  ),
  ecorefibre: (str: string) => (
    <a
      style={{ color: '#7B8A72', wordBreak: 'break-all' }}
      href={'https://www.ecorefibre.eu'}
      target="_blank"
      rel="noreferrer"
    >
      {str}
    </a>
  ),
  woodcircus: (str: string) => (
    <a
      style={{ color: '#7B8A72', wordBreak: 'break-all' }}
      href={'https://www.woodcircus.eu'}
      target="_blank"
      rel="noreferrer"
    >
      {str}
    </a>
  ),
  lifeeffigestru: (str: string) => (
    <a
      style={{ color: '#7B8A72', wordBreak: 'break-all' }}
      href={'https://www.lifeeffige.eu/strumenti/'}
      target="_blank"
      rel="noreferrer"
    >
      {str}
    </a>
  ),
  lifeeffigestruconte: (str: string) => (
    <a
      style={{ color: '#7B8A72', wordBreak: 'break-all' }}
      href={
        'https://www.lifeeffige.eu/wp-content/uploads/2021/06/Deliverable_B4_CommunicationTool.zip'
      }
      target="_blank"
      rel="noreferrer"
    >
      {str}
    </a>
  ),
  envprop: (str: string) => (
    <a
      style={{ color: '#7B8A72', wordBreak: 'break-all' }}
      href={
        'https://environment.ec.europa.eu/publications/proposal-ecodesign-sustainable-products-regulation_en'
      }
      target="_blank"
      rel="noreferrer"
    >
      {str}
    </a>
  ),
  ciruclar: (str: string) => (
    <a
      style={{ color: '#7B8A72', wordBreak: 'break-all' }}
      href={
        'https://circulareconomy.europa.eu/platform/sites/default/files/michele-galatola-european-commission.pdf'
      }
      target="_blank"
      rel="noreferrer"
    >
      {str}
    </a>
  ),
  cirpas: (str: string) => (
    <a
      style={{ color: '#7B8A72', wordBreak: 'break-all' }}
      href={'https://cirpassproject.eu/'}
      target="_blank"
      rel="noreferrer"
    >
      {str}
    </a>
  ),
  mite: (str: string) => (
    <a
      style={{ color: '#7B8A72', wordBreak: 'break-all' }}
      href={
        'https://gpp.mite.gov.it/PDF/GURI%20184%2008.08.22%20-%20Allegato%20Arredi.pdf'
      }
      target="_blank"
      rel="noreferrer"
    >
      {str}
    </a>
  ),
  efrag: (str: string) => (
    <a
      style={{ color: '#7B8A72', wordBreak: 'break-all' }}
      href={'https://www.efrag.org/ '}
      target="_blank"
      rel="noreferrer"
    >
      {str}
    </a>
  ),
  services: (str: string) => (
    <a
      style={{ color: '#7B8A72', wordBreak: 'break-all' }}
      href={
        'https://www.federlegnoarredo.it/it/servizi/normativa/news/approfondimenti/webinar-presentazione-portale-arredo-technical-assistant-e-guida-leed-well'
      }
      target="_blank"
      rel="noreferrer"
    >
      {str}
    </a>
  ),
  omarmail: (str: string) => (
    <a
      style={{ color: '#7B8A72', wordBreak: 'break-all' }}
      href={'mailto:omar.degoli@federlegnoarredo.it'}
      target="_blank"
      rel="noreferrer"
    >
      {str}
    </a>
  ),
  giuliamail: (str: string) => (
    <a
      style={{ color: '#7B8A72', wordBreak: 'break-all' }}
      href={'mailto:giulia.pedraccini@federlegnoarredo.it'}
      target="_blank"
      rel="noreferrer"
    >
      {str}
    </a>
  ),
  p: (str: string) => <p>{str}</p>,
};

export const feedbackBodies = (
  intl: IntlShape,
  htmlParserOptions?: object
) => ({
  [SurveyCategoriesEnum.MATERIA]: {
    title: intl.formatMessage(
      {
        defaultMessage: `Risorse materiche e componenti`,
        description: 'tool feedbackbody MATERIA title',
      },
      { ...htmlParserOptions }
    ),
    under: intl.formatMessage(
      {
        defaultMessage: `Ci sono diverse strategie che l'azienda può utilizzare per contribuire a ridurre l'utilizzo di materie prime vergini e l'impatto ambientale dell'industria, aumentando al contempo l'efficienza e la resilienza delle risorse. Si propone a titolo esemplificativo di:<br></br><br></br><ul>
        <li>Analizzare i propri processi produttivi per individuare opportunità di recupero e riutilizzo di materie prime seconde. Ad esempio, è possibile recuperare e riciclare i rifiuti di produzione, come i residui di lavorazione e i sottoprodotti.</li>
        <li>Sviluppare partnership con altre organizzazioni per condividere materie prime e scarti. Ad esempio, è possibile collaborare con organizzazioni locali per utilizzare i loro scarti come materie prime o energia.</li>
        <li>Investire in tecnologie per il recupero e il riciclaggio delle materie prime seconde. Ad esempio, le tecnologie per il trattamento dei rifiuti possono essere utilizzate per separare i materiali riciclabili e trasformarli in materie prime seconde.</li>
        <li>Monitorare e valutare i progressi nell'utilizzo di materie prime seconde e la simbiosi industriale. Ad esempio, è possibile utilizzare indicatori come la percentuale di materie prime seconde utilizzate o le emissioni di gas serra evitate per valutare l'efficacia delle strategie implementate.</li></ul><br></br>
        <p>Si suggerisce di consultare i seguenti siti web:</p><br></br><ul>
        <li>Simbiosi Industriale <industrialsymbiosis>http://www.industrialsymbiosis.it</industrialsymbiosis></li>  
        <li>Elenco Sottoprodotti <elencosottoprodotti>https://www.elencosottoprodotti.it</elencosottoprodotti>: si tratta del sito che registra i produttori di sottoprodotti ai sensi del decreto n. 264 del 2016. Può essere di aiuto per capire se c’è un mercato per i propri scarti che potenzialmente a quel punto potrebbero essere considerati sottoprodotti, oppure anche per trovare materiali residuali di altri cicli produttivi che potrebbero essere di interesse per la vostra azienda.</li> 
        <li>Laboratorio Valorizzazione delle Risorse nei Sistemi Produttivi e Territoriali di ENEA <risorse>https://risorse.sostenibilita.enea.it/projects</risorse></li>
        <li>Sfridoo: Servizi e marketplace per imparare a riutilizzare i rifiuti <sfrido>https://www.sfridoo.com</sfrido></li> 
        <li> Ecorefibre, esempio di progetto collaborativo che mira a sviluppare nuove tecnologie e metodologie innovative per il riciclo dei pannelli MDF post-consumo, <ecorefibre>www.ecorefibre.eu.</ecorefibre></li>
        <li><woodcircus>www.woodcircus.eu</woodcircus>, la cui finalità è l'identificazione e la diffusione di buone pratiche e soluzioni innovative per ottimizzare l'uso delle risorse forestali in tutte le parti delle catene del valore e sensibilizzare l'opinione pubblica.</li></ul><br></br>
        <p>Nell'ambito del progetto FLA PLUS, FederlegnoArredo ha anche implementato una Banca dati di materiali sostenibili a disposizione degli associati.</p><br></br> 
        <p>Per capire se uno scarto può essere considerato sottoprodotto e uscire quindi dallo stato di rifiuto puoi contattare l’<omarmail>ufficio Ambiente e economia Circolare di FederlegnoArredo</omarmail>.</p>
        `,
        description: 'tool feedbackbody MATERIA under',
      },
      { ...htmlParserOptions, ...linkOptions }
    ),
    over: intl.formatMessage(
      {
        defaultMessage: `Il risultato raggiunto per questa categoria di indicatori fa presupporre che l'azienda abbia intrapreso un approccio corretto nel contribuire a ridurre l'utilizzo di materie prime vergini e l'impatto ambientale, aumentando al contempo l'efficienza e la resilienza delle risorse. Ci sono comunque alcune azioni che l'azienda può intraprendere per continuare a migliorare la sua performance ambientale, come ad esempio:<br></br><br></br><ul>
        <li>Espandere la propria collaborazione con altre aziende, università e organizzazioni del settore per condividere conoscenze e best practice;</li>
        <li>Investire in tecnologie di lavorazione pulite e sostenibili per ridurre l'impatto ambientale della propria attività;</li>
        <li>Sviluppare nuovi prodotti e servizi sostenibili per soddisfare le crescenti esigenze dei consumatori e del mercato (in accordo con la UNI ISO 14062:2007 Integrazione degli aspetti ambientali nella progettazione e nello sviluppo del prodotto e la UNI EN ISO 14006:2020 Sistemi di gestione ambientale- Linee guida per l’integrazione dell’eco-design);</li>
        <li>Misurare, verificare, certificare e comunicare le performance ambientali dei materiali utilizzati (in accordo con gli standard internazionali della serie UNI EN ISO 14020).</li></ul><br></br>
        <p>A livello informativo si invita a visionare alcune esperienze di simbiosi industriale a livello internazionale e progetti collaborativi di ricerca come ad esempio:</p><ul> 
        <li>Laboratorio Valorizzazione delle Risorse nei Sistemi Produttivi e Territoriali di ENEA <risorse>
        https://risorse.sostenibilita.enea.it/projects</risorse></li>
        <li>ECOREFIBRE , <ecorefibre>http://www.ecorefibre.eu/</ecorefibre> progetto che mira a sviluppare nuove tecnologie e metodologie innovative per il riciclo dei pannelli MDF post-consumo, verso una nuova materia prima seconda così da aumentare le risorse di legno disponibili in Europa.</li> 
        <li>WOODCIRCUS , <woodcircus>https://woodcircus.eu/</woodcircus> la cui finalità è l'identificazione e la diffusione di buone pratiche e soluzioni innovative per ottimizzare l'uso delle risorse forestali in tutte le parti delle catene del valore e sensibilizzare l'opinione pubblica.</li></ul><br></br><p>Ti ricordiamo che nell'ambito del progetto FLA PLUS, FederlegnoArredo ha anche implementato una Banca dati di materiali sostenibili a disposizione degli associati.</p>`,
        description: 'tool feedbackbody MATERIA over',
      },
      { ...htmlParserOptions, ...linkOptions }
    ),
  },
  [SurveyCategoriesEnum.ENERGY]: {
    title: intl.formatMessage(
      {
        defaultMessage: `Risorse Energetiche ed Idriche`,
        description: 'tool feedbackbody ENERGY title',
      },
      { ...htmlParserOptions }
    ),
    under: intl.formatMessage(
      {
        defaultMessage: `Per un'azienda del settore legno-arredo, gli indicatori connessi alle risorse idriche e/o energetiche possono essere importanti per valutare l'impatto ambientale dell'azienda e per identificare opportunità per migliorare la sostenibilità.<br></br><br></br>
        Per quanto riguarda le risorse idriche, alcuni indicatori importanti potrebbero essere:<ul>
        <li>il consumo di acqua per unità di prodotto;</li>
        <li>la quantità di acqua utilizzata per il trattamento delle acque reflue;</li>
        <li>la percentuale di acqua riciclata o riutilizzata.</li></ul><br></br>
        <p>Per quanto riguarda le risorse energetiche, alcuni indicatori importanti potrebbero essere:</p><ul>
        <li>l'utilizzo di fonti rinnovabili di energia;</li>
        <li>l'efficienza energetica dei processi produttivi;</li>
        <li>le emissioni di gas a effetto serra;</li>
        <li>il bilancio energetico.</li></ul><br></br>
        <p>In generale, un'azienda che dimostra di utilizzare le risorse idriche ed energetiche in modo efficiente e sostenibile avrà un vantaggio competitivo e potrà attrarre investitori interessati a investire in aziende che rispettano i criteri ESG.</p><br></br><p>
        Sul lato della comunicazione di questi risultati, nell’ambito del progetto Life EFFIGE <lifeeffigestru>https://www.lifeeffige.eu/strumenti/</lifeeffigestru> a cui FederlegnoArredo ha partecipato, sono disponibili strumenti di calcolo e comunicazione semplificati, al fine di aiutare le aziende ad approcciarsi alla misurazione dei propri impatti ambientali (in particolare il communication Tool <lifeeffigestruconte>https://www.lifeeffige.eu/wp-content/uploads/2021/06/Deliverable_B4_CommunicationTool.zip</lifeeffigestruconte> aiuta a convertire in modo immediato i risparmi raggiunti, ad esempio i m3 di acqua risparmiati, in grandezze  facilmente comunicabili al pubblico)</p><br></br><p>
        Sul fronte dell’efficienza energetica e dell’autoproduzione di energia, FederlegnoArredo sta costruendo un'offerta in partnership con importanti player energetici per offrire servizi legati al consumo e produzione di energia.</p><br></br>
        <p>Contatta il segretario della tua associazione o l’<omarmail>Ufficio Ambiente e Economia circolare</omarmail>.</p>`,
        description: 'tool feedbackbody ENERGY under',
      },
      { ...htmlParserOptions, ...linkOptions }
    ),
    over: intl.formatMessage(
      {
        defaultMessage: `Il consumo di risorse in un'azienda del settore del legno arredo dipenderà dalle specifiche pratiche produttive, dalla localizzazione geografica e dalla scala dell'azienda. Tuttavia, in generale, il consumo di energia tende ad essere più impattante rispetto al consumo idrico.<br></br><br></br>
        Per quanto la filiera non sia particolarmente energivora (con l’eccezione di alcuni settori come ad esempio quelli della ceramica sanitaria, della produzione di pannelli base legno, della realizzazione del vetro, ecc) il consumo energetico può comportare un significativo impatto ambientale, in particolare se l'energia utilizzata è prodotta da fonti non rinnovabili.<br></br><br></br>
        Il consumo idrico, d'altra parte, può essere più limitato nel settore del legno arredo, a meno che non siano utilizzati processi produttivi che richiedono una quantità significativa di acqua, come la pulizia e il lavaggio delle attrezzature. Tuttavia, anche il consumo idrico può avere un impatto sulla disponibilità di risorse idriche locali e sulla qualità dell'acqua, in particolare se le pratiche di gestione delle acque reflue sono inadeguate.<br></br><br></br>
        L’impronta idrica, o Water Footprint, si misura in m3 di acqua e valuta la scarsità di acqua legata ai prelievi netti di risorse idriche e al loro inquinamento (degradazione, eutrofizzazione, tossicità e acidificazione) causati dalla produzione di un determinato bene o servizio lungo il suo ciclo di vita: dall’estrazione delle materie prime, alla trasformazione in semilavorati e prodotti finiti e fino alla gestione dei relativi rifiuti. Il riferimento metodologico adottato per il calcolo di Water Footprint è quello sviluppato da Hoekstra e collaboratori - University of Twente, The Netherland - denominato Water Scarcity.<br></br><br></br>
        L’impronta di Carbonio, o Carbon Footprint, si misura in kg di CO2 equivalente e rappresenta la quantità totale dei gas a effetto serra emessi direttamente e indirettamente nel ciclo di vita di un prodotto, dall’estrazione delle materie prime, alla trasformazione in semilavorati e prodotti finiti, fino alla gestione dei relativi rifiuti. È l’indicatore di riferimento utilizzato dalla comunità internazionale per le valutazioni di impatto climatico dei prodotti, tra cui la Product Environmental Footprint della Commissione Europea.<br></br><br></br>
        
        Nell’ambito del progetto Life EFFIGE <lifeeffigestru>https://www.lifeeffige.eu/strumenti/</lifeeffigestru> sono stati elaborati degli strumenti disponibili per tutte le imprese interessate ad approfondire il il calcolo dell’impronta ambientale di prodotti lungo l’intero ciclo di vita.<br></br><br></br>
        In particolare, sono disponibili strumenti di calcolo e comunicazione semplificati, che nascono dalla constatazione che i risultati di uno studio LCA/PEF sono difficili da comprendere per la maggior parte degli stakeholder non tecnici, e mira ad aiutare le aziende a promuovere i risultati di un’analisi del ciclo di vita e del relativo calcolo dell’impronta ambientale.<br></br>
        Per maggiori informazioni contatta l’<omarmail>Ufficio Ambiente e Economia circolare</omarmail>.`,
        description: 'tool feedbackbody ENERGY over',
      },
      { ...htmlParserOptions, ...linkOptions }
    ),
  },
  [SurveyCategoriesEnum.WASTE]: {
    title: intl.formatMessage(
      {
        defaultMessage: `Rifiuti ed Emissioni`,
        description: 'tool feedbackbody WASTE title',
      },
      { ...htmlParserOptions }
    ),
    under: intl.formatMessage(
      {
        defaultMessage: `Per ridurre i rifiuti e le emissioni, un'azienda del settore legno arredo potrebbe implementare le seguenti attività:<br></br><br></br><ul>
        <li>Ottimizzazione del processo produttivo: l'azienda può ottimizzare il processo produttivo per ridurre gli scarti e migliorare l'utilizzo delle materie prime, ad esempio attraverso la riduzione dei tassi di scarto e l'utilizzo di tecnologie efficienti in termini di risorse.</li>
        <li>Riciclo e riutilizzo dei rifiuti: l'azienda può implementare un sistema di gestione dei rifiuti che prevede il riciclo e il riutilizzo dei materiali di scarto, come il legno e i materiali di imballaggio.</li>
        <li>Utilizzo di fonti energetiche pulite: l'azienda può scegliere di utilizzare fonti energetiche pulite come l'energia solare o eolica per ridurre le emissioni di gas serra.</li>
        <li>Miglioramento dell'efficienza energetica: l'azienda può implementare pratiche di miglioramento dell'efficienza energetica per ridurre il consumo di energia, ad esempio attraverso l'utilizzo di apparecchiature efficienti in termini di energia e l'isolamento termico degli stabilimenti.</li>
        <li>Gestione delle acque reflue: l'azienda può implementare pratiche di gestione sostenibile delle acque reflue, ad esempio attraverso la rimozione di contaminanti dalle acque reflue prima che vengano scaricate in ambiente.</li>
        <li>Collaborazione con fornitori sostenibili: l'azienda può scegliere di collaborare con fornitori che hanno pratiche sostenibili per ridurre il proprio impatto ambientale lungo tutta la catena di fornitura.</li></ul><br></br>
        <p>Queste sono solo alcune delle attività che un'azienda del settore legno arredo potrebbe implementare per ridurre i rifiuti e le emissioni. La scelta di quale attività implementare dipenderà dalle specifiche circostanze dell'azienda e dal suo obiettivo di ridurre l'impatto ambientale.</p><br></br>
        
        <p>Per aumentare la consapevolezza dei propri impatti, si può misurare il Carbon footprint dei propri prodotti, per la quale è stata sviluppata una norma ISO dedicata, la UNI EN ISO 14067:2018.</p><br></br> 
        
        <p>Il calcolo della carbon footprint di un prodotto può essere complesso e richiedere un certo grado di specializzazione, ma può fornire informazioni preziose per aiutare le aziende a identificare le aree in cui possono essere effettuate riduzioni delle emissioni e miglioramenti della sostenibilità.</p><br></br>
        <p>È anche possibile calcolare la Carbon footprint dell’intera organizzazione. Gli standard di riferimento sono:</p><br></br><ul>
        <li>Greenhouse Gas Protocol (GHG Protocol): Standard e linee guida per contabilizzazione e rendicontazione dei gas serra delle organizzazioni (World Resource Institute, WBCSD);</li>
        <li>UNI EN ISO 14064: Standard per quantificare e rendicontare a livello di organizzazione le emissioni di gas serra e le loro rimozioni (International Standard Organization).</li></ul>`,
        description: 'tool feedbackbody ENERGY under',
      },
      { ...htmlParserOptions, ...linkOptions }
    ),
    over: intl.formatMessage(
      {
        defaultMessage: `La Carbon footprint di un prodotto (secondo la UNI EN ISO 14067:2018), nota anche come "impronta di carbonio del prodotto", può essere determinata utilizzando diversi metodi. Il metodo più comune è chiamato "Analisi del Ciclo di Vita" (LCA). LCA (in accordo con la UNI EN ISO 14040:2021) consiste nell'analizzare tutte le fasi della vita di un prodotto, dall'estrazione delle materie prime, alla produzione, al trasporto, all'utilizzo e allo smaltimento, per quantificare le emissioni di gas serra associate a ciascuna fase.<br></br><br></br>
        La conoscenza della Carbon Foot Print dei propri prodotti e dei materiali con cui vengono realizzati, diventerà sempre più importante, non solamente in ottica di trasparenza nei confronti dei propri clienti, ma anche di prezzi nell'acquisto di alcune materie prime. Ad esempio il CBAM (Carbon Border Adjustment Mechanism) è un sistema nel quale la Commissione Europea andrà a tassare le emissioni di CO2 dei prodotti da importazione, applicando lo stesso prezzo già pagato dalle aziende europee. Si rivolge alle importazioni di prodotti nelle industrie ad alta intensità di carbonio. L'obiettivo di CBAM è impedire - nel pieno rispetto delle regole del commercio internazionale - che gli sforzi di riduzione delle emissioni di gas serra dell'UE siano controbilanciati dall'aumento delle emissioni al di fuori dei suoi confini attraverso la delocalizzazione della produzione in paesi extra UE (dove le politiche applicate per combattere il cambiamento climatico sono meno ambiziosi di quelli dell'UE) o l'aumento delle importazioni di prodotti ad alta intensità di carbonio. CBAM è progettato per funzionare in parallelo con il sistema di scambio di quote di emissione dell'UE (EU ETS), per rispecchiare e integrare il suo funzionamento sulle merci importate. Sostituirà gradualmente i meccanismi dell'UE esistenti per affrontare il rischio di rilocalizzazione delle emissioni di carbonio, in particolare l'assegnazione gratuita di quote ETS dell'UE.<br></br><br></br>
        Per maggiori informazioni contatta l’<omarmail>Ufficio Ambiente e Economia circolare</omarmail>.`,
        description: 'tool feedbackbody ENERGY over',
      },
      { ...htmlParserOptions, ...linkOptions }
    ),
  },
  [SurveyCategoriesEnum.LOGISTICS]: {
    title: intl.formatMessage(
      {
        defaultMessage: `Logistica`,
        description: 'tool feedbackbody LOGISTICS title',
      },
      { ...htmlParserOptions }
    ),
    under: intl.formatMessage(
      {
        defaultMessage: `La logistica inversa consiste nell'elaborazione, pianificazione e gestione del ritorno di beni dal punto di consumo al punto di origine. Questo può includere la raccolta, il trasporto, la riparazione, il riciclaggio e la gestione dello smaltimento dei prodotti restituiti o difettosi.<br></br><br></br> La logistica inversa è importante per la sostenibilità ambientale, la riduzione dei costi e la gestione dei ritorni dei clienti. Per attuare una strategia di logistica inversa si suggeriscono le seguenti azioni:<br></br><br></br><ul>
        <li>Definire i processi: stabilire i processi per la raccolta, il trasporto, la riparazione, il riciclaggio e la gestione dello smaltimento dei prodotti restituiti o difettosi.</li>
        <li>Coinvolgere i clienti: comunicare ai clienti le politiche e i processi di logistica inversa dell'azienda e incoraggiare la restituzione dei prodotti difettosi o non più desiderati.</li>
        <li>Collaborare con i fornitori: lavorare con i fornitori per garantire che i materiali utilizzati nella produzione siano riciclabili o riciclati.</li>
        <li>Investire in tecnologie: utilizzare tecnologie per tracciare e gestire i prodotti restituiti e monitorare i tassi di restituzione.</li>
        <li>Stabilire obiettivi e metriche: stabilire obiettivi e metriche per misurare il successo della strategia di logistica inversa e monitorare i progressi.</li>
        <li>Adattare processi e politiche: adattare i processi e le politiche in base ai feedback dei clienti e alle esigenze dell'azienda.</li></ul><br></br>
        <p>Un aiuto a sviluppare azioni di logistica inversa può venire anche dall’organizzazione collettiva di un settore. Confrontati con la tua Associazione o con l’<omarmail>Ufficio Ambiente e Economia Circolare</omarmail> per capire come potrebbe funzionare un futuro schema di Responsabilità estesa per il produttore nel tuo settore, e che cambiamenti potrebbe comportare.</p><br></br>
        <p>Ricorda che anche l’efficienza e la lunghezza degli spostamenti incide sulla bontà di un'organizzazione. Monitorare e cercare di accorciare la distanza di approvvigionamento prediligendo fornitori locali, organizzare i propri trasporti ottimizzando i carichi dei mezzi sono altri aspetti in cui l’azienda può cimentarsi.</p>`,
        description: 'tool feedbackbody LOGISTICS under',
      },
      { ...htmlParserOptions, ...linkOptions }
    ),
    over: intl.formatMessage(
      {
        defaultMessage: `Per il settore legno-arredo la tracciabilità dei materiale e dei prodotti può essere un fattore importante per la logistica inversa. La tracciabilità consente di seguire e monitorare i prodotti dal punto di origine al punto di consumo e viceversa. Un sistema adeguato di tracciabilità di prodotto può permettere di:<ul>
        <li>Identificare i prodotti restituiti o difettosi;</li>
        <li>Rintracciare i prodotti per la riparazione o il riciclaggio;</li>
        <li>Monitorare la qualità dei prodotti e prevenire la restituzione dei prodotti non conformi;</li>
        <li>Monitorare i tassi di restituzione e identificare eventuali problemi di qualità o di progettazione;</li>
        <li>Migliorare la comunicazione con i clienti e fornitori, consentendo loro di seguire i prodotti in movimento</li>
        <li>Aumentare la sicurezza dei prodotti e prevenire frodi o furti.</li></ul><br></br>
        <p>Per ottenere tutti questi benefici è importante che l'azienda utilizzi tecnologie avanzate di tracciabilità dei prodotti come codici a barre, RFID, GPS, blockchain, ecc. e che implementi un sistema di gestione della tracciabilità adeguato per la gestione dei dati. 
        A tal proposito si segnala la European Sustainable Products Regulation , prevista nel nuovo <envprop>Circular Economy Action Plan</envprop> e rientrante tra gli obiettivi del Green Deal della Commissione Europea, prevede di stabilire un passaporto digitale dei prodotti (DPP) che raccolga dati sulla sua catena del valore.</p><br></br><p><b>Riferimenti</b>:</p>
        <p><ciruclar>https://circulareconomy.europa.eu/platform/sites/default/files/michele-galatola-european-commission.pdf</ciruclar></p>   
        <p><cirpas>https://cirpassproject.eu/</cirpas></p>
        <p>Per maggiori informazioni sulla ESPR, direttiva sulla sostenibilità dei prodotti, attualmente in discussione, contatta l’<omarmail>Ufficio Ambiente e Economia Circolare</omarmail>.</p>
        `,
        description: 'tool feedbackbody LOGISTICS over',
      },
      { ...htmlParserOptions, ...linkOptions }
    ),
  },
  [SurveyCategoriesEnum.PRODUCTS]: {
    title: intl.formatMessage(
      {
        defaultMessage: `Prodotto/Servizio`,
        description: 'tool feedbackbody PRODUCTS title',
      },
      { ...htmlParserOptions }
    ),
    under: intl.formatMessage(
      {
        defaultMessage: `Con la finalità di migliorare le performance di circolarità dei prodotti/servizi offerti dall'azienda, si consiglia l'utilizzo dei seguenti strumenti di ecoprogettazione:<br></br><br></br><ul>
        <li>Selezione dei materiali: scegliere materiali a basso impatto ambientale, come legni certificati, tessuti riciclati e plastiche riciclate.</li>
        <li>Progettazione per il riciclo: progettare i prodotti in modo che possano essere facilmente smontati e riciclati.</li> 
        <li>Informazioni, servizi  e tool per la riparazione dei prodotti.</li>
        <li>Garanzie estese rispetto a quelle di legge.</li>
        <li>Collaborazione con fornitori: lavorare con fornitori per migliorare l'efficienza energetica e ridurre l'impatto ambientale.</li>
        <li>Formazione del personale: formare il personale sull'ecoprogettazione e sui suoi benefici per l'azienda e per l'ambiente.</li>
        <li>Certificazioni ambientali: ottenere certificazioni/attestazioni ambientali per i prodotti e per l'azienda, come EPD, FSC, Level o produrre prodotti che possono concorrere al raggiungimento di obiettivi del proprio cliente (come crediti LEED, WELL, ecc).</li> 
        <li>Innovazione: Continuare a innovare e sviluppare nuovi prodotti e processi per ridurre l'impatto ambientale.</li></ul><br></br>
        <p>Sulle certificazioni di prodotto per il tuo settore puoi consultare il <services>Technical Assistant</services> e confrontarti con il segretario della tua Associazione. </p><br></br><p>Sugli strumenti di certificazione di sistema puoi sentire l’<omarmail>Ufficio Ambiente e Economia Circolare</omarmail>.</p><br></br>
        <p>Per la formazione dei tuoi dipendenti, puoi confrontarti con l’<giuliamail>Ufficio Formazione di FLA</giuliamail>.</p>
        `,
        description: 'tool feedbackbody PRODUCTS under',
      },
      { ...htmlParserOptions, ...linkOptions }
    ),
    over: intl.formatMessage(
      {
        defaultMessage: `Gli strumenti per l’eco-progettazione, come quelli per la Life Cycle Assessment (LCA), sono sempre più sviluppati per integrarsi in modo semplice con il processo progettuale (ad esempio estensioni per o interoperabilità con strumenti di modellazione 3D e per la gestione del progetto) e con i sistemi aziendali (ad esempio strumenti che usano i dati già presenti nell’ERP).<br></br><br></br>
        Si segnalano inoltre due normative per l’eco-progettazione: la UNI ISO/TR 14062:2007 Integrazione degli aspetti ambientali nella progettazione e nello sviluppo del prodotto e la UNI EN ISO 14006:2020 Sistemi di gestione ambientale- Linee guida per l’integrazione dell’eco-design.<br></br><br></br>
        In un prossimo futuro tali impatti verranno richieste dalle aziende più strutturate ai propri fornitori, in virtù dei nuovi regolamenti europei e internazionali su Tassonomia e Disclosure.<br></br><br></br>
        In ambito normativo europeo, per i prodotti dell’arredo sempre più attenzione viene data al disassemblaggio dei prodotti al fine di recuperare i vari materiali che lo compongono (ad es. il recente progetto di norma in fase di pubblicazione prEN 17902 Furniture - Circularity - Requirement and evaluation methods for dis-reassembly).<br></br><br></br>
        Inoltre si segnala nei nuovi CAM (Criteri Ambientali Minimi) Arredi per gli acquisti verdi della pubblica amministrazione, il Criterio 4.1.1 Ecoprogettazione: l’arredo deve essere provvisto di un bilancio materico che evidenzia le caratteristiche ambientali dei materiali utilizzati per la fabbricazione dell’arredo e la destinazione finale dei relativi componenti.<br></br><br></br>
        Studiare i nuovi modelli di business dell’economia circolare e la loro possibilità di applicazione nel tuo settore può essere una frontiera a cui guardare, per costruire un business “a prova di futuro”.<br></br>
        Rif. MITE <mite>https://gpp.mite.gov.it/PDF/GURI%20184%2008.08.22%20-%20Allegato%20Arredi.pdf</mite><br></br> 
        Per le attività normative in ambito CEN puoi rivolgerti al tecnico normativo della tua Associazione.`,
        description: 'tool feedbackbody PRODUCTS over',
      },
      { ...htmlParserOptions, ...linkOptions }
    ),
  },
  [SurveyCategoriesEnum.HR]: {
    title: intl.formatMessage(
      {
        defaultMessage: `Risorse Umane asset policy sostenibilità`,
        description: 'tool feedbackbody HR title',
      },
      { ...htmlParserOptions }
    ),
    under: intl.formatMessage(
      {
        defaultMessage: `I criteri ESG (ambientali, sociali e di governance) sono importanti per valutare la sostenibilità di un'azienda del settore legno-arredo, ad esempio:<br></br><br></br><ul>
        <li>Per quanto riguarda l'impatto ambientale, le aziende possono essere valutate sulla base di fattori come l'utilizzo di materiali sostenibili, l'efficienza energetica dei loro processi produttivi e le emissioni di gas a effetto serra.</li>
        <li>Per quanto riguarda l'impatto sociale, le aziende  possono essere valutate sulla base di fattori come la gestione dei dipendenti, la responsabilità sociale verso i fornitori e la trasparenza nella comunicazione con i stakeholders.</li>
        <li>Per quanto riguarda la governance, le aziende possono essere valutate sulla base di fattori come la trasparenza nella rendicontazione finanziaria, la diversità e l'equità nella gestione dell'azienda e la presenza di sistemi di gestione dei rischi adeguati.</li></ul><br></br>
        <p>In generale, le aziende che dimostrano di essere impegnate nella sostenibilità ambientale, sociale e di governance avranno un vantaggio competitivo e potranno attrarre investitori interessati a investire in aziende che rispettano i criteri ESG.</p><br></br>
        <p>Esistono diversi strumenti di calcolo ESG disponibili per le aziende. La scelta di quale strumento utilizzare dipenderà dalle specifiche circostanze dell'azienda e dai suoi obiettivi ESG.</p><br></br>
        <p>Gli Standards Global Reporting Initiative (GRI) sono uno strumento universale per la rendicontazione della sostenibilità che aiuta le aziende a misurare e comunicare il loro impatto sociale, ambientale ed economico.</p><br></br>
        <p>FLA ha organizzato dei webinar introduttivi al tema, e dei corsi specifici sulla rendicontazione di sostenibilità.</p><br></br> <p>Se non hai partecipato, contatta l’<omarmail>Ufficio Ambiente e Economia Circolare</omarmail> o l’<giuliamail>Ufficio Formazione</giuliamail> di FederlegnoArredo per saperne di più.</p>
        `,
        description: 'tool feedbackbody HR under',
      },
      { ...htmlParserOptions, ...linkOptions }
    ),
    over: intl.formatMessage(
      {
        defaultMessage: `La tassonomia europea per la finanza sostenibile è uno strumento di classificazione sviluppato dall'Unione Europea per aiutare le aziende e gli investitori a identificare e valutare i rischi e le opportunità connessi alla sostenibilità. La tassonomia fornisce un quadro comune per classificare le attività economiche in base ai loro impatti ambientali e sociali e aiuta a rendere più trasparenti e comparabili i dati ESG.<br></br><br></br>
        La tassonomia europea si applica a una serie di attività economiche, tra cui l'energia, l'agricoltura, la silvicoltura, la produzione di beni di consumo, la costruzione, la trasformazione e la produzione di beni intermedi. Tuttavia, non si applica a tutte le aziende, ma solo a quelle che si qualificano come "attività economiche sostenibili" che soddisfano una serie di criteri ambientali e sociali.<br></br><br></br>
        Questi criteri sono stati sviluppati per aiutare le aziende e gli investitori a identificare le attività economiche che hanno un impatto ambientale e sociale positivo e che soddisfano determinati standard di governance. Le aziende che non soddisfano i criteri non possono utilizzare la tassonomia.<br></br><br></br>
        In sintesi, la tassonomia europea per la finanza sostenibile è uno strumento utile per le aziende e gli investitori per identificare e valutare i rischi e le opportunità connessi alla sostenibilità, ma si applica solo a certe attività economiche e solo alle aziende che soddisfano determinati criteri ambientali e sociali.<br></br><br></br>
        Alcune attività, come la produzione di mobili, sono indirizzate in modo specifico dalla Tassonomia per la sostenibilità con dei criteri di vaglio (o screening criteria) che possono essere un utile bussola per capire quali sono gli aspetti della produzione che sono considerati particolarmente importanti per poter definire l’attività “sostenibile”. <br></br><br></br>Contatta l’<omarmail>Ufficio ambiente e economia circolare</omarmail> per maggiori informazioni.<br></br><br></br>
        Si segnala che <efrag>l'EFRAG</efrag> ha recentemente licenziato il nuovo standard ESRS (European Sustainability Reporting Standard) che costituirà lo standard di reporting di sostenibilità, coerente con la nuova Corporate Sustainability Reporting Directive (CSRD), che le imprese tenute all’obbligo di reporting dovranno progressivamente adottare nei prossimi anni, secondo le scadenze previste per le diverse categorie di imprese.<br></br><br></br>
        A livello internazionale , aziende leader di mercato pongono tra i propri obiettivi l'utilizzo di soli materiali rinnovabili o riciclati nei propri prodotti, ampliando la disponibilità di prodotti circolari offrendo ai clienti l'accesso a soluzioni e servizi che mantengono i prodotti in uso, tra cui una gamma di cura e riparazione, opzioni di riacquisto e rivendita, di seconda mano e/o la possibilità di noleggio dei prodotti.<br></br><br></br>
        Le aziende del settore legno-arredo dovranno quindi adottare un approccio olistico per trasformare l'intera catena di approvvigionamento migliorando al contempo le condizioni di lavoro.`,
        description: 'tool feedbackbody HR over',
      },
      { ...htmlParserOptions, ...linkOptions }
    ),
  },
});
