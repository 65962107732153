import { IntlShape } from 'react-intl';

export const LogisticsQuestions = {
  ['question_22']:
    '<click id={203}>Rifiuti</click> trattati presso <click id={301}>impianti di valorizzazione locali</click> rispetto al totale dei rifiuti trattati.',
  ['question_23']:
    '<click id={302}>Risorse</click> in ingresso (prodotti, componenti, materiali) soggette a forme di <click id={303}>logistica inversa</click> a fine vita, rispetto al totale delle risorse in ingresso.',
  ['question_24']:
    '<click id={302}>Risorse</click> in uscita (prodotti, residui, sottoprodotti, rifiuti, componenti e materiali) soggette a forme di <click id={303}>logistica inversa</click> a fine vita rispetto al totale delle risorse in uscita.',
  ['question_25']:
    'Capacità di carico effettiva utilizzata dai mezzi di trasporto (sia andata che ritorno) rispetto alla capacità totale.',
  ['question_26']:
    "Numero di dipendenti aderenti ad <click id={304}>iniziative di mobilità</click> sostenibile promosse dall'organizzazione o da terzi, rispetto al totale dei dipendenti nell'anno n",
  ['question_27']:
    "<click id={305}>Prodotti, componenti, materiali realizzati che non hanno più mercato</click> e che sono riutilizzati dall'organizzazione e/o da terzi rispetto al totale dei <click id={305}>prodotti componenti, materiali realizzati che non hanno più mercato</click> nell'anno n.",
};

export const logisticsModalsExplanations = (
  intl: IntlShape,
  htmlParserOptions?: object
) => ({
  301: {
    title: intl.formatMessage({
      defaultMessage: 'Impianto di valorizzazione locale',
      description: 'homepage modalsExplanations 301 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Impianto di valorizzazione, collocato nel raggio dei 100 chilometri dal punto di generazione dei residui (all’interno del perimetro di valutazione).`,
        description: 'homepage modalsExplanations 301 body',
      },
      { ...htmlParserOptions }
    ),
  },
  302: {
    title: intl.formatMessage({
      defaultMessage: 'Risorsa',
      description: 'homepage modalsExplanations 302 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Fonte fisica che concorre alla realizzazione di un prodotto o servizio.<br></br>
        <b>Nota 1</b>: Le risorse sono di origine naturale o antropica.<br></br>
        <b>Nota 2</b>: Le risorse possono essere rinnovabili o non rinnovabili.<br></br>
        <b>Nota 3</b>: Il termine risorsa può essere applicato a materiali, acqua ed energia.<br></br>
        <b>Nota 4</b>: Le risorse includono la propria energia incorporata.`,
        description: 'homepage modalsExplanations 302 body',
      },
      { ...htmlParserOptions }
    ),
  },
  303: {
    title: intl.formatMessage({
      defaultMessage: 'Logistica inversa',
      description: 'homepage modalsExplanations 303 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Processo che consiste nel pianificare, implementare e controllare
        l’efficiente ed efficace flusso di materie prime, semi-lavorati, prodotti finiti e le relative informazioni dal punto di consumo al punto di origine con l’obiettivo di recuperarne valore, in alternativa allo smaltimento.`,
        description: 'homepage modalsExplanations 303 body',
      },
      { ...htmlParserOptions }
    ),
  },
  304: {
    title: intl.formatMessage({
      defaultMessage: 'Iniziative di mobilità',
      description: 'homepage modalsExplanations 304 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Iniziative che consentono la realizzazione di spostamenti "sostenibili" (ad esempio sistemi di navette casa-lavoro, contributo per il trasporto pubblico, car pooling, sharing mobility, ecc.).`,
        description: 'homepage modalsExplanations 304 body',
      },
      { ...htmlParserOptions }
    ),
  },
  305: {
    title: intl.formatMessage({
      defaultMessage:
        'Prodotti, componenti e materiali che non hanno più mercato',
      description: 'homepage modalsExplanations 305 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Prodotti, componenti e materiali la cui alienazione non genera un profitto.`,
        description: 'homepage modalsExplanations 305 body',
      },
      { ...htmlParserOptions }
    ),
  },
});

export const logisticsModalsNotes = (
  intl: IntlShape,
  htmlParserOptions?: object
) => ({
  question_22: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra la quantità di rifiuti trattati presso impianti di valorizzazione locali (kg) rispetto alla quantità totale dei rifiuti trattati (kg). Intendiamo per locale una distanza dal luogo di primo conferimento <100 Km.`,
      description: 'homepage notes question_22 title',
    },
    { ...htmlParserOptions }
  ),
  question_23: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra la quantità (kg) di risorse in ingresso (prodotti, componenti, materiali) soggette a forme di logistica inversa a fine vita, rispetto al totale delle risorse in ingresso (kg).`,
      description: 'homepage notes question_23 title',
    },
    { ...htmlParserOptions }
  ),
  question_24: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra la quantità (kg) di risorse in uscita (prodotti, componenti, materiali) soggette a forme di logistica inversa a fine vita, rispetto al totale delle risorse in uscita (kg).`,
      description: 'homepage notes question_24 title',
    },
    { ...htmlParserOptions }
  ),
  question_25: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra la capacità di carico effettiva (m3) utilizzata dai mezzi di trasporto (sia andata che ritorno) rispetto alla capacità totale (m3).<br></br>
      Ad esempio se i camion che l'azienda utilizza per trasportare i suoi prodotti vengono caricati mediamente con 15 m3 ed hanno una capacità di carico effettiva di 16 m3 tale % sarà pari a (15/16) * 100 = 93,75%.`,
      description: 'homepage notes question_25 title',
    },
    { ...htmlParserOptions }
  ),
  question_26: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra il numero di dipendenti che nell'anno in corso aderiscono a iniziative di mobilità sostenibile (ad esempio sistemi di navette casa-lavoro, contributo per il trasporto pubblico, car pooling) promosse dall'organizzazione o da terzi, rispetto al totale dei dipendenti nell'anno in corso.
      Ad esempio se l'azienda A ha 45 dipendenti e 7 di essi utilizzano il trasporto pubblico, per il quale l'azienda riconosce un contributo, tale % sarà pari a: (7/45)*100 = 15,6%.`,
      description: 'homepage notes question_26 title',
    },
    { ...htmlParserOptions }
  ),
  question_27: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra la quantità di prodotti, componenti, materiali realizzati che non hanno più mercato e che sono riutilizzati dall'organizzazione e/o da terzi rispetto alla quantità totale dei prodotti componenti, materiali realizzati che non hanno più mercato nell'anno in corso (ad esempio donazione di materiale informativo ancora funzionante ma non più adatto per l'uso aziendale, o prodotti non più commerciabili).`,
      description: 'homepage notes question_27 title',
    },
    { ...htmlParserOptions }
  ),
});
