import { BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IFilterListIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function FilterListIcon({ color = '#637381', style }: IFilterListIconProps) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M10 18.3784H14V16.3784H10V18.3784ZM3 6.37842V8.37842H21V6.37842H3ZM6 13.3784H18V11.3784H6V13.3784Z"
        fill={color}
      />
    </svg>
  );
}

export default FilterListIcon;
