import { Grid, Typography } from '@mui/material';
import { RHFMultiCheckbox } from '../RHFCheckbox/RHFCheckbox';
import { RHFRadios } from '../RHFRadios/RHFRadios';
import { RHFCheckmarksSelect, RHFSelect } from '../RHFSelect/RHFSelect';
import { RHFTextField } from '../RHFTextField/RHFTextField';
import {
  IDynamicComponent,
  IDynamicFieldFormProps,
} from './DynamicFieldForm.types';
import { useTranslations } from 'hooks';

const DynamicFieldForm = ({
  fieldData,
  color = 'info',
}: IDynamicFieldFormProps) => {
  if (fieldData.type === 'multi-field' || fieldData.type === 'webform_likert')
    return null;
  const i18n = useTranslations();
  const dynamicComponents: IDynamicComponent = {
    text: RHFTextField,
    number: RHFTextField,
    email: RHFTextField,
    textarea: RHFTextField,
    radios: RHFRadios,
    webform_radios_other: RHFRadios,
    select: RHFSelect,
    multiSelect: RHFCheckmarksSelect,
    checkboxes: RHFMultiCheckbox,
    webform_checkboxes_other: RHFMultiCheckbox,
  };

  const DynamicField = dynamicComponents[fieldData.type];

  const textAreaProps = {
    ...(fieldData.type === 'textarea' && {
      multiline: true,
      minRows: 4,
    }),
  };

  const radiosProps = {
    ...(fieldData.type === 'radios' && {
      options: fieldData.options as {
        label: string;
        value: string;
      }[],
      description: fieldData.description || '',
    }),
  };

  const radiosOtherProps = {
    ...(fieldData.type === 'webform_radios_other' && {
      options: fieldData.options as {
        label: string;
        value: string;
      }[],
      description: fieldData.description || '',
      direction: 'column' as 'row' | 'column',
      hasOtherField: true,
      otherFieldName: `${fieldData.name}_other_radio_option`,
    }),
  };

  const selectProps = {
    ...(fieldData.type === 'select' && {
      options: fieldData.options as {
        label: string;
        value: string;
      }[],
    }),
  };

  const checkBoxesProps = {
    ...(fieldData.type === 'checkboxes' && {
      options: fieldData.options as {
        label: string;
        value: string;
      }[],
      label: '',
      gridOptions: {
        lg: 12,
      },
    }),
  };

  const multiOtherCheckboxes = {
    ...(fieldData.type === 'webform_checkboxes_other' && {
      options: fieldData.options as {
        label: string;
        value: string;
      }[],
      label: '',
      gridOptions: {
        lg: 12,
      },
      hasOtherField: true,
      otherFieldName: `${fieldData.name}_other_option`,
    }),
  };

  const multiSelectProps = {
    ...(fieldData.type === 'multiSelect' && {
      options: fieldData.options as {
        label: string;
        value: string;
      }[],
      color: 'info' as
        | 'primary'
        | 'secondary'
        | 'error'
        | 'info'
        | 'success'
        | 'warning',
      label: '',
    }),
  };

  return (
    <Grid item xs={12} md={fieldData.grid}>
      {fieldData.type === 'multiSelect' ||
      fieldData.type === 'checkboxes' ||
      fieldData.type === 'webform_checkboxes_other' ? (
        <Typography
          variant={fieldData.type === 'multiSelect' ? 'body2' : 'subtitle2'}
          sx={{
            mb: 0.5,
            fontSize: '14px',
            ...(fieldData.type === 'multiSelect' && {
              color: '#919EAB',
              ml: '14px',
            }),
          }}
        >
          {fieldData.label} {fieldData.required ? '*' : ''}
        </Typography>
      ) : null}
      <DynamicField
        name={fieldData.name}
        label={
          fieldData.type === 'multiSelect' ||
          fieldData.type === 'checkboxes' ||
          fieldData.type === 'webform_checkboxes_other'
            ? ''
            : (fieldData.label as string)
        }
        required={fieldData.readOnly ? false : fieldData.required}
        disabled={fieldData.readOnly || false}
        rules={{
          ...(fieldData.required && {
            required: i18n.general.errors.requiredField,
          }),
        }}
        type={fieldData.type}
        {...multiSelectProps}
        {...checkBoxesProps}
        {...multiOtherCheckboxes}
        {...textAreaProps}
        {...radiosProps}
        {...selectProps}
        {...radiosOtherProps}
        color={color}
        key={fieldData.name}
      />
    </Grid>
  );
};

export { DynamicFieldForm };
