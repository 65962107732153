// import { CardActionArea } from '@mui/material';
import { useRef } from 'react';
import Slider, { Settings } from 'react-slick';
import { IItemProps } from '../Carousel.type';
import {
  // Arrow,
  Dot,
  DotItemContainer,
  DotsContainer,
} from './CarouselDots.style';
import { useResponsive } from 'hooks';
// import { Iconify } from 'components/Iconify/Iconify';

const CarouselDots = ({
  items,
  currentSlide,
  selectDot,
}: {
  items: IItemProps[];
  currentSlide: number;
  selectDot: (index: number) => void;
}) => {
  //refs
  const carouselRef = useRef<Slider | null>(null);

  //slick settings
  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    lazyLoad: 'ondemand',
    slidesToShow: items.length >= 5 ? 5 : items.length,
    slidesToScroll: items.length >= 5 ? 5 : items.length,
    ...(items.length < 4 && {
      centerMode: true,
    }),
  };

  //functions
  // const handlePrevious = () => {
  //   carouselRef.current?.slickPrev();
  // };

  // const handleNext = () => {
  //   carouselRef.current?.slickNext();
  // };

  return (
    <DotsContainer>
      <Slider {...settings} ref={carouselRef}>
        {items.map((el: IItemProps, index: number) => (
          <CarouselDotsItem
            key={`dot${index}`}
            image={el.image}
            active={index === currentSlide}
            onClick={() => selectDot(index)}
          />
        ))}
      </Slider>
      {/* {items.length > 5 ? (
        <>
          <ArrowLeft onClick={handlePrevious} />
          <ArrowRight onClick={handleNext} />
        </>
      ) : null} */}
    </DotsContainer>
  );
};

export { CarouselDots };

const CarouselDotsItem = ({
  image,
  active,
  onClick,
}: {
  image: string;
  active: boolean;
  onClick: VoidFunction;
}) => {
  const isSmDown = useResponsive('down', 'sm');
  return (
    <DotItemContainer>
      <Dot
        image={image}
        active={active}
        onClick={onClick}
        style={{
          ...(isSmDown && {
            height: 55,
          }),
        }}
      />
    </DotItemContainer>
  );
};

// const ArrowLeft = ({ onClick }: { onClick: VoidFunction }) => {
//   return (
//     <Arrow anchor="left" onClick={onClick}>
//       <CardActionArea
//         sx={{ width: '100%', height: '100%', padding: '0px 5px' }}
//       >
//         <Iconify icon="ph:caret-left-bold" />
//       </CardActionArea>
//     </Arrow>
//   );
// };

// const ArrowRight = ({ onClick }: { onClick: VoidFunction }) => {
//   return (
//     <Arrow anchor="right" onClick={onClick}>
//       <CardActionArea
//         sx={{ width: '100%', height: '100%', padding: '0px 5px' }}
//       >
//         <Iconify icon="ph:caret-right-bold" />
//       </CardActionArea>
//     </Arrow>
//   );
// };
