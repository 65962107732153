import { Box, BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface ISurveyIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function SurveyIcon({ color = '#7b8a72', style, ...other }: ISurveyIconProps) {
  return (
    <Box {...other}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 75" style={style}>
        <path
          id="Tracciato_481"
          data-name="Tracciato 481"
          d="M52.267,11.333v26.25H15.4l-4.388,4.387V11.333Zm3.75-7.5H7.267a3.761,3.761,0,0,0-3.75,3.75v52.5l15-15h37.5a3.761,3.761,0,0,0,3.75-3.75V7.583A3.761,3.761,0,0,0,56.017,3.833Zm18.75,15h-7.5v33.75H18.517v7.5a3.761,3.761,0,0,0,3.75,3.75h41.25l15,15V22.583A3.761,3.761,0,0,0,74.767,18.833Z"
          transform="translate(-3.517 -3.833)"
          fill={color}
        />
      </svg>
    </Box>
  );
}

export default SurveyIcon;
