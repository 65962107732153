import { styled, alpha, Button } from '@mui/material';

export const ArrowContainer = styled('div')(({ theme }) => ({
  height: 32,
  width: 88,
  backgroundColor: alpha(theme.palette.primary.main, 0.48),
  borderRadius: 8,
  position: 'absolute',
  bottom: '20px',
  right: '20px',
  display: 'flex',
  padding: '0px 0px',
  justifyContent: 'space-between',
  alignItems: 'center',
  overflow: 'hidden',
}));

export const ArrowButton = styled(Button)({
  height: '100%',
  backgroundColor: 'transparent',
  width: '10px !important',
  padding: '0',
  gutter: 'none',
  margin: 0,
  minWidth: '25px',
  fontSize: '18px',
});
