import { styled, Theme, Card } from '@mui/material';

export const DotsContainer = styled('div')({
  maxWidth: 455,
  marginTop: '10px',
  position: 'relative',
  padding: '0px 5px',
});

export const DotItemContainer = styled('div')({
  width: '100%',
  padding: '5px',
});

export const Dot = styled('div')(
  ({
    theme,
    image,
    active,
  }: {
    theme?: Theme;
    image: string;
    active?: boolean;
  }) => ({
    height: 78,
    width: '100%',
    maxWidth: 78,
    border: active ? `4px solid ${theme?.palette.success.main}` : 'none',
    borderRadius: 10,
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    boxSizing: 'border-box',
  })
);

export const Arrow = styled(Card)(
  ({ theme, anchor }: { theme?: Theme; anchor: 'left' | 'right' }) => ({
    height: '100%',
    borderRadius: 0,
    padding: '0px 0px',
    backgroundColor: theme?.palette.grey[0],
    position: 'absolute',
    left: anchor === 'left' ? 0 : 'auto',
    right: anchor === 'right' ? 0 : 'auto',
    top: 0,
  })
);
