// @mui

import { CSSProperties } from 'react';

// ----------------------------------------------------------------------

function SideBarCircolarityIcon({
  color = '#919EAB',
  style,
}: {
  color?: string;
  style?: CSSProperties;
}) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, transform: 'translate(-1px, 0px)' }}
    >
      <g id="ICONS">
        <path
          id="Vector"
          d="M19.1759 9.83333C19.1759 9.83333 20.6522 9.10606 15.2971 9.10606C13.3792 9.10606 11.5044 9.67478 9.90972 10.7403C8.31507 11.8058 7.07218 13.3203 6.33824 15.0922C5.6043 16.864 5.41227 18.8138 5.78643 20.6948C6.16059 22.5758 7.08413 24.3037 8.44028 25.6598C9.79642 27.016 11.5243 27.9395 13.4053 28.3137C15.2863 28.6878 17.2361 28.4958 19.0079 27.7619C20.7798 27.0279 22.2943 25.785 23.3598 24.1904C24.1078 23.071 24.6109 21.8135 24.8444 20.5M15.2971 4.5L20.1456 9.34848L15.2971 14.197"
          stroke={color}
          strokeWidth="2.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M20.5642 27.2426C22.875 28.0399 24.8035 27.8592 26.2559 27.043C27.2626 26.477 28.036 25.6076 28.5459 24.5486C29.0503 23.5012 29.2976 22.2652 29.2578 20.9536C29.1701 18.0605 27.6851 14.787 24.4876 12.3436C24.4221 12.2894 24.3287 12.2692 24.2398 12.2982C24.106 12.3419 24.033 12.4811 24.0766 12.6089C24.9163 15.0374 23.2557 16.3127 21.5405 17.6294C19.0489 19.5424 16.4503 21.5381 20.2014 26.8887"
          stroke={color}
          strokeWidth="2.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default SideBarCircolarityIcon;
