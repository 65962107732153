import { Card, Typography } from '@mui/material';
import { styled } from '@mui/material';

export const StyledPaper = styled(Card)({
  width: '100%',
  height: '100%',
});

export const BoxPreview = styled('div')(({ image }: { image: string }) => ({
  height: 300,
  width: '100%',
  backgroundImage: `url(${image})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
}));

export const Label = styled('div')({
  padding: '4px 16px',
  backgroundColor: '#E9DFFF',
  borderRadius: '4px',
  position: 'absolute',
  top: '20px',
  right: '20px',
  fontSize: '14px',
  fontWeight: '400',
  color: '#773DF5',
  textTransform: 'capitalize',
  display: 'flex',
  alignItems: 'center',
});

export const ElipsedTypography = styled(Typography)(
  ({ maxLine = 2 }: { maxLine: number }) => ({
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    whiteSpace: 'pre-wrap',
    WebkitLineClamp: maxLine,
    WebkitBoxOrient: 'vertical',
  })
);
