// @mui

import { CSSProperties } from 'react';

// ----------------------------------------------------------------------

function SideBarAcademyIcon({
  color = '#919EAB',
  style,
}: {
  color?: string;
  style?: CSSProperties;
}) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g id="ICONS">
        <path
          id="Vector"
          d="M16.0033 11.7621L23.2551 15.1541V28.0634M8.74588 19.0058V23.5426C8.74588 23.5426 8.80993 23.6112 8.94487 23.7187C10.9611 25.3107 14.9866 27.4916 20.5527 25.4102M2.3999 11.7541L15.9999 4.5L29.5999 11.7541L15.9999 19.0081L2.3999 11.7541Z"
          stroke={color}
          strokeWidth="2.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default SideBarAcademyIcon;
