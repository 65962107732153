import { Button, Stack, Typography } from '@mui/material';
import { Container } from './GeneralContactsBanner.style';
import { IGeneralContactsBannerProps } from './GeneralContactsBanner.types';
import { useResponsive, useTranslations } from 'hooks';

const GeneralContactsBanner = ({
  color = 'info',
  title = '',
  subtitle = '',
  subtitleSx = {},
  buttonLabel = '',
  buttonAction = () => {},
  marginTop = '0',
  ...other
}: IGeneralContactsBannerProps) => {
  const i18n = useTranslations();
  const isSmDown = useResponsive('down', 'sm');
  return (
    <Container
      color={color}
      alignItems={'center'}
      justifyContent={'space-between'}
      marginTop={marginTop}
      {...other}
    >
      <Stack alignItems={'center'} justifyContent={'center'}>
        <Typography
          variant={'h5'}
          sx={{
            ...(isSmDown && {
              mb: 1,
            }),
          }}
        >
          {title || i18n.common.generalContact.title}
        </Typography>
        <Typography
          variant={'body1'}
          textAlign={'center'}
          sx={{
            ...subtitleSx,
            ...(isSmDown && {
              mb: 1,
            }),
          }}
        >
          {subtitle || i18n.common.generalContact.subtitle}
        </Typography>
      </Stack>
      <Stack alignItems={'center'} justifyContent={'center'}>
        <Button
          color="primary"
          variant="contained"
          onClick={buttonAction}
          size="small"
          sx={{ width: 158, height: 28, minHeight: 'unset' }}
        >
          {buttonLabel || i18n.common.generalContact.button}
        </Button>
      </Stack>
    </Container>
  );
};

export { GeneralContactsBanner };
