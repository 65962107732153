import { Box, BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface ICaseIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function CaseIcon({ color = '#7B8A72', style, ...other }: ICaseIconProps) {
  return (
    <Box {...other}>
      <svg
        width="28"
        height="26"
        viewBox="0 0 28 26"
        fill={color}
        style={style}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16.666 5.66634V2.99967H11.3327V5.66634H16.666ZM3.33268 8.33301V22.9997H24.666V8.33301H3.33268ZM24.666 5.66634C26.146 5.66634 27.3327 6.85301 27.3327 8.33301V22.9997C27.3327 24.4797 26.146 25.6663 24.666 25.6663H3.33268C1.85268 25.6663 0.666016 24.4797 0.666016 22.9997L0.679349 8.33301C0.679349 6.85301 1.85268 5.66634 3.33268 5.66634H8.66602V2.99967C8.66602 1.51967 9.85268 0.333008 11.3327 0.333008H16.666C18.146 0.333008 19.3327 1.51967 19.3327 2.99967V5.66634H24.666Z" />
      </svg>
    </Box>
  );
}

export default CaseIcon;
