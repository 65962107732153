import { Box, BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IFeedbackHeaderIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function FeedbackHeaderIcon({
  color = '#637381',
  style,
  ...other
}: IFeedbackHeaderIconProps) {
  return (
    <Box {...other}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill={color}
        style={style}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M2.33268 4.0013H0.666016V15.668C0.666016 16.5846 1.41602 17.3346 2.33268 17.3346H13.9993V15.668H2.33268V4.0013ZM15.666 0.667969H5.66602C4.74935 0.667969 3.99935 1.41797 3.99935 2.33464V12.3346C3.99935 13.2513 4.74935 14.0013 5.66602 14.0013H15.666C16.5827 14.0013 17.3327 13.2513 17.3327 12.3346V2.33464C17.3327 1.41797 16.5827 0.667969 15.666 0.667969ZM15.666 12.3346H5.66602V2.33464H15.666V12.3346ZM10.2577 7.46797C10.5993 6.85964 11.241 6.5013 11.616 5.96797C12.016 5.4013 11.791 4.3513 10.666 4.3513C9.93268 4.3513 9.56602 4.90964 9.41602 5.3763L8.27435 4.9013C8.59102 3.96797 9.43268 3.16797 10.6577 3.16797C11.6827 3.16797 12.391 3.63464 12.7493 4.21797C13.0577 4.71797 13.2327 5.65964 12.7577 6.35964C12.2327 7.13464 11.7327 7.36797 11.4577 7.86797C11.3493 8.06797 11.3077 8.2013 11.3077 8.8513H10.041C10.0493 8.50964 9.99102 7.9513 10.2577 7.46797ZM9.79102 10.6263C9.79102 10.1346 10.1827 9.75964 10.666 9.75964C11.1577 9.75964 11.5327 10.1346 11.5327 10.6263C11.5327 11.1096 11.166 11.5013 10.666 11.5013C10.1827 11.5013 9.79102 11.1096 9.79102 10.6263Z" />
      </svg>
    </Box>
  );
}

export default FeedbackHeaderIcon;
