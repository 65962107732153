import { FormHelperText } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { UploadAvatar } from 'components/upload';
import { MultiFileProps } from 'types/file';

const RHFUploader = ({ name, ...other }: MultiFileProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && field.value?.length === 0;

        return (
          <UploadAvatar
            file={field.value[0]}
            error={checkError}
            helperText={
              checkError ? (
                <FormHelperText error sx={{ px: 2 }}>
                  {error?.message}
                </FormHelperText>
              ) : (
                other.helperText
              )
            }
            {...other}
          />
        );
      }}
    />
  );
};

export { RHFUploader };
