import { Box, BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IPasswordIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function PasswordIcon({
  color = '#212B36',
  style,
  ...other
}: IPasswordIconProps) {
  return (
    <Box {...other}>
      <svg
        width="20"
        height="10"
        viewBox="0 0 20 10"
        fill={color}
        style={style}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M17.5006 3.33333H10.5423C9.85898 1.39167 8.00898 0 5.83398 0C3.07565 0 0.833984 2.24167 0.833984 5C0.833984 7.75833 3.07565 10 5.83398 10C8.00898 10 9.85898 8.60833 10.5423 6.66667H10.834L12.5007 8.33333L14.1673 6.66667L15.834 8.33333L19.1673 4.96667L17.5006 3.33333ZM5.83398 7.5C4.45898 7.5 3.33398 6.375 3.33398 5C3.33398 3.625 4.45898 2.5 5.83398 2.5C7.20898 2.5 8.33398 3.625 8.33398 5C8.33398 6.375 7.20898 7.5 5.83398 7.5Z" />
      </svg>
    </Box>
  );
}

export default PasswordIcon;
