// @mui

// ----------------------------------------------------------------------

function CertificationIcon({
  style,
  color,
  bgColor,
}: {
  style: React.CSSProperties;
  color: string;
  bgColor: string;
}) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <rect
        opacity="0.5"
        width="31.2615"
        height="31.2615"
        rx="3.90769"
        fill={bgColor}
      />
      <g clipPath="url(#clip0_2629_48794)">
        <path
          d="M6.83789 8.5912C6.92352 8.25264 7.02519 7.9228 7.24682 7.64158C7.65072 7.1296 8.17643 6.84472 8.82761 6.84288C11.6494 6.836 14.4707 6.83692 17.292 6.84151C18.3961 6.84334 19.2804 7.72278 19.2927 8.84352C19.3069 10.1597 19.2982 11.4759 19.295 12.7916C19.2941 13.1402 19.0546 13.3838 18.7359 13.3788C18.4204 13.3737 18.1992 13.1375 18.1987 12.792C18.1969 11.5048 18.1987 10.217 18.1974 8.92977C18.1965 8.41779 17.9157 8.05262 17.4519 7.95766C17.3745 7.9416 17.293 7.93931 17.2133 7.93931C14.4492 7.93839 11.6851 7.93839 8.92057 7.93931C8.31793 7.93931 7.93739 8.32237 7.93739 8.92793C7.93739 12.6645 7.93739 16.4011 7.93739 20.1377C7.93739 20.7423 8.31884 21.1254 8.92194 21.1254C10.7761 21.1263 12.6303 21.1254 14.4844 21.1263C14.5586 21.1263 14.6347 21.1235 14.7065 21.1382C14.9758 21.1923 15.1668 21.446 15.1462 21.7135C15.1256 21.9851 14.9057 22.2034 14.6314 22.2232C14.5747 22.2273 14.517 22.225 14.4597 22.225C12.6339 22.225 10.8082 22.2076 8.98285 22.2314C7.83252 22.2466 6.99084 21.4424 6.86262 20.5175C6.86033 20.5024 6.84659 20.4886 6.83835 20.4739C6.83789 16.5126 6.83789 12.5517 6.83789 8.5912Z"
          fill={color}
        />
        <path
          d="M19.8905 24.422C19.1862 24.0885 18.5185 23.6958 17.9122 23.204C17.0366 22.4934 16.3232 21.658 15.9229 20.5868C15.7375 20.09 15.6349 19.5752 15.6331 19.0444C15.6294 18.0544 15.6312 17.0649 15.6321 16.0749C15.6321 15.763 15.7594 15.59 16.0589 15.4882C17.3022 15.0643 18.5446 14.6367 19.7915 14.2238C19.9321 14.177 20.1148 14.1747 20.255 14.2211C21.5079 14.6349 22.7548 15.0656 24.005 15.4868C24.2202 15.5593 24.3457 15.7052 24.4244 15.9061V19.3743C24.3727 19.6362 24.3351 19.9023 24.2665 20.1601C23.9505 21.3428 23.2411 22.2658 22.3294 23.0503C21.6755 23.6132 20.946 24.0577 20.1657 24.4225H19.8909L19.8905 24.422ZM23.3002 17.8732C23.3085 17.8732 23.3167 17.8732 23.3254 17.8732C23.3254 17.4383 23.3199 17.0034 23.3291 16.5685C23.3314 16.4479 23.2897 16.4002 23.1812 16.3635C22.1687 16.0226 21.1585 15.6758 20.1455 15.3359C20.0764 15.3125 19.9857 15.3125 19.9161 15.3359C18.8981 15.6781 17.8829 16.0281 16.8649 16.3699C16.7573 16.4061 16.728 16.4557 16.7289 16.5635C16.7335 17.353 16.7325 18.143 16.7307 18.9325C16.7293 19.5578 16.8791 20.1455 17.1818 20.6905C17.8224 21.8438 18.8207 22.6094 19.9569 23.22C20.0004 23.2434 20.0805 23.2343 20.1272 23.2095C20.8897 22.803 21.5871 22.3076 22.1719 21.6667C22.775 21.0056 23.2196 20.2574 23.2933 19.3472C23.3327 18.8586 23.3002 18.3646 23.3002 17.8728V17.8732Z"
          fill={color}
        />
        <path
          d="M13.077 12.7038C14.2209 12.7038 15.3653 12.702 16.5092 12.7048C16.9135 12.7057 17.175 13.0291 17.0738 13.3956C17.0193 13.5924 16.8888 13.7236 16.6914 13.7755C16.6044 13.7984 16.5106 13.8012 16.4199 13.8016C14.1833 13.803 11.9463 13.8026 9.70979 13.8021C9.64156 13.8021 9.57195 13.8021 9.50464 13.7902C9.21248 13.7406 9.01602 13.4915 9.03892 13.2053C9.0609 12.9268 9.28483 12.7171 9.57607 12.7052C9.66766 12.7015 9.75925 12.7038 9.85083 12.7038C10.9265 12.7038 12.0017 12.7038 13.0774 12.7038H13.077Z"
          fill={color}
        />
        <path
          d="M11.598 16.7311C10.9294 16.7311 10.2604 16.7339 9.59179 16.7298C9.30192 16.7279 9.07112 16.5169 9.04044 16.2426C9.00976 15.9655 9.19385 15.7077 9.4709 15.6462C9.52585 15.6338 9.58447 15.6333 9.64125 15.6333C10.9445 15.6329 12.2478 15.6324 13.5511 15.6333C13.9165 15.6333 14.1615 15.8549 14.162 16.1816C14.1624 16.5082 13.9174 16.7311 13.5529 16.7316C12.9013 16.7325 12.2496 16.7316 11.598 16.7316V16.7311Z"
          fill={color}
        />
        <path
          d="M11.2184 10.8649C10.6693 10.8649 10.1198 10.8686 9.57073 10.8631C9.33947 10.8608 9.14897 10.7158 9.0725 10.5075C8.99556 10.297 9.04319 10.0469 9.22407 9.91756C9.33352 9.83911 9.48235 9.77535 9.61377 9.77397C10.695 9.7625 11.7766 9.7648 12.8578 9.76938C13.1957 9.77076 13.4357 10.0116 13.4334 10.3236C13.4311 10.6346 13.1893 10.8631 12.8491 10.8649C12.3055 10.8681 11.7619 10.8658 11.2184 10.8658C11.2184 10.8658 11.2184 10.8649 11.2184 10.8644V10.8649Z"
          fill={color}
        />
        <path
          d="M19.7837 19.7393C20.1857 19.2034 20.5727 18.6878 20.9596 18.1717C21.1652 17.8974 21.3699 17.6216 21.5774 17.3487C21.783 17.078 22.1081 17.0179 22.3614 17.2005C22.6183 17.3858 22.6641 17.7267 22.4571 18.0038C21.739 18.9658 21.0192 19.9264 20.2956 20.8843C20.0657 21.1885 19.7067 21.21 19.4343 20.9408C18.9585 20.4705 18.4859 19.9966 18.0147 19.5214C17.7752 19.2796 17.7669 18.9484 17.9863 18.725C18.2097 18.497 18.5482 18.5052 18.7959 18.7502C19.121 19.0713 19.4425 19.3966 19.7832 19.7384L19.7837 19.7393Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2629_48794">
          <rect
            width="17.5846"
            height="17.5846"
            fill="white"
            transform="translate(6.83789 6.83789)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CertificationIcon;
