import { BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface ILetterIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function LetterIcon({ color = '#7B8A72', style }: ILetterIconProps) {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M29.9468 8.00065C29.9468 6.53398 28.7217 5.33398 27.2243 5.33398H5.44419C3.94681 5.33398 2.72168 6.53398 2.72168 8.00065V24.0007C2.72168 25.4673 3.94681 26.6673 5.44419 26.6673H27.2243C28.7217 26.6673 29.9468 25.4673 29.9468 24.0007V8.00065ZM27.2243 8.00065L16.3342 14.6673L5.44419 8.00065H27.2243ZM27.2243 24.0007H5.44419V10.6673L16.3342 17.334L27.2243 10.6673V24.0007Z"
        fill={color}
      />
    </svg>
  );
}

export default LetterIcon;
