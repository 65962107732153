import { BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IFilterIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function FilterIcon({ color = 'white', style }: IFilterIconProps) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M2.83714 2.00065H11.1705L6.99548 7.25065L2.83714 2.00065ZM0.545477 1.67565C2.22881 3.83398 5.33714 7.83398 5.33714 7.83398V12.834C5.33714 13.2923 5.71214 13.6673 6.17048 13.6673H7.83714C8.29548 13.6673 8.67048 13.2923 8.67048 12.834V7.83398C8.67048 7.83398 11.7705 3.83398 13.4538 1.67565C13.8788 1.12565 13.4871 0.333984 12.7955 0.333984H1.20381C0.512144 0.333984 0.120477 1.12565 0.545477 1.67565Z"
        fill={color}
      />
    </svg>
  );
}

export default FilterIcon;
