import { styled, Card } from '@mui/material';

export const StyledCard = styled(Card)(({ color }) => ({
  // minWidth: '404px',
  width: '100%',
  backgroundColor: color,
}));

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: '15px',
});
