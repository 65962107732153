import CryptoJS from 'crypto-js';
import { useCallback } from 'react';

import { create } from 'zustand';
import { subscribeWithSelector, persist } from 'zustand/middleware';

import { IRegisterCheckValue } from 'types/httpClient';
import { AuthStoreDefaultValues, IAuthStore } from 'types/stores';
import { ISession, IUser, IUserToken } from 'types/user';

const isDevelopment = process.env.NODE_ENV === 'development';

const AUTH_STORE_STORAGE_KEY = 'fla-session';

const DEFAULT_VALUES: AuthStoreDefaultValues = {
  isAuthenticated: false,
  isChangingPassword: false,
  isLoggingOut: false,
  remember: false,
  user: null,
  registeredAccount: null,
  tokens: null,
};

const resetDefaults = () => useStore.setState({ ...DEFAULT_VALUES });

const handleLogout = () => {
  resetDefaults();
  useStore.setState({ isLoggingOut: true });
};

const storeSession = (session: ISession, remember = false) => {
  const { tokens, user } = session;
  useStore.setState({
    tokens,
    user,
    remember,
  });
};

const storeUser = (userData: IUser) => {
  useStore.setState({
    user: userData,
  });
};

const storeRegisteredAccount = (registerData: IRegisterCheckValue) => {
  useStore.setState({
    registeredAccount: registerData,
  });
};

const storeTokens = (tokens: IUserToken) => {
  useStore.setState({
    tokens,
  });
};

const deserialize = (rawData: string) => {
  const parsedData = isDevelopment
    ? JSON.parse(rawData)
    : JSON.parse(
        CryptoJS.AES.decrypt(
          rawData,
          process.env.REACT_APP_ENCRYPTION_KEY as string
        ).toString(CryptoJS.enc.Utf8)
      );

  return parsedData;
};

const serialize = (state: unknown) => {
  if (isDevelopment) return JSON.stringify(state);
  return CryptoJS.AES.encrypt(
    JSON.stringify(state),
    process.env.REACT_APP_ENCRYPTION_KEY as string
  ).toString();
};

const useStore = create(
  subscribeWithSelector(
    persist(
      (): IAuthStore => ({
        ...DEFAULT_VALUES,
        storeSession,
        storeUser,
        storeRegisteredAccount,
        storeTokens,
        handleLogout,
        reset: resetDefaults,
      }),
      {
        name: AUTH_STORE_STORAGE_KEY,
        deserialize,
        serialize,
      }
    )
  )
);

useStore.subscribe(
  state => state.tokens,
  tokens => useStore.setState({ isAuthenticated: !!tokens })
);

const useAuthSelector = () => useStore(useCallback(state => state, []));

export { useAuthSelector, useStore as useAuthStore };
