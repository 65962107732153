export enum ContentTypeEnum {
  WEBINAR = 'webinar',
  TUTORIAL = 'video-tutorial',
  DOCUMENTS = 'schede-esportatori',
  DEEPENINGS = 'approfondimenti',
  CONVENTIONS = 'convenzioni',
  STUDY_CENTER_PRODUCT = 'centro-studi-prodotti',
  INTELLECTUAL_PROPERTY_CONVENTION = 'convenzione',
  FINANCE_WEBINAR = 'webinar-finanza',
  INTELLECTUAL_PROPERTY_DEEPENING = 'approfondimenti_proprieta_intellettuale',
  ACADEMY_DEEPENING = 'approfondimenti-academy',
  INTELLECTUAL_PROPERTY_CONTRACTUAL_FORMAT = 'format_contrattuali_proprieta_intellettuale',
}
export enum ContextEnum {
  CERTIFICATIONS = 'portale-certificazioni',
  HOUSEOFFICE = 'sportello-doganale',
  ACADEMY = 'academy',
  INTELLECTUAL_PROPERTY = 'proprieta-intellettuale',
}

export interface IContent {
  type: string;
  id: string;
  drupal_internal__nid: number;
  drupal_internal__vid: number;
  langcode: string;
  revision_timestamp: Date;
  revision_log: null;
  status: boolean;
  title: string;
  created: Date;
  changed: Date;
  promote: boolean;
  sticky: boolean;
  default_langcode: boolean;
  revision_translation_affected: boolean;
  content_translation_source: string;
  content_translation_outdated: boolean;
  body: Body;
  field_section: string;
  count?: number;
  field_type: string;
  field_video: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options: any[];
    title: string;
    uri: string;
  };
  field_content_category?: IContentCategory;
  links: FieldAttachedLinks;
  node_type: NodeType;
  revision_uid: Uid;
  uid: Uid;
  field_file: IFieldFile;
  field_files?: IFieldFile[];
  field_contact: FieldContact;
  field_image: FieldImage;
  relationshipNames: string[];
}

export interface IContentCategory {
  type: string;
  id: string;
  drupal_internal__tid: number;
  drupal_internal__revision_id: number;
  langcode: string;
  revision_created: Date;
  revision_log_message: null;
  status: boolean;
  name: string;
  description: null;
  weight: number;
  changed: Date;
  default_langcode: boolean;
  revision_translation_affected: boolean;
  path: Path;
  links: IContentCategoryLinks;
  resourceIdObjMeta: IContentCategoryResourceIDObjMeta;
  vid: Vid;
  revision_user: null;
  parent: Parent[];
  relationshipNames: string[];
}

export interface IContentCategoryLinks {
  describedby: Describedby;
  self: Describedby;
}

export interface Describedby {
  href: string;
}

export interface Parent {
  type: string;
  id: string;
  resourceIdObjMeta: ParentResourceIDObjMeta;
}

export interface ParentResourceIDObjMeta {
  links: ResourceIDObjMetaLinks;
}

export interface ResourceIDObjMetaLinks {
  help: Help;
}

export interface Help {
  href: string;
  meta: Meta;
}

export interface Meta {
  about: string;
}

export interface Path {
  alias: string;
  pid: number;
  langcode: string;
}

export interface IContentCategoryResourceIDObjMeta {
  drupal_internal__target_id: number;
}

export interface Vid {
  type: string;
  id: string;
  resourceIdObjMeta: VidResourceIDObjMeta;
}

export interface VidResourceIDObjMeta {
  drupal_internal__target_id: string;
}

export interface IFieldFile {
  type: string;
  id: string;
  drupal_internal__mid: number;
  drupal_internal__vid: number;
  langcode: string;
  revision_created: Date;
  revision_log_message: null;
  status: boolean;
  name: string;
  created: Date;
  changed: Date;
  default_langcode: boolean;
  revision_translation_affected: boolean;
  path: Path;
  links: Links;
  resourceIdObjMeta: UidResourceIDObjMeta;
  bundle: Bundle;
  revision_user: null;
  uid: Uid;
  thumbnail: Thumbnail;
  field_media_file: FieldMediaFile;
  relationshipNames: string[];
}

export interface Bundle {
  type: string;
  id: string;
  resourceIdObjMeta: BundleResourceIDObjMeta;
}

export interface BundleResourceIDObjMeta {
  drupal_internal__target_id: string;
}

export interface FieldMediaFile {
  type: string;
  id: string;
  drupal_internal__fid: number;
  langcode: string;
  filename: string;
  uri: URI;
  filemime: string;
  filesize: number;
  status: boolean;
  created: Date;
  changed: Date;
  image_style_uri: null;
  links: Links;
  resourceIdObjMeta: FieldMediaFileResourceIDObjMeta;
  uid: Uid;
  relationshipNames: string[];
}

export interface Links {
  describedby: Describedby;
  self: Describedby;
}

export interface Describedby {
  href: string;
}

export interface FieldMediaFileResourceIDObjMeta {
  display: null;
  description: null;
  drupal_internal__target_id: number;
}

export interface Uid {
  type: string;
  id: string;
  resourceIdObjMeta: UidResourceIDObjMeta;
}

export interface UidResourceIDObjMeta {
  drupal_internal__target_id: number;
}

export interface URI {
  value: string;
  url: string;
}

export interface Thumbnail {
  type: string;
  id: string;
  resourceIdObjMeta: ThumbnailResourceIDObjMeta;
}

export interface ThumbnailResourceIDObjMeta {
  alt: string;
  title: null;
  width: null;
  height: null;
  drupal_internal__target_id: number;
}

export interface Body {
  value: string;
  format: string;
  processed: string;
  summary?: string;
}

export interface FieldAttached {
  type: string;
  id: string;
  drupal_internal__fid: number;
  langcode: string;
  filename: string;
  uri: URI;
  filemime: string;
  filesize: number;
  status: boolean;
  created: Date;
  changed: Date;
  image_style_uri: null;
  links: FieldAttachedLinks;
  resourceIdObjMeta: FieldAttachedResourceIDObjMeta;
  uid: Uid;
  relationshipNames: string[];
}

export interface FieldAttachedLinks {
  describedby: Describedby;
  self: Describedby;
}

export interface Describedby {
  href: string;
}

export interface FieldAttachedResourceIDObjMeta {
  display: boolean;
  description: string;
  drupal_internal__target_id: number;
}

export interface Uid {
  type: string;
  id: string;
  resourceIdObjMeta: RevisionUidResourceIDObjMeta;
}

export interface RevisionUidResourceIDObjMeta {
  drupal_internal__target_id: number;
}

export interface URI {
  value: string;
  url: string;
}
export type DrupalContentLabel =
  | 'webinar'
  | 'document'
  | 'video_tutorial'
  | 'course'
  | 'articolo';

export interface FieldContact {
  type: string;
  id: string;
  drupal_internal__tid: number;
  drupal_internal__revision_id: number;
  langcode: string;
  revision_created: Date;
  revision_log_message: null;
  status: boolean;
  name: string;
  description: Body;
  weight: number;
  changed: Date;
  default_langcode: boolean;
  revision_translation_affected: boolean;
  path: Path;
  field_email: string;
  field_phone: string;
  links: FieldAttachedLinks;
  resourceIdObjMeta: RevisionUidResourceIDObjMeta;
  vid: NodeType;
  revision_user: null;
  parent: Parent[];
  relationshipNames: string[];
}

export interface Parent {
  type: string;
  id: string;
  resourceIdObjMeta: ParentResourceIDObjMeta;
}

export interface ParentResourceIDObjMeta {
  links: ResourceIDObjMetaLinks;
}

export interface ResourceIDObjMetaLinks {
  help: Help;
}

export interface Help {
  href: string;
  meta: Meta;
}

export interface Meta {
  about: string;
}

export interface Path {
  alias: string;
  pid: number;
  langcode: string;
}

export interface NodeType {
  type: string;
  id: string;
  resourceIdObjMeta: NodeTypeResourceIDObjMeta;
}

export interface NodeTypeResourceIDObjMeta {
  drupal_internal__target_id: string;
}

export interface FieldImage {
  type: string;
  id: string;
  drupal_internal__mid: number;
  drupal_internal__vid: number;
  langcode: string;
  revision_created: Date;
  revision_log_message: null;
  status: boolean;
  name: string;
  created: Date;
  changed: Date;
  default_langcode: boolean;
  revision_translation_affected: boolean;
  links: FieldAttachedLinks;
  resourceIdObjMeta: RevisionUidResourceIDObjMeta;
  bundle: NodeType;
  revision_user: null;
  uid: Uid;
  thumbnail: FieldMediaImage;
  field_media_image: FieldMediaImage;
  relationshipNames: string[];
}

export interface FieldMediaImage {
  type: string;
  id: string;
  drupal_internal__fid: number;
  langcode: string;
  filename: string;
  uri: URI;
  filemime: string;
  filesize: number;
  status: boolean;
  created: Date;
  changed: Date;
  image_style_uri: ImageStyleURI;
  links: FieldAttachedLinks;
  resourceIdObjMeta: FieldMediaImageResourceIDObjMeta;
  uid: Uid;
  relationshipNames: string[];
}

export interface ImageStyleURI {
  large: string;
  media_library: string;
  medium: string;
  thumbnail: string;
  wide: string;
}

export interface FieldMediaImageResourceIDObjMeta {
  alt: string;
  title: null | string;
  width: number;
  height: number;
  drupal_internal__target_id: number;
}
