import Jsona from 'jsona';
import {
  TDeserializeOptions,
  TJsonaModel,
  TJsonApiBody,
} from 'jsona/lib/JsonaTypes';
import {
  EntityFieldConfig,
  FilterOperatorEnum,
  ICustomApiError,
} from './../types';

const dataFormatter = new Jsona();

export const deserialize = (
  body?: string | TJsonApiBody,
  options?: TDeserializeOptions | undefined
): TJsonaModel | TJsonaModel[] | null => {
  if (!body) return null;
  return dataFormatter.deserialize(body, options);
};

export const logApiError = (err: ICustomApiError, request?: string) => {
  let errorMessage = `🔴 API ERROR`;
  if (err.statusCode) errorMessage += ` ${err.statusCode}`;
  if (request) errorMessage += ` | ${request}`;
  if (err.message) errorMessage += `: ${err.message}`;
  console.error(errorMessage);
};

export const createQueryParams = ({
  nodeFieldConfig = [],
  additionalParams = [],
}: {
  additionalParams?: string[][];
  nodeFieldConfig?: EntityFieldConfig[];
}) => {
  const includesArray = nodeFieldConfig.map(nfc => {
    const hasTargetBundles = !!Object.entries(
      nfc.attributes.settings?.handler_settings?.target_bundles
    ).length;
    return hasTargetBundles || nfc.attributes.field_type.includes('image')
      ? nfc.attributes.field_name
      : '';
  });
  const params: string[][] = [];
  const includesString = includesArray.filter(i => !!i).join(', ');
  if (includesString) params.push(['include', includesString]);
  if (additionalParams?.length) additionalParams.map(p => params.push(p));
  const query = params?.length && new URLSearchParams(params);
  return query ? `?${new URLSearchParams(query)}` : '';
};

export const buildJsonApiSimpleFilter = ({
  key,
  value,
  operator = 'CONTAINS',
}: {
  key: string;
  value: string;
  operator?: `${FilterOperatorEnum}`;
}) => [
  [`filter[${key}][value]`, value],
  [`filter[${key}][operator]`, operator],
];

export const buildJsonApiGroupFilter = ({
  keys,
  values,
  operator = 'CONTAINS',
  conjuction = 'OR',
  groupName = 'or-group',
}: {
  keys: string[] | string;
  values: string[] | string;
  operator?: `${FilterOperatorEnum}`;
  conjuction?: 'OR' | 'AND';
  groupName?: string;
}) => {
  const filters =
    typeof keys !== 'string' && typeof values === 'string'
      ? keys
          .map(k => [
            [`filter[${k}-filter][condition][path]`, k],
            [`filter[${k}-filter][condition][operator]`, operator],
            [`filter[${k}-filter][condition][value]`, values],
            [`filter[${k}-filter][condition][memberOf]`, groupName],
          ])
          .flat(1)
      : typeof values !== 'string' && typeof keys === 'string'
      ? values
          .map(v => [
            [`filter[${v}-filter][condition][path]`, keys],
            [`filter[${v}-filter][condition][operator]`, operator],
            [`filter[${v}-filter][condition][value]`, v],
            [`filter[${v}-filter][condition][memberOf]`, groupName],
          ])
          .flat(1)
      : [[]];
  return [[`filter[${groupName}][group][conjunction]`, conjuction], ...filters];
};

export const buildJsonApiNestedFilters = ({
  fields,
}: {
  fields: {
    key: string;
    value: string;
    nestedField: string;
    operator?: `${FilterOperatorEnum}`;
  }[];
}) => {
  const filters = fields
    .map((f, idx) => [
      [`filter[${f.key}_${idx}][condition][path]`, f.nestedField],
      [`filter[${f.key}_${idx}][condition][operator]`, f.operator || '='],
      [`filter[${f.key}_${idx}][condition][value]`, f.value],
    ])
    .flat(1);

  return [...filters];
};

export const buildLimit = (limit: number) => [['page[limit]', `${limit}`]];

export const buildOffset = (offset: number) => [['page[offset]', `${offset}`]];

export const buildSorting = ({
  sortingFieldName,
  sortingOrder = 'ASC',
}: {
  sortingFieldName: string;
  sortingOrder: 'ASC' | 'DESC';
}) => [['sort', `${sortingOrder === 'DESC' ? '-' : ''}${sortingFieldName}`]];
