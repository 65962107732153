import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { IRHFCheckboxProps, IRHFMultiCheckboxProps } from './RHFCheckbox.type';

('MuiFormControlLabel-label');

const StyledLabel = styled(FormControlLabel)(({ theme }) => ({
  '&.MuiFormControlLabel-root .MuiFormControlLabel-label': {
    color: theme.palette.primary.main,
    fontSize: '16px',
  },
}));

export function RHFCheckbox({
  name,
  rules,
  disabled,
  formControlStyle,
  defaultValue,
  label,
  required,
  ...other
}: IRHFCheckboxProps) {
  //hooks
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl
      error={!!errors[name]}
      style={formControlStyle}
      required={required}
    >
      <StyledLabel
        label={label}
        control={
          <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field }) => (
              <Checkbox {...field} checked={field.value} disabled={disabled} />
            )}
          />
        }
        {...other}
      />
      {errors[name] && (
        <FormHelperText>{errors?.[name]?.message}</FormHelperText>
      )}
    </FormControl>
  );
}

export function RHFMultiCheckbox({
  name,
  options,
  formGroupStyle,
  gridOptions,
  checkboxSx,
  hasOtherField = false,
  otherFieldName = '',
  ...other
}: IRHFMultiCheckboxProps) {
  const { control } = useFormContext();

  const [isTextfieldDisabled, setIsTextfieldDisabled] = useState(true);

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const onSelected = (option: string) =>
            field.value.includes(option)
              ? field.value.filter((value: string) => value !== option)
              : [...field.value, option];

          return (
            <FormGroup style={formGroupStyle}>
              <Grid container>
                {options?.map(option => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={4}
                    key={option.value}
                    {...gridOptions}
                  >
                    <FormControlLabel
                      key={option.value}
                      control={
                        <Checkbox
                          checked={field.value?.includes(option.value)}
                          onChange={() =>
                            field.onChange(onSelected(option.value))
                          }
                          sx={{
                            ...(checkboxSx && { ...checkboxSx }),
                          }}
                          color={
                            (other.color as
                              | 'error'
                              | 'default'
                              | 'primary'
                              | 'secondary'
                              | 'info'
                              | 'success'
                              | 'warning') || 'default'
                          }
                        />
                      }
                      {...other}
                      label={
                        <Typography variant="body2">{option.label}</Typography>
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
          );
        }}
      />
      {hasOtherField && otherFieldName ? (
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} {...gridOptions}>
            <Stack direction={'row'} alignItems={'center'}>
              <Controller
                name={`${otherFieldName}_isEnabled`}
                control={control}
                render={({ field }) => {
                  return (
                    <FormGroup style={formGroupStyle}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={e => {
                              setIsTextfieldDisabled(!e.target.checked);
                              field.onChange(e.target.checked);
                            }}
                            sx={{
                              ...(checkboxSx && { ...checkboxSx }),
                            }}
                            color={
                              (other.color as
                                | 'error'
                                | 'default'
                                | 'primary'
                                | 'secondary'
                                | 'info'
                                | 'success'
                                | 'warning') || 'default'
                            }
                          />
                        }
                        key={new Date().toISOString()}
                        label={<Typography variant="body2">Altro</Typography>}
                      />
                    </FormGroup>
                  );
                }}
              />
              <Controller
                name={otherFieldName}
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      type={'text'}
                      onWheel={e =>
                        e.target instanceof HTMLElement && e.target.blur()
                      }
                      onChange={e => {
                        field.onChange(e.target.value);
                      }}
                      disabled={isTextfieldDisabled}
                      label={'Specificare'}
                    />
                  );
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}
