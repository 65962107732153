import {
  Autocomplete,
  CircularProgress,
  TextField,
  createFilterOptions,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useFetch } from 'use-http';
import {
  IRHFAutoCompleteProps,
  Option,
  RHFApiAutocompleteProps,
} from './RHFAutocomplete.types';
import { deserialize, DrupalNode, useDebounce } from 'hooks';

const RHFAutocomplete = <T,>({
  label,
  name,
  placeholder,
  rules,
  inputList,
  renderCustomLabel,
  renderOption,
  renderTag,
  multiple = false,
  disabled,
}: IRHFAutoCompleteProps<T>) => {
  //hooks
  const { control, watch } = useFormContext();

  //ref
  const ref = useRef<HTMLDivElement>();
  const fieldValue = watch(name);

  useEffect(() => {
    {
      /*  After one year: still Pure Art 🎨 */
    }
    if (!fieldValue && ref) {
      const input = ref.current;
      if (input) {
        (
          input.querySelector(
            '.MuiAutocomplete-clearIndicator'
          ) as HTMLButtonElement
        )?.click();
      }
    }
  }, [fieldValue]);

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    stringify: (option: any) => option.label,
  });

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          noOptionsText={'Nessuna Opzione'}
          ref={ref}
          id={name}
          multiple={multiple}
          autoHighlight
          options={inputList}
          getOptionLabel={(option: string | T) =>
            typeof option === 'string'
              ? option
              : renderCustomLabel?.(option) || ''
          }
          renderOption={(props, option) => renderOption?.(props, option as T)}
          renderTags={(value, getTagProps) =>
            renderTag?.(value as T[], getTagProps)
          }
          filterOptions={filterOptions}
          onChange={(_event, value) => field.onChange(value)}
          renderInput={params => (
            <TextField
              {...params}
              error={!!error}
              helperText={error?.message}
              autoComplete={'off'}
              label={label}
              inputRef={ref}
              disabled={disabled}
              placeholder={placeholder}
            />
          )}
        />
      )}
    />
  );
};

const RHFApiAutocomplete = ({
  name,
  rules,
  label,
  placeholder,
  charactersCount = 3,
  endpoints,
  inputList = [],
  filterName = 'title',
  condition = 'CONTAINS',
  otherFilters = [],
}: RHFApiAutocompleteProps) => {
  const { control } = useFormContext();
  const { get, loading } = useFetch();
  const [options, setOptions] = useState<Option[]>([]);
  const [characters, setCharacters] = useState('');
  const debouncedText = useDebounce(characters, 500);
  useEffect(() => {
    const fetchEnpoints = async (endpoints: string[]) => {
      const basicQuery = [
        [`filter[${filterName}-filter][condition][path]`, filterName],
        [`filter[${filterName}-filter][condition][operator]`, condition],
        [`filter[${filterName}-filter][condition][value]`, characters],
      ];
      const effectiveQuery = [...basicQuery, ...otherFilters];
      const params = effectiveQuery.length
        ? `?${new URLSearchParams(effectiveQuery)}`
        : '';
      const resPromises = await Promise.all(
        endpoints.map(e => get(`${e}${params}`))
      );
      if (resPromises?.length) {
        const flatted = resPromises
          .map(
            res =>
              deserialize({
                data: res.data,
                included: res.included,
              }) as DrupalNode
          )
          .flat();
        const data = flatted.map(r => ({
          value: r.id,
          label: r.title,
        }));
        setOptions(data);
      }
    };
    if (
      debouncedText &&
      debouncedText.length >= charactersCount &&
      endpoints?.length
    ) {
      fetchEnpoints(endpoints);
    }
    if (
      debouncedText &&
      debouncedText !== '' &&
      debouncedText.length >= charactersCount &&
      inputList.length
    ) {
      setOptions(inputList);
    } else {
      setOptions([]);
    }
  }, [debouncedText]);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          id={name}
          fullWidth
          loadingText={options.length === 0 ? 'Loading...' : ''}
          options={options}
          getOptionLabel={(option: Option) => option.label}
          onChange={(_event, value) => field.onChange(value)}
          onInputChange={(_event, value) => setCharacters(value)}
          renderInput={params => (
            <TextField
              {...field}
              {...params}
              fullWidth
              error={!!error}
              helperText={error?.message}
              autoComplete={'off'}
              label={label}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

export { RHFAutocomplete, RHFApiAutocomplete };
