import { useCallback } from 'react';
import { create } from 'zustand';
import { IDrupalNotification } from 'types/notification';
import { INotification, INotificationsStore } from 'types/stores';

const DrupalNotificationStatusLabel = 'notification_status';

const useStore = create<INotificationsStore>(
  (): INotificationsStore => ({
    notifications: undefined,
  })
);

const useNotificationsSelector = () =>
  useStore(useCallback(state => state, []));

const useProdNotificationIds =
  process.env.REACT_APP_USE_NOTIFICATION_PROD_ID === 'true';

const interestsDrupalMapping = useProdNotificationIds
  ? {
      Academy: 2163,
      'Centro Studi': 2164,
      Finanza: 2165,
      'Proprietà intellettuale': 2166,
      'Libreria Materiali': 2167,
      TECLA: 2168,
      'Survey Generale': 2169,
      'Bosco del Design': 2170,
      'Portale Certificazioni': 2171,
      'Sportello Doganale': 2172,
    }
  : {
      Academy: 2176,
      'Centro Studi': 2177,
      Finanza: 2178,
      'Proprietà intellettuale': 2179,
      'Libreria Materiali': 2180,
      TECLA: 2181,
      'Survey Generale': 2182,
      'Bosco del Design': 2183,
      'Portale Certificazioni': 2184,
      'Sportello Doganale': 2185,
    };

const iconsMapping = {
  [interestsDrupalMapping.Academy]: 'formazione',
  [interestsDrupalMapping['Centro Studi']]: 'centro_studi',
  [interestsDrupalMapping.Finanza]: 'finanziamenti_fisco',
  [interestsDrupalMapping['Proprietà intellettuale']]: 'proprieta_intelletuale',
  [interestsDrupalMapping['Libreria Materiali']]: 'libreria_materiali',
  [interestsDrupalMapping.TECLA]: 'circolarità',
  [interestsDrupalMapping['Survey Generale']]: 'survey',
  [interestsDrupalMapping['Bosco del Design']]: 'bosco_design',
  [interestsDrupalMapping['Portale Certificazioni']]: 'portale_certificazioni',
  [interestsDrupalMapping['Sportello Doganale']]: 'sportello_doganale',
};

//THIS TO PARSE LINK IF YOU WANT TO USE NAVIGATE
// const composeNotificationLink = (link: string) => {
//   console.log('LOG::  link:', link);
//   if (link.includes('fla')) {
//     const linkComposed = link.split('/').slice(3).join('/');
//     return `/${linkComposed}`;
//   } else {
//     return link;
//   }
// };

const convertDrupalToSystemNotification = (
  notification: IDrupalNotification
): INotification => ({
  id: notification.id,
  createdAt: notification.created,
  description: '',
  link: notification.fla_notification.field_link?.uri
    ? notification.fla_notification.field_link?.uri
    : '',
  avatar: '',
  isUnRead: notification[DrupalNotificationStatusLabel] === 'unread',
  title: notification.title,
  type: iconsMapping[
    Object.values(interestsDrupalMapping).includes(
      notification.fla_notification.taxonomy_term.resourceIdObjMeta
        .drupal_internal__target_id
    )
      ? notification.fla_notification.taxonomy_term.resourceIdObjMeta
          .drupal_internal__target_id
      : interestsDrupalMapping['Bosco del Design']
  ],
  isArchieved: notification[DrupalNotificationStatusLabel] === 'archived',
});

export {
  useNotificationsSelector,
  useStore as useNotificationsStore,
  interestsDrupalMapping,
  convertDrupalToSystemNotification,
  DrupalNotificationStatusLabel,
};
