import { Box, BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface ISustenibilityIcon extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function SustenibilityIcon({
  color = '#7B8A72',
  style,
  ...other
}: ISustenibilityIcon) {
  return (
    <Box {...other}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        style={style}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.225 4.54167L6.275 1.14167C6.6 0.6 7.38333 0.6 7.70833 1.14167L8.94167 3.19167L7.91667 4.90833L7.08333 6.25833L4.225 4.54167ZM17.5167 9.39167L16.1833 7.175L13.3 8.84167L15.1417 11.9167H16.0833C16.7167 11.9167 17.2917 11.5583 17.575 10.9917C17.6917 10.7583 17.75 10.5083 17.75 10.25C17.75 9.95 17.6667 9.65833 17.5167 9.39167ZM12.75 16.0833H14C14.6333 16.0833 15.2083 15.725 15.4917 15.1583L16.7 12.75H12.75V11.0833L9.41667 14.4167L12.75 17.75V16.0833ZM7.75 12.75H4.16667L3.46667 13.925C3.21667 14.3417 3.2 14.8583 3.41667 15.3C3.65 15.775 4.14167 16.0833 4.68333 16.0833H7.75V12.75ZM4.51667 10.5417L5.95833 11.4083L4.81667 6.83333L0.25 7.975L1.66667 8.825L1.325 9.4C1.03333 9.89167 1.00833 10.4917 1.26667 11L2.625 13.7167L4.51667 10.5417ZM13.6 2.86667L12.5167 1.05833C12.2083 0.558333 11.6667 0.25 11.0833 0.25H8.14167L10.7417 4.58333L9.30833 5.44167L13.8833 6.58333L15.025 2.00833L13.6 2.86667Z"
          fill={color}
        />
      </svg>
    </Box>
  );
}

export default SustenibilityIcon;
