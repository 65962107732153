// @mui

import { CSSProperties } from 'react';

// ----------------------------------------------------------------------

function SideBarLibraryIcon({
  color = '#919EAB',
  style,
}: {
  color?: string;
  style?: CSSProperties;
}) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g id="ICONS">
        <path
          id="Icon"
          d="M28 16.4999H20.8L18.4 20.0999H13.6L11.2 16.4999H4M28 16.4999V23.6999C28 24.3364 27.7471 24.9469 27.2971 25.397C26.847 25.847 26.2365 26.0999 25.6 26.0999H6.4C5.76348 26.0999 5.15303 25.847 4.70294 25.397C4.25286 24.9469 4 24.3364 4 23.6999V16.4999M28 16.4999L23.86 8.2319C23.6613 7.83205 23.355 7.49555 22.9755 7.26024C22.5961 7.02493 22.1585 6.90014 21.712 6.8999H10.288C9.8415 6.90014 9.40392 7.02493 9.02445 7.26024C8.64499 7.49555 8.33869 7.83205 8.14 8.2319L4 16.4999"
          stroke={color}
          strokeWidth="2.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default SideBarLibraryIcon;
