import {
  Button,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { UseFormReturn } from 'react-hook-form';
import {
  ContainerScroll,
  MainContainer,
  StyledMobileResultCard,
} from './GlossarySearchBar.style';
import { IGlossaryWord } from './GlossatySearchBar.types';
import { Iconify, RHFTextField } from 'components';
import { useResponsive } from 'hooks';
import { FormProvider } from 'providers';

interface IMobileResCard {
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  methods: UseFormReturn<any>;
  setTyping: (bool: boolean) => void;
  reduceGlossary: () => void;
  setGlossaryWords: (value: React.SetStateAction<IGlossaryWord[]>) => void;
  setCharacters: (value: React.SetStateAction<string>) => void;
  isExpanded: boolean;
  typing: boolean;
  glossaryWords: IGlossaryWord[];
}

const MOBILE_TABLE_HEAD = [
  { key: 'name', label: 'IT' },
  { key: 'field_name_en', label: 'EN' },
  { key: 'field_name_fr', label: 'FR' },
  { key: 'field_name_es', label: 'ES' },
  { key: 'field_name_de', label: 'DE' },
  { key: 'field_name_ru', label: 'RU' },
  { key: 'field_name_zh', label: 'ZH' },
];

const MobileResultCard = ({
  onClose,
  methods,
  setTyping,
  reduceGlossary,
  setGlossaryWords,
  setCharacters,
  isExpanded,
  typing,
  glossaryWords,
}: IMobileResCard) => {
  const theme = useTheme();
  const isSmDown = useResponsive('down', 600);
  return (
    <>
      <Stack
        sx={{ width: '100%' }}
        alignItems="flex-end"
        justifyContent="flex-start"
      >
        <Button
          size="small"
          color="primary"
          onClick={() => onClose()}
          endIcon={
            <Iconify
              icon="ic:outline-close"
              style={{
                width: 32,
                height: 32,
                marginBottom: 2,
              }}
            />
          }
          sx={{
            minHeight: '0px',
            minWidth: '0px',
            padding: '0px 10px',
            color: theme.palette.grey[600],
            fontSize: '13px',
          }}
        ></Button>
      </Stack>
      <FormProvider methods={methods} style={{ width: '100%', marginTop: 16 }}>
        <RHFTextField
          name="searchGlossaryBar"
          label="Cerca nel glossario"
          onKeyUp={event => {
            if (event.code !== 'Enter') {
              setTyping(true);
              reduceGlossary();
              setGlossaryWords([]);
            }
          }}
          onKeyDown={event => {
            if (event.code === 'Enter') {
              event.preventDefault();
            }
          }}
          onBlur={e => (e.target.value.length === 0 ? setTyping(false) : null)}
          onChange={e => setCharacters(e.target.value)}
          style={{ width: isSmDown ? '95%' : '98%' }}
          InputProps={{
            startAdornment: (
              <InputAdornment component="div" position="start">
                <Iconify icon="ic:baseline-search" style={{ width: '17px' }} />
              </InputAdornment>
            ),
            endAdornment:
              methods.watch('searchGlossaryBar')?.length > 0 ? (
                <IconButton
                  onClick={() => {
                    methods.setValue('searchGlossaryBar', '');
                  }}
                >
                  <Iconify
                    icon="mdi:close-circle-outline"
                    style={{ width: '17px' }}
                  />
                </IconButton>
              ) : null,
            style: { height: 51, paddingRight: 5 },
          }}
        />
      </FormProvider>
      <MainContainer expandDrawer={isExpanded} typing={typing}>
        <Fade
          in={isExpanded || typing}
          style={{ transitionDelay: isExpanded ? '0.3s' : '0' }}
        >
          <ContainerScroll typing={typing}>
            <Grid container style={{ width: '100%', marginLeft: 0 }}>
              {glossaryWords.map((el, index) => (
                <Grid key={index} xs={12} sm={6} md={4}>
                  <StyledMobileResultCard
                    style={{
                      ...((index + 1) % 2 == 0 ? {} : { marginLeft: 0 }),
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        paddingLeft: 44,
                        paddingRight: 34,
                        position: 'relative',
                      }}
                    >
                      {MOBILE_TABLE_HEAD.map((mth, idx) => (
                        <div key={idx}>
                          <Typography
                            style={{
                              fontSize: 12,
                              color: '#E28352',
                              position: 'absolute',
                              left: 27,
                            }}
                          >
                            {mth.label}
                          </Typography>
                          <Typography style={{ fontSize: 14 }}>
                            {el[mth.key as keyof IGlossaryWord]}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  </StyledMobileResultCard>
                </Grid>
              ))}
            </Grid>
          </ContainerScroll>
        </Fade>
      </MainContainer>
    </>
  );
};
export default MobileResultCard;
