import { BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IPolicyIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function PolicyIcon({ color = '#368FD3', style }: IPolicyIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M10 2.65825L15.8333 5.24992V9.16658C15.8333 12.9333 13.35 16.4083 10 17.4416C6.65 16.4083 4.16667 12.9333 4.16667 9.16658V5.24992L10 2.65825ZM10 0.833252L2.5 4.16658V9.16658C2.5 13.7916 5.7 18.1166 10 19.1666C14.3 18.1166 17.5 13.7916 17.5 9.16658V4.16658L10 0.833252ZM9.16667 5.83325H10.8333V7.49992H9.16667V5.83325ZM9.16667 9.16658H10.8333V14.1666H9.16667V9.16658Z"
        fill={color}
      />
    </svg>
  );
}

export default PolicyIcon;
