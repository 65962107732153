import { Stack, Typography, useTheme } from '@mui/material';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import { ITitlePageProps } from './TitlePage.types';
import { Breadcrumbs } from 'components';
import { useResponsive, useTranslations } from 'hooks';
import {
  getCustomBreadcrumbs,
  excludePaths,
} from 'routes/customBreadcrumbsMap';

const TitlePage = ({
  title,
  subtitle,
  icon,
  color,
  sx,
  buttons,
  sticky = false,
  showBreadcrumbs = true,
  additionalButtons,
}: ITitlePageProps) => {
  //hooks
  const theme = useTheme();
  const i18n = useTranslations();
  const routes = getCustomBreadcrumbs(i18n);
  const isSmDown = useResponsive('down', 'sm');
  const isMdDown = useResponsive('down', 'md');

  const breadcrumbs = useReactRouterBreadcrumbs(routes, {
    excludePaths,
  });

  return (
    <Stack
      flexDirection={isSmDown ? 'column' : 'row'}
      justifyContent="space-between"
      alignItems={isSmDown ? 'flex-start' : 'flex-end'}
      sx={{
        backgroundColor: '#EFEEEA',
        position: sticky ? 'sticky' : 'auto',
        top: sticky ? '60px' : 'auto',
        paddingBottom: '10px',
        zIndex: '2',
      }}
    >
      <Stack
        sx={{ margin: isSmDown ? '20px 0 10px 0' : '20px 0px', ...sx }}
        gap={1}
      >
        {showBreadcrumbs ? <Breadcrumbs links={breadcrumbs} /> : null}
        <Stack
          flexDirection={additionalButtons && isMdDown ? 'column' : 'row'}
          alignItems={additionalButtons && isMdDown ? 'flex-start' : 'center'}
          justifyContent={additionalButtons ? 'space-between' : 'flex-start'}
          gap="15px"
        >
          {icon}
          <Typography
            variant="h3"
            sx={{
              color: color ? color : theme.palette.primary.dark,
              fontWeight: '700px',
              paddingTop: '5px',
            }}
          >
            {title}
          </Typography>
          {additionalButtons || null}
        </Stack>
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.grey[600],
            fontWeight: 400,
          }}
        >
          {subtitle}
        </Typography>
      </Stack>
      {buttons}
    </Stack>
  );
};

export { TitlePage };
