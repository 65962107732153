import { Box, BoxProps } from '@mui/material';
import { memo } from 'react';

interface ILogoProps extends BoxProps {
  color?: string;
}

const Logo = ({ color = '#fff', ...other }: ILogoProps) => {
  return (
    <Box {...other}>
      <svg viewBox="0 0 161 88" fill={color} xmlns="http://www.w3.org/2000/svg">
        <path
          d="M67.6549 0.150497L55.5981 62.365H61.5298L73.5866 0.150497H67.6549Z"
          fill=""
        />
        <path d="M106.469 62.3649L105.932 57.1301H105.76C103.374 61.137 99.2908 63.2697 93.8749 63.2697C83.9458 63.2697 78.1001 56.7854 78.1001 45.5834V20.4218H92.2846V43.8815C92.2846 48.3193 94.3262 50.6458 97.7864 50.6458C101.87 50.6458 103.997 48.0608 103.997 43.0845V20.4218H118.182V62.3649H106.469Z" />
        <path d="M157.103 22.1888V34.6403H156.565C153.9 31.969 149.559 30.4611 144.509 30.4611C140.877 30.4611 138.921 31.5382 138.921 33.477C138.921 34.8988 140.167 35.6959 144.96 36.0621C155.512 36.8592 160.026 40.7799 160.026 49.2245C160.026 58.1215 153.557 63.2701 142.381 63.2701C135.375 63.2701 130.066 62.0207 126.692 59.6295V47.0056H127.229C130.69 50.474 135.568 52.2404 141.779 52.2404C145.239 52.2404 147.195 51.1633 147.195 49.2245C147.195 47.4365 145.777 46.6394 140.554 46.2086C130.367 45.3253 126.112 41.6847 126.112 33.9509C126.112 25.0539 133.118 19.5606 144.208 19.5606C149.194 19.5175 153.9 20.5085 157.103 22.1888Z" />
        <path d="M45.841 41.5116C45.841 51.0333 39.372 57.6684 30.2596 57.7761C21.1471 57.6684 14.6782 51.0549 14.6782 41.5116C14.6782 31.9683 21.1471 25.2686 30.2596 25.1609C39.372 25.2686 45.841 31.9899 45.841 41.5116ZM51.8586 41.5116C51.8586 28.8877 43.0901 19.8184 30.8398 19.8184C30.6034 19.8184 29.5504 19.8184 29.3139 19.8184V19.8399C24.8222 19.9046 20.8033 21.1971 17.5365 23.4375L18.2887 19.5168H12.3571L0.300293 81.1065H6.23197L11.7338 53.3384C15.2799 59.2841 21.577 62.9894 29.3139 63.0971C29.5504 63.0971 30.6034 63.0971 30.8398 63.0971C43.0901 63.1186 51.8586 54.1354 51.8586 41.5116Z" />
        <path d="M27.487 80.4826C27.487 82.6799 26.0256 83.8217 23.9624 83.8217H21.19V87.7855H19.6211V77.1435H24.0699C26.0471 77.1651 27.487 78.2853 27.487 80.4826ZM25.8752 80.4826C25.8752 79.3193 25.1659 78.6515 24.0484 78.6515H21.19V82.3137H24.0484C25.2734 82.3137 25.8752 81.6243 25.8752 80.4826Z" />
        <path d="M32.108 87.7855H30.5176V77.1435H32.108V87.7855Z" />
        <path d="M43.8426 83.3475C43.8426 86.3203 42.4026 87.9576 39.7591 87.9576C37.1372 87.9576 35.7832 86.2557 35.7832 83.3259V77.1648H37.3736V83.2829C37.3736 85.2863 38.2118 86.3634 39.8021 86.3634C41.4785 86.3634 42.2522 85.3294 42.2522 83.3906V77.1648H43.8426V83.3475V83.3475ZM40.7693 76.6909H39.5227L37.0297 74.2566H39.1574L40.7693 76.6909Z" />
        <path d="M60.0682 85.9112C59.316 87.2253 58.0265 87.9362 56.4146 87.9362C53.6637 87.9362 51.9229 85.6743 51.9229 82.4429C51.9229 79.2116 53.6637 76.9496 56.4146 76.9496C58.1339 76.9496 59.2945 77.639 60.0252 78.9315L58.6067 79.8148C58.0695 78.9531 57.5107 78.5437 56.4146 78.5437C54.5663 78.5437 53.5562 80.0517 53.5562 82.4214C53.5562 84.791 54.5663 86.299 56.4146 86.299C57.5107 86.299 58.1984 85.8466 58.7572 84.9849L60.0682 85.9112Z" />
        <path d="M71.4371 82.464C71.4371 85.7816 69.5888 87.9573 66.8809 87.9573C64.1945 87.9573 62.3892 85.8031 62.3892 82.4856C62.3892 79.168 64.2374 76.9923 66.9239 76.9923C69.6103 76.9923 71.4371 79.1465 71.4371 82.464ZM69.8038 82.4856C69.8038 80.0728 68.5572 78.5649 66.8594 78.5649C65.1831 78.5649 64.001 80.0297 64.001 82.4425C64.001 84.8552 65.2261 86.3632 66.9239 86.3632C68.6002 86.3847 69.8038 84.8983 69.8038 82.4856Z" />
        <path d="M84.053 87.7855H82.4841V82.1629C82.4841 81.5166 82.5056 80.5903 82.5056 80.2887C82.3767 80.5903 82.0758 81.3443 81.8179 81.969L79.2389 87.9578L76.7244 81.969C76.4665 81.3658 76.1656 80.6118 76.0366 80.2887C76.0581 80.5903 76.0581 81.5166 76.0581 82.1629V87.7855H74.5537V77.1435H76.1871L78.5726 82.8092C78.8305 83.4124 79.1744 84.1879 79.3033 84.6403C79.4538 84.2094 79.7547 83.4339 80.0126 82.8092L82.3552 77.1435H84.053V87.7855V87.7855Z" />
        <path d="M95.572 80.4826C95.572 82.6799 94.1106 83.8217 92.0474 83.8217H89.275V87.7855H87.7061V77.1435H92.1548C94.1106 77.1651 95.572 78.2853 95.572 80.4826ZM93.9601 80.4826C93.9601 79.3193 93.2509 78.6515 92.1333 78.6515H89.275V82.3137H92.1333C93.3369 82.3137 93.9601 81.6243 93.9601 80.4826Z" />
        <path d="M105.587 87.7855H98.5166V77.1435H105.351V78.6946H100.107V81.3227H103.159V82.8738H100.107V86.2344H105.587V87.7855V87.7855Z" />
        <path d="M115.86 78.7161H112.765V87.7855H111.175V78.7161H108.08V77.1435H115.86V78.7161V78.7161Z" />
        <path d="M120.502 87.7855H118.912V77.1435H120.502V87.7855Z" />
        <path d="M131.356 78.7161H128.261V87.7855H126.671V78.7161H123.576V77.1435H131.356V78.7161Z" />
        <path d="M135.998 87.7855H134.407V77.1435H135.998V87.7855Z" />
        <path d="M148.076 77.165L144.423 87.7854H142.746L139.071 77.1434H140.791L143.004 83.9078C143.198 84.5325 143.499 85.4804 143.606 85.8466C143.692 85.4588 143.993 84.511 144.208 83.8862L146.4 77.1219H148.076V77.165Z" />
        <path d="M152.74 87.7855H151.15V77.1435H152.74V87.7855Z" />
        <path d="M158.199 86.7514C158.199 87.3976 157.662 87.9577 156.996 87.9577C156.351 87.9577 155.792 87.4192 155.792 86.7514C155.792 86.0836 156.329 85.545 156.996 85.545C157.662 85.545 158.199 86.1051 158.199 86.7514Z" />
      </svg>
    </Box>
  );
};

export default memo(Logo);
