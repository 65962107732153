import React from 'react';

const UnreadNotificationDot = () => {
  return (
    <div
      style={{
        background: '#FF4842',
        borderRadius: '50%',
        minWidth: '9px',
        minHeight: '9px',
        marginTop: '3px',
        marginLeft: 'auto',
      }}
    />
  );
};

export default UnreadNotificationDot;
