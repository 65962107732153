import { IntlShape } from 'react-intl';

const htmlParserOptions = {
  strong: (str: string) => <strong>{str}</strong>,
};

export const cartTranslations = (intl: IntlShape) => ({
  cart: {
    title: intl.formatMessage({
      defaultMessage: 'Carrello',
      description: 'cart title',
    }),
    cartCard: {
      content: intl.formatMessage({
        defaultMessage: 'Contenuto',
        description: 'cart cartCard content',
      }),
      price: intl.formatMessage({
        defaultMessage: 'Prezzo',
        description: 'cart cartCard price',
      }),
      discount: intl.formatMessage({
        defaultMessage: 'Sconto',
        description: 'cart cartCard discount',
      }),
      participants: intl.formatMessage({
        defaultMessage: 'Partecipanti',
        description: 'cart cartCard participants',
      }),
      emptyCartTitle: intl.formatMessage({
        defaultMessage: 'Il tuo carrello è vuoto',
        description: 'cart cartCard emptyCartTitle',
      }),
      emptyCartSubtitle: intl.formatMessage({
        defaultMessage: 'Scopri contenuti che potrebbero interessarti',
        description: 'cart cartCard emptyCartSubitle',
      }),
      emptyCartButton: intl.formatMessage({
        defaultMessage: 'Portami',
        description: 'cart cartCard emptyCartButton',
      }),
      discountBanner: intl.formatMessage(
        {
          defaultMessage:
            '<strong>50% di sconto</strong> a partire dalla seconda persona che iscrivi',
          description: 'cart cartCard discountBanner text',
        },
        { ...htmlParserOptions }
      ),
      totalPrice: intl.formatMessage({
        defaultMessage: 'Totale provvisorio :',
        description: 'cart cartCard totalPrice',
      }),
      IVA: intl.formatMessage({
        defaultMessage: '€ + IVA',
        description: 'cart cartCard IVA',
      }),
      buy: intl.formatMessage({
        defaultMessage: 'Acquista',
        description: 'cart cartCard buy',
      }),
    },
    cartItemCard: {
      delete: intl.formatMessage({
        defaultMessage: 'Elimina',
        description: 'cart cartItemCard delete',
      }),
    },
  },
});
