import { Box, BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IBarChartIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function BarChartIcon({
  color = '#637381',
  style,
  ...other
}: IBarChartIconProps) {
  return (
    <Box {...other}>
      <svg
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill={color}
        style={style}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0.666016 5.76602H4.66602V18.8327H0.666016V5.76602ZM8.13268 0.166016H11.866V18.8327H8.13268V0.166016ZM15.5993 10.8327H19.3327V18.8327H15.5993V10.8327Z" />
      </svg>
    </Box>
  );
}

export default BarChartIcon;
