import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  useTheme,
} from '@mui/material';

import { IDialogProps } from './Dialog.types';

const Dialog = ({ open, title, text, actions, ...others }: IDialogProps) => {
  const theme = useTheme();
  return (
    <MuiDialog open={open} {...others}>
      <DialogTitle sx={{ mb: 3 }}>
        <Typography variant="h6">{title}</Typography>
      </DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <Typography variant={'body2'} sx={{ color: theme.palette.grey[600] }}>
          {text}
        </Typography>
      </DialogContent>
      <DialogActions>
        {actions?.map((el, index) => (
          <Button
            key={`button${index}`}
            variant={el.variant}
            color="primary"
            onClick={el.action}
          >
            <Typography sx={{ fontWeight: 700, fontSize: 14 }}>
              {el.title}
            </Typography>
          </Button>
        ))}
      </DialogActions>
    </MuiDialog>
  );
};

export { Dialog };
