import { Theme, styled } from '@mui/material';

export const ContainerSmall = styled('div')(({ theme }) => ({
  height: '110px',
  width: '392px',
  backgroundColor: theme.palette.grey[200],
  borderRadius: '8px',
  borderLeft: `9px solid ${theme.palette.secondary.main}`,
  padding: '15px 20px',
  display: 'flex',
  gap: '10px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    padding: 20,
    gap: '2px',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

export const BoxPreviewSmall = styled('div')(
  ({ theme, image }: { theme?: Theme; image: string }) => ({
    height: 85,
    minWidth: 85,
    width: 85,
    borderRadius: 8,
    backgroundColor: 'red',
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    ...(theme && {
      [theme.breakpoints.down('sm')]: {
        height: 108,
        minWidth: 40,
        width: '100%',
        backgroundSize: 'cover',
      },
    }),
  })
);
