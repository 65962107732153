import {
  DataGridProps,
  GridOverlay,
  GridSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { useState } from 'react';
import { StyledDataGrid } from './Table.style';
import { ITableProps } from './Table.type';

export const DEFAULT_TABLE_PAGE_SIZE = 25;

const Table = ({
  tableData,
  columns,
  checkboxSelection = false,
  handlePageChange,
  handlePageSizeChange,
  handleSortModelChange,
  handleSelectionModelChange,
  customHeader,
  customNotFoundMsg = '',
  customDefaultPageSize,
  rowHeight = 56,
  ...other
}: ITableProps & Partial<DataGridProps>) => {
  const [pageSize, setPageSize] = useState<number>(
    customDefaultPageSize || DEFAULT_TABLE_PAGE_SIZE
  );
  const customNoRowsOverlay = () => {
    return (
      <GridOverlay>
        <div>{customNotFoundMsg || 'No Results found'} </div>
      </GridOverlay>
    );
  };

  return (
    <>
      {customHeader && customHeader}
      <StyledDataGrid
        rows={tableData}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={other.rowsPerPageOptions || [25, 50, 100]}
        paginationMode={'server'}
        sortingMode={'server'}
        filterMode={'server'}
        autoHeight
        components={{
          NoRowsOverlay: customNoRowsOverlay,
          NoResultsOverlay: customNoRowsOverlay,
        }}
        onPageChange={(page: number) => {
          handlePageChange?.(page + 1);
        }}
        onPageSizeChange={(pageSize: number) => {
          handlePageSizeChange?.(pageSize);
          setPageSize(pageSize);
        }}
        onSelectionModelChange={(selectionModel: GridSelectionModel) => {
          handleSelectionModelChange?.(selectionModel);
        }}
        onSortModelChange={(model: GridSortModel) => {
          handleSortModelChange?.(model);
        }}
        checkboxSelection={checkboxSelection}
        rowHeight={rowHeight}
        {...other}
      />
    </>
  );
};

export { Table };
