import { useCallback } from 'react';
import { create } from 'zustand';
import { DashboardCardEnum, IDashboardStore } from 'types/stores';

const triggerCachedData = () => {
  useStore.setState(prev => ({
    ...prev,
    hasCachedData: !prev.hasCachedData,
  }));
};

const useStore = create<IDashboardStore>(
  (): IDashboardStore => ({
    [DashboardCardEnum.STUDY_CENTER]: undefined,
    [DashboardCardEnum.ACADEMY]: undefined,
    [DashboardCardEnum.INTELLECTUAL_PROPERTY]: undefined,
    [DashboardCardEnum.FINANCE]: undefined,
    [DashboardCardEnum.MATERIAL]: undefined,
    [DashboardCardEnum.BOSCO]: undefined,
    [DashboardCardEnum.SPORTELLO]: undefined,
    [DashboardCardEnum.TECLA]: undefined,
    hasCachedData: false,
    triggerCachedData,
    materialsSearchParams: { cat: '', sub: '', type: '' },
    certificationSearchParams: {
      sector: '',
      nation: [],
    },
    librarySearchText: '',
    hasDashboardFilters: false,
  })
);

const useDashboardSelector = () => useStore(useCallback(state => state, []));

export { useDashboardSelector, useStore as useDashboardStore };
