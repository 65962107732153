import { styled, Theme, Card } from '@mui/material';
import { ILabelBackgroundColor } from './CardImage.type';

export const Container = styled(Card)(
  ({
    theme,
    isCardFullWidth = false,
  }: {
    theme?: Theme;
    isCardFullWidth: boolean;
  }) => ({
    width: isCardFullWidth ? '100%' : 'calc(100% /4)',
    maxWidth: isCardFullWidth ? '100%' : 'calc(100% / 4 - 16px)',
    borderRadius: 12,
    overflow: 'hidden',
    backgroundColor: theme?.palette.grey[0],
    cursor: 'pointer',
    ...(theme &&
      !isCardFullWidth && {
        [theme.breakpoints.down('lg')]: {
          width: 'calc(100% /3)',
          maxWidth: 'calc(100% / 3 - 16px)',
        },
        [theme.breakpoints.down('md')]: {
          width: 'calc(100% /2)',
          maxWidth: 'calc(100% / 2 - 16px)',
        },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          maxWidth: '100%',
        },
      }),
  })
);

export const LabelIconColors: ILabelBackgroundColor = {
  webinar: '#FFDFE3',
  document: '#DEECF8',
  convention: '#DEECF8',
  video_tutorial: '#D0F1DA',
  course: '#E9DFFF',
  articolo: '#FDDF86',
};

export const LabelTextColor: ILabelBackgroundColor = {
  webinar: '#D01127',
  document: '#368FD3',
  convention: '#368FD3',
  video_tutorial: '#2F9D50',
  course: '#773DF5',
  articolo: '#8D6A02',
};

export const BoxPreview = styled('div')(({ image }: { image: string }) => ({
  height: 300,
  width: '100%',
  backgroundImage: `url(${image})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
}));

export const MaterialCategoryLabel = styled('div')({
  padding: '2px 15px',
  backgroundColor: '#CAD1C7',
  borderRadius: 4,
});

export const Label = styled('div')(
  ({
    type,
  }: {
    type:
      | 'webinar'
      | 'document'
      | 'video_tutorial'
      | 'course'
      | 'articolo'
      | undefined;
    theme?: Theme;
  }) => {
    const backgrounds = LabelIconColors;

    const textColor = LabelTextColor;

    return {
      padding: '4px 16px',
      backgroundColor: type && backgrounds[type],
      borderRadius: type ? '4px' : '4px',
      position: 'absolute',
      top: '20px',
      right: '20px',
      fontSize: '14px',
      fontWeight: '400',
      color: type && textColor[type],
      textTransform: 'capitalize',
      display: 'flex',
      alignItems: 'center',
    };
  }
);

export const MaterialCircle = styled('div')(({ color }: { color: string }) => ({
  height: 16,
  width: 16,
  borderRadius: '100%',
  backgroundColor: color,
}));

export const ArticleContainer = styled(Card)({
  minWidth: 407,
  maxWidth: 407,
  position: 'relative',
});

export const ArticleImage = styled('div')(({ image }: { image: string }) => ({
  height: 191,
  width: '100%',
  backgroundImage: `url(${image})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  position: 'relative',
}));
