import { Box, BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IFeedbackIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function FeedbackIcon({
  color = '#212B36',
  style,
  ...other
}: IFeedbackIconProps) {
  return (
    <Box {...other}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill={color}
        style={style}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M15.666 0.667969H2.33268C1.41602 0.667969 0.666016 1.41797 0.666016 2.33464V17.3346L3.99935 14.0013H15.666C16.5827 14.0013 17.3327 13.2513 17.3327 12.3346V2.33464C17.3327 1.41797 16.5827 0.667969 15.666 0.667969ZM15.666 12.3346H3.99935L2.33268 14.0013V2.33464H15.666V12.3346ZM4.83268 6.5013H6.49935V8.16797H4.83268V6.5013ZM8.16602 6.5013H9.83268V8.16797H8.16602V6.5013ZM11.4993 6.5013H13.166V8.16797H11.4993V6.5013Z" />
      </svg>
    </Box>
  );
}

export default FeedbackIcon;
