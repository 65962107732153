import { format } from 'date-fns';
import { it } from 'date-fns/locale';
import imagePlaceHolder from 'assets/images/login/frontImage.png';
import {
  IContentBasicProps,
  IContentDetailProps,
} from 'templates/ContentDetail/ContentDetail.types';
import { ContentTypeEnum, DrupalContentLabel, IContent } from 'types/content';
import { FieldFile } from 'types/mediaCourse';

export const standardContentsIncludes = [
  'field_file.field_media_file',
  'field_contact',
  'field_image.field_media_image',
];

export const contentIncludes = {
  [ContentTypeEnum.DEEPENINGS]: [...standardContentsIncludes],
  [ContentTypeEnum.WEBINAR]: [
    ...standardContentsIncludes,
    'field_files.field_media_file',
  ],
  [ContentTypeEnum.TUTORIAL]: [...standardContentsIncludes],
  [ContentTypeEnum.DOCUMENTS]: [...standardContentsIncludes],
  [ContentTypeEnum.CONVENTIONS]: [...standardContentsIncludes],
  [ContentTypeEnum.STUDY_CENTER_PRODUCT]: [
    ...standardContentsIncludes,
    'field_content_category',
    'field_files.field_media_file',
  ],
  [ContentTypeEnum.INTELLECTUAL_PROPERTY_CONVENTION]: [
    ...standardContentsIncludes,
  ],
  [ContentTypeEnum.FINANCE_WEBINAR]: [
    ...standardContentsIncludes,
    'field_files.field_media_file',
  ],
  [ContentTypeEnum.INTELLECTUAL_PROPERTY_DEEPENING]: [
    ...standardContentsIncludes,
    'field_content_category',
    'field_files.field_media_file',
  ],
  [ContentTypeEnum.ACADEMY_DEEPENING]: [
    ...standardContentsIncludes,
    'field_content_category',
    'field_files.field_media_file',
  ],
  [ContentTypeEnum.INTELLECTUAL_PROPERTY_CONTRACTUAL_FORMAT]: [
    ...standardContentsIncludes,
    'field_content_category',
    'field_files.field_media_file',
  ],
};

export const DrupalContentEntities = {
  [ContentTypeEnum.WEBINAR]: 'customs_webinar',
  [ContentTypeEnum.TUTORIAL]: 'customs_tutorial',
  [ContentTypeEnum.DOCUMENTS]: 'customs_doc',
  [ContentTypeEnum.DEEPENINGS]: 'certification_deepening',
  [ContentTypeEnum.CONVENTIONS]: 'certification_convention',
  [ContentTypeEnum.STUDY_CENTER_PRODUCT]: 'study_center_product',
  [ContentTypeEnum.INTELLECTUAL_PROPERTY_CONVENTION]:
    'intellectual_property_convention',
  [ContentTypeEnum.FINANCE_WEBINAR]: 'finance_webinar',
  [ContentTypeEnum.INTELLECTUAL_PROPERTY_DEEPENING]:
    'intellectual_property_deepening',
  [ContentTypeEnum.ACADEMY_DEEPENING]: 'academy_deepening',
  [ContentTypeEnum.INTELLECTUAL_PROPERTY_CONTRACTUAL_FORMAT]:
    'intellectual_property_contractual_format',
};

export const parseContent = ({
  contentType,
  data,
  isCard = false,
}: {
  contentType: `${ContentTypeEnum}`;
  data: IContent;
  isCard?: boolean;
}): IContentDetailProps => {
  const basicInfo: IContentBasicProps = {
    id: data.id,
    date: data.changed
      ? format(new Date(data.changed), 'dd LLLL yyyy', {
          locale: it,
        })
      : '',
    label: data.field_type as DrupalContentLabel,
    title: data.title,
    image: data.field_image
      ? isCard
        ? data.field_image.thumbnail.image_style_uri.wide
        : data.field_image.field_media_image.image_style_uri.wide
      : imagePlaceHolder,
  };

  const attached =
    data.field_file?.id &&
    data.field_file?.field_media_file?.id &&
    `${process.env.REACT_APP_HOST}${data.field_file?.field_media_file.uri.url}`;
  const body = data.body?.value && data.body.value;
  const address = data.field_contact?.id && {
    name: data.field_contact.name || '',
    email: data.field_contact.field_email || '',
    phoneNumber: data.field_contact.field_phone || '',
  };
  const videoFrame = data.field_video?.uri || '';
  const category = data.field_content_category?.id
    ? {
        id: data.field_content_category?.id,
        name: data.field_content_category?.name,
      }
    : undefined;

  return {
    ...basicInfo,
    contentType,
    ...(attached && {
      downloadLink: attached,
    }),
    ...(body && {
      description: body,
    }),
    ...(address && {
      contacts: address,
    }),
    ...(videoFrame && {
      video: videoFrame,
    }),
    ...(category && {
      category: category,
    }),
    ...(data.field_files?.length && {
      multiFiles: data.field_files,
    }),
    ...(data.field_files?.length && {
      files: data.field_files as FieldFile[],
    }),
  };
};
