import { create } from 'zustand';

interface IUseGlossaryProps {
  glossaryState: boolean;
  isExpanded: boolean;
  typing: boolean;
  openGlossary: VoidFunction;
  closeGlossary: VoidFunction;
  setTyping: (bool: boolean) => void;
  expandGlossary: VoidFunction;
  reduceGlossary: VoidFunction;
}

const useGlossary = create<IUseGlossaryProps>(set => ({
  glossaryState: false,
  isExpanded: false,
  typing: false,
  openGlossary: () => set(state => ({ ...state, glossaryState: true })),
  closeGlossary: () => set(state => ({ ...state, glossaryState: false })),
  setTyping: (props: boolean) => set(state => ({ ...state, typing: props })),
  expandGlossary: () => set(state => ({ ...state, isExpanded: true })),
  reduceGlossary: () => set(state => ({ ...state, isExpanded: false })),
}));

export { useGlossary };
