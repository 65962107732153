import {
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Link,
  useTheme,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Separator } from './Breadcrumbs.style';
import { IBreadcrumbProps, LinkItemProps } from './Breadcrumbs.types';

const Breadcrumbs = ({
  links,
  activeLast = false,
  ...other
}: IBreadcrumbProps) => {
  //hooks
  const theme = useTheme();

  const LinkItem = ({ link }: LinkItemProps) => {
    const { match, key } = link;
    return (
      <Link
        key={key}
        variant="body2"
        component={RouterLink}
        to={match.pathname || '#'}
        sx={{ color: theme.palette.primary.dark }}
      >
        {link.breadcrumb}
      </Link>
    );
  };

  const listDefault = links.map(link => (
    <LinkItem key={link.key} link={link} />
  ));

  const listActiveLast = links.map((link, idx) => (
    <div key={link.key}>
      {idx < links.length - 1 ? (
        <>{link.breadcrumb ? <LinkItem link={link} key={idx} /> : null}</>
      ) : (
        <Typography variant="body2" key={idx}>
          {link.breadcrumb}
        </Typography>
      )}
    </div>
  ));

  return (
    <MuiBreadcrumbs
      separator={<Separator />}
      aria-label="breadcrumb"
      {...other}
    >
      {activeLast ? listDefault : listActiveLast}
    </MuiBreadcrumbs>
  );
};

export { Breadcrumbs };
