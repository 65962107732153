import {
  Typography,
  useTheme,
  FormHelperText,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Stack } from '@mui/material';
import { TextField } from '@mui/material';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { RadiosOtherValue } from '../DynamicFieldForm/DynamicFieldForm.types';
import { IRHFRadiosProps } from './RHFRadios.types';

const RHFRadios = ({
  name,
  label,
  description,
  formGroupStyle,
  rules,
  options,
  defaultValue = '',
  direction = 'row',
  color = 'primary',
  hasOtherField = false,
  otherFieldName = '',
  disabled = false,
}: IRHFRadiosProps) => {
  const { control } = useFormContext();
  const theme = useTheme();
  const [isTextfieldDisabled, setIsTextfieldDisabled] = useState(true);
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <FormControl error={!!error} disabled={disabled}>
          {label && (
            <Typography variant="subtitle2">
              {label}
              {rules?.required ? ' *' : ''}
            </Typography>
          )}
          {description && (
            <Typography variant="body2" sx={{ color: theme.palette.grey[500] }}>
              {description}
            </Typography>
          )}

          <RadioGroup
            defaultValue={defaultValue}
            style={formGroupStyle}
            value={field.value}
            onChange={e => {
              field.onChange(e.target.value);
              setIsTextfieldDisabled(
                e.target.value !==
                  RadiosOtherValue.CUSTOM_OTHER_VALUE.toString()
              );
            }}
            row={direction === 'row' ? true : false}
          >
            {options?.map((o, idx) => (
              <FormControlLabel
                value={o.value}
                control={<Radio color={color} />}
                label={
                  <Typography variant={'body2'} sx={{ marginTop: '3px' }}>
                    {o.label}
                  </Typography>
                }
                key={idx}
              />
            ))}
            {hasOtherField && otherFieldName ? (
              <Stack direction={'row'} alignItems={'center'}>
                <FormControlLabel
                  value={RadiosOtherValue.CUSTOM_OTHER_VALUE.toString()}
                  control={<Radio color={color} />}
                  label={
                    <Typography variant={'body2'} sx={{ marginTop: '3px' }}>
                      Altro
                    </Typography>
                  }
                  key={new Date().toISOString()}
                />
                <Controller
                  name={otherFieldName}
                  control={control}
                  render={({ field }) => {
                    return (
                      <TextField
                        type={'text'}
                        onWheel={e =>
                          e.target instanceof HTMLElement && e.target.blur()
                        }
                        onChange={e => {
                          field.onChange(e.target.value);
                        }}
                        disabled={isTextfieldDisabled}
                        label={'Specificare'}
                      />
                    );
                  }}
                />
              </Stack>
            ) : null}
          </RadioGroup>
          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};
export { RHFRadios };
