import {
  Button,
  Grid,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DynamicFieldForm } from 'components';
import { useResponsive, useTranslations, formFieldDataGenerator } from 'hooks';
import { FormProvider } from 'providers';
import { useAuthSelector } from 'stores';
import { IUser } from 'types/user';
import { IDynamicFieldForm, IWebFormRes } from 'types/webform';

const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

const RHFWebform = ({
  webform,
  gridContainerSx = {},
  buttonTxt,
  onSubmit,
}: {
  webform: IWebFormRes;
  gridContainerSx?: SxProps<Theme>;
  buttonTxt?: string;
  onSubmit: (data: { [key: string]: string }) => Promise<void>;
  color?: 'info' | 'success' | 'error' | 'warning' | 'primary';
}) => {
  //hooks
  const theme = useTheme();
  const i18n = useTranslations();
  const isMobile = useResponsive('down', 'md');
  const isSmDown = useResponsive('down', 'sm');
  const methods = useForm();
  const { handleSubmit, setValue } = methods;
  const [formData, setFormData] = useState<IDynamicFieldForm[]>([]);
  const { user } = useAuthSelector();

  useEffect(() => {
    if (webform) {
      const data = formFieldDataGenerator(webform);
      const multiSelect = data.find(fd => fd.type === 'multiSelect');
      if (multiSelect) {
        setValue(multiSelect.name, []);
      }
      setFormData(data);
    }
  }, [webform]);

  useEffect(() => {
    if (formData.length) {
      formData.map(fd => {
        if (
          fd.entity &&
          fd.entity === 'user' &&
          fd.entityKey &&
          user &&
          fd.type !== 'multiSelect'
        ) {
          setValue(fd.name, user[fd.entityKey as keyof IUser]);
        }
      });
    }
  }, [formData]);

  const renderDynamicFields = (data: IDynamicFieldForm[]) =>
    data.map((f, idx) => (
      <DynamicFieldForm fieldData={f} key={idx} color="success" />
    ));

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="20px" sx={{ marginTop: isSmDown ? '20px' : '50px' }}>
        {formData.length ? (
          <Grid
            container
            columnSpacing={'20px'}
            rowGap={'20px'}
            sx={{ ...gridContainerSx }}
          >
            {renderDynamicFields(formData)}
          </Grid>
        ) : null}
        <Typography
          variant="caption"
          sx={{
            color: theme.palette.grey[500],
            textTransform: 'lowercase',
          }}
        >
          {i18n.common.formMessage.required}
        </Typography>
        <Stack alignItems="flex-end">
          <Button
            variant="contained"
            color="primary"
            size="small"
            fullWidth={isMobile}
            type="submit"
            sx={{ width: isSmDown ? '100%' : '173px', textTransform: 'unset' }}
          >
            {capitalizeFirstLetter(
              buttonTxt || i18n.common.buttons.sendRequest
            )}
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );
};

export { RHFWebform };
