import { IntlShape } from 'react-intl';

export const surveyTranslations = (intl: IntlShape) => ({
  newSurvey: {
    subtitle: intl.formatMessage({
      defaultMessage:
        "Il presente questionario ha l'obiettivo di mappare le performance delle imprese associate rispetto ai 10 punti del Decalogo. Potrai salvare cliccando avanti ad ogni step e riprenderla in un secondo momento. Ti ricordiamo che una volta inviata non potrai modificarla. Ti suggeriamo di avere a portata di mano i dati relativi alla quantità di rifiuti prodotti, l'energia consumata e le certificazioni di sistema e ambientali di prodotto.",
      description: 'newSurvey subtitle',
    }),
  },
});
