import { IntlShape } from 'react-intl';
import {
  CustomizeQuestions,
  customizeModalsExplanations,
  customizeModalsNotes,
} from './utils/toolsData/CustomizeQuestions';
import {
  EnergyQuestions,
  energyModalsExplanations,
  energyModalsNotes,
} from './utils/toolsData/EnergyQuestions';
import {
  HrQuestions,
  hrModalsExplanations,
  hrModalsNotes,
} from './utils/toolsData/HrQuestions';
import {
  LogisticsQuestions,
  logisticsModalsExplanations,
  logisticsModalsNotes,
} from './utils/toolsData/LogisticsQuestions';
import {
  MateriaQuestions,
  materiaModalsExplanations,
  materiaModalsNotes,
} from './utils/toolsData/MateriaQuestions';
import {
  ProductsQuestions,
  productsModalsExplanations,
  productsModalsNotes,
} from './utils/toolsData/ProductsQuestions';
import {
  WasteQuestions,
  wasteModalsExplanations,
  wasteModalsNotes,
} from './utils/toolsData/WasteQuestions';

export const modalsData: {
  [key: string]: string;
} = {
  ...CustomizeQuestions,
  ...MateriaQuestions,
  ...EnergyQuestions,
  ...WasteQuestions,
  ...LogisticsQuestions,
  ...ProductsQuestions,
  ...HrQuestions,
};

export const modalsExplanations = (
  intl: IntlShape,
  htmlParserOptions?: object
) => ({
  ...customizeModalsExplanations(intl),
  ...materiaModalsExplanations(intl, htmlParserOptions),
  ...energyModalsExplanations(intl, htmlParserOptions),
  ...wasteModalsExplanations(intl, htmlParserOptions),
  ...logisticsModalsExplanations(intl, htmlParserOptions),
  ...productsModalsExplanations(intl, htmlParserOptions),
  ...hrModalsExplanations(intl, htmlParserOptions),
});

export const modalsNotes = (intl: IntlShape, htmlParserOptions?: object) => ({
  ...customizeModalsNotes(intl, htmlParserOptions),
  ...materiaModalsNotes(intl, htmlParserOptions),
  ...energyModalsNotes(intl, htmlParserOptions),
  ...wasteModalsNotes(intl, htmlParserOptions),
  ...logisticsModalsNotes(intl, htmlParserOptions),
  ...productsModalsNotes(intl, htmlParserOptions),
  ...hrModalsNotes(intl, htmlParserOptions),
});
