import { IntlShape } from 'react-intl';

const htmlParserOptions = {
  br: () => <br />,
  b: (str: string) => <b>{str}</b>,
  email: (str: string) => (
    <a
      href={'centrostudi@federlegnoarredo.it'}
      target="_blank"
      rel="noreferrer"
      style={{ color: 'black' }}
    >
      {str}
    </a>
  ),
};

export const trainingAndConsultingTranslations = (intl: IntlShape) => ({
  trainingAndConsulting: {
    academy: {
      subtitle: intl.formatMessage({
        defaultMessage:
          'Un servizio che mette a disposizione molte opportunità formative. L’Ufficio Formazione di Federlegno Arredo Eventi Spa è accreditato ISO 9001:2015 settore A37, al CNAPPC, a Regione Lombardia e a Fondimpresa.',
        description: 'Academy Page subtitle',
      }),
      insights: intl.formatMessage({
        defaultMessage: 'Approfondimenti',
        description: 'Academy Page insights',
      }),
    },
    studyCenter: {
      subtitle: intl.formatMessage({
        defaultMessage:
          'Esplora dati di sintesi e prodotti elaborati dal Centro Studi di Federlegno Arredo Eventi Spa, e tutti gli aggiornamenti disponibili del settore.',
        description: 'StudyCenter Page subtitle',
      }),
      kpi: {
        title: intl.formatMessage({
          defaultMessage: 'Consuntivi',
          description: 'StudyCenter Page Kpi title',
        }),
        selectFieldLabel: {
          FLA: intl.formatMessage({
            defaultMessage: 'Feder Legno Arredo',
            description: 'StudyCenter Page Kpi label select field',
          }),
        },
        euroValue: intl.formatMessage({
          defaultMessage: '* valori in milioni di euro',
          description: 'StudyCenter Page Kpi euro value',
        }),
        kpiPercentageText: intl.formatMessage({
          defaultMessage: "rispetto all'anno precedente",
          description: 'StufyCenter Page Kpi percentage text',
        }),
      },
      infoData: {
        title: intl.formatMessage({
          defaultMessage: 'INFODATA',
          description: 'StudyCenter Page info data title',
        }),
        subtitle: intl.formatMessage(
          {
            defaultMessage:
              'Gli Infodata FederlegnoArredo sono una piattaforma digitale dove i dati del Centro Studi diventano interattivi. Per ottenere una licenza puoi contattare <email>centrostudi@federlegnoarredo.it</email> Se hai già una licenza clicca sul pulsante per accedere.',
            description: 'StudyCenter Page info data subtitle',
          },
          { ...htmlParserOptions }
        ),
        buttonLabel: intl.formatMessage({
          defaultMessage: 'Accedi',
          description: 'StudyCenter Page Infodata button label',
        }),
      },
      contactUs: {
        title: intl.formatMessage({
          defaultMessage: 'Contattaci',
          description: 'StudyCenter Page ContactUs title',
        }),
        subtitle: intl.formatMessage({
          defaultMessage:
            'Compila il form per richiedere i prodotti elaborati dal centro studi FLA',
          description: 'StudyCenter Page ContactUs subtitle',
        }),
        buttonLabel: intl.formatMessage({
          defaultMessage: 'Compila il form',
          description: 'StudyCenter Page ContactUs button label',
        }),
      },
    },
    finance: {
      subtitle: intl.formatMessage({
        defaultMessage:
          'Informativa puntuale sugli incentivi e le principali agevolazioni pubbliche destinate alle imprese della Filiera a livello europeo, nazionale e regionale.',
        description: 'Finance Page subtitle',
      }),
    },
    intellectualProperty: {
      subtitle: intl.formatMessage({
        defaultMessage:
          'I diritti di proprietà intellettuale rappresentano uno degli asset principali di un’impresa, essenza stessa del business. Scopri come valorizzare e tutelare le tue idee e prodotti.',
        description: 'Intellectual Property Page subtitle',
      }),
    },
    library: {
      subtitle: intl.formatMessage({
        defaultMessage:
          'In questa sezione troverai tutti i contenuti formativi a tua disposizione, da guardare in qualsiasi momento.',
        description: 'Library Page subtitle',
      }),
      searchInput: intl.formatMessage({
        defaultMessage: 'Search...',
        description: 'Library Page search input field label',
      }),
      tableHeader: {
        name: intl.formatMessage({
          defaultMessage: 'Nome',
          description: 'Library Page table name col label',
        }),
        area: intl.formatMessage({
          defaultMessage: 'Area',
          description: 'Library Page table area col label',
        }),
        service: intl.formatMessage({
          defaultMessage: 'Servizio',
          description: 'Library Page table service col label',
        }),
        typology: intl.formatMessage({
          defaultMessage: 'Tipologia',
          description: 'Library Page table typology col label',
        }),
        addInsertion: intl.formatMessage({
          defaultMessage: 'Data inserimento',
          description: 'Library Page table addINsertion col label',
        }),
        dimension: intl.formatMessage({
          defaultMessage: 'Dimensione',
          description: 'Library Page table dimension col label',
        }),
      },
    },
  },
});
