import { CardActionArea, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import parse from 'html-react-parser';
import {
  BoxPreview,
  ElipsedTypography,
  Label,
  StyledPaper,
} from './MediaCourseCard.styles';
import { IMediaCourseCardProps } from './MediaCourseCard.type';
import { Iconify } from 'components/Iconify/Iconify';
import { getOrdinal } from 'hooks';

const MediaCourseCard = ({
  body,
  image,
  title,
  id,
  onClick,
  weight,
}: IMediaCourseCardProps) => {
  const theme = useTheme();
  return (
    <StyledPaper>
      <CardActionArea onClick={() => onClick?.(id)}>
        <BoxPreview image={image}>
          <Label>
            <Iconify
              icon={'material-symbols:play-circle-outline'}
              style={{
                fontSize: '14px',
                color: '#773DF5',
                marginRight: 8,
                fill: 'none',
              }}
            />{' '}
            Corso
          </Label>
        </BoxPreview>
        <div style={{ padding: '19px 19px 38px' }}>
          <Typography
            variant="subtitle1"
            sx={{ color: theme.palette.grey[400] }}
          >
            {weight ? getOrdinal(weight) : title}
          </Typography>
          {body ? (
            <div style={{ overflow: 'hidden' }}>
              <ElipsedTypography
                variant="body1"
                sx={{
                  lineHeight: '22px',
                }}
                maxLine={3}
              >
                {parse(body)}
              </ElipsedTypography>
            </div>
          ) : null}
        </div>
      </CardActionArea>
    </StyledPaper>
  );
};

export { MediaCourseCard };
