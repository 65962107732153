import { Box } from '@mui/material';
import { forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import { IPageProps } from './Page.types';
import { useAuthSelector } from 'stores';
import { sendGTMData } from 'utils/dataLayerHelper';

const Page = forwardRef<HTMLDivElement, IPageProps>(
  ({ children, title = '', meta, withoutPadding, ...other }, ref) => {
    const { user } = useAuthSelector();
    const location = useLocation();

    const generateGtmUserData = () => {
      const pathArray = location.pathname.split('/').filter(s => !!s);
      const area = pathArray?.length === 1 ? pathArray[0] : pathArray[1];
      const areaId =
        pathArray?.length > 2 ? pathArray[pathArray.length - 1] : undefined;
      const gtmUserData = {
        username: user?.email,
        ragione_sociale: user?.field_company,
        service: area,
        ...(areaId && {
          service_id: areaId,
        }),
        title,
      };
      return gtmUserData;
    };

    sendGTMData({ data: generateGtmUserData() });
    return (
      <>
        <Helmet>
          <title>{`${title} | Plus`}</title>
          {meta}
          <script>{`<script
                    id="Cookiebot"
                    src="https://consent.cookiebot.com/uc.js"
                    data-cbid="21e02dca-15dd-48c6-bbf1-76588a148c95"
                    data-blockingmode="auto"
                    type="text/javascript"
                  ></script>`}</script>
        </Helmet>

        <Box
          ref={ref}
          sx={{
            padding: withoutPadding ? '0px' : '0px 25px',
            margin: '0 auto',
            minHeight: '100vh',
          }}
          {...other}
        >
          {children}
        </Box>
      </>
    );
  }
);

export { Page };
