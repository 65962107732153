import { BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IEditNoteIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function EditNoteIcon({ color = '#637381', style }: IEditNoteIconProps) {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M2.64062 7.08333H12.5435V8.75H2.64062V7.08333ZM2.64062 5.41667H12.5435V3.75H2.64062V5.41667ZM2.64062 12.0833H8.94244V10.4167H2.64062V12.0833ZM16.1535 9.475L16.7927 8.88333C17.1438 8.55833 17.711 8.55833 18.0621 8.88333L18.7012 9.475C19.0523 9.8 19.0523 10.325 18.7012 10.65L18.0621 11.2417L16.1535 9.475ZM15.5143 10.0667L10.743 14.4833V16.25H12.6515L17.4229 11.8333L15.5143 10.0667Z"
        fill={color}
      />
    </svg>
  );
}

export default EditNoteIcon;
