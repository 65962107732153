import { IntlShape } from 'react-intl';

export const WasteQuestions = {
  ['question_16']:
    '<click id={201}>Rifiuti urbani</click> e/o <click id={202}>speciali</click> conferiti in discarica rispetto al totale dei <click id={203}>rifiuti</click> prodotti.',
  ['question_17']:
    '<click id={201}>Rifiuti urbani</click> e/o <click id={202}>speciali</click> raccolti in modo <click id={204}>differenziato</click> rispetto al totale dei <click id={203}>rifiuti</click> prodotti.',
  ['question_18']:
    'Per i soggetti che producono rifiuti speciali. <click id={202}>Rifiuti speciali</click> avviati a <click id={205}>recupero di materia</click> rispetto al totale dei rifiuti speciali prodotti (non applicabile a smaltitori, recuperatori, conto terzisti nella gestione dei rifiuti avviati a smaltimento e/o recupero).',
  ['question_19']:
    "L'organizzazione ha effettuato la valutazione della propria carbon footprint secondo la UNI EN ISO 14064-1 nell'anno n e/o n-1 e/o n-2?",
  ['question_20']:
    "<click id={206}>Carbon footprint</click> delle <click id={13}>risorse materiche</click> in ingresso nell'anno n e/o n-1 e/o n-2. La valutazione deve essere effettuata in conformità alla UNI EN ISO 14067 o una <click id={207}>EPD</click> in conformità alla UNI EN ISO 14025. Soglie (% risorse materiche in ingresso per le quali è stata effettuata la carbon footprint): - Oltre il 20% delle risorse materiche in ingresso (100%) - Dal 10% al 20% delle risorse materiche in ingresso (75%) -Dal 1% al 10% delle risorse materiche in ingresso (25%) - nessuna carbon footprint.",
  ['question_21']:
    "<click id={206}>Carbon footprint</click> dei prodotti in uscita nell'anno n e/o n-1 e/o n-2. La valutazione deve essere effettuata in conformità alla UNI EN ISO 14067 o una <click id={207}>EPD</click> in conformità alla UNI EN ISO 14025. Soglie (% prodotti in uscita per i quali è stata effettuata la carbon footprint): - Oltre il 20% dei prodotti in uscita (100%) - Dal 10% al 20% dei prodotti in uscita (75%) -Dal 1% al 10% dei prodotti in uscita (25%) - nessuna carbon footprint (0%).",
};

export const wasteModalsExplanations = (
  intl: IntlShape,
  htmlParserOptions?: object
) => ({
  201: {
    title: intl.formatMessage({
      defaultMessage: 'Rifiuti urbani',
      description: 'homepage modalsExplanations 201 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Rifiuti che rientrano nelle seguenti categorie:<br></br><ul>
          <li>rifiuti domestici indifferenziati e da raccolta differenziata;</li>
          <li>rifiuti indifferenziati e da raccolta differenziata provenienti da altre fonti che sono
          simili per natura e composizione ai rifiuti domestici, come da normativa vigente;</li>
          <li>rifiuti provenienti dallo spazzamento delle strade e dallo svuotamento dei cestini
          portarifiuti;</li><li>rifiuti di qualunque natura o provenienza, giacenti sulle strade ed aree
          pubbliche o sulle strade ed aree private;</li></ul>`,
        description: 'homepage modalsExplanations 201 body',
      },
      { ...htmlParserOptions }
    ),
  },
  202: {
    title: intl.formatMessage({
      defaultMessage: 'Rifiuti speciali',
      description: 'homepage modalsExplanations 202 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Rifiuti prodotti nell'ambito delle attività agricole, agro-industriali e della
        silvicoltura, delle attività di costruzione e demolizione, nonché i rifiuti che derivano dalle
        attività di scavo, fermo restando quanto disposto per i sottoprodotti.<br></br>
        <b>Nota</b>: Se diversi dai rifiuti urbani, sono rifiuti speciali quelli prodotti nell'ambito delle lavorazioni industriali e artigianali, delle attività commerciali e di servizio, nonchè i rifiuti derivanti dall'attività di recupero e smaltimento di rifiuti, i fanghi prodotti dalla potabilizzazione e da altri trattamenti delle acque e dalla depurazione delle acque reflue, da abbattimento di fumi, dalle fosse settiche e dalle reti fognarie, da attività sanitarie. Sono rifiuti speciali anche i veicoli fuori uso.`,
        description: 'homepage modalsExplanations 202 body',
      },
      { ...htmlParserOptions }
    ),
  },
  203: {
    title: intl.formatMessage({
      defaultMessage: 'Rifiuto',
      description: 'homepage modalsExplanations 203 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Qualsiasi sostanza od oggetto di cui il detentore si disfi o abbia l’intenzione o l'obbligo di disfarsi. `,
        description: 'homepage modalsExplanations 203 body',
      },
      { ...htmlParserOptions }
    ),
  },
  204: {
    title: intl.formatMessage({
      defaultMessage: 'Raccolta differenziata',
      description: 'homepage modalsExplanations 204 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Raccolta in cui un flusso di rifiuti è tenuto separato in base al tipo
        ed alla natura dei rifiuti al fine di facilitarne il trattamento specifico.`,
        description: 'homepage modalsExplanations 204 body',
      },
      { ...htmlParserOptions }
    ),
  },
  205: {
    title: intl.formatMessage({
      defaultMessage: 'Recupero di materia',
      description: 'homepage modalsExplanations 205 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Qualsiasi operazione di recupero di residui diversa dal recupero
        energetico.`,
        description: 'homepage modalsExplanations 205 body',
      },
      { ...htmlParserOptions }
    ),
  },
  206: {
    title: intl.formatMessage({
      defaultMessage: 'Carbon footprint',
      description: 'homepage modalsExplanations 206 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `<b>Carbon footprint (impronta climatica) di un prodotto (CFP)</b>: Somma delle emissioni di <b>GHG</b> e delle rimozioni di GHG in un sistema di prodotto espressa come CO2 equivalenti
        e basata su una valutazione del ciclo di vita utilizzando la sola categoria di impatto del
        cambiamento climatico.<br></br><br></br>        
        <b>Gas a effetto serra (GHG)</b>: Costituente gassoso dell'atmosfera, sia naturale che antropico,
        che assorbe ed emette radiazioni a specifiche lunghezze d'onda all'interno dello spettro
        della radiazione infrarossa emessa dalla superficie terrestre, dall'atmosfera e dalle nuvole.<br></br>
        <b>Nota 1</b>: Il vapore acqueo e l'ozono sono GHG antropici così come naturali, ma non sono inclusi come GHG riconosciuti a causa delle difficoltà, nella maggior parte dei casi, nell'isolare la componente antropica del riscaldamento globale attribuibile alla loro presenza nell'atmosfera.<br></br>
        <b>Nota 2</b>: Le emissioni di GHG devono essere aggregate nelle seguenti categorie a livello organizzativo:<br></br><ul>
        <li>emissioni e rimozioni dirette di GHG;</li>
        <li>emissioni indirette di GHG da energia importata;</li>
        <li>emissioni indirette di GHG da trasporto;</li>
        <li>emissioni indirette di GHG dai prodotti utilizzati dall'organizzazione;</li>
        <li>emissioni indirette di GHG associate all'utilizzo dei prodotti dell'organizzazione;</li>
        <li>emissioni indirette di GHG da altre fonti.</li></ul><br></br>
        In ciascuna categoria devono essere separate le emissioni non biogene, le emissioni biogeniche antropogeniche e, se quantificate e comunicate, le emissioni biogeniche non antropogeniche.`,
        description: 'homepage modalsExplanations 206 body',
      },
      { ...htmlParserOptions }
    ),
  },
  207: {
    title: intl.formatMessage({
      defaultMessage: 'EPD',
      description: 'homepage modalsExplanations 207 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `<b>EPD (Environmental Product Declaration, ovvero Dichiarazione Ambientale di Prodotto)</b>: è una certificazione che descrive le prestazioni ambientali legate al Ciclo di Vita dei prodotti o servizi, in accordo con lo Standard Internazionale ISO 14025.`,
        description: 'homepage modalsExplanations 207 body',
      },
      { ...htmlParserOptions }
    ),
  },
});

export const wasteModalsNotes = (
  intl: IntlShape,
  htmlParserOptions?: object
) => ({
  question_16: intl.formatMessage(
    {
      defaultMessage: `Per ottenere la pecentuale occorre dividere la quantità di rifiuti urbani e/o speciali conferiti in discarica nell’anno n, per i rifiuti totali prodotti nell’anno n. e moltiplicare questo numero per 100.<br></br><br></br> Per esempio, se un’azienda produce 1000 kg di rifiuti al giorno e 200 kg vengono conferiti in discarica, la percentuale è (200/1000) x 100 = 20%.<br></br>
      Consideriamo conferiti in discarica i rifiuti assegnati al servizio pubblico in modo indifferenziato e i rifiuti speciali avviati all'impianto di smaltimento con un codice D sul formulario di identificazione (tutti quelli avviati a recupero hanno un codice R, la gran parte dei rifiuti speciali ha destinazione recupero)`,
      description: 'homepage notes question_16 title',
    },
    { ...htmlParserOptions }
  ),
  question_17: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra: la quantità (m3) di rifiuti urbani e/o speciali raccolti in modo differenziato rispetto alla quantità totale di rifiuti prodotti (m3) nell’anno n. I rifiuti speciali si considerano raccolti in modo indifferenziato solo se hanno un Codice Rifiuto che finisce con le cifre 99.`,
      description: 'homepage notes question_17 title',
    },
    { ...htmlParserOptions }
  ),
  question_18: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra: la quantità (m3) di rifiuti speciali avviati a recupero di materia (ad esempio riciclo) rispetto alla quantità totale di rifiuti speciali prodotti (m3).<br></br>
      <b>Nota</b>: Per rispondere a questa domanda bisogna calcolare la percentuale di rifiuti speciali avviati a recupero di materia rispetto alla quantità totale di rifiuti speciali prodotti. Per fare un esempio per un’azienda del settore legno-arredo, si potrebbe considerare il caso in cui l’azienda produca 100 m3 di rifiuti speciali all’anno e di questi 40 m3 siano avviati a recupero di materia (ad esempio riciclo) e 60 m3 siano avviati a smaltimento (ad esempio incenerimento). In questo caso la percentuale di rifiuti speciali avviati a recupero di materia è del 40%.`,
      description: 'homepage notes question_18 title',
    },
    { ...htmlParserOptions }
  ),
  question_19: intl.formatMessage(
    {
      defaultMessage: `L'organizzazione ha effettuato la valutazione della propria carbon footprint secondo la norma UNI EN ISO 14064-1 nell'anno in corso, nello scorso anno o nell'anno ancora precedente?`,
      description: 'homepage notes question_19 title',
    },
    { ...htmlParserOptions }
  ),
  question_20: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia % di prodotti per i quali è stata effettuata una carbon footprint delle risorse materiche in ingresso nell'anno n e/o n-1 e/o n-2. La valutazione deve essere effettuata in conformità alla UNI EN ISO 14067 o una EPD in conformità alla UNI EN ISO 14025. Soglie (% risorse materiche in ingresso per le quali è stata effettuata la carbon footprint):<ul><li>Oltre il 20% delle risorse materiche in ingresso (100%)</li>
      <li>Dal 10% al 20% delle risorse materiche in ingresso (75%)</li>
      <li>Dal 1% al 10% delle risorse materiche in ingresso (25%)</li>
      <li>nessuna carbon footprint (0%)</li></ul>`,
      description: 'homepage notes question_20 title',
    },
    { ...htmlParserOptions }
  ),
  question_21: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia % di prodotti per i quali è stata effettuata una carbon footprint nell'anno in corso o in uno dei due anni precedenti secondo la norma UNI EN ISO 14067 o una dichiarazione EPD secondo la UNI EN ISO 14025.<ul> 
      <li>Oltre il 20% dei prodotti in uscita (100%)</li> 
      <li>Dal 10% al 20% dei prodotti in uscita (75%)</li> 
      <li>Dal 1% al 10% dei prodotti in uscita (25%)</li> 
      <li>nessuna carbon footprint (0%)</li></ul>`,
      description: 'homepage notes question_21 title',
    },
    { ...htmlParserOptions }
  ),
});
