import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          minHeight: '36px',
          borderRadius: '8px',
          '&:hover': {
            boxShadow: 'none',
          },
          textTransform: 'unset',
        },
        sizeLarge: {
          height: 48,
        },
        sizeSmall: {
          minHeight: '36px',
          padding: '0px 20px',
          fontSize: '14px',
        },
        // contained
        containedInherit: {
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[0],
          },
        },
        containedPrimary: {
          backgroundColor: theme.palette.primary.dark,
          boxShadow: theme.customShadows.primary,
          color: theme.palette.grey[0],
          '&:hover': {
            backgroundColor: theme.palette.primary,
          },
        },
        outlinedPrimary: {
          border: `1px solid ${theme.palette.primary.dark}`,
          backgroundColor: 'transparent',
          boxShadow: 'none',
          color: 'black',
          '&:hover': {
            backgroundColor: theme.palette.primary,
          },
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
          color: theme.palette.grey[0],
        },
        containedInfo: {
          boxShadow: theme.customShadows.info,
          color: theme.palette.grey[0],
        },
        containedSuccess: {
          boxShadow: theme.customShadows.success,
          color: theme.palette.grey[0],
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning,
          color: theme.palette.grey[0],
        },
        containedError: {
          boxShadow: theme.customShadows.error,
          color: theme.palette.grey[0],
        },
        // outlined
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
