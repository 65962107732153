import { Button, Dialog, DialogActions, Typography } from '@mui/material';
import { ModalBody, ModalContainer, ModalTitle } from './Modal.styles';
import { ModalProps } from './Modal.types';
import { CloseIcon } from 'assets/icons';
import { useResponsive, useTranslations } from 'hooks';
import { ICustomi18n } from 'types/intl';

const Modal = ({
  open,
  children,
  title,
  subtitle = '',
  disableCloseOnConfirm = false,
  colorVariant,
  showCancel,
  confirmText = 'ok',
  titleColor = '#7B8A72',
  cancelText,
  handleConfirm,
  handleClose,
  onCloseAction,
  fullPageOnMobile = false,
}: ModalProps) => {
  const i18n: ICustomi18n = useTranslations();
  const isMdDown = useResponsive('down', 'md');
  const isSmDown = useResponsive('down', 'sm');
  const doConfirm = () => {
    handleConfirm?.();
    if (!disableCloseOnConfirm) doClose();
  };

  const doClose = () => {
    onCloseAction?.();
    handleClose();
  };

  const getConfirmText = () => i18n.general.buttons[confirmText] || confirmText;

  return (
    <Dialog
      open={!!open}
      onClose={() => doClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={false}
      PaperProps={{
        ...(fullPageOnMobile &&
          isSmDown && {
            style: {
              maxWidth: 'unset',
              maxHeight: '100%',
              width: '100%',
              margin: 0,
              borderRadius: 0,
            },
          }),
      }}
    >
      <ModalContainer>
        <CloseIcon
          sx={{
            position: 'absolute',
            top: isMdDown ? 15 : 30,
            right: isMdDown ? 25 : 50,
            cursor: 'pointer',
          }}
          onClick={() => doClose()}
          style={{
            ...(isMdDown && {
              width: '20px',
            }),
          }}
        />
        <ModalTitle id="alert-dialog-title" color={titleColor}>
          {title}
        </ModalTitle>
        <Typography
          variant={'h4'}
          sx={{
            pl: isSmDown ? 1.3 : 3,
            ...(isMdDown && {
              mb: 2,
            }),
          }}
          style={{ maxWidth: '720px' }}
        >
          {subtitle}
        </Typography>
        <ModalBody>{children}</ModalBody>

        {handleConfirm || showCancel || cancelText ? (
          <DialogActions>
            {handleConfirm && (
              <Button
                variant="contained"
                color={colorVariant || 'primary'}
                onClick={doConfirm}
                sx={{ width: '180px' }}
                size={'small'}
              >
                {getConfirmText()}
              </Button>
            )}

            {(showCancel || cancelText) && (
              <Button
                onClick={() => doClose()}
                variant="outlined"
                color="primary"
              >
                {cancelText || i18n.general.buttons.cancel}
              </Button>
            )}
          </DialogActions>
        ) : null}
      </ModalContainer>
    </Dialog>
  );
};

export { Modal };
