// @mui

import { CSSProperties } from 'react';

// ----------------------------------------------------------------------

function SideBarBookIcon({
  color = '#919EAB',
  style,
}: {
  color?: string;
  style?: CSSProperties;
}) {
  return (
    // <svg
    //   width="30"
    //   height="30"
    //   viewBox="0 0 30 30"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   style={style}
    // >
    //   <path
    //     d="M22.5 2.5H7.5C6.125 2.5 5 3.625 5 5V25C5 26.375 6.125 27.5 7.5 27.5H22.5C23.875 27.5 25 26.375 25 25V5C25 3.625 23.875 2.5 22.5 2.5ZM11.25 5H13.75V11.25L12.5 10.3125L11.25 11.25V5ZM22.5 25H7.5V5H8.75V16.25L12.5 13.4375L16.25 16.25V5H22.5V25Z"
    //     fill={color}
    //   />
    // </svg>
    <svg
      width="30"
      height="30"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, transform: 'translate(-1px, 0px)' }}
    >
      <g id="ICONS" clipPath="url(#clip0_718_2533)" width={'18px'}>
        <path
          id="Vector"
          d="M22 4H25C25.7956 4 26.5587 4.31607 27.1213 4.87868C27.6839 5.44129 28 6.20435 28 7V28C28 28.7956 27.6839 29.5587 27.1213 30.1213C26.5587 30.6839 25.7956 31 25 31H7C6.20435 31 5.44129 30.6839 4.87868 30.1213C4.31607 29.5587 4 28.7956 4 28V7C4 6.20435 4.31607 5.44129 4.87868 4.87868C5.44129 4.31607 6.20435 4 7 4H10M11.5 1H20.5C21.3284 1 22 1.67157 22 2.5V5.5C22 6.32843 21.3284 7 20.5 7H11.5C10.6716 7 10 6.32843 10 5.5V2.5C10 1.67157 10.6716 1 11.5 1Z"
          stroke={color}
          strokeWidth="2.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.5574 15.609C19.6667 15.3951 19.9263 15.26 20.2126 15.26C20.5009 15.26 20.7566 15.3961 20.8695 15.605L20.8711 15.6079L22.9854 19.6568C22.9883 19.661 22.991 19.665 22.9935 19.669C22.9993 19.6782 23.004 19.6872 23.0079 19.6956L23.9463 21.4891C24.0944 21.7768 23.9268 22.1122 23.5613 22.2326C23.2019 22.351 22.7829 22.2172 22.6322 21.9251L21.8774 20.4755H18.5591L17.8003 21.9289C17.6861 22.1459 17.4221 22.2768 17.1457 22.2768C17.0455 22.2768 16.9551 22.2584 16.8787 22.2348L16.8716 22.2326C16.506 22.1122 16.3385 21.7768 16.4866 21.4891L16.4872 21.4879L19.5574 15.609ZM20.2164 17.3004L19.1511 19.3355H21.2818L20.2164 17.3004ZM11.9116 13.2128C11.9116 12.8889 12.2416 12.625 12.6464 12.625C13.0512 12.625 13.3811 12.8889 13.3811 13.2128V13.9612H16.5621C16.9669 13.9612 17.2968 14.2251 17.2968 14.549C17.2968 14.8729 16.9669 15.1367 16.5621 15.1367H16.2378V15.1334H15.1649C14.9098 15.586 14.4082 16.3756 13.6435 17.154C14.2752 17.6224 15.0189 17.9969 15.876 18.1465L15.8772 18.1467C16.2723 18.2169 16.5238 18.5287 16.4354 18.8469L16.4346 18.8498C16.3555 19.12 16.0578 19.3095 15.7165 19.3095L15.7116 19.3095C15.6864 19.3096 15.6232 19.3098 15.5539 19.2956C14.3926 19.0914 13.4278 18.6041 12.6458 18.0267C11.8622 18.6027 10.8919 19.0935 9.72747 19.2969L9.71588 19.2989L9.69958 19.3008C9.67345 19.3038 9.62454 19.3095 9.57208 19.3095C9.23646 19.3095 8.93022 19.1234 8.86058 18.847C8.77473 18.5299 9.02587 18.22 9.41965 18.15L9.42085 18.1498C10.2778 18.0002 11.0213 17.6259 11.6529 17.1575C10.8853 16.3789 10.3862 15.5891 10.1318 15.1367H8.73476C8.32995 15.1367 8 14.8729 8 14.549C8 14.2251 8.32995 13.9612 8.73476 13.9612H11.9116V13.2128ZM11.7682 15.1367C11.9856 15.4647 12.2785 15.86 12.6464 16.2567C13.0142 15.86 13.3072 15.4647 13.5245 15.1367H11.7682Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_718_2533">
          <rect width="32" height="32" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SideBarBookIcon;
