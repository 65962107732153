import { styled, Theme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export const StyledDataGrid = styled(DataGrid)(
  ({ theme }: { theme?: Theme }) => ({
    fontSize: '14px',
    '&.MuiDataGrid-root .MuiDataGrid-main': {
      // paddingLeft: 10,
      // paddingRight: 10,
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'unset',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
      outline: 'none',
      fontWeight: 700,
      color: theme?.palette.grey[600],
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeadersInner': {
      backgroundColor: theme?.palette.grey[200],
      borderRadius: '8px',
      // paddingLeft: 10,
      // paddingRight: 10,
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:last-of-type': {
      paddingRight: 0,
      outline: 'unset',
    },
  })
);
