// @mui

import { CSSProperties } from 'react';

// ----------------------------------------------------------------------

function SideBarFinanceIcon({
  color = '#919EAB',
  style,
}: {
  color?: string;
  style?: CSSProperties;
}) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g id="ICONS">
        <path
          id="Icon"
          d="M18.2107 7.16669C18.212 5.69335 15.2077 4.5 11.506 4.5C7.80433 4.5 4.80273 5.69468 4.80005 7.16669M4.80005 7.16669C4.80005 8.64004 7.80165 9.83339 11.506 9.83339C15.2104 9.83339 18.212 8.64004 18.212 7.16669L18.212 17.5134M4.80005 7.16669V23.1668C4.80139 24.6401 7.80299 25.8335 11.506 25.8335C13.3019 25.8335 14.9234 25.5495 16.1265 25.0921M4.80139 12.5001C4.80139 13.9734 7.80299 15.1668 11.5074 15.1668C15.2118 15.1668 18.2134 13.9734 18.2134 12.5001M16.2069 19.7335C14.9972 20.2069 13.3381 20.5002 11.506 20.5002C7.80299 20.5002 4.80139 19.3069 4.80139 17.8335M25.8327 18.2576C28.1892 20.6003 28.1892 24.4003 25.8327 26.743C23.4762 29.0857 19.6538 29.0857 17.2973 26.743C14.9408 24.4003 14.9408 20.6003 17.2973 18.2576C19.6538 15.9149 23.4762 15.9149 25.8327 18.2576Z"
          stroke={color}
          strokeWidth="2.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default SideBarFinanceIcon;
