import { IntlShape } from 'react-intl';

export const faqTranslations = (intl: IntlShape) => ({
  faq: {
    title: intl.formatMessage({
      defaultMessage: 'Faq',
      description: 'Faq Page title',
    }),
    scope: {
      question: intl.formatMessage({
        defaultMessage: 'Qual è lo scopo di questa banca dati?',
        description: 'Faq Page scope question',
      }),
      answer: intl.formatMessage({
        defaultMessage:
          'Lo scopo è quello di fornire alle aziende associate delle informazioni di primo orientamento, ma specifiche per il settore arredo, necessarie ad affrontare determinati mercati esteri. Qualora fosse necessario approfondire le implicazioni di una determinata conformità o certificazione, invitiamo le aziende associate a contattare i riferimenti interni indicati o gli enti di certificazione coinvolti.',
        description: 'Faq Page scope answer',
      }),
    },
    infoType: {
      question: intl.formatMessage({
        defaultMessage:
          'Che tipo di informazioni ottengo dalla ricerca per Paese?',
        description: 'Faq Page info Type question',
      }),
    },
    coutryNotFoud: {
      question: intl.formatMessage({
        defaultMessage:
          'Cosa significa se non trovo il Paese che mi interessa nella banca dati?',
        description: 'Faq Page country not found question',
      }),
    },
    forMoreInfo: {
      question: intl.formatMessage({
        defaultMessage:
          'Chi posso contattare per avere maggiori informazioni ed eventuali chiarimenti su una particolare conformità/certificazione?',
        description: 'Faq Page for more info question',
      }),
    },
    howToContribute: {
      question: intl.formatMessage({
        defaultMessage:
          'Come posso contribuire a rendere la banca dati più completa e rispondente alle esigenze delle aziende del settore?',
        description: 'Faq Page how to contribute question',
      }),
    },
  },
});
