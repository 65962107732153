import { DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IModalTitleProps } from './Modal.types';

export const ModalBody = styled(DialogContent)(({ theme }) => ({
  minWidth: 600,
  fontSize: theme.typography.body2.fontSize,
  color: theme.palette.grey[600],
  paddingBottom: 0,
  paddingTop: 0,
  [theme.breakpoints.down('md')]: {
    minWidth: 'unset',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0 10px',
  },
}));

export const ModalTitle = styled(DialogTitle)<IModalTitleProps>(
  ({ theme, color = '#5DB0B4' }) => ({
    fontSize: '14px !important',
    textTransform: 'uppercase',
    color: color,
    [theme.breakpoints.down('sm')]: {
      padding: '0 10px',
      marginBottom: 8,
    },
  })
);

export const ModalContainer = styled('div')(({ theme }) => ({
  padding: '20px 20px 28px 20px',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    padding: '20px 30px 30px 30px',
  },
}));
