import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import { ItemContainer, CarouselContainer } from './Carousel.style';
import { ICarouselProps, IItemProps } from './Carousel.type';
import { CarouselArrows } from './CarouselArrows/CarouselArrows';
import { CarouselDots } from './CarouselDots/CarouselDots';
import { useResponsive } from 'hooks';

const Carousel = ({ carouselSettings, items }: ICarouselProps) => {
  //refs
  const carouselRef = useRef<Slider | null>(null);

  //states
  const [currentSlide, setCurrentSlide] = useState<number>();

  //Slick settings
  const settings: Settings = {
    dots: false,
    infinite: true,
    lazyLoad: 'ondemand',
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current: number) => setCurrentSlide(current),
    ...carouselSettings,
  };

  //functions
  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  const handleGoTo = (index: number) => {
    carouselRef.current?.slickGoTo(index);
  };

  return (
    <>
      <CarouselContainer>
        <Slider ref={carouselRef} {...settings}>
          {items?.map((el: IItemProps, index: number) => (
            <CarouselItem key={`item${index}`} image={el.image} />
          ))}
        </Slider>
        <CarouselArrows
          next={handleNext}
          previous={handlePrevious}
          currentSlide={currentSlide || 0}
          numberOfSlide={items.length}
        />
      </CarouselContainer>
      <CarouselDots
        items={items}
        currentSlide={currentSlide || 0}
        selectDot={(index: number) => handleGoTo(index)}
      />
    </>
  );
};

export { Carousel };

const CarouselItem = ({ image }: { image: string }) => {
  const isSmDown = useResponsive('down', 'sm');
  return (
    <ItemContainer
      image={image}
      style={{
        ...(isSmDown && {
          height: 279,
        }),
      }}
    ></ItemContainer>
  );
};
