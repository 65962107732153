import {
  IWebFormRes,
  IWebform,
  IDynamicFieldForm,
  DynamicFormType,
  DynimicVisibleField,
} from 'types/webform';

const BREAK_KEY = 'break';

export const formFieldDataGenerator = (
  webform: IWebFormRes,
  webformKey = 'flexbox'
): IDynamicFieldForm[] =>
  Object.entries(webform)
    .filter(
      ([key, value]) =>
        (key.includes(webformKey) || !key.includes('#')) && !!value
    )
    .map(([, value]) => {
      // la chiave è flex, quindi va considerata una 'Row', il value è la colonna
      const row: IWebform = value;
      const columnCount = Object.keys(row).filter(
        v => !v.includes('#')
      )?.length;
      return Object.entries(row)
        .filter(([k, v]) => !k.includes('#') && !v.hidden)
        .map(([, v]) => {
          const webFormField = v as IWebform;

          if (webFormField['#type'] === 'webform_custom_composite') {
            const { parent, condition } =
              parseDynamicVisibilityCondition(webFormField);
            if (
              (parent && condition && webFormField['#element']) ||
              (!parent && !condition && webFormField['#element'])
            ) {
              const fieldsGroupedByRow: {
                [key: string]: DynimicVisibleField[];
              } = {};
              Object.entries(webFormField['#element']).map(([k, v], idx) => {
                const value = { ...v, '#webform_key': k };
                if (Object.keys(fieldsGroupedByRow).length) {
                  const foundKey = Object.keys(fieldsGroupedByRow).at(
                    -1
                  ) as string;
                  k !== BREAK_KEY
                    ? fieldsGroupedByRow[foundKey].push(value)
                    : (fieldsGroupedByRow[`row_${foundKey.charAt(-1) + 1}`] =
                        []);
                } else {
                  fieldsGroupedByRow[`row_${idx}`] = [value];
                }
              });
              const children = Object.values(fieldsGroupedByRow)
                .map(row => {
                  const columnCount = row.length;
                  return row.map(field =>
                    getBasictInput(field as IWebform, columnCount)
                  );
                })
                .flat();
              const dynamicMultiFielForm: IDynamicFieldForm = {
                name: webFormField['#webform_key'],
                required: false,
                grid: 12,
                type: 'multi-field',
                label: '',
                description: '',
                children,
                visibilityParentKey: parent,
                visibilityParentValue: condition,
                isMultiple: webFormField['#webform_multiple'],
              };
              return dynamicMultiFielForm;
            }
          }
          return {
            ...getBasictInput(webFormField, columnCount),
            ...(webFormField['#options'] && {
              options: Object.entries(webFormField['#options']).map(
                ([k, v]) => ({ value: k, label: v as string })
              ),
            }),
            ...(webFormField['#type'] === 'webform_likert' &&
              webFormField['#answers'] &&
              webFormField['#questions'] && {
                answers: Object.entries(webFormField['#answers']).map(
                  ([value, label]) => ({ value, label: label as string })
                ),
                questions: Object.entries(webFormField['#questions']).map(
                  ([value, label]) => ({ value, label: label as string })
                ),
              }),
          };
        });
    })
    .flat();

export const dynamicVisibleFormFieldsGenerator = (
  webform: IWebFormRes
): IDynamicFieldForm[] => {
  const parsed = formFieldDataGenerator(webform);
  const multipleFields = parsed.filter(field => field.isMultiple);
  const dynamicVisibleFields = parsed.map(field => {
    if (multipleFields.find(f => f.visibilityParentKey === field.name)) {
      field.visibilityChildrenKey =
        multipleFields.find(f => f.visibilityParentKey === field.name)?.name ||
        '';
    }
    return field;
  });
  return dynamicVisibleFields;
};

// General Utils
const parseDynamicVisibilityCondition = (
  field: IWebform
): { parent?: string; condition?: string } => {
  if (field['#states']) {
    const [[parentField, { value: showCondition }]] = Object.entries(
      field['#states']?.visible
    );
    if (parentField && showCondition) {
      const regex = /name="(.+?)"/;
      const match = parentField.match(regex);
      if (match) {
        const nameValue = match[1];
        return { parent: nameValue, condition: showCondition };
      }
    }
  }
  return { parent: '', condition: '' };
};

const getBasictInput = (
  field: IWebform,
  columnCount: number
): {
  name: string;
  required: boolean;
  label: string;
  description: string;
  type: DynamicFormType;
  grid: number;
  isMultiple: boolean;
  readOnly: boolean;
  entity?: string;
  entityKey?: string;
  isMultipleSelect?: boolean;
} => {
  const isMultiSelect = field['#type'] === 'select' && field['#multiple'];
  return {
    name: field['#webform_key'],
    required: field['#required'] || false,
    label: field['#title'] || '',
    description: field['#description'] || '',
    type: isMultiSelect
      ? 'multiSelect'
      : field['#type'] === 'textfield'
      ? 'text'
      : (field['#type'] as DynamicFormType),
    grid: 12 / columnCount,
    isMultiple: isMultiSelect ? false : field['#webform_multiple'] || false,
    isMultipleSelect: isMultiSelect || false,
    readOnly: field['#readonly'] || !!field?.['#react_key'] || false,
    ...(field?.['#react_key'] && {
      entity: field['#react_key']?.split('__')[0],
      entityKey: field['#react_key']?.split('__')[1],
    }),
  };
};
