import TagManager from 'react-gtm-module';

export const sendGTMData = ({
  eventName,
  data,
}: {
  eventName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: { [key: string]: any };
}) =>
  TagManager.dataLayer({
    dataLayerName: 'dataLayer',
    dataLayer: { ...(eventName && { event: eventName }), ...data },
  });
