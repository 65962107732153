// routes
import { useEffect, useState } from 'react';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import ThemeSettings from './components/settings';
import Router from './routes';
// theme
import ThemeProvider from './theme';
import { DevModeBadge, ProgressBarStyle, ScrollToTop } from 'components';
import { DEV_NAMESPACE } from 'constants/paths';
import { buildSorting, useJsonApi } from 'hooks';
import { LoadingProvider, SnackbarProvider } from 'providers';
import {
  DrupalNotificationStatusLabel,
  convertDrupalToSystemNotification,
  useGlobalStore,
  useNotificationsSelector,
  useNotificationsStore,
} from 'stores';
import { IDrupalNotification } from 'types/notification';
// components

export default function App() {
  const { notifications } = useNotificationsSelector();
  const { showLoader, hideLoader } = useGlobalStore();
  const { listEntities } = useJsonApi({ showLoader, hideLoader });
  const [isDevMode, setIsDevMode] = useState(false);
  const [hrefPrev, setHrefPrev] = useState('');
  const { origin, href } = window.location;

  useEffect(() => {
    const fetchNotifications = async () => {
      const res = await listEntities({
        body: {
          entity: 'fla_notification_user',
          bundle: 'fla_notification_user',
          additionalParams: [
            ...buildSorting({
              sortingFieldName: 'created',
              sortingOrder: 'DESC',
            }),
            ...[['include', 'fla_notification']],
          ],
          deserializeEntities: true,
          specificResValue: [
            'id',
            'title',
            'created',
            DrupalNotificationStatusLabel,
            'fla_notification',
          ],
          disableStatusFilter: true,
        },
      });
      const drupalNotification = res
        ? (res as unknown as IDrupalNotification[])
        : [];
      useNotificationsStore.setState(prev => ({
        ...prev,
        notifications: drupalNotification
          .filter(
            n => !!n.fla_notification && !!n.fla_notification.taxonomy_term
          )
          .map(n => convertDrupalToSystemNotification(n)),
      }));
    };
    if (hrefPrev !== href) {
      setHrefPrev(href);
      fetchNotifications();
    }
  }, [href]);

  useEffect(() => {
    if (!isDevMode) {
      setIsDevMode(origin.includes(DEV_NAMESPACE));
    }
  }, [isDevMode]);

  if (!notifications) return <></>;

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          {isDevMode ? <DevModeBadge /> : null}
          <SnackbarProvider>
            <ProgressBarStyle />
            <ScrollToTop />
            <LoadingProvider />
            <Router />
          </SnackbarProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
