import { BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IWebIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function WebIcon({ color = '#7B8A72', style }: IWebIconProps) {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M16.3216 2.66602C8.80747 2.66602 2.72266 8.63935 2.72266 15.9993C2.72266 23.3594 8.80747 29.3327 16.3216 29.3327C23.8494 29.3327 29.9478 23.3594 29.9478 15.9993C29.9478 8.63935 23.8494 2.66602 16.3216 2.66602ZM25.7551 10.666H21.7394C21.3038 8.99935 20.6776 7.39935 19.8609 5.91935C22.3656 6.75935 24.4483 8.46602 25.7551 10.666ZM16.3352 5.38602C17.4651 6.98602 18.3499 8.75935 18.9352 10.666H13.7352C14.3206 8.75935 15.2054 6.98602 16.3352 5.38602ZM5.7991 18.666C5.5813 17.8127 5.44517 16.9193 5.44517 15.9993C5.44517 15.0793 5.5813 14.186 5.7991 13.3327H10.4001C10.2912 14.2127 10.2096 15.0927 10.2096 15.9993C10.2096 16.906 10.2912 17.786 10.4001 18.666H5.7991ZM6.91533 21.3327H10.931C11.3666 22.9993 11.9928 24.5993 12.8096 26.0793C10.3049 25.2393 8.22213 23.546 6.91533 21.3327ZM10.931 10.666H6.91533C8.22213 8.45268 10.3049 6.75935 12.8096 5.91935C11.9928 7.39935 11.3666 8.99935 10.931 10.666ZM16.3352 26.6127C15.2054 25.0127 14.3206 23.2393 13.7352 21.3327H18.9352C18.3499 23.2393 17.4651 25.0127 16.3352 26.6127ZM19.5206 18.666H13.1499C13.0274 17.786 12.9321 16.906 12.9321 15.9993C12.9321 15.0927 13.0274 14.1993 13.1499 13.3327H19.5206C19.6431 14.1993 19.7384 15.0927 19.7384 15.9993C19.7384 16.906 19.6431 17.786 19.5206 18.666ZM19.8609 26.0793C20.6776 24.5993 21.3038 22.9993 21.7394 21.3327H25.7551C24.4483 23.5327 22.3656 25.2393 19.8609 26.0793ZM22.2703 18.666C22.3792 17.786 22.4609 16.906 22.4609 15.9993C22.4609 15.0927 22.3792 14.2127 22.2703 13.3327H26.8713C27.0891 14.186 27.2253 15.0793 27.2253 15.9993C27.2253 16.9193 27.0891 17.8127 26.8713 18.666H22.2703Z"
        fill={color}
      />
    </svg>
  );
}

export default WebIcon;
