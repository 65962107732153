import { styled, Card } from '@mui/material';

export const StyledCard = styled(Card)({
  width: '100%',
});

export const Container = styled('div')({
  width: '100%',
  height: '90px',
  display: 'flex',
  justyfyContent: 'center',
  alignItems: 'center',
  gap: '24px',
  padding: '18px 37px',
});

export const Icon = styled('div')({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});
