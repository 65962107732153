import { View, Text, Link } from '@react-pdf/renderer';
import { styles } from './index.styles';

const PdfDisclaimerFooter = ({ moreText = '', moreTextTitle = '' }) => {
  return (
    <View style={styles.disclaimer} break>
      {moreTextTitle ? (
        <Text style={{ fontWeight: 'bold' }}>{moreTextTitle}</Text>
      ) : null}
      {moreText ? (
        <Text style={{ paddingBottom: moreTextTitle ? '1cm' : '' }}>
          {moreText}
        </Text>
      ) : null}
      {moreTextTitle ? (
        <>
          <Text style={{ paddingBottom: '1cm' }}>
            Tool realizzato in collaborazione con{' '}
            <Link
              src="https://www.manifaktura.net/"
              style={{ color: '#7B8A72' }}
            >
              Manifaktura
            </Link>{' '}
          </Text>
        </>
      ) : null}
      <Text style={{ fontWeight: 'bold' }}>DISCLAIMER</Text>
      <Text>
        Tutti i dati forniti agli Utenti attraverso la piattaforma fla-plus.it
        sono a mero titolo informativo.
        <br></br> FederlegnoArredo non si assume alcuna responsabilità per
        quanto riguarda tali informazioni.
        <br /> In particolare:
      </Text>
      <Text>
        • Le informazioni contenute sulla piattaforma non costituiscono parere
        di tipo professionale o giuridico avendo esclusivamente finalità
        divulgativa e validità per il tempo nel quale sono rese.
      </Text>
      <Text>
        • Sebbene FederlegnoArredo presti la massima attenzione
        all’aggiornamento, alla completezza e precisione delle informazioni
        diffuse attraverso la piattaforma, non si assume alcuna responsabilità
        quanto all’utilizzo dei dati o delle informazioni ivi contenuti. Gli
        Utenti, di conseguenza, sono tenuti ad adottare tutte le precauzioni
        necessarie prima di utilizzare tali dati assumendosi conseguentemente
        tutti i rischi connessi.
      </Text>
      <Text>
        • Le informazioni contenute sulla piattaforma possono rinviare a siti
        esterni, sui quali i servizi della FederlegnoArredo non hanno alcun
        controllo e per i quali FederlegnoArredo non assume alcuna
        responsabilità.
      </Text>
      <Text>
        • È vietata la divulgazione a Terzi dei dati contenuti sulla
        piattaforma, con qualsiasi mezzo analogico o digitale.
      </Text>
      <Text>
        • I dati inseriti vengono utilizzati da FederlegnoArredo unicamente in
        forma aggregata per rapporti di settore. I dati sulle frequenze di
        utilizzo dei diversi indicatori verranno condivisi con UNI per
        informazioni sull’utilizzo della norma.
      </Text>
    </View>
  );
};

export { PdfDisclaimerFooter };
