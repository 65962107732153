import { useMemo } from 'react';
import {
  CachePolicies,
  Provider as FetchProvider,
  IncomingOptions,
} from 'use-http';
import { useAuth } from 'hooks/api';
import { useAuthSelector, useAuthStore } from 'stores';
import { ICustomApiError } from 'types/error';
import {
  ICustomRetryOpts,
  IHttpProviderProps,
  IInterceptorReq,
} from 'types/httpClient';

const accessTokenForbiddenList = [
  '/jsonapi/node/material',
  '/jsonapi/node/furniture_certification',
  '/jsonapi/node/bathroom_certification',
  '/jsonapi/node/lighting_certification',
];

const HttpProvider = ({
  children,
  baseUrl,
  customOptions,
}: IHttpProviderProps) => {
  const { handleLogout, remember, tokens } = useAuthSelector();
  const { refreshTokens } = useAuth();
  const globalOptions: IncomingOptions = {
    cachePolicy: CachePolicies.NO_CACHE,
    onError: ({ error }) => {
      throw error;
    },
    retries: 1,
    retryOn: async ({ error }: ICustomRetryOpts) => {
      if (!error || !remember) return false;
      if ('statusCode' in error && error.statusCode === 401) {
        await refreshTokens();
        return true;
      }
      return false;
    },
    interceptors: {
      request: async ({
        options,
        route,
      }: IInterceptorReq): Promise<RequestInit> => {
        const authToken = useAuthStore.getState().tokens?.access_token;
        const notAccessToken = !!accessTokenForbiddenList.find(pathChunck =>
          route?.includes(pathChunck)
        );
        if (authToken)
          options.headers = [
            ['Authorization', `Bearer ${authToken}`],
            ['Content-Type', 'application/json'],
          ];
        if (notAccessToken)
          options.headers = [['Content-Type', 'application/json']];

        return options;
      },
      response: async ({ response }) => {
        if (!response.ok) {
          const statusCode = response.status;
          const errorData = response.data;
          const isUnauthorized = statusCode === 401;
          const _isChangingPass = useAuthStore.getState().isChangingPassword;
          if (isUnauthorized && !_isChangingPass && !remember) handleLogout();
          const customError: ICustomApiError = {
            statusCode,
            message: errorData.message || '',
          };

          throw customError;
        }
        return response;
      },
    },
  };

  const options: IncomingOptions = useMemo(
    () => ({
      ...globalOptions,
      ...customOptions,
    }),
    [customOptions, tokens]
  );

  return (
    <FetchProvider
      url={baseUrl || `${process.env.REACT_APP_HOST}`}
      options={options}
    >
      {children}
    </FetchProvider>
  );
};

export { HttpProvider };
