import { styled, Theme, TableHead } from '@mui/material';

export const RootStyle = styled('div')<{ big?: boolean }>(
  ({ theme, big }: { theme?: Theme; big?: boolean }) => ({
    width: big ? 133 : 75,
    height: big ? 133 : 75,
    margin: 'auto',
    borderRadius: '50%',
    padding: 3,
    border: `1px dashed ${theme?.palette.grey[500_32]}`,
  })
);

export const Avatar = styled('div')(
  ({ image, fixSize = '100%' }: { image?: string; fixSize?: string }) => ({
    height: fixSize,
    width: fixSize,
    backgroundColor: '#F4F6F8',
    borderRadius: '50%',
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
  })
);

export const StyledTableHead = styled(TableHead)({});
