import { Box, BoxProps } from '@mui/material';
import { memo } from 'react';

interface ILogoSmallProps extends BoxProps {
  color?: string;
}

const LogoSmall = ({ color = '#fff', ...other }: ILogoSmallProps) => {
  return (
    <Box {...other}>
      <svg width="100%" viewBox="0 0 51 51" fill={color}>
        <path d="M47.3362 20.9819L30.4432 20.9673L33.5865 4.3645H24.5769L21.4264 20.96L5.353 20.9527L3.6637 30.04L19.7014 30.0473L16.558 46.6354H25.5891L28.7253 30.0473H45.6469L47.3362 20.9819Z" />
      </svg>
    </Box>
  );
};

export default memo(LogoSmall);
