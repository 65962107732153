import { useCallback } from 'react';
import { create } from 'zustand';
import { IRegistrationStore } from 'types/stores';

const useStore = create<IRegistrationStore>(
  (): IRegistrationStore => ({
    code: '',
    vatNumber: '',
    businessName: '',
  })
);

const useRegistrationSelector = () => useStore(useCallback(state => state, []));

export { useRegistrationSelector, useStore as useRegistrationStore };
