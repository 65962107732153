import { Box, BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IMessageIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function MailIcon({ color = '#E28352', style, ...other }: IMessageIconProps) {
  return (
    <Box {...other}>
      <svg
        width="21"
        height="16"
        viewBox="0 0 21 16"
        fill={color}
        style={style}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.7686 2C20.7686 0.9 19.8575 0 18.7438 0H2.54532C1.43167 0 0.520508 0.9 0.520508 2V14C0.520508 15.1 1.43167 16 2.54532 16H18.7438C19.8575 16 20.7686 15.1 20.7686 14V2ZM18.7438 2L10.6446 7L2.54532 2H18.7438ZM18.7438 14H2.54532V4L10.6446 9L18.7438 4V14Z"
          fill={color}
        />
      </svg>
    </Box>
  );
}

export default MailIcon;
