import { IntlShape } from 'react-intl';

export const MateriaQuestions = {
  ['question_1']:
    "Da compilare se l'organizzazione produce <click id={11}>residui</click>. <click id={12}>Risorse materiche secondarie autoprodotte</click>, rispetto al totale delle <click id={13}>risorse materiche</click> <click id={3}>prime</click> e <click id={14}>secondarie</click>.",
  ['question_2']:
    '<click id={3}>Materie prime</click> e <click id={14}>risorse materiche secondarie</click> acquistate e/o acquisite da <click id={15}>fornitori locali</click> rispetto al totale delle <click id={13}>materie prime</click> acquistate e/o acquisite. Nota: Se le materie prime e risorse materiche secondarie acquistate e/o acquisite non fossero reperibili localmente escluderle dal denominatore.',
  ['question_3']:
    '<click id={13}>Risorse materiche</click> (in ingresso) dotate di un <click id={16}>sistema di tracciamento</click> (es. passaporto di prodotto) rispetto al totale delle <click id={13}>risorse materiche</click> (in ingresso).',
  ['question_4']:
    '<click id={17}>Sottoprodotti</click> e/o <click id={14}>risorse materiche secondarie</click> (in ingresso) rispetto al totale delle <click id={13}>risorse materiche</click> (in ingresso).',
  ['question_5']:
    '<click id={18}>Materie prime rinnovabili</click> (in ingresso) rispetto al totale delle <click id={13}>risorse materiche</click> (in ingresso).',
  ['question_6']:
    '<click id={14}>Risorse materiche secondarie</click> e/o <click id={17}>sottoprodotti</click> e/o <click id={20}>componenti</click> soggetti ad <click id={19}>upcycling</click> rispetto al totale delle <click id={14}>risorse materiche secondarie</click> e/o <click id={17}>sottoprodotti</click> e/o <click id={20}>componenti</click>.',
  ['question_7']:
    'Quantità di <click id={21}>risorse materiche rinnovabili</click> o <click id={22}>riciclate</click> utilizzate per il <click id={23}>packaging</click> rispetto al packaging totale utilizzato.',
  ['question_8']:
    '<click id={24}>Materie prime critiche</click> in ingresso provenienti da <click id={25}>riciclaggio</click> e/o da <click id={26}>recupero</click> e/o <click id={17}>sottoprodotti</click> rispetto al totale delle materie prime critiche.',
  ['question_9']:
    '<click id={27}>Articoli/oggetti</click> complessi e <click id={28}>miscele</click> contenenti <click id={29}>sostanze in autorizzazione o in restrizione</click> in ingresso, ovvero sostanze in quanto tali in autorizzazione o in restrizione in ingresso rispetto al totale delle <click id={13}>risorse materiche</click> in ingresso.',
  ['question_10']:
    'Differenza tra le <click id={13}>risorse materiche</click> in ingresso e i <click id={11}>residui</click> prodotti rispetto al totale delle risorse materiche in ingresso.',
};

export const materiaModalsExplanations = (
  intl: IntlShape,
  htmlParserOptions?: object
) => ({
  11: {
    title: intl.formatMessage({
      defaultMessage: 'Residui',
      description: 'homepage modalsExplanations 11 title',
    }),
    body: intl.formatMessage({
      defaultMessage: 'Insieme dei rifiuti e dei sottoprodotti.',
      description: 'homepage modalsExplanations 11 body',
    }),
  },
  12: {
    title: intl.formatMessage({
      defaultMessage: 'Risorse Materiche secondarie autoprodotte',
      description: 'homepage modalsExplanations 12 title',
    }),
    body: intl.formatMessage({
      defaultMessage:
        'Risorse materiche secondarie prodotte internamente con i mezzi propri dell’azienda (e non acquistate).',
      description: 'homepage modalsExplanations 12 body',
    }),
  },
  13: {
    title: intl.formatMessage({
      defaultMessage: 'Risorse materiche',
      description: 'homepage modalsExplanations 13 title',
    }),
    body: intl.formatMessage({
      defaultMessage:
        "Beni materiali disponibili per un'organizzazione al fine di raggiungere i suoi obiettivi quali: materie prime e risorse materiche secondarie.",
      description: 'homepage modalsExplanations 13 body',
    }),
  },
  14: {
    title: intl.formatMessage({
      defaultMessage: 'Risorsa materica secondaria',
      description: 'homepage modalsExplanations 14 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage:
          "Qualsiasi residuo utilizzato.<br></br> Alcuni esempi possono essere:<br></br><ul><li>scaglie provenienti da impianti di lavaggio e trattamenti plastica come input a processi produttivi nell'industria della plastica</li><li>residui di lavorazione che sono reimmessi nello stesso ciclo produttivo o immessi in altri cicli produttivi.</li></ul>",
        description: 'homepage modalsExplanations 14 body',
      },
      { ...htmlParserOptions }
    ),
  },
  15: {
    title: intl.formatMessage({
      defaultMessage: 'Fornitore locale',
      description: 'homepage modalsExplanations 15 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage:
          'Fornitore collocato nel raggio di 100 km dal luogo in cui il bene o il servizio è utilizzato, all’interno del perimetro di valutazione.',
        description: 'homepage modalsExplanations 15 body',
      },
      { ...htmlParserOptions }
    ),
  },
  16: {
    title: intl.formatMessage({
      defaultMessage: 'Sistema di tracciamento',
      description: 'homepage modalsExplanations 16 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage:
          'Strumento che tiene traccia delle informazioni delle risorse, prodotti e sottoprodotti.<br></br><b>Nota</b>: Alcuni esempi di sistemi di tracciamento sono il passaporto di prodotto, i sistemi RFID e i sensori IoT.',
        description: 'homepage modalsExplanations 16 body',
      },
      { ...htmlParserOptions }
    ),
  },
  17: {
    title: intl.formatMessage({
      defaultMessage: 'Sottoprodotto',
      description: 'homepage modalsExplanations 17 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage:
          'Qualsiasi sostanza od oggetto che soddisfa tutte le seguenti condizioni:<ul><li>la sostanza o l’oggetto è originato da un processo di produzione, di cui costituisce parte integrante, e il cui scopo primario non è la produzione di tale sostanza od oggetto;</li><li>è certo che la sostanza o l’oggetto sarà utilizzato, nel corso dello stesso o di un successivo processo di produzione o di utilizzazione, da parte del produttore o di terzi;</li><li>la sostanza o l’oggetto può essere utilizzato direttamente senza alcun ulteriore trattamento diverso dalla normale pratica industriale;</li><li>l’ulteriore utilizzo è legale, ossia la sostanza o l’oggetto soddisfa, per l’utilizzo specifico, tutti i requisiti pertinenti riguardanti i prodotti e la protezione della salute e dell’ambiente e non porterà a impatti complessivi negativi sull’ambiente o la salute umana</li></ul>',
        description: 'homepage modalsExplanations 17 body',
      },
      { ...htmlParserOptions }
    ),
  },
  18: {
    title: intl.formatMessage({
      defaultMessage: 'Materie prime rinnovabili',
      description: 'homepage modalsExplanations 18 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage:
          'Materie prime di origine vegetale o animale che possono essere rigenerate al termine di ogni ciclo produzione/consumo entro determinati tassi di sfruttamento e, per estensione, il cui utilizzo non pregiudica le risorse naturali per le generazioni future.',
        description: 'homepage modalsExplanations 18 body',
      },
      { ...htmlParserOptions }
    ),
  },
  19: {
    title: intl.formatMessage({
      defaultMessage: 'Upcycling',
      description: 'homepage modalsExplanations 19 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage:
          'Processo grazie al quale risorse materiche secondarie e/o sottoprodotti sono trasformati o convertiti in nuovi materiali, componenti o prodotti di migliore qualità, migliore funzionalità e/o dal valore più elevato.',
        description: 'homepage modalsExplanations 19 body',
      },
      { ...htmlParserOptions }
    ),
  },
  20: {
    title: intl.formatMessage({
      defaultMessage: 'Componente',
      description: 'homepage modalsExplanations 20 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage:
          'Parte di un prodotto, identificabile univocamente, realizzata nello stesso sito produttivo o in un diverso sito produttivo rispetto al prodotto finito.',
        description: 'homepage modalsExplanations 20 body',
      },
      { ...htmlParserOptions }
    ),
  },
  21: {
    title: intl.formatMessage({
      defaultMessage: 'Risorse materiche rinnovabili',
      description: 'homepage modalsExplanations 21 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage:
          "Risorse naturali che, per caratteristiche o per effetto della coltivazione o allevamento, si rinnovano nel tempo (a un tasso di rinnovamento maggiore o uguale al tasso di consumo/utilizzo) e possono essere considerate inesauribili, ovvero possono risultare disponibili per l'utilizzo  pressoché indefinitamente.",
        description: 'homepage modalsExplanations 21 body',
      },
      { ...htmlParserOptions }
    ),
  },
  22: {
    title: intl.formatMessage({
      defaultMessage: 'Risorse materiche riciclate',
      description: 'homepage modalsExplanations 22 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage:
          'Risorse che sono già state sottoposte ad un processo di riciclo.',
        description: 'homepage modalsExplanations 22 body',
      },
      { ...htmlParserOptions }
    ),
  },
  23: {
    title: intl.formatMessage({
      defaultMessage: 'Imballaggio (packaging)',
      description: 'homepage modalsExplanations 23 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage:
          "Prodotto composto di materiali di qualsiasi natura, adibito a<br></br> contenere le merci, dalle materie prime ai prodotti finiti, a proteggerle, a consentire la loro<br></br> manipolazione e la loro consegna dal produttore al consumatore o all'utilizzatore, ad assicurare la loro presentazione, nonchè gli articoli a perdere usati allo stesso scopo.<br></br><b>Nota</b>: L’imballaggio può essere:<br></br><ul><li>di tipo primario, o per la vendita, cioè concepito in modo da costituire, nel punto di vendita, un'unità di vendita per l'utente finale o per il consumatore;</li><li>di tipo secondario, o multiplo, cioè tale da costituire, nel punto di vendita, il raggruppamento di un certo numero di unità di vendita, indipendentemente dal fatto che sia venduto come tale all'utente finale o al consumatore, o che serva soltanto a facilitare il rifornimento degli scaffali nel punto di vendita. Esso può essere rimosso dal prodotto senza alterarne le caratteristiche;</li><li>di tipo terziario, cioè concepito in modo da facilitare la manipolazione ed il trasporto di merci, dalle materie prime ai prodotti finiti, di un certo numero di unità di vendita oppure di imballaggi multipli per evitare la loro manipolazione ed i danni connessi al trasporto, esclusi i container per i trasporti stradali, ferroviari marittimi ed aerei.</li></ul>",
        description: 'homepage modalsExplanations 23 body',
      },
      { ...htmlParserOptions }
    ),
  },
  24: {
    title: intl.formatMessage({
      defaultMessage: 'Materie prime critiche (CRMS, Critical Raw Materials)',
      description: 'homepage modalsExplanations 24 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Materie prime che sono economicamente e strategicamente importanti per l’economia ma la cui fornitura è ad alto
          rischio.<br></br><b>Nota</b>: Le concause del rischio:<br></br><ul><li>hanno un'importanza significativa per settori chiave dell’economia;</li><li>sono soggetti ad alto rischio di approvigionamento a causa della loro disponibilità solo in alcuni paesi;</li><li>non sono facilmente sostituibili con altre materie prime.</li></ul>`,
        description: 'homepage modalsExplanations 24 body',
      },
      { ...htmlParserOptions }
    ),
  },
  25: {
    title: intl.formatMessage({
      defaultMessage: 'Riciclaggio',
      description: 'homepage modalsExplanations 25 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Qualsiasi operazione di recupero attraverso cui i materiali di rifiuto sono
        ritrattati per ottenere prodotti, materiali o sostanze da utilizzare per la loro funzione originaria o per altri fini.`,
        description: 'homepage modalsExplanations 25 body',
      },
      { ...htmlParserOptions }
    ),
  },
  26: {
    title: intl.formatMessage({
      defaultMessage: 'Recupero di materia',
      description: 'homepage modalsExplanations 26 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Qualsiasi operazione di recupero di scarti diversa dal recupero energetico.`,
        description: 'homepage modalsExplanations 26 body',
      },
      { ...htmlParserOptions }
    ),
  },
  27: {
    title: intl.formatMessage({
      defaultMessage: 'Articolo',
      description: 'homepage modalsExplanations 27 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Oggetto a cui sono dati durante la produzione una forma, una superficie o un disegno particolari che ne determinano la funzione in misura maggiore della sua composizione chimica.`,
        description: 'homepage modalsExplanations 27 body',
      },
      { ...htmlParserOptions }
    ),
  },
  28: {
    title: intl.formatMessage({
      defaultMessage: 'Miscela',
      description: 'homepage modalsExplanations 28 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Preparato o soluzione composta da due o più sostanze.<br></br>
        <b>Nota</b>: Ai sensi della legislazione dell'UE sulle sostanze chimiche, le miscele non sono
        considerate sostanze. Quando due composti chimici A e B vengono miscelati e non
        reagiscono, si tratta di una miscela e non di una sostanza/componente che conserva le sue
        proprietà chimiche essenziali.`,
        description: 'homepage modalsExplanations 28 body',
      },
      { ...htmlParserOptions }
    ),
  },
  29: {
    title: intl.formatMessage({
      defaultMessage: 'Sostanza in autorizzazione o in restrizione',
      description: 'homepage modalsExplanations 29 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Sostanze oggetto di divieto o restrizione
        all’immissione in commercio o all’utilizzo stabilito a livello europeo.`,
        description: 'homepage modalsExplanations 29 body',
      },
      { ...htmlParserOptions }
    ),
  },
});

export const materiaModalsNotes = (
  intl: IntlShape,
  htmlParserOptions?: object
) => ({
  question_1: intl.formatMessage(
    {
      defaultMessage:
        "<b>Compilare questa domanda solo se l'organizzazione produce residui.</b><br></br>Indicare la fascia percentuale in cui si trova il rapporto tra le risorse materiche secondarie  autoprodotte e il totale delle risorse materiche acquistate.<br></br>(Es. Caso di un'azienda produttrice di pannelli in truciolare riciclato.<br></br> Gli scarti di pannelli prodotti dall'azienda che vengono riciclati sono risorsa materica secondaria autoprodotta. Il materiale riciclato che arriva in azienda dalla raccolta è risorsa materica secondaria. <br></br>Ipotizziamo che il materiale autoprodotto sia pari a 500 kg e il materiale riciclato che arriva dall'esterno sia pari a 10.000 kg mentre quello vergine sia 500 kg: il rapporto sarà  (500/10.500)*100= 4,8% (fascia da spuntare 0-10)).",
      description: 'homepage notes question_1 title',
    },
    { ...htmlParserOptions }
  ),
  question_2: intl.formatMessage(
    {
      defaultMessage:
        'Indicare la fascia percentuale in cui si trova il rapporto tra la quantità totale (kg) di Materie prime e risorse materiche secondarie  acquistate e/o acquisite da fornitori locali (che distano meno di 100 km) e il totale (kg) delle risorse materiche acquistate e/o acquisite da fornitori non locali.<br></br><b>Nota:</b> Se le materie prime e risorse materiche secondarie acquistate e/o acquisite non fossero reperibili localmente escluderle dal denominatore.<br></br>Sono considerati fornitori locali quei fornitori localizzati ad una distanza < 100 km.',
      description: 'homepage notes question_2 title',
    },
    { ...htmlParserOptions }
  ),
  question_3: intl.formatMessage(
    {
      defaultMessage:
        'Indicare la fascia percentuale in cui si trova  il rapporto tra  la quantità totale di risorse materiche (in ingresso) dotate di un sistema di tracciamento (es. passaporto di prodotto) e la quantità totale delle risorse materiche (in ingresso).<br></br><b>Nota</b>: La domanda si riferisce alla percentuale di risorse materiche (come legno, metallo, plastica ecc.) che entrano in un’azienda e che sono dotate di un sistema di tracciamento che ne attesta l’origine, la qualità e l’impatto ambientale. Un esempio di sistema di tracciamento è il passaporto digitale dei prodotti (DPP), che è previsto dalla Sustainable Products Initiative dell’Unione Europea.<br></br>Per rispondere alla domanda, bisogna calcolare il rapporto tra le risorse materiche tracciate e il totale delle risorse materiche che entrano nell’azienda. Ad esempio, se un’azienda del settore legno arredo acquista 100 tonnellate di legno all’anno e 80 tonnellate sono certificate da un sistema di tracciamento come il FSC (Forest Stewardship Council), la risposta alla domanda è 80% (80/100 x 100).',
      description: 'homepage notes question_3 title',
    },
    { ...htmlParserOptions }
  ),
  question_4: intl.formatMessage(
    {
      defaultMessage:
        'Indicare la fascia percentuale in cui si trova il rapporto tra la somma delle quantità (Kg/anno) di Sottoprodotti e/o di  risorse materiche secondarie (in ingresso) e la quantità (kg/anno) totale delle risorse materiche (in ingresso).<br></br><b>Nota</b>: Per rispondere alla domanda, bisogna calcolare il rapporto tra le risorse materiche provenienti da sottoprodotti o materie prime secondarie e il totale delle risorse materiche che entrano nell’azienda. Ad esempio, se un’azienda del settore legno arredo acquista 100 tonnellate di legno all’anno e e di queste 100 20 tonnellate provengono da scarti di lavorazione di altre aziende (sottoprodotti) o da legno riciclato (materia prima secondaria), la risposta alla domanda è 20% (20/100 x 100).',
      description: 'homepage notes question_4 title',
    },
    { ...htmlParserOptions }
  ),
  question_5: intl.formatMessage(
    {
      defaultMessage:
        'Indicare la fascia percentuale in cui si trova  il rapporto tra  la quantità totale di materie prime rinnovabili (kg) e la quantità totale di risosrse materiche in ingresso (kg).<br></br><b>Nota</b>: Per rispondere alla domanda, bisogna calcolare il rapporto tra le risorse materiche rinnovabili e il totale delle risorse materiche che entrano nell’azienda. Ad esempio, se un’azienda del settore legno arredo acquista 100 tonnellate di materiali inerti da costruzione e 100 tonnellate di legno certificato (materiale rinnovabile) all’anno la risposta alla domanda è 50% (100/200 x 100). ',
      description: 'homepage notes question_5 title',
    },
    { ...htmlParserOptions }
  ),
  question_6: intl.formatMessage(
    {
      defaultMessage:
        'Indicare la fascia percentuale in cui si trova il rapporto tra la quantità di risorse materiche secondarie e/o sottoprodotti e/o componenti che vengono convertite in nuovi materiali, componenti o prodotti di migliore qualità, migliore funzionalità e/o dal valore più elevato, rispetto al totale delle risorse materiche secondarie e/o sottoprodotti e/o componenti.',
      description: 'homepage notes question_6 title',
    },
    { ...htmlParserOptions }
  ),
  question_7: intl.formatMessage(
    {
      defaultMessage:
        'Indicare la fascia percentuale in cui ricade il rapporto tra la quantità di risorse materiche rinnovabili o riciclate utilizzate per il packaging e la quantità totale di risorse materiche utilizzate per il packaging.<br></br>(ad es. se la quantità di plastica riciclata e di cartone riciclato utilizzata nel packaging è pari a 7000 kg e la quantità di risorse materiche utilizzate per il packaging è pari a 10.000  kg la percentuale sarà pari a (7.000/10.000)*100= 70%.',
      description: 'homepage notes question_7 title',
    },
    { ...htmlParserOptions }
  ),
  question_8: intl.formatMessage(
    {
      defaultMessage:
        'Indicare la fascia percentuale in cui ricade il rapporto tra la quantità di Materie prime critiche in ingresso provenienti da riciclaggio e/o da recupero e/o sottoprodotti rispetto al totale delle Materie prime critiche.<br></br><b>Nota</b>: Le materie prime critiche sono quei materiali che hanno una grande importanza economica per l’Europa e che allo stesso tempo presentano un alto rischio di fornitura. Alcune materie prime critiche sono il litio, la grafite, le terre rare, il vanadio, il cobalto e il tungsteno.<br></br>Per rispondere a questa domanda bisogna calcolare la percentuale di materie prime critiche in ingresso che provengono da fonti rinnovabili o secondarie rispetto al totale delle materie prime critiche utilizzate dall’azienda. Un esempio di risposta per un’azienda del settore legno-arredo potrebbe essere:<br></br>L’azienda utilizza come materia prima critica il niobio, che serve per produrre acciai speciali per le strutture metalliche dei mobili.<br></br>L’azienda acquista il 60% del niobio da fornitori che lo riciclano da rottami metallici e il 40% da fornitori che lo estraggono da miniere.<br></br>La percentuale di materia prima critica in ingresso proveniente da riciclaggio e/o da recupero e/o sottoprodotti rispetto al totale della materia prima critica è quindi del 60%.',
      description: 'homepage notes question_8 title',
    },
    { ...htmlParserOptions }
  ),
  question_9: intl.formatMessage(
    {
      defaultMessage: `Indicare nella risposta la fascia percentuale in cui si ricade.
      Per eseguire il calcolo si deve dividere  la quantità di articoli/oggetti complessi e miscele contenenti 
      sostanze in autorizzazione o in restrizione in ingresso, ovvero sostanze in quanto tali in autorizzazione o in 
      restrizione in ingresso  per il totale delle risorse materiche in ingresso nell’anno n. Il risultato si moltiplica 
      per 100 per ottenere la percentuale. Per esempio, se un’azienda riceve 1000 kg di risorse materiche al 
      giorno e 100 kg contengono sostanze in autorizzazione o in restrizione, la percentuale è(100/1000) x 100 = 
      10%. <br></br> 
      Nota: Questa domanda si riferisce alla quantità di sostanze chimiche pericolose che un’azienda utilizza 
      e che sono soggette a divieti, restrizioni od obblighi di autorizzazione stabiliti a livello europeo dal 
      regolamento REACH. Le sostanze in autorizzazione o in restrizione sono quelle che presentano un 
      rischio elevato per la salute umana o l’ambiente e che devono essere sostituite con alternative più 
      sicure quando possibile.
      Per rispondere a questa domanda bisogna calcolare la percentuale di articoli/oggetti complessi e 
      miscele contenenti sostanze in autorizzazione o in restrizione in ingresso, ovvero sostanze in quanto 
      tali in autorizzazione o in restrizione in ingresso rispetto al totale delle risorse materiche in ingresso. 
      Per fare un esempio per un’azienda del settore legno-arredo, si potrebbe considerare il caso in cui 
      l’azienda utilizzi come risorse materiche il legno, la vernice e le colle. Se la vernice contiene una 
      sostanza in restrizione come il piombo, le colle contengono una sostanza in autorizzazione come il 
      formaldeide e il legno non contiene nessuna sostanza soggetta a REACH, allora la percentuale di 
      articoli/oggetti complessi e miscele contenenti sostanze in autorizzazione o in restrizione è data dal 
      rapporto tra il peso della vernice e delle colle (al numeratore) e il peso totale del legno, della vernice, 
      delle colle (al denominatore), valore poi da trasformare in percentuale e inserire la fascia in cui ricade.`,
      description: 'homepage notes question_9 title',
    },
    { ...htmlParserOptions }
  ),
  question_10: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto  tra: la differenza tra  risorse materiche in ingresso e i residui prodotti e il totale delle risorse materiche in ingresso.<br></br>
      Ad es. se le risorse materiche in ingresso sono pari a 10000 kg, i residui sono pari a 2000 kg la loro differenza sarà 10000-2000 = 8000 kg e il rapporto sarà pari a (8000/10000)x100 = 80%.`,
      description: 'homepage notes question_10 title',
    },
    { ...htmlParserOptions }
  ),
});
