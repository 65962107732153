import { BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IEnergyIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function EnergyIcon({ color = '#FFC533', style }: IEnergyIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M10 2.5C6 2.5 2.5 5.71667 2.5 10C2.5 11.7667 3.11667 13.3917 4.14167 14.675L2.5 16.325L3.675 17.5L5.31667 15.8583C6.60833 16.8833 8.23333 17.5 10 17.5C11.9167 17.5 13.8417 16.7667 15.3 15.3C16.7667 13.8417 17.5 11.9167 17.5 10V2.5H10ZM15.8333 10C15.8333 11.5583 15.225 13.025 14.125 14.125C13.025 15.225 11.5583 15.8333 10 15.8333C6.78333 15.8333 4.16667 13.2167 4.16667 10C4.16667 8.41667 4.78333 6.93333 5.91667 5.84167C7.01667 4.75833 8.46667 4.16667 10 4.16667H15.8333V10Z"
        fill={color}
      />
      <path
        d="M7.05 10.525L10.425 10.8583L8.39167 13.6333C8.3 13.7667 8.30833 13.95 8.425 14.0667C8.55 14.1917 8.75833 14.2 8.89167 14.075L13.1917 10.2167C13.4667 9.96667 13.3167 9.50833 12.9417 9.475L9.56667 9.14167L11.6 6.36667C11.6917 6.23333 11.6833 6.05 11.5667 5.93333C11.4417 5.80833 11.2333 5.8 11.1 5.925L6.8 9.78333C6.53333 10.0333 6.68333 10.4917 7.05 10.525Z"
        fill={color}
      />
    </svg>
  );
}

export default EnergyIcon;
