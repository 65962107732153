import { Box, BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IHourglassIconIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function HourglassIcon({
  color = '#5db0b4',
  style,
  ...other
}: IHourglassIconIconProps) {
  return (
    <Box {...other}>
      <svg
        width="12"
        height="20"
        viewBox="0 0 12 20"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <path
          d="M0 0V6H0.0100002L0 6.01L4 10L0 14L0.0100002 14.01H0V20H12V14.01H11.99L12 14L8 10L12 6.01L11.99 6H12V0H0ZM10 14.5V18H2V14.5L6 10.5L10 14.5ZM6 9.5L2 5.5V2H10V5.5L6 9.5Z"
          fill="#919EAB"
        />
      </svg>
    </Box>
  );
}

export default HourglassIcon;
