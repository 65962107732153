import { Typography, useTheme } from '@mui/material';
import { Stack } from '@mui/material';
import {
  FooterContainer,
  FooterWrapper,
  ImagesContainer,
  StyledHr,
  StyledLink,
} from './index.style';
import {
  FooterITAIcon,
  FooterLogoIcon,
  FooterMaeciIcon,
  FooterbeITIcon,
} from 'assets/icons';

import { useResponsive } from 'hooks';

const Footer = () => {
  //hooks
  const isMobile = useResponsive('down', 'md');
  const theme = useTheme();

  return (
    <FooterWrapper>
      {isMobile ? null : (
        <div style={{ display: 'flex', gap: '20px' }}>
          <StyledLink href="https://www.fla-plus.it/chi-siamo/" target="_blank">
            Chi Siamo
          </StyledLink>
          <StyledLink href="https://www.fla-plus.it/contatti/" target="_blank">
            Contatti
          </StyledLink>
        </div>
      )}
      <FooterContainer>
        <Stack
          flexDirection={isMobile ? 'column' : 'row'}
          gap={isMobile ? '20px' : '31px'}
        >
          <FooterLogoIcon
            style={
              isMobile
                ? { width: '175' }
                : { width: '350px', minWidth: '150px' }
            }
          />
          <Typography
            fontSize={13}
            fontWeight={300}
            fontStyle={'normal'}
            lineHeight={'normal'}
            width={isMobile ? '100%' : 'auto'}
            color={theme.palette.grey[400]}
          >
            <strong>FederlegnoArredo</strong> è il cuore della filiera italiana
            del legno-arredo. Dal 1945 costruiamo progetti, iniziative e
            relazioni per rispondere alle esigenze delle aziende e degli
            imprenditori, sostenere lo sviluppo delle imprese, valorizzare il
            saper fare e rafforzare il posizionamento del settore sul mercato
            internazionale.
          </Typography>
          <ImagesContainer>
            <FooterbeITIcon />
            <FooterITAIcon />
            <FooterMaeciIcon />
          </ImagesContainer>
        </Stack>
      </FooterContainer>

      <Stack
        justifyContent={'flex-start'}
        style={{
          ...(isMobile ? { margin: '0 auto' } : { width: '100%' }),
        }}
      >
        <StyledHr />
        <Typography
          width={'100%'}
          fontSize={'12px'}
          fontWeight={300}
          textAlign={'start'}
          color={theme.palette.grey[400]}
          lineHeight={'26px'}
        >
          © 2024 Tutti i diritti riservati FederlegnoArredo C.F. 97228150153 –{' '}
          {isMobile ? null : (
            <StyledLink
              href="https://www.fla-plus.it/privacy-policy/"
              target="blank"
              color={theme.palette.grey[500_80]}
              style={{
                marginRight: 'auto',
                color: theme.palette.grey[400],
              }}
            >
              Privacy Policy
            </StyledLink>
          )}
        </Typography>
        {isMobile ? (
          <StyledLink
            href="https://www.fla-plus.it/privacy-policy/"
            target="blank"
          >
            Privacy Policy - Terms of Services
          </StyledLink>
        ) : null}
      </Stack>
      <Typography
        sx={{
          fontSize: '10px',
          letterSpacing: 0.2,
          fontWeight: '300',
          lineHeight: '12.66px',
          color: theme.palette.grey[400],
          mt: 5,
          mb: 2,
        }}
      >
        <strong>DISCLAIMER</strong>: Tutti i dati forniti agli Utenti attraverso
        la piattaforma fla-plus.it sono a mero titolo informativo.
        FederlegnoArredo non si assume alcuna responsabilità perquanto riguarda
        tali informazioni. In particolare: • Le informazioni contenute sulla
        piattaforma non costituiscono parere di tipo professionale o giuridico
        avendo esclusivamente finalità divulgativa e validità per il tempo nel
        quale sono rese. • Sebbene FederlegnoArredo presti la massima attenzione
        all'aggiornamento, alla completezza e precisione delle informazioni di
        use attraverso la piattaforma, non si assume alcuna responsabilità
        quanto all'utilizzo dei dati o delle informazioni ivi contenuti. Gli
        Utenti, di conseguenza, sono tenuti ad adottare tutte le precauzioni
        necessarie prima di utilizzare tali dati assumendosi conseguentemente
        tutti i rischi connessi. • Le informazioni contenute sulla piattaforma
        possono rinviare a siti esterni, sui quali i servizi della
        FederlegnoArredo non hanno alcun controllo e per i quali
        FederlegnoArredo non assume alcuna responsabilità. È vietata la
        divulgazione a Terzi dei dati contenuti sulla piattaforma, con qualsiasi
        mezzo analogico o digitale.
      </Typography>
    </FooterWrapper>
  );
};

export default Footer;
