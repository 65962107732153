// @mui

import { CSSProperties } from 'react';

// ----------------------------------------------------------------------

function SideBarTreesIcon({
  color = '#919EAB',
  style,
}: {
  color?: string;
  style?: CSSProperties;
}) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style, transform: 'translate(-4px, 0px)' }}
    >
      <g id="ICONS">
        <path
          id="Vector"
          d="M20.2248 20.2337H22.1749H22.1787C22.2546 20.2337 22.3287 20.2107 22.3912 20.1677C22.4537 20.1247 22.5017 20.0637 22.5289 19.9928C22.556 19.922 22.561 19.8445 22.5432 19.7708C22.5254 19.697 22.4857 19.6303 22.4292 19.5796C21.2127 18.1474 20.1506 16.591 19.2604 14.9361H21.175H21.1787C21.2554 14.9361 21.3302 14.9126 21.3931 14.8689C21.456 14.8251 21.5039 14.7631 21.5305 14.6912C21.5571 14.6193 21.561 14.541 21.5417 14.4668C21.5224 14.3927 21.4809 14.3262 21.4226 14.2764C17.6279 10.1175 16.4906 4.85102 16.4795 4.79828C16.4619 4.71397 16.4158 4.6383 16.349 4.58398C16.2821 4.52966 16.1986 4.50001 16.1125 4.5C16.0264 4.50007 15.9427 4.52978 15.8759 4.58412C15.8091 4.63847 15.763 4.71415 15.7454 4.79846C15.7344 4.85137 14.5972 10.1376 10.7736 14.3077C10.7244 14.3613 10.6919 14.4281 10.68 14.4999C10.6681 14.5717 10.6774 14.6455 10.7067 14.7121C10.736 14.7787 10.7841 14.8354 10.845 14.8751C10.906 14.9149 10.9772 14.9361 11.05 14.9361H12.9648C12.0681 16.604 10.9963 18.1715 9.76744 19.6122C9.72011 19.6665 9.6894 19.7332 9.67897 19.8045C9.66855 19.8757 9.67885 19.9484 9.70866 20.014C9.73846 20.0795 9.7865 20.1351 9.84704 20.174C9.90759 20.213 9.97806 20.2337 10.0501 20.2337H12.0002C11.3615 21.2527 9.98258 23.4161 8.87453 24.9007C8.83292 24.9564 8.80764 25.0227 8.80151 25.092C8.79538 25.1613 8.80864 25.2309 8.83982 25.2931C8.871 25.3553 8.91886 25.4075 8.97805 25.4441C9.03724 25.4806 9.10543 25.5 9.175 25.5H14.3313V28.125C14.3313 28.2245 14.3708 28.3198 14.4411 28.3902C14.5114 28.4605 14.6068 28.5 14.7063 28.5H17.5188C17.6182 28.5 17.7136 28.4605 17.7839 28.3902C17.8542 28.3198 17.8937 28.2245 17.8938 28.125V25.5H23.05C23.1196 25.5 23.1878 25.4806 23.2469 25.4441C23.3061 25.4075 23.354 25.3553 23.3852 25.2931C23.4164 25.2309 23.4296 25.1613 23.4235 25.092C23.4174 25.0227 23.3921 24.9564 23.3505 24.9007C22.2427 23.4164 20.8635 21.2529 20.2248 20.2337Z"
          stroke={color}
          strokeWidth="2.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M22.5923 10.1351C23.0292 9.13859 23.3777 8.10562 23.6336 7.04809C23.6513 6.96386 23.6974 6.88826 23.7642 6.83399C23.831 6.77971 23.9146 6.75006 24.0006 6.75C24.0867 6.75002 24.1702 6.77964 24.237 6.83388C24.3038 6.88812 24.3499 6.96369 24.3677 7.04792C24.3775 7.09479 25.3893 11.7625 28.7631 15.4497C28.8214 15.4995 28.8631 15.566 28.8824 15.6402C28.9018 15.7143 28.898 15.7927 28.8714 15.8646C28.8449 15.9366 28.7969 15.9986 28.734 16.0425C28.6711 16.0863 28.5963 16.1098 28.5196 16.1098H28.5158H26.8772C27.6621 17.5543 28.5952 18.9131 29.6615 20.1643C29.7181 20.215 29.758 20.2816 29.7758 20.3554C29.7937 20.4292 29.7888 20.5067 29.7617 20.5776C29.7346 20.6485 29.6866 20.7096 29.624 20.7526C29.5615 20.7957 29.4873 20.8187 29.4114 20.8187H27.7428C28.3283 21.7484 29.5238 23.6127 30.4883 24.9001C30.53 24.9559 30.5554 25.0221 30.5616 25.0915C30.5678 25.1608 30.5546 25.2305 30.5235 25.2928C30.4923 25.355 30.4445 25.4074 30.3852 25.444C30.326 25.4806 30.2578 25.5 30.1881 25.5H27.3101M24.007 28.5H26.8195C26.9189 28.5 27.0143 28.4605 27.0846 28.3902C27.155 28.3198 27.1945 28.2245 27.1945 28.125V25.5"
          stroke={color}
          strokeWidth="2.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default SideBarTreesIcon;
