import { IconButton, TextField } from '@mui/material';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IRHFTextFieldProps } from './RHFTextField.types';
import { Iconify } from 'components';
const RHFTextField = ({
  name,
  rules,
  defaultValue,
  onChange,
  type = 'text',
  InputProps,
  ...other
}: IRHFTextFieldProps) => {
  //hooks
  const { control } = useFormContext();

  //states
  const [showPassword, setShowPassword] = useState<boolean>(false);
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          type={
            type === 'password' ? (showPassword ? 'text' : 'password') : type
          }
          fullWidth
          error={!!error}
          helperText={error?.message}
          onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
          {...other}
          onChange={e => {
            field.onChange(e.target.value);
            onChange?.(e);
          }}
          InputProps={
            type === 'password'
              ? {
                  endAdornment: (
                    <IconButton
                      onClick={
                        showPassword
                          ? () => setShowPassword(false)
                          : () => setShowPassword(true)
                      }
                    >
                      {showPassword ? (
                        <Iconify icon="material-symbols:visibility-off" />
                      ) : (
                        <Iconify icon="material-symbols:visibility" />
                      )}
                    </IconButton>
                  ),
                }
              : InputProps
          }
        />
      )}
    />
  );
};

export { RHFTextField };
