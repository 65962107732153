import { useCallback } from 'react';
import { create } from 'zustand';

export interface ICartStore {
  totalPrice: number;
  items: ICartItem[];
  addItems: (item: ICartItem) => void;
  addParticipants: (itemName: string) => void;
  removeParticipants: (itemName: string) => void;
  updateTotalPrice: () => void;
}

export interface ICartItem {
  id: string;
  date: string;
  itemName: string;
  itemType: string;
  itemPrice: number;
  itemNetPrice: number;
  participants: number;
  itemImage?: string;
  href: string;
}

const useStore = create<ICartStore>(
  (set): ICartStore => ({
    totalPrice: 0,
    items: [],
    addItems: (item: ICartItem) =>
      set(state => ({ items: [...state.items, item] })),
    addParticipants: (itemName: string) =>
      set(state => ({
        items: state.items.map(i =>
          itemName === i.itemName
            ? { ...i, participants: i.participants + 1 }
            : i
        ),
      })),
    removeParticipants: (itemName: string) =>
      set(state => ({
        items: state.items.map(i =>
          itemName === i.itemName
            ? { ...i, participants: i.participants - 1 }
            : i
        ),
      })),
    updateTotalPrice: () =>
      set(state => ({
        totalPrice: state.items.length
          ? state.items
              .map(i => {
                const updatedPrices =
                  i.itemPrice + (i.itemPrice / 2) * (i.participants - 1);
                return updatedPrices;
              })
              .reduce((prev, current) => prev + current)
          : 0,
      })),
  })
);

const useCartSelector = () => useStore(useCallback(state => state, []));

export { useCartSelector, useStore as useCartStore };
