import { useCallback } from 'react';
import { create } from 'zustand';
import {
  IToolStore,
  SurveyCategoriesEnum,
  ToolContextEnum,
} from 'types/stores';

export const toolStoreDefaultValue: IToolStore = {
  clickedTextId: '',
  currentContext: ToolContextEnum.WELCOME,
  pathSelected: 'complete',
  feedbackData: {
    savedSurvey: undefined,
    calculated: [
      { category: SurveyCategoriesEnum.MATERIA, value: 0 },
      { category: SurveyCategoriesEnum.ENERGY, value: 0 },
      { category: SurveyCategoriesEnum.WASTE, value: 0 },
      { category: SurveyCategoriesEnum.LOGISTICS, value: 0 },
      { category: SurveyCategoriesEnum.PRODUCTS, value: 0 },
      { category: SurveyCategoriesEnum.HR, value: 0 },
    ],
  },
  feedbackChartCanvasImg: '',
  feedbackTotalPercentage: 0,
  calculatedQuestions: [],
  questionsPossibleOptions: undefined,
  customizedSelectedChoices: {
    A: undefined,
    B: undefined,
    C: undefined,
    D: undefined,
    E: undefined,
    F: undefined,
    G: undefined,
    H: undefined,
  },
  surveySelectedChoices: undefined,
  draftedSurvey: undefined,
  completedSurvey: undefined,
  wantFeedbackOnly: false,
  wantCompletedSurveyFeedback: false,
  wantViewCompletedSurvey: false,
  isEditing: false,
  temporaryDraftId: '',
};

const useStore = create<IToolStore>(
  (): IToolStore => ({
    ...toolStoreDefaultValue,
  })
);

const useToolSelector = () => useStore(useCallback(state => state, []));

export { useToolSelector, useStore as useToolStore };
