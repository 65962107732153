import { CSSProperties } from 'react';

function TextSnippetIcon({
  fill = 'white',
  style,
}: {
  fill?: string;
  style?: CSSProperties;
}) {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M13.4892 5.41665L17.9167 9.84415V18.25H5.08333V5.41665H13.4892ZM13.4892 3.58331H5.08333C4.075 3.58331 3.25 4.40831 3.25 5.41665V18.25C3.25 19.2583 4.075 20.0833 5.08333 20.0833H17.9167C18.925 20.0833 19.75 19.2583 19.75 18.25V9.84415C19.75 9.35831 19.5575 8.89081 19.2092 8.55165L14.7817 4.12415C14.4425 3.77581 13.975 3.58331 13.4892 3.58331ZM6.91667 14.5833H16.0833V16.4166H6.91667V14.5833ZM6.91667 10.9166H16.0833V12.75H6.91667V10.9166ZM6.91667 7.24998H13.3333V9.08331H6.91667V7.24998Z"
        fill={fill}
      />
    </svg>
  );
}

export default TextSnippetIcon;
