import { BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface ILogisticsIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function LogisticsIcon({ color = '#FF800A', style }: ILogisticsIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M16.6673 6.66659H14.1673V3.33325H2.50065C1.58398 3.33325 0.833984 4.08325 0.833984 4.99992V14.1666H2.50065C2.50065 15.5499 3.61732 16.6666 5.00065 16.6666C6.38398 16.6666 7.50065 15.5499 7.50065 14.1666H12.5007C12.5007 15.5499 13.6173 16.6666 15.0006 16.6666C16.384 16.6666 17.5006 15.5499 17.5006 14.1666H19.1673V9.99992L16.6673 6.66659ZM16.2506 7.91658L17.884 9.99992H14.1673V7.91658H16.2506ZM5.00065 14.9999C4.54232 14.9999 4.16732 14.6249 4.16732 14.1666C4.16732 13.7083 4.54232 13.3333 5.00065 13.3333C5.45898 13.3333 5.83398 13.7083 5.83398 14.1666C5.83398 14.6249 5.45898 14.9999 5.00065 14.9999ZM6.85065 12.4999C6.39232 11.9916 5.74232 11.6666 5.00065 11.6666C4.25898 11.6666 3.60898 11.9916 3.15065 12.4999H2.50065V4.99992H12.5007V12.4999H6.85065ZM15.0006 14.9999C14.5423 14.9999 14.1673 14.6249 14.1673 14.1666C14.1673 13.7083 14.5423 13.3333 15.0006 13.3333C15.459 13.3333 15.834 13.7083 15.834 14.1666C15.834 14.6249 15.459 14.9999 15.0006 14.9999Z"
        fill={color}
      />
    </svg>
  );
}

export default LogisticsIcon;
