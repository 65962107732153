import { BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IListIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function ListIcon({ color = 'white', style }: IListIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M2.5 10.834H4.16667V9.16732H2.5V10.834ZM2.5 14.1673H4.16667V12.5007H2.5V14.1673ZM2.5 7.50065H4.16667V5.83398H2.5V7.50065ZM5.83333 10.834H17.5V9.16732H5.83333V10.834ZM5.83333 14.1673H17.5V12.5007H5.83333V14.1673ZM5.83333 5.83398V7.50065H17.5V5.83398H5.83333ZM2.5 10.834H4.16667V9.16732H2.5V10.834ZM2.5 14.1673H4.16667V12.5007H2.5V14.1673ZM2.5 7.50065H4.16667V5.83398H2.5V7.50065ZM5.83333 10.834H17.5V9.16732H5.83333V10.834ZM5.83333 14.1673H17.5V12.5007H5.83333V14.1673ZM5.83333 5.83398V7.50065H17.5V5.83398H5.83333Z"
        fill={color}
      />
    </svg>
  );
}

export default ListIcon;
