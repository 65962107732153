import { Button, Stack } from '@mui/material';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useInterestsStore } from '../../stores/interests';
import { Container, StyledPaper } from './ProfileInteresets.style';
import InterestCard from 'components/InterestCard/InterestCard';
import { useTranslations, useResponsive, useJsonApi, useSnackbar } from 'hooks';
import { useAuth } from 'hooks/api';
import { FormProvider } from 'providers';
import { interestsDrupalMapping, useAuthSelector } from 'stores';
import { useGlobalStore } from 'stores';
import { IInterest } from 'types/stores';
import { IUserToken } from 'types/user';

const interestCards: IInterest[] = [
  {
    interestType: 'Academy',
    iconType: 'formazione',
    drupalId: interestsDrupalMapping['Academy'],
  },
  {
    interestType: 'Centro Studi',
    iconType: 'centro_studi',
    drupalId: interestsDrupalMapping['Centro Studi'],
  },
  {
    interestType: 'Finanza',
    iconType: 'finanziamenti_fisco',
    drupalId: interestsDrupalMapping['Finanza'],
  },
  {
    interestType: 'Proprietà\nintellettuale',
    iconType: 'proprieta_intellettuale',
    drupalId: interestsDrupalMapping['Proprietà intellettuale'],
  },
  {
    interestType: 'Libreria Materiali',
    iconType: 'libreria_materiali',
    drupalId: interestsDrupalMapping['Libreria Materiali'],
  },
  {
    interestType: 'TECLA',
    iconType: 'TECLA',
    drupalId: interestsDrupalMapping['TECLA'],
  },
  {
    interestType: 'Survey Generale',
    iconType: 'survey',
    drupalId: interestsDrupalMapping['Survey Generale'],
  },
  {
    interestType: 'Bosco del Design',
    iconType: 'bosco_design',
    drupalId: interestsDrupalMapping['Bosco del Design'],
  },
  {
    interestType: 'Sportello\nDoganale',
    iconType: 'sportello_doganale',
    drupalId: interestsDrupalMapping['Sportello Doganale'],
  },
  {
    interestType: 'Portale\nCertificazioni',
    iconType: 'portale_certificazioni',
    drupalId: interestsDrupalMapping['Portale Certificazioni'],
  },
];

const ProfileInterests = () => {
  //hooks
  const isMobile = useResponsive('down', 'md');
  const { user, storeUser } = useAuthSelector();
  const [cardsSelected, setCardsSelected] = useState<IInterest[]>([]);
  const methods = useForm();
  const { handleSubmit } = methods;
  const i18n = useTranslations();
  const { showLoader, hideLoader } = useGlobalStore();
  const { editUser, listEntities } = useJsonApi({ showLoader, hideLoader });
  const { refreshTokens } = useAuth();
  const { errorSnackbar, successSnackbar } = useSnackbar();

  const onSetInterests = async (interest: IInterest) => {
    setCardsSelected(prev => [...prev, interest]);
  };

  const onRemoveInterests = (interest: IInterest) => {
    setCardsSelected(prev =>
      prev.filter(i => i.interestType !== interest.interestType)
    );
  };

  const onSubmit = async () => {
    try {
      showLoader();
      const _interests = await listEntities({
        body: {
          entity: 'taxonomy_term',
          bundle: 'interests',
          deserializeEntities: true,
          disableStatusFilter: true,
          specificResValue: ['id', 'drupal_internal__tid'],
        },
      });
      if (_interests) {
        const interests = _interests as unknown as {
          drupal_internal__tid: number;
          id: string;
        }[];
        const _field_interest = cardsSelected
          .map(card => ({
            type: 'taxonomy_term--interests',
            id: interests.find(
              interest => interest.drupal_internal__tid === card.drupalId
            )?.id,
          }))
          .filter(int => !!int.id);
        const newToken = await refreshTokens();
        const res = await editUser({
          useLoader: false,
          id: user?.uuid as string,
          body: {
            data: {
              type: 'user--user',
              id: user?.uuid,
              relationships: {
                field_interests: {
                  data: _field_interest,
                },
              },
            },
          },
          accessToken: newToken?.access_token as string,
        });
        if (!res) throw new Error();
        await refreshTokens(newToken as IUserToken);
        useInterestsStore.setState({ interests: cardsSelected });
        successSnackbar(i18n.general.snackbar.success);
        user &&
          storeUser({
            ...user,
            field_interests: cardsSelected.map(card => `${card.drupalId}`),
          });
      } else {
        throw new Error();
      }
    } catch (error) {
      errorSnackbar(i18n.general.errors.generic);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (user?.field_interests) {
      const _selected = interestCards.filter(card =>
        user?.field_interests?.includes(`${card.drupalId}`)
      );
      setCardsSelected(_selected);
    }
  }, [user]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <StyledPaper height="auto" padding="50px" width="100%">
          <Stack
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent={'space-between'}
            width={'100%'}
            gap={'40px'}
          >
            <Typography
              style={isMobile ? { width: '100%' } : { width: '300px' }}
            >
              Seleziona i servizi che ti interessano maggiormente cliccando sulle icone a lato, quindi premi "Salva Modifiche". Ti terremo aggiornato rispetto alle tematiche di maggior interesse per te.
            </Typography>

            <Stack
              direction="row"
              gap={'16px'}
              flexWrap={'wrap'}
              width={isMobile ? '100%' : 'calc(100% - 300px)'}
              justifyContent={isMobile ? 'center' : 'flex-start'}
            >
              {interestCards.map((e, index) => (
                <InterestCard
                  key={index}
                  interest={e}
                  onSetInterests={onSetInterests}
                  onRemoveInterests={onRemoveInterests}
                  cardsSelected={cardsSelected}
                />
              ))}
            </Stack>
          </Stack>
          <Stack
            flexDirection={'row'}
            justifyContent={isMobile ? 'center' : 'flex-end'}
            alignItems={'center'}
            sx={{ width: '100%' }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              fullWidth={isMobile}
              type="submit"
              sx={{
                width: '144px',
                height: '36px',
                backgroundColor: '#0A2233',
                marginTop: '40px',
              }}
            >
              {i18n.common.buttons.saveChanges}
            </Button>
          </Stack>
        </StyledPaper>
      </Container>
    </FormProvider>
  );
};

export { ProfileInterests };
