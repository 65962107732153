import { Box, BoxProps, styled, alpha } from '@mui/material';
// @mui

interface IPositiveOrNegativeIconProps extends BoxProps {
  isPositive: boolean;
}

const IconContainer = styled('div')(({ color }: { color: string }) => ({
  height: 24,
  width: 24,
  borderRadius: 100,
  backgroundColor: color,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

// ----------------------------------------------------------------------

function PositiveOrNegativeIcon({
  isPositive,
  ...other
}: IPositiveOrNegativeIconProps) {
  return (
    <IconContainer color={alpha(isPositive ? '#6AC954' : '#FF4842', 0.16)}>
      <Box {...other}>
        {isPositive ? (
          <svg
            width="14"
            height="9"
            viewBox="0 0 14 9"
            fill="#6AC954"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12.9992 1.16667C13.0055 1.12021 13.0055 1.07312 12.9992 1.02667C12.9934 0.987526 12.9822 0.949383 12.9659 0.913333C12.9483 0.880752 12.9282 0.849551 12.9059 0.82C12.8805 0.777835 12.8491 0.739646 12.8126 0.706667L12.7326 0.66C12.6941 0.631304 12.6513 0.60881 12.6059 0.593333H12.4726C12.4319 0.553997 12.3845 0.522372 12.3326 0.5H8.99922C8.63103 0.5 8.33256 0.798477 8.33256 1.16667C8.33256 1.53486 8.63103 1.83333 8.99922 1.83333H10.8859L8.21922 4.97333L5.33922 3.26C5.05741 3.09239 4.69556 3.15458 4.48589 3.40667L1.15256 7.40667C1.03918 7.54272 0.984605 7.7183 1.00086 7.89465C1.01712 8.071 1.10288 8.23363 1.23922 8.34667C1.35916 8.44605 1.51012 8.5003 1.66589 8.5C1.86418 8.50032 2.05232 8.41236 2.17922 8.26L5.14589 4.7L7.99256 6.40667C8.27129 6.57199 8.62854 6.51292 8.83922 6.26667L11.6659 2.96667V4.5C11.6659 4.86819 11.9644 5.16667 12.3326 5.16667C12.7007 5.16667 12.9992 4.86819 12.9992 4.5V1.16667Z" />
          </svg>
        ) : (
          <svg
            width="12"
            height="9"
            viewBox="0 0 12 9"
            fill="#FF4842"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12.0008 4.50085C12.0008 4.13266 11.7024 3.83418 11.3342 3.83418C10.966 3.83418 10.6675 4.13266 10.6675 4.50085V6.03418L7.84084 2.70085C7.63016 2.45459 7.27291 2.39552 6.99418 2.56085L4.14751 4.30085L1.18084 0.740847C1.02841 0.557451 0.789714 0.469308 0.554671 0.509621C0.319629 0.549934 0.123947 0.712578 0.0413378 0.936287C-0.0412714 1.16 0.00174203 1.41078 0.154175 1.59418L3.48751 5.59418C3.69718 5.84626 4.05903 5.90846 4.34084 5.74085L7.19418 4.02751L9.86084 7.16751H8.00084C7.63265 7.16751 7.33418 7.46599 7.33418 7.83418C7.33418 8.20237 7.63265 8.50085 8.00084 8.50085H11.3342C11.4162 8.49883 11.4974 8.48306 11.5742 8.45418L11.6675 8.40085C11.7023 8.38333 11.7357 8.36327 11.7675 8.34085C11.804 8.30787 11.8355 8.26968 11.8608 8.22751C11.8832 8.19796 11.9032 8.16676 11.9208 8.13418C11.9372 8.09813 11.9484 8.05999 11.9542 8.02085C11.9812 7.96207 11.997 7.89876 12.0008 7.83418V4.50085Z" />
          </svg>
        )}
      </Box>
    </IconContainer>
  );
}

export default PositiveOrNegativeIcon;
