import { IntlShape } from 'react-intl';

export const generalTranslations = (intl: IntlShape) => ({
  general: {
    testEnv: intl.formatMessage({
      defaultMessage: 'Ambiente di Test',
      description: 'general testEnv',
    }),
    errors: {
      generic: intl.formatMessage({
        defaultMessage: 'Si è verificato un errore, riprova più tardi',
        description: 'general errors',
      }),
      requiredField: intl.formatMessage({
        defaultMessage: 'Campo Obbligatorio',
        description: 'general required field error',
      }),
      invalidUsernameOrPassword: intl.formatMessage({
        defaultMessage: 'Username o password non sono corretti',
        description: 'Login page invalid username or password snackbar error',
      }),
      mismatch: intl.formatMessage({
        defaultMessage: 'non corrisponde',
        description: 'Generic mismatch error message',
      }),
      emailNotValid: intl.formatMessage({
        defaultMessage: 'Indirizzo email non valido',
        description: 'general errors Invalid Email',
      }),
      invalidPhone: intl.formatMessage({
        defaultMessage:
          'Inserisci un numero di telefono valido, comprensivo di prefisso internazionale',
        description: 'Enter Data invalid phone number error message',
      }),
      invalidVat: intl.formatMessage({
        defaultMessage: 'Inserisci una Partita IVA valida',
        description: 'Enter Data invalid vat error message',
      }),
      passwordMissing: intl.formatMessage({
        defaultMessage: 'Inserisci una Password valida',
        description: 'Enter Data invalid vat error message',
      }),
      permissionDenied: intl.formatMessage({
        defaultMessage: 'Permesso negato',
        description: 'general errors permission denied',
      }),
    },
    snackbar: {
      success: intl.formatMessage({
        defaultMessage: 'Operazione riuscita',
        description: 'General snackbar success message',
      }),
      dataSaved: intl.formatMessage({
        defaultMessage: 'Salvataggio riuscito',
        description: 'General snackbar data save message',
      }),
      failed: intl.formatMessage({
        defaultMessage: 'Operazione fallita',
        description: 'General snackbar failed message',
      }),
      genericError: intl.formatMessage({
        defaultMessage: 'Qualcosa è andato storto',
        description: 'General snackbar generic error message',
      }),
      successfullRegister: intl.formatMessage({
        defaultMessage: 'Utente registrato con successo',
        description: 'General snackbar successfullRegister message',
      }),
      successfullRecovery: intl.formatMessage({
        defaultMessage:
          'Richiesta inviata con successo, segui le istruzioni ricevute per reimpostare la password.',
        description: 'General snackbar successfullRecovery message',
      }),
    },
    buttons: {
      ok: intl.formatMessage({
        defaultMessage: 'Ok',
        description: 'General button ok',
      }),
      send: intl.formatMessage({
        defaultMessage: 'Invia',
        description: 'General button send',
      }),
      cancel: intl.formatMessage({
        defaultMessage: 'Annulla',
        description: 'General button cancel',
      }),
    },
    webform: {
      common: {
        fieldArrayTitle: intl.formatMessage({
          defaultMessage: 'Compila i campi',
          description: 'General webform common fieldArrayTitle',
        }),
        fieldArrayAdd: intl.formatMessage({
          defaultMessage: 'Aggiungi',
          description: 'General webform common fieldArrayAdd',
        }),
      },
      academy: {
        fieldArrayTitle: intl.formatMessage({
          defaultMessage: 'Inserisci i dati delle persone che vuoi iscrivere.',
          description: 'General webform common fieldArrayTitle',
        }),
        fieldArrayAdd: intl.formatMessage({
          defaultMessage: 'Aggiungi Persona',
          description: 'General webform common fieldArrayAdd',
        }),
      },
    },
  },
});
