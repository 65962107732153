import { CSSProperties } from 'react';

function ShoppingCartIcon({
  fill = 'white',
  style,
}: {
  fill?: string;
  style?: CSSProperties;
}) {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M15.1676 12.75C15.8551 12.75 16.4601 12.3741 16.7717 11.8058L20.0534 5.85663C20.3926 5.25163 19.9526 4.49996 19.2559 4.49996H5.68924L4.82758 2.66663H1.83008V4.49996H3.66341L6.96341 11.4575L5.72591 13.6941C5.05674 14.9225 5.93674 16.4166 7.33008 16.4166H18.3301V14.5833H7.33008L8.33841 12.75H15.1676ZM6.56008 6.33329H17.6976L15.1676 10.9166H8.73258L6.56008 6.33329ZM7.33008 17.3333C6.32174 17.3333 5.50591 18.1583 5.50591 19.1666C5.50591 20.175 6.32174 21 7.33008 21C8.33841 21 9.16341 20.175 9.16341 19.1666C9.16341 18.1583 8.33841 17.3333 7.33008 17.3333ZM16.4967 17.3333C15.4884 17.3333 14.6726 18.1583 14.6726 19.1666C14.6726 20.175 15.4884 21 16.4967 21C17.5051 21 18.3301 20.175 18.3301 19.1666C18.3301 18.1583 17.5051 17.3333 16.4967 17.3333Z"
        fill={fill}
      />
    </svg>
  );
}

export default ShoppingCartIcon;
