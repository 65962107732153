import { Button, styled, alpha } from '@mui/material';

export const Container = styled('div')(
  ({ colorMain }: { colorMain: string }) => ({
    borderRadius: 8,
    padding: 4,
    display: 'inline-flex',
    gap: 4,
    border: `2px solid ${colorMain}`,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  })
);

export const StyledButton = styled(Button)(
  ({
    colorMain,
    colorDark,
    active,
  }: {
    colorMain: string;
    colorDark: string;
    active?: boolean;
  }) => ({
    height: 44,
    padding: 13,
    borderRadius: 8,
    fontSize: '14px',
    color: active ? colorMain : colorDark,
    backgroundColor: active ? alpha(colorMain, 0.12) : 'trasnparent',
  })
);
