import { styled } from '@mui/material';
import { IRootStyleProps } from './LoadingScreen.types';

export const RootStyle = styled('div')<IRootStyleProps>(
  ({ theme, seeThrough }) => ({
    right: 0,
    bottom: 0,
    zIndex: 99999,
    width: '100%',
    height: '100%',
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: seeThrough
      ? 'rgba(0, 0, 0, 0.2)'
      : theme.palette.background.default,
  })
);
