import { Stack, Typography, useTheme } from '@mui/material';
import { ContainerSmall, BoxPreviewSmall } from './VideoCards.style';
import { IVideoCardSmallProps } from './VideoCards.type';
import { useResponsive } from 'hooks';

const VideoCardSmall = ({
  title,
  subtitle,
  preview,
  isClickable = false,
  onClick,
}: IVideoCardSmallProps) => {
  const theme = useTheme();
  const isSmDown = useResponsive('down', 'sm');
  return (
    <ContainerSmall
      style={{
        ...(isClickable && { cursor: 'pointer' }),
        ...(isSmDown && { height: '250px', gap: '20px' }),
      }}
      onClick={() => onClick?.()}
    >
      <Stack gap="5px">
        <div style={{ overflow: 'hidden' }}>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 700,
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              whiteSpace: 'pre-wrap',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {title}
          </Typography>
        </div>
        <div style={{ overflow: 'hidden' }}>
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.grey[600],
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              whiteSpace: 'pre-wrap',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {subtitle}
          </Typography>
        </div>
      </Stack>
      <BoxPreviewSmall image={preview} />
    </ContainerSmall>
  );
};

export { VideoCardSmall };
