import { BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IProductsIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function ProductsIcon({ color = '#2F9D50', style }: IProductsIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M9.58398 1.66675L5.00065 9.16675H14.1673L9.58398 1.66675ZM9.58398 4.86675L11.1923 7.50008H7.96732L9.58398 4.86675ZM14.1673 10.8334C12.0923 10.8334 10.4173 12.5084 10.4173 14.5834C10.4173 16.6584 12.0923 18.3334 14.1673 18.3334C16.2423 18.3334 17.9173 16.6584 17.9173 14.5834C17.9173 12.5084 16.2423 10.8334 14.1673 10.8334ZM14.1673 16.6667C13.0173 16.6667 12.084 15.7334 12.084 14.5834C12.084 13.4334 13.0173 12.5001 14.1673 12.5001C15.3173 12.5001 16.2507 13.4334 16.2507 14.5834C16.2507 15.7334 15.3173 16.6667 14.1673 16.6667ZM2.08398 17.9167H8.75065V11.2501H2.08398V17.9167ZM3.75065 12.9167H7.08398V16.2501H3.75065V12.9167Z"
        fill={color}
      />
    </svg>
  );
}

export default ProductsIcon;
