import { BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IAddIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function AddIcon({ color = 'white', style }: IAddIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M15.8327 10.8337H10.8327V15.8337H9.16602V10.8337H4.16602V9.16699H9.16602V4.16699H10.8327V9.16699H15.8327V10.8337Z"
        fill={color}
      />
    </svg>
  );
}

export default AddIcon;
