// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
  Stack,
  Checkbox,
} from '@mui/material';
import { noCase } from 'change-case';
import { useState } from 'react';
// utils
// components
import { useNavigate } from 'react-router-dom';
import { ITypeNotificationProps } from './index.types';
import {
  GlossaryIcon,
  SideBarSurveyIcon,
  SideBarCertificationsIcon,
  SideBarLibraryIcon,
  SideBarCircolarityIcon,
  SideBarTreesIcon,
  SideBarChartIcon,
  SideBarCopyrightIcon,
  SideBarFinanceIcon,
  SideBarAcademyIcon,
  SideBarSportelloIcon,
  SideBarBookIcon,
} from 'assets/icons';
import { Iconify, MenuPopover, Scrollbar } from 'components';
import { IconButtonAnimate } from 'components/animate';
import * as PATHS from 'constants/paths';
import { useJsonApi, useResponsive, useTranslations } from 'hooks';
import {
  useAuthSelector,
  useGlobalSelector,
  useNotificationsSelector,
  useNotificationsStore,
} from 'stores';
import { fToNow } from 'utils/formatTime';
import UnreadNotificationDot from 'components/NotificationsPopover/UnreadNotificationDot';
// ----------------------------------------------------------------------
export const notificationType: ITypeNotificationProps = {
  portale_certificazioni: (
    <SideBarCertificationsIcon
      style={{ width: '24px', height: '24px' }}
      color="#0A2233"
    />
  ),
  sportello_doganale: (
    <SideBarSportelloIcon
      style={{ width: '24px', height: '24px' }}
      color="#0A2233"
    />
  ),
  glossario: (
    <GlossaryIcon style={{ width: '24px', height: '24px' }} color="#0A2233" />
  ),
  libreria_materiali: (
    <SideBarLibraryIcon
      style={{ width: '24px', height: '24px' }}
      color="#0A2233"
    />
  ),
  circolarità: (
    <SideBarCircolarityIcon
      style={{ width: '24px', height: '24px' }}
      color="#0A2233"
    />
  ),
  bosco_design: (
    <SideBarTreesIcon
      style={{ width: '24px', height: '24px' }}
      color="#0A2233"
    />
  ),
  survey: (
    <SideBarSurveyIcon
      style={{ width: '24px', height: '24px' }}
      color="#0A2233"
    />
  ),
  centro_studi: (
    <SideBarChartIcon
      style={{ width: '24px', height: '24px' }}
      color="#0A2233"
    />
  ),
  proprieta_intelletuale: (
    <SideBarCopyrightIcon
      style={{ width: '24px', height: '24px' }}
      color="#0A2233"
    />
  ),
  formazione: (
    <SideBarAcademyIcon
      style={{ width: '24px', height: '24px' }}
      color="#0A2233"
    />
  ),
  finanziamenti_fisco: (
    <SideBarFinanceIcon
      style={{ width: '24px', height: '24px' }}
      color="#0A2233"
    />
  ),
  biblioteca: (
    <SideBarBookIcon
      style={{ width: '24px', height: '24px' }}
      color="#0A2233"
    />
  ),
};

export default function NotificationsPopover() {
  //hooks
  const i18n = useTranslations();
  const navigate = useNavigate();
  const { notifications } = useNotificationsSelector();
  const { showLoader, hideLoader } = useGlobalSelector();
  const { tokens } = useAuthSelector();
  const { trySubrequest, generateActionEntityBlueprint } = useJsonApi({
    showLoader,
    hideLoader,
  });
  //state

  const [open, setOpen] = useState<HTMLElement | null>(null);

  //functions
  const totalUnRead =
    notifications?.filter(item => item.isUnRead === true).length || 0;

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = async () => {
    showLoader();
    try {
      const isUnreadIds = notifications
        ?.filter(n => !!n.isUnRead)
        .map(n => n.id) as string[];
      const bluePrints = isUnreadIds?.map((notificationId, idx) =>
        generateActionEntityBlueprint({
          entity: 'fla_notification_user',
          bundle: 'fla_notification_user',
          idx: idx + 1,
          id: notificationId,
          action: 'update',
          body: {
            data: {
              type: 'fla_notification_user',
              id: notificationId,
              attributes: {
                notification_status: 'read',
              },
            },
          },
        })
      );
      trySubrequest({
        accessToken: tokens?.access_token as string,
        blueprint: bluePrints,
      });
      useNotificationsStore.setState(prev => ({
        ...prev,
        notifications: prev.notifications?.map(notification => ({
          ...notification,
          isUnRead: false,
        })),
      }));
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40, ml: -2 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            py: 2,
            px: 2.5,
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">
              {i18n.common.header.notifications.title}
            </Typography>
            {totalUnRead > 0 ? (
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {i18n.common.header.notifications.firstPartMessage}{' '}
                {totalUnRead}{' '}
                {totalUnRead > 1
                  ? i18n.common.header.notifications.secondPartMessage.plural
                  : i18n.common.header.notifications.secondPartMessage.singolar}
              </Typography>
            ) : null}
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton
                sx={{ color: '#174E76' }}
                onClick={handleMarkAllAsRead}
              >
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List disablePadding>
            {notifications?.slice(0, 5).map(notification => (
              <NotificationItem
                key={notification.id}
                notification={notification}
              />
            ))}
          </List>
        </Scrollbar>

        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            disableRipple
            variant="text"
            color="primary"
            size="medium"
            onClick={() => {
              setOpen(null);
              navigate(PATHS.NOTIFICATIONS_ROUTE);
            }}
          >
            {i18n.common.buttons.viewAll}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

type NotificationItemProps = {
  id: string;
  title: string;
  description: string;
  link: string | null;
  avatar: string | null;
  type: string;
  createdAt: Date;
  isUnRead: boolean;
};

const NavigationWithoutLinks: { [key: string]: string } = {
  ['portale_certificazioni']: PATHS.CERTIFICATION,
  ['sportello_doganale']: PATHS.CUSTOMHOUSEOFFICE,
  ['libreria_materiali']: PATHS.MATERIALLIBRARY,
  ['circolarità']: PATHS.CIRCULARITYTOOL,
  ['bosco_design']: PATHS.DESIGNWOODS,
  ['survey']: PATHS.GENERALSURVEY,
  ['centro_studi']: PATHS.STUDYCENTER,
  ['proprieta_intellettuale']: PATHS.INTELLECTUALPROPERTY,
  ['formazione']: PATHS.ACADEMY,
  ['finanziamenti_fisco']: PATHS.FINANCEANDTAX,
};

export function NotificationItem({
  notification,
  hasDivider = true,
  hasCheckbox = false,
  onSetCheckedItems,
  showDescription = true,
  checkIfSelected,
}: {
  notification: NotificationItemProps;
  hasDivider?: boolean;
  hasCheckbox?: boolean;
  onSetCheckedItems?: (itemID: string) => void;
  showDescription?: boolean;
  checkIfSelected?: (itemID: string) => boolean;
}) {
  const { title } = renderContent(notification);
  const isSmDown = useResponsive('down', 'sm');

  return (
    <>
      <ListItemButton
        sx={{
          py: 1,
          px: 2.5,
          display: 'flex',
          alignItems: 'flex-start',
          mt: '1px',
          padding: hasCheckbox && isSmDown ? '0.75rem 0px' : '0.75rem 1.25rem',
        }}
      >
        {hasCheckbox ? (
          <Checkbox
            checked={checkIfSelected?.(notification.id)}
            onChange={() => onSetCheckedItems?.(notification.id)}
          />
        ) : null}
        <Stack
          onClick={() => {
            if (notification.link && notification.link !== '') {
              window.open(notification.link);
            } else window.open(NavigationWithoutLinks[notification.type]);
          }}
          flexDirection={'row'}
        >
          <ListItemAvatar>
            <div
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                backgroundColor: '#EFEEEA',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {notificationType[notification.type]}
            </div>
          </ListItemAvatar>
          <ListItemText
            primary={title(showDescription)}
            secondary={
              <Typography
                variant="caption"
                sx={{
                  mt: 0.5,
                  display: 'flex',
                  alignItems: 'center',
                  color: 'text.disabled',
                }}
              >
                <Iconify
                  icon="eva:clock-outline"
                  sx={{ mr: 0.5, width: 16, height: 16 }}
                />
                {fToNow(notification.createdAt)}
              </Typography>
            }
          />
        </Stack>
        {notification.isUnRead ? <UnreadNotificationDot /> : null}
      </ListItemButton>
      {hasDivider ? <Divider /> : null}
    </>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification: NotificationItemProps) {
  const title = (showDescription?: boolean) => (
    <Stack>
      <Typography variant="subtitle2">{notification.title}</Typography>
      <Typography
        component="span"
        variant="caption"
        sx={{ color: 'text.secondary' }}
      >
        {showDescription ? noCase(notification.description) : null}
      </Typography>
    </Stack>
  );

  return {
    avatar: notification.avatar ? (
      <img alt={notification.title} src={notification.avatar} />
    ) : null,
    title,
  };
}
