import { Box, BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IVideoIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function VideoIcon({ color = '#919EAB', style, ...other }: IVideoIconProps) {
  return (
    <Box {...other}>
      <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill={color}
        style={style}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.50065 3.83333V10.5L13.334 7.16667L7.50065 3.83333ZM17.5006 0.5H2.50065C1.58398 0.5 0.833984 1.25 0.833984 2.16667V12.1667C0.833984 13.0833 1.58398 13.8333 2.50065 13.8333H6.66732V15.5H13.334V13.8333H17.5006C18.4173 13.8333 19.1673 13.0833 19.1673 12.1667V2.16667C19.1673 1.25 18.4173 0.5 17.5006 0.5ZM17.5006 12.1667H2.50065V2.16667H17.5006V12.1667Z" />
      </svg>
    </Box>
  );
}

export default VideoIcon;
