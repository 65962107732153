import { CardActionArea, Stack, Typography, useTheme } from '@mui/material';
import { Chip } from '@mui/material';
import { format, getMonth } from 'date-fns';
import getDate from 'date-fns/getDate';
import it from 'date-fns/locale/it';
import {
  ArticleContainer,
  ArticleImage,
  BoxPreview,
  Container,
  Label,
  LabelTextColor,
  MaterialCategoryLabel,
  MaterialCircle,
} from './CardImage.style';
import { ICardImageProps, IDateProps } from './CardImage.type';
import { Iconify } from 'components';
const CardImage = ({
  title,
  subtitle,
  image,
  label,
  isMaterial,
  producer,
  centerTitle,
  sustainabilityCriteria,
  circlesOnMouseEnter,
  circlesOnMouseLeave,
  categoriesLabel,
  date,
  onClick,
  id,
  isCardFullWidth = false,
}: ICardImageProps) => {
  //hooks
  const theme = useTheme();

  //renders
  const renderDate = (dateObject: IDateProps) => {
    const startMonth = getMonth(dateObject?.start);
    const endMonth = getMonth(dateObject?.end);

    if (startMonth === endMonth) {
      return (
        <Typography
          variant="caption"
          style={{ color: theme.palette.grey[500], fontWeight: 400 }}
        >
          {getDate(dateObject?.start)},&nbsp;
          {getDate(dateObject?.end)}&nbsp;
          {format(dateObject?.start, 'MMMM', { locale: it })}
        </Typography>
      );
    } else {
      return (
        <Typography
          variant="caption"
          style={{ color: theme.palette.grey[500], fontWeight: 400 }}
        >
          {getDate(dateObject?.start)}&nbsp;
          {format(dateObject?.start, 'MMMM', { locale: it })}
          &nbsp;-&nbsp;
          {getDate(dateObject?.end)}&nbsp;
          {format(dateObject?.end, 'MMMM', { locale: it })}
        </Typography>
      );
    }
  };

  if (label === 'articolo') {
    return (
      <ArticleContainer>
        <CardActionArea>
          <Stack
            alignItems="flex-start"
            justifyContent="flex-start"
            gap="20px"
            sx={{ padding: '30px' }}
          >
            <Chip variant="filled" color="info" label="24 Novembre 2022" />
            <Typography variant="subtitle1">{title}</Typography>
            <ArticleImage image="https://www.almalaboris.com/images/blog/approfondimenti/core-business-cos-e-significato.jpg">
              <Label type={label}>{label}</Label>
            </ArticleImage>
            <Typography variant="body1" sx={{ color: theme.palette.grey[600] }}>
              {subtitle}
            </Typography>
          </Stack>
        </CardActionArea>
      </ArticleContainer>
    );
  }

  const LabelIconify = {
    webinar: 'material-symbols:play-circle-outline',
    document: 'ic:outline-insert-drive-file',
    convention: 'ic:outline-insert-drive-file',
    video_tutorial: 'material-symbols:play-circle-outline',
    course: 'material-symbols:article-outline',
  };

  const labelName = {
    webinar: 'Webinar',
    document: 'Documento',
    convention: 'Documento',
    video_tutorial: 'Video Tutorial',
    course: 'Corso',
  };

  const criteriaMapping: {
    [key: string]: 'proc' | 'circ' | 'cam' | 'tox' | 'lca' | 'circ';
  } = {
    '#68ABDE': 'cam',
    '#F36878': 'lca',
    '#FFC533': 'circ',
    '#AE8BF9': 'tox',
    '#72D58F': 'proc',
  };

  return (
    <Container isCardFullWidth={isCardFullWidth}>
      <CardActionArea onClick={() => onClick?.(id)}>
        <BoxPreview image={image}>
          {/* {label === 'webinar' ? (
            <Iconify
              icon="material-symbols:play-circle-outline"
              style={{ fontSize: '42px', color: theme.palette.grey[500] }}
            />
          ) : null} */}
          {isMaterial && categoriesLabel?.length ? (
            <Stack
              flexDirection={'row-reverse'}
              alignItems={'center'}
              justifyContent={'flex-end'}
              gap={'5px'}
              style={{ position: 'absolute', top: '23px', right: '19px' }}
            >
              {categoriesLabel.map((label, idx) => (
                <MaterialCategoryLabel key={idx}>
                  <Typography
                    variant="body2"
                    style={{
                      color: '#4D5A49',
                    }}
                  >
                    {label}
                  </Typography>
                </MaterialCategoryLabel>
              ))}
            </Stack>
          ) : (
            <Label type={label}>
              {label ? (
                <Iconify
                  icon={`${LabelIconify[label]}`}
                  style={{
                    fontSize: '14px',
                    color: label ? LabelTextColor[label] : undefined,
                    marginRight: 8,
                    fill: 'none',
                  }}
                />
              ) : null}
              {label && labelName[label]}
            </Label>
          )}
        </BoxPreview>
        <Stack
          sx={{
            padding: isMaterial ? '32px' : '15px 20px',
            ...(isMaterial && {
              paddingTop: '12px',
            }),
            minHeight: centerTitle ? 84 : 138,
          }}
          gap="10px"
        >
          {typeof date === 'string' && label !== 'course' ? (
            <Typography
              variant="subtitle1"
              style={{
                color: '#C4CDD5',
              }}
            >
              {date}
            </Typography>
          ) : null}
          <Typography
            variant={
              centerTitle || label === 'course' || isMaterial
                ? 'subtitle1'
                : 'body1'
            }
            sx={centerTitle ? { textAlign: 'center' } : {}}
            style={{
              display: '-webkit-box',
              height: 45,
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {title}
          </Typography>
          <Typography variant="body1" sx={{ color: theme.palette.grey[500] }}>
            {subtitle}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontWeight: '400',
              color: theme.palette.grey[600],
              marginTop: '0px',
              marginBottom: '5px',
            }}
          >
            {producer}
          </Typography>
          {isMaterial ? (
            <>
              <Typography
                variant="body1"
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  color: theme.palette.grey[500],
                  lineHeight: 0,
                }}
              >
                Caratteristiche di sostenibilità
              </Typography>
              <Stack flexDirection="row" gap="8px" sx={{ mt: 1.8 }}>
                {sustainabilityCriteria?.map((el, index) => (
                  <MaterialCircle
                    key={`material${index}`}
                    color={el.color}
                    onMouseEnter={e => {
                      circlesOnMouseEnter?.(
                        e,
                        criteriaMapping[el.color],
                        el.color
                      );
                    }}
                    onMouseLeave={circlesOnMouseLeave}
                  />
                ))}
              </Stack>
            </>
          ) : null}
          {label === 'course' ? (
            <Stack flexDirection="row" gap="10px" alignItems="center">
              <Iconify
                icon="material-symbols:calendar-today-outline"
                sx={{
                  fontSize: '36px',
                  color: theme.palette.grey[500],
                  marginLeft: '-5px',
                }}
              />
              <Stack>
                {date && typeof date !== 'string' && date.length ? (
                  date.map(el => renderDate(el))
                ) : (
                  <Typography
                    variant="caption"
                    style={{
                      color: theme.palette.grey[500],
                      fontWeight: 400,
                    }}
                  >
                    {date}
                  </Typography>
                )}
              </Stack>
            </Stack>
          ) : null}
        </Stack>
      </CardActionArea>
    </Container>
  );
};

export { CardImage };
