import { Box, BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IGlossaryIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function GlossaryIcon({
  color = '#e28352',
  style,
  ...other
}: IGlossaryIconProps) {
  return (
    <Box {...other}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 75" style={style}>
        <path
          id="Tracciato_476"
          data-name="Tracciato 476"
          d="M52.5,0H7.5A7.522,7.522,0,0,0,0,7.5v60A7.522,7.522,0,0,0,7.5,75h45A7.522,7.522,0,0,0,60,67.5V7.5A7.522,7.522,0,0,0,52.5,0ZM18.75,7.5h7.5V26.25L22.5,23.438,18.75,26.25Zm33.75,60H7.5V7.5h3.75V41.25L22.5,32.813,33.75,41.25V7.5H52.5Z"
          fill={color}
        />
      </svg>
    </Box>
  );
}

export default GlossaryIcon;
