import { CSSProperties } from 'react';
// @mui

interface IIconProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function FooterLogoIcon({ color = 'white', style }: IIconProps) {
  return (
    <svg
      version="1.1"
      id="Livello_2_00000116930680281360829160000009917388022325019566_"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 914.3 208.5"
      style={style}
    >
      <g id="Livello_1-2_00000049939909255219802150000015464009234664999351_">
        <g>
          <g>
            <g>
              <polygon
                className="st0"
                fill={color}
                points="695.3,0.4 666.8,147.6 680.8,147.6 709.4,0.4 				"
              />
              <g>
                <path
                  className="st0"
                  fill={color}
                  d="M787.4,147.6l-1.3-12.4h-0.4c-5.7,9.5-15.3,14.5-28.2,14.5c-23.5,0-37.4-15.4-37.4-41.8V48.4h33.6v55.5
              c0,10.5,4.8,16,13,16c9.7,0,14.7-6.1,14.7-17.9V48.4h33.6v99.2L787.4,147.6L787.4,147.6z"
                />
                <path
                  className="st0"
                  fill={color}
                  d="M907.4,52.6V82h-1.3c-6.3-6.3-16.6-9.9-28.6-9.9c-8.6,0-13.2,2.5-13.2,7.2c0,3.4,2.9,5.3,14.3,6.1
              c25,1.9,35.7,11.1,35.7,31.1c0,21-15.3,33.2-41.8,33.2c-16.6,0-29.2-2.9-37.2-8.6v-29.8h1.3c8.2,8.2,19.8,12.4,34.5,12.4
              c8.2,0,12.8-2.5,12.8-7.2c0-4.2-3.4-6.1-15.8-7.2c-24.2-2.1-34.3-10.7-34.3-29c0-21,16.6-34,42.9-34
              C888.7,46.3,899.8,48.6,907.4,52.6"
                />
                <path
                  className="st0"
                  fill={color}
                  d="M643.6,98.3c0,22.5-15.3,38.2-36.9,38.5c-21.6-0.2-36.9-15.9-36.9-38.5s15.3-38.4,36.9-38.7
              C628.3,59.9,643.6,75.8,643.6,98.3 M657.9,98.3c0-29.8-20.8-51.3-49.8-51.3c-0.6,0-3.1,0-3.6,0v0c-10.7,0.2-20.2,3.2-27.9,8.5
              l1.8-9.2h-14.1L535.6,192h14.1l13-65.7c8.4,14.1,23.3,22.8,41.7,23.1v0h3.6C637.1,149.4,657.9,128.2,657.9,98.3"
                />
              </g>
            </g>
            <g>
              <path
                className="st0"
                fill={color}
                d="M610.1,190.5c0,5.2-3.5,7.9-8.3,7.9h-6.6v9.4h-3.7v-25.1H602C606.7,182.6,610.1,185.3,610.1,190.5
            L610.1,190.5z M606.3,190.5c0-2.8-1.7-4.3-4.3-4.3h-6.8v8.7h6.8C604.8,194.8,606.3,193.2,606.3,190.5z"
              />
              <path
                className="st0"
                fill={color}
                d="M621.1,207.8h-3.8v-25.1h3.8V207.8z"
              />
              <path
                className="st0"
                fill={color}
                d="M648.9,197.3c0,7-3.4,10.9-9.7,10.9s-9.5-4-9.5-11v-14.6h3.8v14.5c0,4.7,2,7.3,5.8,7.3s5.8-2.4,5.8-7v-14.7
            h3.8L648.9,197.3L648.9,197.3z M641.6,181.5h-3l-5.9-5.8h5L641.6,181.5L641.6,181.5z"
              />
              <path
                className="st0"
                fill={color}
                d="M687.3,203.3c-1.8,3.1-4.8,4.8-8.7,4.8c-6.5,0-10.6-5.4-10.6-13s4.1-13,10.6-13c4.1,0,6.8,1.6,8.5,4.7
            l-3.3,2.1c-1.3-2.1-2.6-3-5.2-3c-4.4,0-6.8,3.6-6.8,9.2s2.4,9.2,6.8,9.2c2.6,0,4.2-1.1,5.5-3.1L687.3,203.3L687.3,203.3z"
              />
              <path
                className="st0"
                fill={color}
                d="M714.3,195.1c0,7.8-4.4,13-10.8,13s-10.7-5.1-10.7-12.9s4.4-13,10.7-13S714.3,187.3,714.3,195.1
            L714.3,195.1z M710.4,195.2c0-5.7-3-9.3-7-9.3s-6.8,3.5-6.8,9.2s2.9,9.3,6.9,9.3S710.5,200.9,710.4,195.2L710.4,195.2z"
              />
              <path
                className="st0"
                fill={color}
                d="M744.2,207.8h-3.7v-13.3c0-1.5,0-3.7,0.1-4.4c-0.3,0.7-1,2.5-1.6,3.9l-6.1,14.1l-6-14.1
            c-0.6-1.4-1.3-3.2-1.6-3.9c0,0.7,0.1,2.9,0.1,4.4v13.3h-3.6v-25.1h3.9l5.6,13.4c0.6,1.4,1.4,3.3,1.7,4.3c0.4-1,1.1-2.8,1.7-4.3
            l5.5-13.4h4L744.2,207.8L744.2,207.8z"
              />
              <path
                className="st0"
                fill={color}
                d="M771.5,190.5c0,5.2-3.5,7.9-8.3,7.9h-6.6v9.4h-3.7v-25.1h10.5C768.1,182.6,771.5,185.3,771.5,190.5
            L771.5,190.5z M767.7,190.5c0-2.8-1.7-4.3-4.3-4.3h-6.8v8.7h6.8C766.3,194.8,767.7,193.2,767.7,190.5z"
              />
              <path
                className="st0"
                fill={color}
                d="M795.3,207.8h-16.7v-25.1h16.2v3.7h-12.4v6.2h7.2v3.7h-7.2v7.9h13V207.8L795.3,207.8z"
              />
              <path
                className="st0"
                fill={color}
                d="M819.6,186.3h-7.3v21.4h-3.8v-21.4h-7.3v-3.7h18.4L819.6,186.3L819.6,186.3z"
              />
              <path
                className="st0"
                fill={color}
                d="M830.7,207.8h-3.8v-25.1h3.8V207.8z"
              />
              <path
                className="st0"
                fill={color}
                d="M856.4,186.3H849v21.4h-3.8v-21.4h-7.3v-3.7h18.4L856.4,186.3L856.4,186.3z"
              />
              <path
                className="st0"
                fill={color}
                d="M867.4,207.8h-3.8v-25.1h3.8V207.8z"
              />
              <path
                className="st0"
                fill={color}
                d="M896,182.6l-8.6,25.1h-4l-8.7-25.1h4.1l5.2,16c0.5,1.5,1.2,3.7,1.4,4.6c0.2-0.9,0.9-3.2,1.4-4.6l5.2-16H896
            L896,182.6z"
              />
              <path
                className="st0"
                fill={color}
                d="M907.1,207.8h-3.8v-25.1h3.8V207.8z"
              />
            </g>
          </g>
          <g id="Livello_1-2">
            <g id="Raggruppa_85">
              <g id="Tracciato_182">
                <path
                  className="st0"
                  fill={color}
                  d="M2.5,180.2v27.8H7v-12.4h12.4v-3.8H7V184h13.7v-3.8L2.5,180.2L2.5,180.2z"
                />
              </g>
              <g id="Tracciato_183">
                <path
                  className="st0"
                  fill={color}
                  d="M24.6,180.2v27.8h18.5v-3.8h-14v-8.7h12.7v-3.8H29.2V184h14v-3.8L24.6,180.2L24.6,180.2z"
                />
              </g>
              <g id="Tracciato_184">
                <path
                  className="st0"
                  fill={color}
                  d="M47.6,208.1v-27.8h9.1c10.1,0,12.9,6,12.9,13.9s-2.8,13.9-12.9,13.9H47.6
              C47.6,208.1,47.6,208.1,47.6,208.1z M52.2,204.2h4.6c6.1,0,8-4,8-10.1s-2-10.1-8-10.1h-4.6V204.2L52.2,204.2L52.2,204.2z"
                />
              </g>
              <g id="Tracciato_185">
                <path
                  className="st0"
                  fill={color}
                  d="M91.3,195.6H78.6v8.7h14v3.8H74v-27.8h18.5v3.8h-14v7.7h12.7L91.3,195.6L91.3,195.6L91.3,195.6z"
                />
              </g>
              <g id="Tracciato_186">
                <path
                  className="st0"
                  fill={color}
                  d="M101.6,208.1H97v-27.8h9.9c7.9,0,10.4,3.2,10.4,8.3c0.4,3.4-1.7,6.5-5,7.4l6.4,12.1h-5.3l-5.5-11.3h-6.3
              L101.6,208.1L101.6,208.1L101.6,208.1z M101.6,193h6c4.2,0,4.8-2.2,4.8-4.4s-0.6-4.4-4.8-4.4h-6V193L101.6,193z"
                />
              </g>
              <g id="Tracciato_187">
                <path
                  className="st0"
                  fill={color}
                  d="M126.8,204.2h13.3v3.8h-17.8v-27.8h4.6L126.8,204.2L126.8,204.2L126.8,204.2z"
                />
              </g>
              <g id="Tracciato_188">
                <path
                  className="st0"
                  fill={color}
                  d="M160.8,195.6h-12.7v8.7h14v3.8h-18.5v-27.8h18.5v3.8h-14v7.7h12.7L160.8,195.6L160.8,195.6L160.8,195.6z"
                />
              </g>
              <g id="Tracciato_189">
                <path
                  className="st0"
                  fill={color}
                  d="M166.2,194.1c0-8.8,3.3-14.4,12.5-14.4c3-0.1,6,0.4,8.7,1.4v4.1c-2.6-1.1-5.4-1.6-8.2-1.6
              c-6.3,0-8.4,3.9-8.4,10.5s2.2,10.5,8.4,10.5c1.5,0,2.9-0.1,4.4-0.5v-7.6h-5.4v-3.8h10v14.1c-3.1,1.2-6.3,1.8-9.6,1.7
              C169.5,208.5,166.2,202.9,166.2,194.1"
                />
              </g>
              <g id="Tracciato_190">
                <path
                  className="st0"
                  fill={color}
                  d="M210.7,208.1l-13.3-19.8l0.1,19.8h-4.5v-27.8h4.1l13.3,19.8l-0.1-19.8h4.5v27.8H210.7z"
                />
              </g>
              <g id="Tracciato_191">
                <path
                  className="st0"
                  fill={color}
                  d="M219.4,194.1c0-7.9,2-14.4,11.8-14.4s11.8,6.5,11.8,14.4s-1.9,14.4-11.8,14.4S219.4,202,219.4,194.1
               M224.1,194.1c0,6.6,1.3,10.6,7.1,10.6s7.1-4,7.1-10.6s-1.3-10.6-7.1-10.6S224.1,187.6,224.1,194.1"
                />
              </g>
              <g id="Tracciato_192">
                <path
                  className="st0"
                  fill={color}
                  d="M262.1,201.3h-9.8l-2.1,6.8h-5l9.7-28.1h4.6l9.7,28.1h-5L262.1,201.3L262.1,201.3L262.1,201.3z
               M257.2,185.6l-3.9,12.3h7.8L257.2,185.6L257.2,185.6L257.2,185.6z"
                />
              </g>
              <g id="Tracciato_193">
                <path
                  className="st0"
                  fill={color}
                  d="M277.2,208.1h-4.6v-27.8h9.9c7.9,0,10.4,3.2,10.4,8.3c0.4,3.4-1.7,6.5-5,7.4l6.4,12.1h-5.3l-5.5-11.3h-6.4
              L277.2,208.1L277.2,208.1L277.2,208.1z M277.2,193h6c4.2,0,4.8-2.2,4.8-4.4s-0.6-4.4-4.8-4.4h-6V193L277.2,193z"
                />
              </g>
              <g id="Tracciato_194">
                <path
                  className="st0"
                  fill={color}
                  d="M302.5,208.1H298v-27.8h9.9c7.9,0,10.4,3.2,10.4,8.3c0.4,3.4-1.7,6.5-5,7.4l6.4,12.1h-5.3l-5.5-11.3h-6.4
              L302.5,208.1L302.5,208.1L302.5,208.1z M302.5,193h6c4.2,0,4.8-2.2,4.8-4.4s-0.6-4.4-4.8-4.4h-6V193L302.5,193z"
                />
              </g>
              <g id="Tracciato_195">
                <path
                  className="st0"
                  fill={color}
                  d="M340.5,195.6h-12.7v8.7h14v3.8h-18.5v-27.8h18.5v3.8h-14v7.7h12.7V195.6L340.5,195.6L340.5,195.6z"
                />
              </g>
              <g id="Tracciato_196">
                <path
                  className="st0"
                  fill={color}
                  d="M346.2,208.1v-27.8h9.1c10.1,0,12.9,6,12.9,13.9s-2.8,13.9-12.9,13.9H346.2z M350.8,204.2h4.6
              c6.1,0,8-4,8-10.1s-2-10.1-8-10.1h-4.6V204.2L350.8,204.2L350.8,204.2z"
                />
              </g>
              <g id="Tracciato_197">
                <path
                  className="st0"
                  fill={color}
                  d="M372.2,194.1c0-7.9,2-14.4,11.8-14.4s11.8,6.5,11.8,14.4s-2,14.4-11.8,14.4S372.2,202,372.2,194.1
               M376.9,194.1c0,6.6,1.3,10.6,7.1,10.6s7.1-4,7.1-10.6s-1.3-10.6-7.1-10.6S376.9,187.6,376.9,194.1"
                />
              </g>
              <g id="Tracciato_198">
                <path
                  className="st0"
                  fill={color}
                  d="M66.1,68.3H36.7v30.5H15.6V18.2h53.7v18.6H36.7v12.9h29.5L66.1,68.3L66.1,68.3L66.1,68.3z"
                />
              </g>
              <g id="Tracciato_199">
                <path
                  className="st0"
                  fill={color}
                  d="M96,80.2h30.3v18.6H75V18.2H96L96,80.2L96,80.2L96,80.2z"
                />
              </g>
              <g id="Tracciato_200">
                <path
                  className="st0"
                  fill={color}
                  d="M153.3,84.2l-3,14.6h-22.2L148,17.7h27.7l19.8,81.1h-22.2l-3-14.6L153.3,84.2L153.3,84.2L153.3,84.2z
               M161.8,37.4l-5.3,30.8h10.5L161.8,37.4L161.8,37.4z"
                />
              </g>
              <g id="Tracciato_201">
                <path
                  className="st0"
                  fill={color}
                  d="M396.4,164.7H0.7V1.8h395.7L396.4,164.7L396.4,164.7L396.4,164.7z M2.1,163.3H395V3.2H2.1L2.1,163.3
              L2.1,163.3L2.1,163.3z"
                />
              </g>
              <g id="Tracciato_202">
                <path
                  className="st0"
                  fill={color}
                  d="M397.1,165.4H0V1.1h397.1V165.4z M1.4,164h394.3H1.4z M2.8,162.6h391.5V3.9H2.8V162.6z"
                />
              </g>
              <g id="Tracciato_203">
                <path
                  className="st0"
                  fill={color}
                  d="M163.5,154.2c-5.8-70.8,46.9-133,117.7-138.9c3.6-0.3,7.2-0.4,10.7-0.4c39.9,0,77.5,18.5,101.9,50.2
              c0,0,0,0,0,0c-16.8-15.4-38.7-23.9-61.4-23.9c-2.5,0-5.1,0.1-7.6,0.3c-23.6,2.3-45.4,13.7-60.8,31.7
              c-16.2,18.2-24.3,42.2-22.4,66.4c0.7,8.4,2.5,16.6,5.5,24.5h6.4c-0.2-0.4-0.3-0.8-0.5-1.1c0,0,0-0.1-0.1-0.1
              c-10.5-27.3,3.1-58,30.5-68.5c4.7-1.8,9.6-2.9,14.7-3.3c1.5-0.1,3-0.2,4.4-0.2c27.5,0.2,50.4,21.2,52.8,48.7
              c0.7,8.4-0.6,16.8-3.8,24.5h6.4c2.9-8,4.1-16.5,3.4-25c-2.6-30.5-28.1-54-58.7-54.1c-1.6,0-3.3,0.1-4.9,0.2
              c-15.6,1.2-30,8.7-40.1,20.6c-3.9,4.6-7,9.7-9.4,15.2c6.1-39.2,37.8-69.3,77.2-73.6c2.4-0.2,4.7-0.3,7.1-0.3
              c21.3,0,41.8,8,57.4,22.4c2.1,1.9,4,3.9,5.9,6V57.9C370.1,27,332,9,291.9,9c-3.7,0-7.5,0.2-11.2,0.5
              c-74.1,6.2-129.2,71.2-123.2,145.3c0.2,3.1,0.6,6.2,1.1,9.3h6C164.1,160.8,163.7,157.6,163.5,154.2"
                />
              </g>
            </g>
          </g>
          <rect
            x="465.7"
            className="st0"
            fill={color}
            width="3"
            height="207.8"
          />
        </g>
      </g>
    </svg>
  );
}

export default FooterLogoIcon;
