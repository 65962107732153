import { makePath } from 'utils/navigation';

export const ROOT = '/';

export const AUTH = makePath(ROOT, 'auth');
export const HOME = makePath(ROOT, 'home');
export const LOGIN = makePath(AUTH, '/login');
export const RECOVERY_PASSWORD = makePath(AUTH, '/recovery-password');
export const RECOVERY_PASSWORD_EMAIL_SENT = makePath(
  AUTH,
  '/recovery-email-sent'
);
export const RESET_PASSWORD = makePath(
  AUTH,
  '/password-reset/:timestamp/:hash'
);
export const PASSWORD_CONFIRMATION = makePath(AUTH, '/password-confirmation');
export const REGISTER_CONFIRMATION = makePath(AUTH, '/register-confirmation');
export const REGISTRATION = makePath(AUTH, '/registration');
export const SET_PASSWORD = makePath(AUTH, '/set-password');

export const DASHBOARD = makePath(ROOT, 'dashboard');
export const PERSONAL_PROFILE = makePath(DASHBOARD, '/personal-profile');
export const FAQ_ROUTE = makePath(DASHBOARD, '/faq');
export const NOTIFICATIONS_ROUTE = makePath(DASHBOARD, '/notifiche');
export const CUSTOMHOUSEOFFICE = makePath(DASHBOARD, '/sportello-doganale');
export const CUSTOMHOUSEOFFICE_SERVICES = makePath(
  CUSTOMHOUSEOFFICE,
  '/tagliando-doganale'
);
export const MATERIALLIBRARY = makePath(DASHBOARD, '/libreria-materiali');
export const MATERIALDETAILS = makePath(MATERIALLIBRARY, '/:materialDetails');
export const MATERIAL_PRODUCER_DETAILS = makePath(
  MATERIALDETAILS,
  '/:producerId'
);
export const CIRCULARITYTOOL = makePath(DASHBOARD, '/tool-di-circolarita');
export const CIRCULARITYTOOL_NEW_SURVEY = makePath(
  CIRCULARITYTOOL,
  '/nuovo-sondaggio'
);
export const DESIGNWOODS = makePath(DASHBOARD, '/bosco-del-design');
export const GENERALSURVEY = makePath(DASHBOARD, '/survey-generale');
export const GENERALSURVEY_NEW = makePath(GENERALSURVEY, '/:surveyName');
export const ACADEMY = makePath(DASHBOARD, '/academy');
export const EDUCATION = makePath(DASHBOARD, '/biblioteca');
export const CART = makePath(DASHBOARD, '/carrello');
export const CART_CHECKOUT = makePath(CART, '/checkout');

/**
 * Academy
 */
export const ACADEMYFLA = makePath(ACADEMY, '/academy-fla');
export const ACADEMYFLA_CATEGORY_ID = makePath(ACADEMYFLA, '/:categoryId');
export const ACADEMYARCHITECTS = makePath(ACADEMY, '/academy-architetti');
export const ACADEMYFLA_ID = makePath(ACADEMYFLA_CATEGORY_ID, '/:id');
export const ACADEMYFLA_ID_FINANCE_REQ = makePath(ACADEMYFLA_ID, '/finanzialo');
export const ACADEMYFLA_ID_MORE_INFO = makePath(ACADEMYFLA_ID, '/info');
export const ACADEMYARCHITECTS_ID = makePath(ACADEMYARCHITECTS, '/:id');
/**
 * Handling Dynamic Routes
 */

// Certifications
export const CERTIFICATION = makePath(DASHBOARD, '/portale-certificazioni');
export const CERTIFICATION_MORE_CONTENT = makePath(
  CERTIFICATION,
  '/:contentType'
);
export const CERTIFICATION_DYNAMIC_BUNDLE = makePath(
  CERTIFICATION,
  '/scheda-certificazione'
);
export const CERTIFICATION_DYNAMIC_ID = makePath(
  CERTIFICATION_DYNAMIC_BUNDLE,
  '/:certificationId'
);

//centro studi
export const STUDYCENTER = makePath(DASHBOARD, '/centro-studi');
export const STUDYCENTER_CATEGORIES_LIST = makePath(STUDYCENTER, '/prodotti');
export const STUDYCENTER_PRODUCT_CATEGORY = makePath(
  STUDYCENTER_CATEGORIES_LIST,
  '/:categoryId'
);
export const STUDYCENTER_DETAIL = makePath(
  STUDYCENTER_PRODUCT_CATEGORY,
  '/:id'
);

// Finanza
export const FINANCEANDTAX = makePath(DASHBOARD, '/finanza');
export const FINANCE_LIST = makePath(FINANCEANDTAX, '/assistenza');
export const FINANCE_WEBINAR_LIST = makePath(FINANCEANDTAX, '/approfondimenti');
export const FINANCE_DETAIL = makePath(FINANCE_LIST, '/:id');
export const FINANCE_WEBINAR_DETAIL = makePath(FINANCE_WEBINAR_LIST, '/:id');

//  Proprietà Intellettuale
export const INTELLECTUALPROPERTY = makePath(
  DASHBOARD,
  '/proprieta-intellettuale'
);
export const INTELLECTUAL_LIST = makePath(
  INTELLECTUALPROPERTY,
  '/approfondimenti'
);
export const INTELLECTUAL_CONVENTION = makePath(
  INTELLECTUALPROPERTY,
  '/convenzione'
);
export const INTELLECTUAL_COURSE = makePath(INTELLECTUALPROPERTY, '/corso');
export const INTELLECTUAL_ON_DEMAND_LIST = makePath(
  INTELLECTUALPROPERTY,
  '/on-demand'
);
export const INTELLECTUAL_DETAIL = makePath(INTELLECTUAL_LIST, '/:id');
export const INTELLECTUAL_ON_DEMAND_DETAIL = makePath(
  INTELLECTUAL_ON_DEMAND_LIST,
  '/:id'
);

export const BASE_FO =
  process.env.REACT_APP_FO_LINK || 'https://fla-portal-dev.nautes.eu';

export const DEV_NAMESPACE =
  process.env.REACT_APP_DEV_NAMESPACE || '.nautes.eu';

// export const ITALIAN_WOOD_STOCK = 'https://www.bmti.it/mercati-sperimentali/';
export const ITALIAN_WOOD_STOCK = makePath(
  DASHBOARD,
  '/borsa-italiana-del-legno'
);
export const CERTIFICATIONS_FSC_PEFC =
  'https://mycert.pandp.it/index.php/auth/login';

// Contents
export const CONTENT_TYPE = makePath(CUSTOMHOUSEOFFICE, '/:contentType');
export const ACADEMY_CONTENT_TYPE = makePath(ACADEMY, '/:contentType');
export const INTELLECTUALPROPERTY_CONTENT_TYPE = makePath(
  INTELLECTUALPROPERTY,
  '/:contentType'
);
export const CONTENT_MASTER = makePath(CUSTOMHOUSEOFFICE, '/master');
export const CONTENT_DETAIL_ID = makePath(
  DASHBOARD,
  '/:context/:contentType/:contentId'
);
export const CONTENT_EXPERT = makePath(
  CUSTOMHOUSEOFFICE,
  "/parlane-con-l'esperto"
);
