import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { StyledPaper } from './SendFeedback.style';
import { RHFWebform } from 'components/form';
import { useTranslations, useJsonApi, useSnackbar } from 'hooks';

import { useGlobalStore } from 'stores';
import { IWebFormRes, WebformIdEnum } from 'types/webform';

const SendFeedback = () => {
  //hooks
  const theme = useTheme();
  const i18n = useTranslations();
  const { errorSnackbar } = useSnackbar();
  const { showLoader, hideLoader } = useGlobalStore();
  const { getWebform, submitWebform } = useJsonApi({ showLoader, hideLoader });
  //states
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [webform, setWebForm] = useState<IWebFormRes>({});

  useEffect(() => {
    const handleWebform = async () => {
      if (!WebformIdEnum.FEEDBACK) return;
      await getWebform({
        useLoader: true,
        setData: setWebForm,
        body: {
          webformId: WebformIdEnum.FEEDBACK,
        },
      });
    };
    handleWebform();
  }, []);

  const onSubmit = async (data: { [key: string]: string }) => {
    const input: { [key: string]: string } = {
      webform_id: WebformIdEnum.FEEDBACK,
    };
    Object.entries(data)
      .filter(([, v]) => !!v)
      .map(([k, v]) => {
        input[k] = v;
      });
    const res = await submitWebform({
      useLoader: true,
      body: input,
    });
    if (res) {
      setShowDialog(true);
    } else {
      errorSnackbar(i18n.general.errors.generic);
    }
  };

  return (
    <>
      <Dialog open={showDialog} sx={{ '& .MuiPaper-root': { width: '450px' } }}>
        <DialogTitle sx={{ paddingBottom: '20px' }}>
          <Typography variant="h5">
            {i18n.auth.personalProfile.feedbackTab.dialog.title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" sx={{ color: theme.palette.grey[600] }}>
            {i18n.auth.personalProfile.feedbackTab.dialog.subtitle}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => setShowDialog(false)}
          >
            {i18n.auth.personalProfile.feedbackTab.dialog.closeButton}
          </Button>
        </DialogActions>
      </Dialog>
      <StyledPaper>
        <Typography variant="body1" sx={{ marginBottom: '20px' }}>
          {i18n.auth.personalProfile.feedbackTab.text}
        </Typography>
        {Object.keys(webform).length ? (
          <>
            <Typography
              variant="body1"
              sx={{ fontWeight: '700', marginBottom: '-20px' }}
            >
              {i18n.auth.personalProfile.feedbackTab.title}
            </Typography>
            <RHFWebform
              webform={webform}
              onSubmit={onSubmit}
              buttonTxt="Invia feedback"
              color="primary"
            />
          </>
        ) : null}
      </StyledPaper>
    </>
  );
};

export { SendFeedback };
