import { useCallback } from 'react';
import { create } from 'zustand';
import { IGeneralSurveyStore } from 'types/stores';

const useStore = create<IGeneralSurveyStore>(
  (): IGeneralSurveyStore => ({
    selectedSurveyWebformId: '',
    selectedSurveyId: '',
    selectedSurveyStatus: undefined,
    allSurveys: [],
    submittedData: undefined,
    selectedSurveyIsDraft: false,
  })
);

const useGeneralSurveySelector = () =>
  useStore(useCallback(state => state, []));

export { useGeneralSurveySelector, useStore as useGeneralSurveyStore };
