import { useState } from 'react';
import { IModalConfig, IUseModalReturn } from './useModal.types';

const defaultConfig: IModalConfig = { open: false, fullPageOnMobile: false };

const useModal = (config?: IModalConfig): IUseModalReturn => {
  const [modalConfig, resetModalConfig] = useState<IModalConfig>({
    ...defaultConfig,
    ...config,
  });

  const toggleModal = () =>
    resetModalConfig(prev => ({ ...prev, open: !prev.open }));

  const showModal = () => resetModalConfig(prev => ({ ...prev, open: true }));

  const hideModal = () => resetModalConfig(prev => ({ ...prev, open: false }));

  const setModalConfig = (config: Partial<IModalConfig>) =>
    resetModalConfig(prev => ({ ...prev, ...config }));

  const extendConfig = {
    ...modalConfig,
    handleClose: hideModal,
  };

  return {
    toggleModal,
    showModal,
    hideModal,
    modalConfig: extendConfig,
    setModalConfig,
    resetModalConfig,
  };
};
export { useModal };
