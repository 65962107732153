import { CSSProperties } from 'react';
// @mui

interface IIconProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function FooterITAIcon({ color = 'white', style }: IIconProps) {
  return (
    <svg
      width="63"
      viewBox="0 0 63 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g clipPath="url(#clip0_1410_6631)">
        <path
          d="M60.1476 6.60559H57.5412L58.6961 4.84946C58.8588 4.59407 58.7801 4.25707 58.5255 4.09383C58.2682 3.93059 57.9244 4.00431 57.7617 4.2597L56.0818 6.82676L56.0004 7.02949C55.9663 7.17429 55.9847 7.32964 56.0739 7.46128L57.7512 10.0257C57.9165 10.2811 58.263 10.3522 58.5176 10.1916C58.7748 10.0257 58.8483 9.69133 58.6856 9.43594L57.5438 7.70087H60.1528C60.4573 7.70087 60.704 7.45601 60.704 7.15323C60.704 6.85045 60.4573 6.60823 60.1528 6.60823"
          fill={color}
        />
        <path
          d="M55.3916 7.15323L56.9009 4.84946C57.0663 4.59407 56.9928 4.2597 56.7329 4.09383C56.4783 3.93059 56.1318 4.00431 55.9691 4.2597L54.2866 6.82676L54.2105 7.03212C54.1711 7.1743 54.1921 7.32964 54.2787 7.46128L55.956 10.0283C56.124 10.2811 56.4678 10.3522 56.725 10.1916C56.9796 10.0283 57.0558 9.69133 56.8878 9.43857L55.3916 7.15323Z"
          fill={color}
        />
        <path
          d="M54.9545 4.09388C55.2117 4.25712 55.2852 4.59413 55.1225 4.84952L51.7208 10.0205C51.5554 10.2732 51.2116 10.347 50.9517 10.1837C50.6945 10.0205 50.6236 9.68348 50.7864 9.43072L54.1881 4.26238C54.3508 4.007 54.6947 3.93327 54.9519 4.09651"
          fill={color}
        />
        <path
          d="M55.6714 13.7329C52.0492 13.7329 49.1016 10.7761 49.1016 7.14278C49.1016 3.5094 52.0492 0.550049 55.6714 0.550049C59.2936 0.550049 62.2412 3.50677 62.2412 7.14278C62.2412 10.7788 59.2962 13.7329 55.6714 13.7329ZM55.6714 1.28725C52.4534 1.28725 49.8339 3.91487 49.8339 7.14278C49.8339 10.3707 52.4534 12.9957 55.6714 12.9957C58.8894 12.9957 61.5089 10.3707 61.5089 7.14278C61.5089 3.91487 58.8894 1.28725 55.6714 1.28725Z"
          fill={color}
        />
        <path
          d="M0.242188 1.25564V0.5H10.6206V1.25564H10.1324C9.01684 1.25564 8.28715 1.34515 7.94068 1.52682C7.5942 1.70849 7.36585 2.0455 7.24773 2.53521C7.12962 3.0302 7.07187 4.19919 7.07187 6.05011V16.9739C7.07187 18.7248 7.11387 19.8727 7.19786 20.4125C7.28186 20.9548 7.50496 21.3577 7.86718 21.6209C8.2294 21.8816 8.92497 22.0159 9.95126 22.0159H10.6206V22.7715H0.242188V22.0159H0.911507C1.8853 22.0159 2.55462 21.8948 2.92734 21.6552C3.30006 21.4103 3.53891 21.0364 3.63866 20.5283C3.7384 20.0175 3.78827 18.8354 3.78827 16.9739V6.05011C3.78827 4.27818 3.7384 3.13288 3.63866 2.61683C3.53891 2.10342 3.31581 1.74798 2.96934 1.55052C2.62287 1.35569 1.8748 1.25564 0.725148 1.25564H0.242188Z"
          fill={color}
        />
        <path
          d="M44.366 14.6728H36.0402L35.4654 16.0629C34.6255 18.085 34.2081 19.4014 34.2081 20.0149C34.2081 20.6731 34.4286 21.1734 34.8774 21.513C35.3237 21.8553 35.8959 22.0238 36.5967 22.0238V22.7689H30.0898V22.0238C31.0584 21.8737 31.7802 21.5314 32.2527 20.997C32.7251 20.4651 33.2973 19.3382 33.964 17.6269C34.0296 17.4663 34.2921 16.837 34.7488 15.7444L41.1087 0.5H41.7281L49.0591 17.9428L50.0644 20.244C50.2902 20.7626 50.592 21.1707 50.97 21.4682C51.348 21.7631 51.9202 21.9527 52.6787 22.0264V22.7715H44.0878V22.0264C45.143 22.0264 45.8569 21.9501 46.227 21.7921C46.5971 21.6367 46.7835 21.3261 46.7835 20.86C46.7835 20.6231 46.5367 19.9175 46.0433 18.7379L44.3634 14.6728H44.366ZM44.0668 13.9435L40.2084 4.58096L36.3447 13.9435H44.0668Z"
          fill={color}
        />
        <path
          d="M20.9391 20.5415C20.8315 21.0522 20.5979 21.4261 20.2331 21.6657C19.8682 21.9079 19.2094 22.029 18.2592 22.029H17.1096V22.7847H22.3959V0.5H11.7812V5.84738H12.5372C12.7655 4.43352 13.0989 3.38037 13.5294 2.69055C13.9598 2.00074 14.4218 1.58475 14.9126 1.44784C15.4035 1.31356 16.3667 1.24247 17.7999 1.24247H21.094V16.9818C21.094 18.8433 21.0415 20.0333 20.9417 20.5415"
          fill={color}
        />
        <path
          d="M24.9633 20.5388C25.0657 21.0496 25.3019 21.4235 25.6667 21.6657C26.0342 21.9079 26.693 22.029 27.6432 22.029H28.7928V22.782H23.5039V0.5H34.108L34.1212 5.84737H33.3678C33.1369 4.43352 32.8061 3.38037 32.3757 2.69055C31.9452 2.00074 31.4806 1.58738 30.9898 1.44784C30.499 1.31093 29.5383 1.24247 28.1052 1.24247H24.8084V16.9818C24.8084 18.8433 24.8609 20.0307 24.9659 20.5415"
          fill={color}
        />
        <path
          d="M0.242188 32.4211V28.427H1.04537V32.4211H0.242188Z"
          fill={color}
        />
        <path
          d="M3.97048 28.427V29.0879H3.10167V32.4211H2.29849V29.0879H1.42969V28.427H3.9731H3.97048Z"
          fill={color}
        />
        <path
          d="M4.78691 28.427H5.77908L6.83162 32.4211H5.99694L5.8132 31.5759H4.75279L4.56906 32.4211H3.73438L4.78691 28.427ZM4.8919 30.9151H5.67409L5.28825 29.1194H5.27512L4.88928 30.9151H4.8919Z"
          fill={color}
        />
        <path
          d="M7.11328 32.4211V28.427H7.91646V31.7629H9.3391V32.4211H7.11328Z"
          fill={color}
        />
        <path
          d="M9.73438 32.4211V28.427H10.5376V32.4211H9.73438Z"
          fill={color}
        />
        <path
          d="M11.9106 28.427H12.9054L13.958 32.4211H13.1207L12.9369 31.5759H11.8765L11.6928 32.4211H10.8555L11.908 28.427H11.9106ZM12.0182 30.9151H12.7978L12.4146 29.1194H12.4041L12.0182 30.9151Z"
          fill={color}
        />
        <path
          d="M15.1635 28.427L16.1347 31.1626H16.1452V28.427H16.8827V32.4211H15.9693L14.9876 29.625H14.9771V32.4211H14.2422V28.427H15.1687H15.1635Z"
          fill={color}
        />
        <path
          d="M21.1137 28.427V29.0879H20.2449V32.4211H19.4417V29.0879H18.5703V28.427H21.1137Z"
          fill={color}
        />
        <path
          d="M21.457 28.427H22.94C23.5989 28.427 24.0004 28.7745 24.0004 29.4565C24.0004 29.9857 23.7905 30.3437 23.339 30.4412V30.4517C23.885 30.5228 23.9689 30.8256 23.9794 31.6707C23.9847 32.0893 24.0083 32.3105 24.1579 32.3869V32.4211H23.2891C23.2104 32.3105 23.1946 32.1578 23.1894 32.0025L23.1658 31.2337C23.1553 30.9203 23.0162 30.7308 22.6933 30.7308H22.2628V32.4211H21.4597V28.427H21.457ZM22.2602 30.1726H22.5936C22.9794 30.1726 23.1973 30.0041 23.1973 29.5749C23.1973 29.2063 23.003 29.0194 22.6408 29.0194H22.2628V30.1726H22.2602Z"
          fill={color}
        />
        <path
          d="M25.3364 28.427H26.3286L27.3837 32.4211H26.5464L26.3627 31.5759H25.3023L25.1186 32.4211H24.2812L25.3364 28.427ZM25.4414 30.9151H26.2236L25.8377 29.1194H25.8272L25.4414 30.9151Z"
          fill={color}
        />
        <path
          d="M27.6992 28.427H29.0142C30.1298 28.427 30.3293 29.1932 30.3293 30.3674C30.3293 31.7866 30.0274 32.4211 28.9644 32.4211H27.6992V28.427ZM28.5024 31.826H28.8594C29.4185 31.826 29.5287 31.4732 29.5287 30.3885C29.5287 29.4828 29.4552 29.0168 28.8436 29.0168H28.5024V31.8234V31.826Z"
          fill={color}
        />
        <path
          d="M33.0891 28.427V29.0879H31.6665V30.0278H33.0051V30.6886H31.6665V31.7629H33.1442V32.4211H30.8633V28.427H33.0891Z"
          fill={color}
        />
        <path
          d="M35.6606 28.427H36.6528L37.7053 32.4211H36.8707L36.6869 31.5759H35.6265L35.4428 32.4211H34.6055L35.6606 28.427ZM35.7656 30.9151H36.5452L36.1593 29.1194H36.1488L35.763 30.9151H35.7656Z"
          fill={color}
        />
        <path
          d="M39.7564 29.6144C39.7564 29.3169 39.6199 28.9404 39.2971 28.9404C38.906 28.9404 38.7511 29.3458 38.7511 30.341C38.7511 31.3363 38.8404 31.9076 39.3023 31.9076C39.4913 31.9076 39.7984 31.6996 39.7984 31.2678V30.8597H39.2682V30.2673H40.5675V32.421H39.9769V32.0524H39.9638C39.7748 32.3657 39.4992 32.5 39.1291 32.5C38.1395 32.5 37.9453 31.7628 37.9453 30.3805C37.9453 28.9983 38.2577 28.3506 39.284 28.3506C40.1659 28.3506 40.5229 28.8061 40.5229 29.617H39.7538L39.7564 29.6144Z"
          fill={color}
        />
        <path
          d="M43.3118 28.427V29.0879H41.8891V30.0278H43.2278V30.6886H41.8891V31.7629H43.3669V32.4211H41.0859V28.427H43.3091H43.3118Z"
          fill={color}
        />
        <path
          d="M44.7 28.427L45.6712 31.1626H45.6817V28.427H46.4166V32.4211H45.5032L44.5215 29.625H44.5084V32.4211H43.7734V28.427H44.7Z"
          fill={color}
        />
        <path
          d="M48.6768 29.775C48.6768 29.1931 48.5586 28.9404 48.1964 28.9404C47.7896 28.9404 47.6951 29.3511 47.6951 30.4806C47.6951 31.6891 47.8605 31.9076 48.2069 31.9076C48.4983 31.9076 48.7109 31.7575 48.7109 30.8939H49.5141C49.5141 31.7575 49.3015 32.5 48.2489 32.5C47.0441 32.5 46.8945 31.6391 46.8945 30.4253C46.8945 29.2115 47.0441 28.3506 48.2489 28.3506C49.4537 28.3506 49.4826 29.1957 49.4826 29.7776H48.682L48.6768 29.775Z"
          fill={color}
        />
        <path
          d="M50.61 28.427L51.1349 29.9725H51.1454L51.7019 28.427H52.5733L51.5418 30.8492V32.4211H50.7386V30.8492L49.707 28.427H50.61Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1410_6631">
          <rect
            width="62"
            height="32"
            fill={color}
            transform="translate(0.242188 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FooterITAIcon;
