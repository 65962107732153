import { Stack } from '@mui/material';
import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { StyledPaper } from './ChangePassword.style';
import { IFormProps } from './ChangePassword.types';
import { RHFTextField } from 'components';
import { useTranslations, useResponsive, useJsonApi, useSnackbar } from 'hooks';
import { useAuth } from 'hooks/api';
import { FormProvider } from 'providers';
import { useAuthSelector, useGlobalStore } from 'stores';
import { IUserToken } from 'types/user';

const defaultValues: IFormProps = {
  old_password: '',
  new_password: '',
  confirm_password: '',
};

const ChangePassword = () => {
  //hooks
  const methods = useForm<IFormProps>({ defaultValues });
  const i18n = useTranslations();
  const isMobile = useResponsive('down', 'md');
  const { handleSubmit, getValues, clearErrors } = methods;
  const { showLoader, hideLoader } = useGlobalStore();
  const { editUser } = useJsonApi({ showLoader, hideLoader });
  const { user } = useAuthSelector();
  const { refreshTokens } = useAuth();
  const { errorSnackbar, successSnackbar } = useSnackbar();

  const passwordValidationPattern = {
    value:
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/,
    message: i18n.auth.register.common.errors.passwordFormatNotValid,
  };

  const passwordsEquality = (): boolean | string => {
    const isValid = getValues('new_password') === getValues('confirm_password');
    if (!isValid) return i18n.auth.register.common.errors.passwordMismatch;
    else {
      clearErrors();
      return isValid;
    }
  };

  const onSubmit = async (data: IFormProps) => {
    showLoader();
    const newToken = await refreshTokens();
    const response = await editUser({
      useLoader: false,
      id: user?.uuid as string,
      body: {
        data: {
          type: 'user--user',
          id: user?.uuid,
          attributes: {
            pass: {
              existing: data.old_password,
              value: data.new_password,
            },
          },
        },
      },
      accessToken: newToken?.access_token as string,
    });
    await refreshTokens(newToken as IUserToken);
    methods.reset();
    response
      ? successSnackbar(i18n.general.snackbar.success)
      : errorSnackbar(i18n.general.errors.generic);
    hideLoader();
  };

  return (
    <StyledPaper>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack gap="20px">
          <RHFTextField
            name="old_password"
            label={i18n.common.fields.oldPassword}
            type="password"
            required
            rules={{
              required: i18n.general.errors.requiredField,
            }}
          />
          <RHFTextField
            name="new_password"
            label={i18n.common.fields.newPassword}
            type="password"
            required
            rules={{
              required: i18n.general.errors.requiredField,
            }}
          />
          <RHFTextField
            name="confirm_password"
            label={i18n.common.fields.confirmPassword}
            type="password"
            required
            rules={{
              required: i18n.general.errors.requiredField,
              validate: passwordsEquality,
              pattern: passwordValidationPattern,
            }}
          />
        </Stack>
        <Stack
          alignItems="flex-end"
          justifyContent="flex-end"
          sx={{ width: '100%', paddingTop: !isMobile ? '200px' : '50px' }}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            fullWidth={isMobile}
            type="submit"
          >
            {i18n.common.buttons.saveChanges}
          </Button>
        </Stack>
      </FormProvider>
    </StyledPaper>
  );
};

export { ChangePassword };
