import { Box, BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IPageIcon extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function PageIcon({ color = '#637381', style, ...other }: IPageIcon) {
  return (
    <Box {...other}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        style={style}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.1003 17.1794H2.60026C1.68359 17.1794 0.933594 16.4294 0.933594 15.5127V2.17936C0.933594 1.2627 1.68359 0.512695 2.60026 0.512695H9.26693L14.2669 5.5127V10.5127H12.6003V6.34603H8.43359V2.17936H2.60026V15.5127H10.1003V17.1794ZM13.4336 16.896V15.0294L15.8919 17.4877L17.0669 16.3127L14.6086 13.846H16.4753V12.1794H11.7669V16.896H13.4336Z"
          fill={color}
        />
      </svg>
    </Box>
  );
}

export default PageIcon;
