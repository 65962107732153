import { BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IAddressIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function AddressIcon({ color = '#7B8A72', style }: IAddressIconProps) {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M16.3354 5.33268C18.9627 5.33268 23.1417 7.19935 23.1417 12.1993C23.1417 15.0793 20.8004 18.426 16.3354 21.9593C11.8705 18.426 9.52915 15.066 9.52915 12.1993C9.52915 7.19935 13.7082 5.33268 16.3354 5.33268ZM16.3354 2.66602C11.8841 2.66602 6.80664 5.94602 6.80664 12.1993C6.80664 16.3593 9.97837 20.746 16.3354 25.3327C22.6925 20.746 25.8642 16.3593 25.8642 12.1993C25.8642 5.94602 20.7867 2.66602 16.3354 2.66602Z"
        fill={color}
      />
      <path
        d="M16.3354 9.33268C14.8381 9.33268 13.6129 10.5327 13.6129 11.9993C13.6129 13.466 14.8381 14.666 16.3354 14.666C17.0575 14.666 17.75 14.3851 18.2605 13.885C18.7711 13.3849 19.058 12.7066 19.058 11.9993C19.058 11.2921 18.7711 10.6138 18.2605 10.1137C17.75 9.61363 17.0575 9.33268 16.3354 9.33268ZM6.80664 26.666H25.8642V29.3327H6.80664V26.666Z"
        fill={color}
      />
    </svg>
  );
}

export default AddressIcon;
