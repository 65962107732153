import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  'pb-1': {
    paddingBottom: '1cm',
  },
  disclaimer: {
    color: '#637381',
    fontSize: '8px',
    paddingTop: '1cm',
  },
});
