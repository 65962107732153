// @mui
import {
  Box,
  InputAdornment,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';

import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { IconButtonAnimate } from '../../../components/animate';
import { HEADER } from '../../../config';
import useOffSetTop from '../../../hooks/useOffSetTop';

//
import AccountPopover from './AccountPopover';
import CartHeaderIcon from './CartIcon';
import NotificationsPopover from './NotificationsPopover';
import { RootStyle } from './index.style';
import { IDashboardHeaderProps } from './index.types';

// import { FeedbackHeaderIcon } from 'assets/icons';
import SearchV2Icon from 'assets/icons/SearchV2Icon';
import { Logo, RHFTextField } from 'components';
import { Iconify } from 'components';
// import { FAQ_ROUTE } from 'constants/paths';
import * as PATHS from 'constants/paths';
// import { isFaqEnabled } from 'constants/user';
import { useTranslations, useResponsive } from 'hooks';
import { FormProvider } from 'providers';
import { useAuthSelector } from 'stores';
import { useDashboardStore } from 'stores/dashboard';
// ----------------------------------------------------------------------

export default function DashboardHeader({
  onOpenSidebar,
  // isCollapse = true,
  verticalLayout = false,
}: IDashboardHeaderProps) {
  //hooks
  const i18n = useTranslations();
  const { user } = useAuthSelector();
  const [userHasEmptySearchBar, setUserHasEmptySearchBar] = useState(false);
  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const formDefaultValues = {
    search_library: undefined,
  };

  const methods = useForm<{ search_library: string }>({
    defaultValues: formDefaultValues,
  });

  const navigate = useNavigate();
  const isDesktop = useResponsive('up', 'lg');
  const isMdDown = useResponsive('down', 'md');
  const theme = useTheme();
  const wSearchbar = methods.watch('search_library') as unknown as string;

  const searchInLibrary = () => {
    const text = methods.getValues('search_library');
    useDashboardStore.setState(prev => ({
      ...prev,
      librarySearchText: text,
      hasDashboardFilters: true,
    }));
    navigate(PATHS.EDUCATION);
  };

  useEffect(() => {
    if (userHasEmptySearchBar) {
      useDashboardStore.setState(prev => ({
        ...prev,
        librarySearchText: '',
        hasDashboardFilters: true,
      }));
      setUserHasEmptySearchBar(false);
    }
  }, [userHasEmptySearchBar]);

  const isLibrary = window.location.pathname.includes(PATHS.EDUCATION);

  return (
    <RootStyle
      isCollapse={true}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
    >
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

        {!isDesktop && (
          <IconButtonAnimate
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary' }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}
        {!isMdDown && !isLibrary ? (
          <FormProvider
            methods={methods}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
              }
            }}
          >
            <Stack flexDirection={'row'} alignItems={'center'} gap={'16px'}>
              <RHFTextField
                name="search_library"
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    searchInLibrary();
                    event.preventDefault();
                  }
                }}
                placeholder="Cerca contenuti qui..."
                onChange={e => {
                  !e.target.value && setUserHasEmptySearchBar?.(true);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchV2Icon />
                    </InputAdornment>
                  ),
                  endAdornment:
                    wSearchbar?.length > 0 ? (
                      <IconButton
                        onClick={() => {
                          methods.setValue('search_library', '');
                          setUserHasEmptySearchBar?.(true);
                        }}
                      >
                        <Iconify
                          icon="mdi:close-circle-outline"
                          style={{ width: '18px', height: '18px' }}
                        />
                      </IconButton>
                    ) : null,
                }}
                sx={{ width: '350px' }}
                variant="standard"
              />
            </Stack>
          </FormProvider>
        ) : null}

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          gap="20px"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <Stack>
            {isDesktop && (
              <>
                <Typography
                  variant="subtitle2"
                  sx={{ color: theme.palette.primary.dark, textAlign: 'right' }}
                >
                  {i18n.common.header.profileMessage}{' '}
                  {user?.field_name && user?.field_surname
                    ? `${user?.field_name} ${user?.field_surname}`
                    : user?.name}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ color: theme.palette.primary.dark, textAlign: 'right' }}
                >
                  {user?.field_role}
                </Typography>
              </>
            )}
          </Stack>
          <AccountPopover isOffset={isOffset} />
          <CartHeaderIcon />
          {/* {isFaqEnabled ? (
            <IconButton
              sx={{
                height: '40px',
                width: '40px',
              }}
              onClick={() => navigate(FAQ_ROUTE)}
            >
              <FeedbackHeaderIcon />
            </IconButton>
          ) : null} */}
          <NotificationsPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
