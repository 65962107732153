import { IntlShape } from 'react-intl';

export const ProductsQuestions = {
  ['question_28']:
    "Valore economico degli <click id={401}>acquisti verdi</click> nell'anno n rispetto al valore economico totale degli acquisti nell'anno n.",
  ['question_29']:
    "<click id={13}>Risorse materiche</click>, prodotti e <click id={17}>sottoprodotti</click> (in uscita) nell'anno n dotati di un sistema di tracciamento (es. passaporto di prodotto) rispetto al totale delle risorse materiche, prodotti e sottoprodottti in uscita nell'anno n.",
  ['question_30']:
    "Valore delle forniture provenienti da fornitori che possiedono <click id={402}>certificazioni di sostenibilità</click> e/o di circolarità di prodotto e/o servizio e/o di organizzazione nell'anno n rispetto al totale del valore delle forniture nell'anno n.",
  ['question_31']:
    "<click id={17}>Sottoprodotti</click> generati nell'anno n rispetto al totale dei <click id={403}>residui</click> di produzione generati nell'anno n.",
  ['question_32']:
    "Valore dei <click id={404}>prodotti e/o servizi immessi sul mercato</click> che possiedono <click id={402}>certificazioni di sostenibilità</click> e/o circolarità di prodotto e/o servizio nell'anno n rispetto al totale del valore dei prodotti e/o servizi immessi sul mercato nell'anno n.",
  ['question_33']:
    'Sono presenti <click id={405}>sistemi di contabilizzazione delle risorse</click>?',
  ['question_34']:
    "Valore dei prodotti e servizi approvvigionati riferiti a <click id={406}>modelli di business circolari</click> nell'anno n rispetto al totale dei prodotti e servizi approvvigionati nell'anno n.",
  ['question_35']:
    "Valore dei prodotti e servizi generati riferiti a <click id={406}>modelli di business circolari</click> nell'anno n rispetto/totale dei prodotti e servizi generati.",
  ['question_36']:
    "Prodotti e servizi per i quali sono stati forniti ai clienti documentazione e/o strumenti di supporto per la riparazione nell'anno n rispetto al totale dei prodotti immessi sul mercato nell'anno n.",
  ['question_37']:
    "<click id={407}>Prodotti rigenerati</click> reimmessi sul mercato nell'anno n rispetto al totale dei <click id={404}>prodotti immessi sul mercato</click> nell'anno n.",
  ['question_38']:
    "Valore dei prodotti e/o servizi per i quali viene incluso un aumento del periodo di garanzia del prodotto e/o servizio, oltre al termine obbligatorio per legge nell'anno n rispetto al totale del valore dei <click id={404}>prodotti e/o servizi immessi sul mercato</click> nell'anno n.",
  ['question_39']:
    "Esiste una strategia e un sistema di monitoraggio per l'allungamento della <click id={408}>vita utile</click> dei prodotti?",
  ['question_40']:
    "Quantità di prodotti generati nell'anno n rispetto alla quantità di <click id={302}>risorse</click> impiegate nell'anno n.",
  ['question_41']:
    "Valore dei prodotti e servizi (escluse le <click id={3}>materie prime</click>) approvvigionati da <click id={409}>fornitori locali</click> nell'anno n rispetto al valore totale dei prodotti e servizi (escluse le materie prime). Nota: Se i prodotti e servizi approvvigionati non fossero reperibili localmente escluderli dal denominatore del LC.",
  ['question_42']:
    "Esistono accordi e/o partnership formalizzati con stakeholders esterni e/o iniziative esterne finalizzate allo sviluppo di strategie, condivisione di conoscenze, relativamente all'<click id={10}>economia circolare</click>?",
  ['question_43']:
    "L'organizzazione ha effettuato investimenti in <click id={5}>progettazione circolare</click> dei propri prodotti e/o servizi negli anni n e/o n-1 e/o n-2?",
  ['question_44']:
    "L'organizzazione ha effettuato investimenti in <click id={5}>progettazione circolare</click> dei propri processi negli anni n e/o n-1 e/o n-2?",
  ['question_45']:
    "L'organizzazione ha effettuato investimenti in <click id={5}>progettazione circolare</click> dei propri <click id={410}>asset</click> negli anni n e/o n-1 e/o n-2?",
  ['question_46']:
    "Investimenti in ricerca e sviluppo legati ai principi dell'<click id={10}>economia circolare</click> negli anni n e/o n-1 e/o n-2 rispetto al totale degli investimenti in ricerca e sviluppo negli anni n e/o n-1 e/o n-2.",
  ['question_47']:
    '<click id={13}>Risorse materiche</click> in ingresso derivanti da meccanismi di <click id={2}>simbiosi industriale</click> rispetto al totale delle risorse materiche in ingresso.',
  ['question_48']:
    '<click id={13}>Risorse materiche</click> in uscita valorizzate esternamente con meccanismi di <click id={2}>simbiosi industriale</click> rispetto al totale delle risorse materiche in uscita.',
  ['question_49']:
    '<click id={411}>Risorse idriche</click> in ingresso derivanti da meccanismi di <click id={2}>simbiosi industriale</click> rispetto al totale delle risorse idriche in ingresso.',
  ['question_50']:
    '<click id={411}>Risorse idriche</click> in uscita valorizzate esternamente con meccanismi di <click id={2}>simbiosi industriale</click> rispetto al totale delle risorse idriche in uscita.',
  ['question_51']:
    '<click id={412}>Risorse energetiche</click> in ingresso derivanti da meccanismi di <click id={2}>simbiosi industriale</click> rispetto al totale delle risorse energetiche in ingresso.',
  ['question_52']:
    '<click id={412}>Risorse energetiche</click> in uscita valorizzate esternamente con meccanismi di <click id={2}>simbiosi industriale</click> rispetto al totale delle risorse energetiche in uscita.',
  ['question_53']:
    "L'organizzazione implementa modelli di <click id={2}>simbiosi industriale</click> per condividere i propri <click id={410}>asset</click>?",
};

export const productsModalsExplanations = (
  intl: IntlShape,
  htmlParserOptions?: object
) => ({
  401: {
    title: intl.formatMessage({
      defaultMessage: 'Acquisti verdi',
      description: 'homepage modalsExplanations 401 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Procedure e processi d’acquisto, utilizzate da organizzazioni private o
        pubbliche, per acquisire beni e servizi a minor impatto ambientale.`,
        description: 'homepage modalsExplanations 401 body',
      },
      { ...htmlParserOptions }
    ),
  },
  402: {
    title: intl.formatMessage({
      defaultMessage: 'Certificazioni di sostenibilità',
      description: 'homepage modalsExplanations 402 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Certificazioni che includono norme e standard volontari
        nazionali, europei ed internazionali, afferenti la sostenibilità di prodotti, processi e/o servizi.`,
        description: 'homepage modalsExplanations 402 body',
      },
      { ...htmlParserOptions }
    ),
  },
  403: {
    title: intl.formatMessage({
      defaultMessage: 'Residuo',
      description: 'homepage modalsExplanations 403 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Materiale o sostanza che non è deliberatamente prodotto in un processo di
        produzione e che può essere o non essere un rifiuto.`,
        description: 'homepage modalsExplanations 403 body',
      },
      { ...htmlParserOptions }
    ),
  },
  404: {
    title: intl.formatMessage({
      defaultMessage: 'Prodotti e/o servizi immessi sul mercato',
      description: 'homepage modalsExplanations 404 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Prodotti e/o servizi che vengono messi a
        disposizione per la distribuzione, vendita, utilizzo o trasferimento da un fabbricante ad un
        altro soggetto.`,
        description: 'homepage modalsExplanations 404 body',
      },
      { ...htmlParserOptions }
    ),
  },
  405: {
    title: intl.formatMessage({
      defaultMessage: 'Sistemi di contabilizzazione',
      description: 'homepage modalsExplanations 405 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Sistemi che consentano di gestire in modo autonomo gli
        utilizzi di una risorsa (ad esempio il bilancio delle risorse in ingresso e in uscita).`,
        description: 'homepage modalsExplanations 405 body',
      },
      { ...htmlParserOptions }
    ),
  },
  406: {
    title: intl.formatMessage({
      defaultMessage: 'Modello di business circolare',
      description: 'homepage modalsExplanations 406 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Modello di business che integra i principi dell’economia circolare.`,
        description: 'homepage modalsExplanations 406 body',
      },
      { ...htmlParserOptions }
    ),
  },
  407: {
    title: intl.formatMessage({
      defaultMessage: 'Prodotto rigenerato',
      description: 'homepage modalsExplanations 407 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Prodotto mantenuto in circolo grazie alla sostituzione delle parti
        obsolete o danneggiate per il riutilizzo o la vendita.`,
        description: 'homepage modalsExplanations 407 body',
      },
      { ...htmlParserOptions }
    ),
  },
  408: {
    title: intl.formatMessage({
      defaultMessage: 'Vita utile',
      description: 'homepage modalsExplanations 408 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Periodo durante il quale il prodotto mantiene livelli prestazionali sufficienti, prima
        che si manifestino degradi tali da pregiudicarne la funzionalità.`,
        description: 'homepage modalsExplanations 408 body',
      },
      { ...htmlParserOptions }
    ),
  },
  409: {
    title: intl.formatMessage({
      defaultMessage: 'Fornitore locale',
      description: 'homepage modalsExplanations 409 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Fornitore collocato nel raggio di 100 km dal luogo in cui il bene o il servizio
        è utilizzato, all’interno del perimetro di valutazione.`,
        description: 'homepage modalsExplanations 409 body',
      },
      { ...htmlParserOptions }
    ),
  },
  410: {
    title: intl.formatMessage({
      defaultMessage: 'Asset',
      description: 'homepage modalsExplanations 410 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Qualsiasi bene, materiale ed immateriale, di proprietà e/o utilizzato dall’organizzazione.`,
        description: 'homepage modalsExplanations 410 body',
      },
      { ...htmlParserOptions }
    ),
  },
  411: {
    title: intl.formatMessage({
      defaultMessage: 'Risorse idriche',
      description: 'homepage modalsExplanations 411 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Risorse rappresentate da tutte le varie forme di disponibilità dell'acqua.`,
        description: 'homepage modalsExplanations 411 body',
      },
      { ...htmlParserOptions }
    ),
  },
  412: {
    title: intl.formatMessage({
      defaultMessage: 'Risorse energetiche',
      description: 'homepage modalsExplanations 411 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Risorse che possono essere utilizzate per generare energia utile per le attività umane.`,
        description: 'homepage modalsExplanations 411 body',
      },
      { ...htmlParserOptions }
    ),
  },
});

export const productsModalsNotes = (
  intl: IntlShape,
  htmlParserOptions?: object
) => ({
  question_28: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra il valore economico (€) degli acquisti verdi relativi all'ultimo anno in cui si è in possesso dei dati rispetto al valore economico totale (€) degli acquisti relativo all'ultimo anno in cui si è in possesso dei dati.`,
      description: 'homepage notes question_28 title',
    },
    { ...htmlParserOptions }
  ),
  question_29: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra la quantità (kg) di risorse materiche, prodotti e sottoprodotti (in uscita) dotate di sistema di tracciamento nell'ultimo anno di cui si è in possesso dei dati e la quantità totale (kg) di risorse materiche, prodotti e sottoprodotti in uscita nell'ultimo anno in cui si è in possesso dei dati (si possono contare ad esempio prodotti inseriti in una catena di custodia).`,
      description: 'homepage notes question_29 title',
    },
    { ...htmlParserOptions }
  ),
  question_30: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra il valore (€) delle forniture provenienti da fornitori che possiedono certificazioni di sostenibilità e/o di circolarità di prodotto e/o servizio e/o di organizzazione nell'ultimo anno n di cui si hanno i dati rispetto al totale del valore (€) delle forniture nell'ultimo anno n di cui si possiadono i dati.<br></br><br></br>Esempio: Se il valore delle forniture da fornitori certificati FSC (certificazione di sostenibilità) o da aziende in possesso di una certificazione ISO 14001 è pari a 1.500.000,00 €, mentre il valore totale delle forniture è pari a 4.000.000,00 €, il rapporto percentuale sarà pari a 37,5 %.`,
      description: 'homepage notes question_30 title',
    },
    { ...htmlParserOptions }
  ),
  question_31: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra la quantità (kg) di sottoprodotti generati nell'ultimo anno n di cui si hanno i dati rispetto alla quantità totale (kg) dei residui di produzione generati nell'ultimo anno n di cui si conoscono i dati.`,
      description: 'homepage notes question_31 title',
    },
    { ...htmlParserOptions }
  ),
  question_32: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra il valore (€) dei prodotti e/o servizi immessi sul mercato che possiedono certificazioni di sostenibilità e/o circolarità di prodotto e/o servizio relativi all'ultimo anno n di cui si possiedono i dati rispetto al totale del valore (€) dei prodotti e/o servizi immessi sul mercato nell'ultimo anno n di cui si conoscono i dati.`,
      description: 'homepage notes question_32 title',
    },
    { ...htmlParserOptions }
  ),
  question_33: intl.formatMessage(
    {
      defaultMessage: `Sono presenti sistemi di contabilizzazione delle risorse (ad es. contabilizzatori di energia e/o acqua utlizzata per un determinato processo produttivo)?`,
      description: 'homepage notes question_33 title',
    },
    { ...htmlParserOptions }
  ),
  question_34: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra il valore (€) dei prodotti e servizi approvvigionati riferiti a modelli di business circolari (adozione di politiche di sharing, sostituzione di materiali tradizionali con altri materiali riciclati e/o riciclabili, azioni che mirano ad allungare il ciclo di vita del prodotto, ecc.) relativi all'ultimo anno n di cui si possiedono i dati e il valore (€) totale dei prodotti e servizi approvvigionati nell'ultimo anno di cui si hanno i dati.`,
      description: 'homepage notes question_34 title',
    },
    { ...htmlParserOptions }
  ),
  question_35: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra il valore (€) dei prodotti e servizi generati riferiti a modelli di business circolari (adozione di politiche di sharing, sostituzione di materiali tradizionali con altri materiali riciclati e/o riciclabili, azioni che mirano ad allungare il ciclo di vita del prodotto, ecc.) relativi all'ultimo anno n di cui si possiedono i dati e il valore (€) totale dei prodotti e servizi generati nell'ultimo anno di cui si hanno i dati.`,
      description: 'homepage notes question_35 title',
    },
    { ...htmlParserOptions }
  ),
  question_36: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra la quantità n di prodotti e servizi per i quali sono stati forniti ai clienti documentazione e/o strumenti di supporto per la riparazione nell'anno n e la quantità totale N dei prodotti immessi sul mercato nell'anno n.<br></br>
      Ad es. Se un'azienda produce 10.000 scrivanie riparabili (di cui fornisce documentazione per la riparabilità) e in totale produce 600.000 scrivanie il rapporto % sarà pari a (10.000/600.000)*100 = 1,7%.`,
      description: 'homepage notes question_36 title',
    },
    { ...htmlParserOptions }
  ),
  question_37: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra la quantità di prodotti rigenerati reimmessi sul mercato nell'anno e il totale dei prodotti immessi sul mercato nell'anno n.
      Ad es. se un'azienda produttrice di cucine nell'anno 2021 ha rigenerato attraverso la sostituzione di parti obsolete o danneggiate (ad es. ante e Top) 2.000 cucine e le cucine prodotte nell'anno 2021 sono 100.000 il rapporto sarà pari al 2%.`,
      description: 'homepage notes question_37 title',
    },
    { ...htmlParserOptions }
  ),
  question_38: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra il valore (€) dei prodotti e/o servizi per i quali viene incluso un aumento del periodo di garanzia del prodotto e/o servizio, oltre al termine obbligatorio per legge nell'anno n e il totale del valore (€) dei prodotti e/o servizi immessi sul mercato nell'anno n.<br></br>
      Ad es. se un'azienda che produce armadi offre una garanzia aggiuntiva di 2 anni rispetto a quella di legge sul suo prodotto di punta di cui nell'anno 2021 ha venduto per 4.000.000 € e la sua produzione totale è di 20.000.000 € nell'anno 2021, il rapporto % sarà (4.000.000/20.000.000)*100= 20%.`,
      description: 'homepage notes question_38 title',
    },
    { ...htmlParserOptions }
  ),
  question_39: intl.formatMessage(
    {
      defaultMessage: `Indicare se all'interno dell'azienda esiste una strategia e un sistema di monitoraggio per far si che ci sia un allungamento della vita utile dei prodotti.<br></br>
      Es. di allungamento delle vita utile di un prodotto riguardano ad esempio l'estensione della garazia del prodotto, l'assistenza post vendita con la possibilità di avere pezzi di ricambio, fornire al cliente un kit di riparazione.`,
      description: 'homepage notes question_39 title',
    },
    { ...htmlParserOptions }
  ),
  question_40: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra la quantità (kg) di prodotti generati nell'anno n e la quantità totale (kg) di risorse materiche  impiegate nell'anno n.<br></br>
      Ad es. per un'azienda che produce pannelli in truciolare riciclato si dovranno considerare:<ul> 
      <li>Per il numeratore: kg di pannello prodotto</li>
      <li>Per il denominatore: kg di materiale legnoso in ingresso + kg di colle in ingresso +...</li></ul>`,
      description: 'homepage notes question_40 title',
    },
    { ...htmlParserOptions }
  ),
  question_41: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra il valore (€) dei prodotti e servizi (escluse le materie prime) approvvigionati da fornitori locali (che distano meno di 100 Km) nell'anno n rispetto al valore totale (€) dei prodotti e servizi (escluse le materie prime).<br></br><br></br><b>Nota</b>: Se i prodotti e servizi approvvigionati non fossero reperibili localmente escluderli dal denominatore del LC.`,
      description: 'homepage notes question_41 title',
    },
    { ...htmlParserOptions }
  ),
  question_42: intl.formatMessage(
    {
      defaultMessage: `Esistono accordi e/o partnership formalizzati con stakeholders esterni e/o iniziative esterne finalizzate allo sviluppo di strategie, condivisione di conoscenze, relativamente all'economia circolare?<br></br> 
      Ad es. Stakeholders esterni possono essere le istituzioni (comuni, province, regioni) o le società che si occupano ad esempio di raccolta differenziata con cui si possono siglare degli accordi o costruire progetti come ad esempio quelli di simbiosi industriale dove attraverso la messa in rete di più soggetti, il rifiuto di una realtà industriale può diventare risorsa per un'altra realtà. Altri progetti possono riguardare lo sharing (condivisione di mezzi di trasporto, di mezzi per la logistica, di reti, ecc.).`,
      description: 'homepage notes question_42 title',
    },
    { ...htmlParserOptions }
  ),
  question_43: intl.formatMessage(
    {
      defaultMessage: `Indicare se la la vostra organizzazione ha effettuato investimenti in progettazione circolare dei propri prodotti e/o servizi negli anni n e/o n-1 e/o n-2.<br></br>
      Investimenti in progettazione circolare di prodotto  riguardano ad esempio investimenti che mirano a:<ul>
      <li>utilizzare maggior quantità di materie prime riciclate, riciclabili o rinnovabili;</li>
      <li>progettare un prodotto che sia riparabile;</li>
      <li>progettare un prodotto facilmente disassemblabile;</li>
      <li>progettare prodotti che possano avere una vita utile maggiore.</li></ul>`,
      description: 'homepage notes question_43 title',
    },
    { ...htmlParserOptions }
  ),
  question_44: intl.formatMessage(
    {
      defaultMessage: `Indicare se la vostra organizzazione ha effettuato investimenti in progettazione circolare dei propri processi negli anni n e/o n-1 e/o n-2.<br></br>
      Investimenti in progettazione circolare di processo riguardano ad esempio investimenti che mirano a:<ul>
      <li>ridurre i consumi di risorse (ad es. energia, acqua, risorse materiche) durante il processo produttivo;</li> 
      <li>ottimizzare l'utilizzo delle risorse (ad es. riutilizzo degli scarti);</li>
      <li>utilizzo di risorse rinnovabili.</li></ul>`,
      description: 'homepage notes question_44 title',
    },
    { ...htmlParserOptions }
  ),
  question_45: intl.formatMessage(
    {
      defaultMessage: `Indicare se la vostra organizzazione ha effettuato investimenti in progettazione circolare dei propri asset negli anni n e/o n-1 e/o n-2.<br></br>
      Investimenti in progettazione circolare degli asset riguardano ad esempio investimenti che mirano a:<ul>
      <li>rendere completamente autosufficiente l'azienda dal punto di vista energetico attraverso la produzione di energie rinnovabili (fotovolatico, geotermico,ecc.);</li> 
      <li>effettuare corsi di formazione sui temi di economia circolare ai propri dipendenti/collaboratori;</li> 
      <li>assumere figure qualificate nell'ambito dei temi di economia circolare (ad es. energy manager, mobility manager).</li></ul>`,
      description: 'homepage notes question_45 title',
    },
    { ...htmlParserOptions }
  ),
  question_46: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra il valore (€) degli investimenti in ricerca e sviluppo legati ai principi dell'economia circolare negli anni n e/o n-1 e/o n-2 e il valore totale (€) degli investimenti in ricerca e sviluppo negli anni n e/o n-1 e/o n-2.<br></br><br></br>
      Ad es. se un azienda nell'anno 2021 ha investito in ricerca e sviluppo legati all'economia circolare 150.000 € ed in totale nell'anno 2021 ha investito in riceca e sviluppo 300.000 € allora il rapporto % sarà: (150.000/300.000)*100 = 50%.`,
      description: 'homepage notes question_45 title',
    },
    { ...htmlParserOptions }
  ),
  question_47: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra la quantità (kg) di risorse materiche in ingresso che provengono da meccanismi di simbiosi industriali rispetto al totale delle risorse materiche in ingresso nella propria organizzazione.<br></br> 
      Ad esempio: un’azienda A del settore agricolo effettua un processo di triturazione dei semi per la produzione di olio di colza; i semi frantumati e pressati vengo ceduti all’azienda B che produce resine per la realizzazione di pannelli truciolari. La % annua utilizzata dall’azienda B del materiale acquistato dall’azienda A, rispetto al totale delle materie prime annue in ingresso nell’azienda B, è il valore che l’azienda B deve indicare per rispondere al quesito.`,
      description: 'homepage notes question_47 title',
    },
    { ...htmlParserOptions }
  ),
  question_48: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra la quantità (kg) di risorse materiche in uscita che provengono da meccanismi di simbiosi industriali rispetto al totale delle risorse materiche in ingresso nella propria organizzazione.<br></br> 
      Ad esempio: un’azienda A che produce arredi ha uno scarto annuo proveniente dal processo di sezionatura di X kg che cede ad altre aziende (B, C, D,..) che lo utilizzano per la realizzazione di nuovi materiali compositi. La % annua ceduta dall'azienda A, rispetto al totale delle risorse materiche in uscita dall'azienda, è il valore che l'azienda A deve indicare per rispondere al quesito.`,
      description: 'homepage notes question_48 title',
    },
    { ...htmlParserOptions }
  ),
  question_49: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra la quantità (m3) di risorse idriche in ingresso che provengono da meccanismi di simbiosi industriale rispetto al totale delle risorse idriche in ingresso nella propria organizzazione.<br></br> 
      Ad esempio azienda A utilizza acqua proveniente dalle raffinerie del distretto che, a seguito dei processi di depurazione, viene destinata nei propri impanti per attività di raffreddamento. La % annua di risorse idriche utilizzata dall’azienda A, rispetto al totale delle risorse idriche in ingresso nell’azienda A, è il valore che l’azienda A deve indicare per rispondere al quesito.`,
      description: 'homepage notes question_49 title',
    },
    { ...htmlParserOptions }
  ),
  question_50: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra la quantità (m3) di risorse idriche in uscita che provengono da meccanismi di simbiosi industriale rispetto al totale delle risorse idriche in ingresso nella propria organizzazione.<br></br>  
      Ad esempio: un’azienda A che produce piani in marmo ha un consumo idrico X m3 che sottopone ad un processo di depurazione delle acque reflue depurate che potranno essere utilizzate da un'altra industria (ad esempio per sistemi di irrigazione). La % annua di acqua depurata dall'azienda A, rispetto al totale delle risorse idriche in uscita dall'azienda, è il valore che l'azienda A deve indicare per rispondere al quesito.`,
      description: 'homepage notes question_50 title',
    },
    { ...htmlParserOptions }
  ),
  question_51: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra la quantità di risorse energetiche in ingresso che provengono da meccanismi di simbiosi industriale rispetto al totale delle risorse energetiche in ingresso nella propria organizzazione.<br></br>  
      Ad esempio se un'azienda A produce biometano dagli scarti del suo allevamento di bestiame e l'azienda B utilizza parte di questo biometano per alcuni  dei suoi processi produttivi la % che l'azienda B utilizza rispetto al totale è il valore che va inserito per rispondere al quesito.`,
      description: 'homepage notes question_51 title',
    },
    { ...htmlParserOptions }
  ),
  question_52: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra la quantità di risorse energetiche in uscita che provengono da meccanismi di simbiosi industriale rispetto al totale delle risorse energetiche in ingresso nella propria organizzazione.<br></br> 
      Se ad esempio un'azienda A che produce pannelli in legno utilizza i suoi scarti per alimentare una caldaia e produrre energia che in parte immette in rete, la % che immette in rete rispetto al totale è quella che va indicata per rispondere al quesito.`,
      description: 'homepage notes question_52 title',
    },
    { ...htmlParserOptions }
  ),
  question_53: intl.formatMessage(
    {
      defaultMessage: `La vostra organizzazione implementa modelli di simbiosi industriale trasferendo e/o condividendo risorse (materiali, energia, acqua, servizi) con altre organizzazioni?`,
      description: 'homepage notes question_52 title',
    },
    { ...htmlParserOptions }
  ),
});
