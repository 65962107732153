// @mui

import { CSSProperties } from 'react';

// ----------------------------------------------------------------------

function SideBarSurveyIcon({
  color = '#919EAB',
  style,
}: {
  color?: string;
  style?: CSSProperties;
}) {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style }}
    >
      <g id="ICONS">
        <path
          id="Vector"
          d="M11.0153 9.11538H20.2461M11.0153 13.7308H20.2461M11.0153 18.3462H15.6307M9.16913 4.5H22.0922C23.6216 4.5 24.8614 5.73983 24.8614 7.26923V25.7308C24.8614 27.2602 23.6216 28.5 22.0922 28.5H9.16913C7.63973 28.5 6.3999 27.2602 6.3999 25.7308V7.26923C6.3999 5.73983 7.63973 4.5 9.16913 4.5Z"
          stroke={color}
          strokeWidth="2.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default SideBarSurveyIcon;
