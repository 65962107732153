import { IntlShape } from 'react-intl';
import * as PATHS from 'constants/paths';

const htmlParserOptions = {
  br: () => <br />,
  privacy: (str: string) => (
    <a href={PATHS.BASE_FO} target="_blank" rel="noreferrer">
      {str}
    </a>
  ),
};

export const internazionalizationTranslations = (intl: IntlShape) => ({
  internazionalization: {
    certifications: {
      subtitle: intl.formatMessage({
        defaultMessage:
          'Strumento per poter ricercare in modo semplice e intuitivo le conformità tecniche che richiedono i prodotti per poter essere esportati.',
        description: 'Certifications Page subtitle',
      }),
      disclamer: intl.formatMessage(
        {
          defaultMessage:
            "DISCLAIMER<br></br><br></br> Tutti i dati forniti agli Associati attraverso la piattaforma FLA Plus a mero titolo informativo. FederlegnoArredo non si assume alcuna responsabilità per quanto riguarda tali informazioni. In particolare:<br></br>• Le informazioni contenute sulla piattaforma non costituiscono parere di tipo professionale o giuridico avendo esclusivamente finalità divulgativa e validità per il tempo nel quale sono rese.<br></br>• Sebbene FederlegnoArredo presti la massima attenzione all'aggiornamento, alla completezza e precisione delle informazioni diffuse attraverso la piattaforma, non si assume alcuna responsabilità quanto all'utilizzo dei dati o delle informazioni ivi contenuti. Gli Associati, di conseguenza, sono tenuti ad adottare tutte le precauzioni necessarie prima di utilizzare tali dati assumendosi conseguentemente tutti i rischi connessi.<br></br>• Le informazioni contenute sulla piattaforma possono rinviare a siti esterni, sui quali i servizi della FederlegnoArredo non hanno alcun controllo e per i quali FederlegnoArredo non assume alcuna responsabilità.<br></br>È vietata la divulgazione a Terzi dei dati contenuti sulla piattaforma, con qualsiasi mezzo analogico o digitale.",
          description: 'Certifications Page disclamer',
        },
        { ...htmlParserOptions }
      ),
      formText: intl.formatMessage({
        defaultMessage:
          'Se hai necessità di approfondire maggiormente un argomento ed essere accompagnato individualmente contattaci.',
        description: 'Certifications formText',
      }),
      exploreRegulations: {
        title: intl.formatMessage({
          defaultMessage:
            'Esplora le normative riconosciute per singolo Paese o a livello Internazionale.',
          description: 'Certification Page exploreRegulations section title',
        }),
        subtitle: intl.formatMessage(
          {
            defaultMessage:
              // TODO: Reactivate External Link to FO
              "Se hai necessità di approfondire maggiormente un argomento ed essere accompagnato individualmente puoi contattare l'Ufficio Normativa.",
            description:
              'Certifications Page exploreRegulations section subtitle',
          },
          { ...htmlParserOptions }
        ),
        merceologies: {
          title: intl.formatMessage({
            defaultMessage: '1. Seleziona il settore merceologico',
            description: 'Certifications Page merceologies title',
          }),
          furnishing: intl.formatMessage({
            defaultMessage: 'Arredo',
            description: 'Certifications Page furniture merceology label',
          }),
          bath: intl.formatMessage({
            defaultMessage: 'Bagno',
            description: 'Certifications Page bath merceology label',
          }),
          light: intl.formatMessage({
            defaultMessage: 'Luce',
            description: 'Certifications Page light merceology label',
          }),
          allLabel: intl.formatMessage({
            defaultMessage: 'Tutte',
            description: 'Certifications Page all label',
          }),
          autocompleteLabel: intl.formatMessage({
            defaultMessage: 'Paese di destinazione',
            description:
              'Certifications Page merceologies autocomplete input label',
          }),
          nationTitle: intl.formatMessage({
            defaultMessage: ' 2. Seleziona il paese di destinazione',
            description:
              'Certifications Page merceologies autocomplete input label',
          }),
        },
        categories: {
          title: intl.formatMessage({
            defaultMessage:
              '3. Scegli le categorie di prodotto che ti interessano',
            description: 'Certification Page categories title',
          }),
          all: intl.formatMessage({
            defaultMessage: 'Tutte',
            description: 'Certifications Page all category label',
          }),
          sealingStickers: intl.formatMessage({
            defaultMessage: 'Adesivi, Sigillanti, vernici e rivestimenti',
            description: 'Certifications Page sealingStickers category label',
          }),
          schoolFurniture: intl.formatMessage({
            defaultMessage: 'Arredi Scolastici',
            description: 'Certifications Page school furniture category label',
          }),
          officeFurniture: intl.formatMessage({
            defaultMessage: 'Arredo ufficio',
            description: 'Certifications Page office furniture category label',
          }),
          woodBasedComposites: intl.formatMessage({
            defaultMessage: 'Compositi a base di legno',
            description:
              'Certifications Page wood-based composites category label',
          }),
          bunkBeds: intl.formatMessage({
            defaultMessage: 'Letti a castello',
            description: 'Certifications Page bunk beds category label',
          }),
          mattresses: intl.formatMessage({
            defaultMessage: 'Materassi',
            description: 'Certifications Page mattresses category label',
          }),
          storageFurniture: intl.formatMessage({
            defaultMessage: 'Mobili contenitori',
            description: 'Certifications Page storage furniture category label',
          }),
          electricFurniture: intl.formatMessage({
            defaultMessage: 'Mobili elettrificati',
            description:
              'Certifications Page electric furniture category label',
          }),
          upholsteredFurniture: intl.formatMessage({
            defaultMessage: 'Mobili imbottiti',
            description:
              'Certifications Page upholstered furniture category label',
          }),
          motorizedFurniture: intl.formatMessage({
            defaultMessage: 'Mobili motorizzati',
            description:
              'Certifications Page motorized furniture category label',
          }),
          childrensFurniture: intl.formatMessage({
            defaultMessage: 'Mobili per Bambini',
            description:
              'Certifications Page childrens furniture category label',
          }),
          constructionProducts: intl.formatMessage({
            defaultMessage: 'Prodotti da costruzione',
            description: 'Certifications Page construction page category label',
          }),
          textileWallReversal: intl.formatMessage({
            defaultMessage: 'Riverstimento murari tessili',
            description:
              'Certifications Page textile wall reversal category label',
          }),
          sittings: intl.formatMessage({
            defaultMessage: 'Sedute',
            description: 'Certifications Page sittings category label',
          }),
          rugs: intl.formatMessage({
            defaultMessage: 'Tappeti e moquettes',
            description: 'Certifications Page rugs category label',
          }),
          tables: intl.formatMessage({
            defaultMessage: 'Tavoli e scrivanie',
            description: 'Certifications Page tables category label',
          }),
          drapery: intl.formatMessage({
            defaultMessage: 'Tendaggi',
            description: 'Certifications Page drapery category label',
          }),
          allWoodArticles: intl.formatMessage({
            defaultMessage: 'Tutti gli articoli contenenti legno',
            description: 'Certifications Page all wood articles category label',
          }),
        },
        usedDestinations: {
          title: intl.formatMessage({
            defaultMessage: "4. Seleziona le destinazioni d'uso",
            description: 'Certifications Page usedDestinations title',
          }),
          all: intl.formatMessage({
            defaultMessage: 'Tutti',
            description: 'Certifications page all udeDestination label',
          }),
          domestic: intl.formatMessage({
            defaultMessage: 'Domestico',
            description: 'Certifications Page domestic useDestination label',
          }),
          notDomestic: intl.formatMessage({
            defaultMessage: 'Non domestico/contract',
            description: 'Certifications Page notDomestic useDestination label',
          }),
          office: intl.formatMessage({
            defaultMessage: 'Ufficio',
            description: 'Certifications Page office useDestination label',
          }),
        },
      },
      certifications: {
        title: intl.formatMessage({
          defaultMessage: 'Certificazioni',
          description: 'Certifications Page certifications section title',
        }),
      },
      insights: {
        title: intl.formatMessage({
          defaultMessage: 'Approfondimenti',
          description: 'Certifications Page insights section title',
        }),
        subtitle: intl.formatMessage({
          defaultMessage:
            "Questa sezione contiene documenti che approfondiscono, nel dettaglio, le conseguenze per l'arredo di specifiche conformità, certificazioni,schemi di rating e criteri vari e le convenzioni disponibili.",
          description: 'Certifications Page insights section subtitle',
        }),
      },
    },
    certificationsTab: {
      title: intl.formatMessage({
        defaultMessage: 'Scheda certificazione',
        description: 'CertificationTab Page title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'I dati riportati nel presente documento sono forniti agli Associati a mero titolo informativo.',
        description: 'CertificationTab Page subtitle',
      }),
      buttons: {
        pdf: intl.formatMessage({
          defaultMessage: 'Pdf',
          description: 'CertificationTab Page pdf button label',
        }),
        dataset: intl.formatMessage({
          defaultMessage: 'Dataset',
          description: 'CertificationTab Page dataset button label',
        }),
        exampleLabel: intl.formatMessage({
          defaultMessage: 'Esempio di Etichetta',
          description: 'CertificationsTab Page exampleLabel button label',
        }),
      },
      from: intl.formatMessage({
        defaultMessage: 'Da',
        description: 'CertificationTab from label',
      }),
      tabLabels: {
        yesLabel: intl.formatMessage({
          defaultMessage: 'Si',
          description: 'CertificationTab Page yesLabel tabLabel',
        }),
        noLabel: intl.formatMessage({
          defaultMessage: 'No',
          description: 'CertificationTab Page noLabel tabLabel',
        }),
        mandatoryDescription: intl.formatMessage({
          defaultMessage: 'Descrizione obbligatorietà',
          description: 'CertificationTab Page mandatoryDescription tabLabel',
        }),
        description: intl.formatMessage({
          defaultMessage: 'Descrizione',
          description: 'CertificationTab Page description tabLabel',
        }),
        mandatory: intl.formatMessage({
          defaultMessage: 'Obbligatorietà',
          description: 'CertificationTab Page mandatory tabLabel',
        }),
        inEffect: intl.formatMessage({
          defaultMessage: 'In Vigore',
          description: 'CertificationTab Page inEffect tabLabel',
        }),
        productCategory: intl.formatMessage({
          defaultMessage: 'Categoria di Prodotto',
          description: 'CertificationTab Page productCategory tabLabel',
        }),
        usedDestinations: intl.formatMessage({
          defaultMessage: "Destinazioni d'uso",
          description: 'CertificationTab Page usedDestinations tabLabel',
        }),
        references: intl.formatMessage({
          defaultMessage: 'Riferimenti',
          description: 'CertificationTab Page references tabLabel',
        }),
        macroCategories: intl.formatMessage({
          defaultMessage: 'Macrocategoria Certificazioni',
          description: 'CertificationTab Page macroCategories tabLabel',
        }),
        mandatoryLabel: intl.formatMessage({
          defaultMessage: 'Etichettatura Obbligatoria',
          description: 'CertificationTab Page mandatoryLabel tabLabel',
        }),
        textLink: intl.formatMessage({
          defaultMessage: 'Link al testo',
          description: 'CertificationTab Page textLink tabLabel',
        }),
        contacts: intl.formatMessage({
          defaultMessage: 'Contatti',
          description: 'CertificationTab Page contacts tabLabel',
        }),
        usefulLinks: intl.formatMessage({
          defaultMessage: 'Link Utili',
          description: 'CertificationTab Page usefulLink tabLabel',
        }),
        notesAndAdvice: intl.formatMessage({
          defaultMessage: 'Note e consigli',
          description: 'CertificationTab Page notesAndAdvice tabLabel',
        }),
        faq: intl.formatMessage({
          defaultMessage: 'FAQ',
          description: 'CertificationTab Page faq tabLabel',
        }),
        insights: intl.formatMessage({
          defaultMessage: 'Approfondimenti',
          description: 'CertificationTab Page insights tabLabel',
        }),
        customsInformation: intl.formatMessage({
          defaultMessage: 'Informazioni Doganali',
          description: 'CertificationTab Page customsInfo tabLabel',
        }),
        plugType: intl.formatMessage({
          defaultMessage: 'Tipologia di sistema spine/prese utilizzate',
          description: 'CertificationTab Page plugType tabLabel',
        }),
        eletricFeature: intl.formatMessage({
          defaultMessage: 'Caratteristiche impianti elettrici',
          description: 'CertificationTab Page eletricFeature tabLabel',
        }),
        import: intl.formatMessage({
          defaultMessage: 'Importatore',
          description: 'CertificationTab Page import tabLabel',
        }),
        certifications: intl.formatMessage({
          defaultMessage: 'Certificazioni',
          description: 'CertificationTab Page certifications tabLabel',
        }),
        security: intl.formatMessage({
          defaultMessage: 'Sicurezza',
          description: 'CertificationTab Page security tabLabel',
        }),
        compatibility: intl.formatMessage({
          defaultMessage: 'Compatibilità Elettromagnetica',
          description: 'CertificationTab Page compatibility tabLabel',
        }),
        efficency: intl.formatMessage({
          defaultMessage: 'Efficienza energetica',
          description: 'CertificationTab Page efficency tabLabel',
        }),
        product: intl.formatMessage({
          defaultMessage: 'Marcatura prodotto',
          description: 'CertificationTab Page product tabLabel',
        }),
        oblige: intl.formatMessage({
          defaultMessage: 'Ulteriori obblighi',
          description: 'CertificationTab Page oblige tabLabel',
        }),
        maintenance: intl.formatMessage({
          defaultMessage: 'Mantenimento della certificazione',
          description: 'CertificationTab Page maintenance tabLabel',
        }),
        product_import: intl.formatMessage({
          defaultMessage: 'Importazione dei prodotti',
          description: 'CertificationTab Page maintenance tabLabel',
        }),
      },
    },
    customHouseOffice: {
      subtitle: intl.formatMessage({
        defaultMessage:
          'Consulta la documentazione pratica per esportare nei mercati internazionali, impara ad utilizzare le banche dati con i tutorial, segui i webinar di approfondimento e richiedi una consulenza personalizzata.',
        description: 'CustomHouseOffice Page subtitle',
      }),
      tabs: {
        all: intl.formatMessage({
          defaultMessage: 'Tutti',
          description: 'CustomHouseOffice Page all tab label',
        }),
        documents: intl.formatMessage({
          defaultMessage: 'Documenti',
          description: 'CustomHouseOffice Page documents tab label',
        }),
        webinar: intl.formatMessage({
          defaultMessage: 'Webinar',
          description: 'CustomHouseOffice Page webinar tab label',
        }),
        tutorials: intl.formatMessage({
          defaultMessage: 'Video tutorial',
          description: 'CustomHouseOffice Page tutorial tab label',
        }),
      },
      consultingServices: {
        title: intl.formatMessage({
          defaultMessage: 'Servizi di consulenza',
          description: 'CustomHouseOffice Page consultingService section title',
        }),
        card: {
          firstConsulting: {
            title: intl.formatMessage({
              defaultMessage: 'Richiedi una prima consulenza',
              description: 'CustomHouseOffice Page firstConsulting title card',
            }),
            subtitle: intl.formatMessage({
              defaultMessage:
                'I nostri consulenti ti contatteranno e ti presenteranno la migliore soluzione pensata sulla base delle tue esigenze.',
              description:
                'CustomHouseOffice Page firstConsulting subtitle card',
            }),
          },
          customsCoupon: {
            title: intl.formatMessage({
              defaultMessage: 'Richiedi il “tagliando doganale”',
              description: 'CustomHouseOffice Page customsCoupon title card',
            }),
            subtitle: intl.formatMessage({
              defaultMessage:
                'Pensato per formare dipendenti e collaboratori delle aziende associate, mettiamo a disposizione un percorso di 10 ore tenuto da uno dei nostri consulenti specializzati.',
            }),
          },
          customsMaster: {
            title: intl.formatMessage({
              defaultMessage: 'Iscriviti al master dogane 2023',
              description: 'CustomHouseOffice Page customsMaster title card',
            }),
            subtitle: intl.formatMessage({
              defaultMessage:
                'A disposizione degli associati il percorso formativo su dogane e contrattualistica tenuto da esperti del settore.',
              description: 'CustomHouseOffice Page customsMaster subtitle card',
            }),
          },
        },
      },
    },
    customHouseOfficeServices: {
      title: intl.formatMessage({
        defaultMessage: 'Tagliando doganale',
        description: 'CustomHouseOffice Services Page title',
      }),
      subtitle: intl.formatMessage({
        defaultMessage:
          'Il servizio del “Tagliando doganale” è a pagamento . Puoi fare espressa richiesta compilando il form a seguire.  ',
        description: 'CustomHouseOffice Services Page subtitle',
      }),
      customCouponTab: {
        title: intl.formatMessage({
          defaultMessage: 'Form richiesta tagliando doganale',
          description: 'CustomHouseOffice Services Page customCouponTab title',
        }),
        subtitle: intl.formatMessage({
          defaultMessage:
            "Il servizio Tagliando Doganale è pensato per formare dipendenti e collaboratori delle aziende associate in modo tale da fornire loro una maggiore conoscenza sull'argomento e poter affrontare sempre al meglio le tematiche doganali. Si tratta di un accompagnamento di 10 ore tenuto da uno dei nostri consulenti specializzati, che approfondirà temi specifici con l'obiettivo di fornire maggiore consapevolezza e conoscenza alle risorse umane che ogni giorno in azienda si trovano a gestire le problematiche connesse al tema.",
          description:
            'CustomHouseOffice Services Page customCouponTab subtitle ',
        }),
      },
    },
  },
});
