import { IntlShape } from 'react-intl';

export const notificationsTranslation = (intl: IntlShape) => ({
  notifications: {
    title: intl.formatMessage({
      defaultMessage: 'Notifiche',
      description: 'Notifications Page title',
    }),
  },
});
