import imagePlaceHolder from 'assets/images/login/frontImage.png';
import {
  ChipData,
  FiltersMapping,
  IActiveFilters,
  IDrawerFilters,
  IFormFiltersProps,
} from 'pages/Sustainability/MaterialLibrary/index.types';
import {
  IMaterial,
  IMaterialDetailProps,
  IMaterialGeneralFilter,
  MaterialSustainabilitiesEnum,
} from 'types/material';

export const materialIncludes = [
  'field_company',
  'field_images.field_media_image',
  'field_categories.parent',
  'field_sustainabilities',
  'field_admission_criteria',
  'field_material_types',
  'field_raw_materials_origin',
  'field_production_site',
];

export const MaterialSustainabilityLabelsColor = {
  field_sustainability_sticker: '#72D58F',
  field_circularity_sticker: '#FFC533',
  field_cam_sticker: '#68ABDE',
  field_toxity_sticker: '#AE8BF9',
  field_carbon_footprint_sticker: '#F36878',
};

export const materialCardSpecificResValues = [
  'id',
  'title',
  'field_images',
  'field_company',
  'field_categories',
  'field_cam_sticker',
  'field_carbon_footprint_sticker',
  'field_circularity_sticker',
  'field_toxity_sticker',
  'field_sustainability_sticker',
  'field_sustainabilities',
  'field_admission_criteria',
  'field_material_types',
  'field_circularity_sticker_summary',
  'field_cam_sticker_summary',
  'field_sustainability_sticker_summary',
];

export enum DrupalMaterialsFiltersEnum {
  MATERIAL_CATEGORIES = 'material_categories',
  MATERIAL_TYPES = 'material_types',
  MATERIAL_CRITERIA = 'material_admission_criteria',
  MATERIAL_SUSTAINABILITIES = 'material_sustainabilities',
}

export const MaterialDrupalReactFilterMapping: FiltersMapping = {
  field_categories: 'categories',
  field_subcategories: 'subcategories',
  field_material_types: 'materials',
  field_admission_criteria: 'criteria',
  field_sustainabilities: 'sustainabilities',
};

export const materialFiltersSpecificResValue = [
  'id',
  'name',
  'parent',
  'weight',
];

export const parseMaterialCard = ({
  fetchedMaterial,
}: {
  fetchedMaterial: IMaterial;
}) => {
  const colorsArray = Object.entries(MaterialSustainabilityLabelsColor)
    .filter(([k]) => !!fetchedMaterial[k as keyof IMaterial])
    .map(([, v]) => ({ color: v }));
  const effectiveCategories: {
    label: string;
    value: string;
  }[] = [];
  const effectiveSubCategories: {
    label: string;
    value: string;
  }[] = [];
  fetchedMaterial.field_categories?.map(c => {
    effectiveSubCategories.push({
      label: c.name,
      value: c.id,
    });
    if (
      c?.parent?.[0]?.name &&
      !effectiveCategories.find(
        alreadySavedCat => alreadySavedCat.value === c?.parent?.[0].id
      )
    )
      effectiveCategories.push({
        label: c?.parent?.[0].name,
        value: c?.parent?.[0].id,
      });
  });

  return {
    id: fetchedMaterial.id,
    title: fetchedMaterial.title,
    image: fetchedMaterial.field_images?.length
      ? fetchedMaterial.field_images[0].field_media_image.image_style_uri.wide
      : imagePlaceHolder,
    ...(fetchedMaterial.field_company?.title && {
      producer: {
        label: fetchedMaterial.field_company.title,
        value: fetchedMaterial.field_company.id,
      },
    }),
    ...(fetchedMaterial.field_categories?.length && {
      categories: effectiveCategories,
    }),
    ...(fetchedMaterial.field_categories?.length && {
      subcategories: effectiveSubCategories,
    }),
    sustainabilityCriteria: colorsArray,
    summaries: {
      ...(fetchedMaterial.field_circularity_sticker_summary.length && {
        circ: fetchedMaterial.field_circularity_sticker_summary,
      }),
      ...(fetchedMaterial.field_cam_sticker_summary.length && {
        cam: fetchedMaterial.field_cam_sticker_summary,
      }),
      ...(fetchedMaterial.field_sustainability_sticker_summary.length && {
        proc: fetchedMaterial.field_sustainability_sticker_summary,
      }),
    },
  };
};

export const parseMaterialDetail = ({
  fetchedMaterial,
}: {
  fetchedMaterial: IMaterial;
}): IMaterialDetailProps => {
  const hasSustFeatures =
    fetchedMaterial.field_cam_features?.length ||
    fetchedMaterial.field_carbon_footprint_features?.length ||
    fetchedMaterial.field_material_circularity_features?.length ||
    fetchedMaterial.field_toxity_reduction_features?.length ||
    fetchedMaterial.field_sustainability_process_features?.length;

  const output: IMaterialDetailProps = {
    id: fetchedMaterial.id,
    name: fetchedMaterial.title,
    ...(fetchedMaterial.field_company.title && {
      companyName: fetchedMaterial.field_company.title,
    }),
    ...(fetchedMaterial.field_images?.[0]?.field_media_image?.image_style_uri
      ?.wide && {
      images: fetchedMaterial.field_images
        .map(img => img.field_media_image?.image_style_uri?.wide || '')
        .filter(i => !!i),
    }),
    ...(fetchedMaterial.field_description?.value && {
      description: fetchedMaterial.field_description?.value,
    }),
    ...(fetchedMaterial.field_processing?.value && {
      processing: fetchedMaterial.field_processing?.value,
    }),
    ...(fetchedMaterial.field_composition?.value && {
      composition: fetchedMaterial.field_composition?.value,
    }),
    ...(fetchedMaterial.field_other_data?.value && {
      other: fetchedMaterial.field_other_data.value,
    }),
    ...(fetchedMaterial.field_raw_materials_origin?.length && {
      rawMaterialsOrigin: fetchedMaterial.field_raw_materials_origin
        .map(r => r.name)
        .join(', '),
    }),
    ...(fetchedMaterial.field_production_site?.length && {
      productionSite: fetchedMaterial.field_production_site
        .map(r => r.name)
        .join(', '),
    }),
    ...(fetchedMaterial.field_admission_criteria?.length && {
      admissionCriteria: fetchedMaterial.field_admission_criteria
        .map(r => r.name)
        .join(', '),
    }),
    ...(hasSustFeatures && {
      sustainabilitityFeatures: Object.values(MaterialSustainabilitiesEnum)
        .filter(v => !!fetchedMaterial[v]?.length)
        .map(v => ({ key: v, features: fetchedMaterial[v] })),
    }),
    ...(fetchedMaterial.field_website?.uri && {
      website: fetchedMaterial.field_website?.uri,
    }),
    ...(fetchedMaterial.field_datasheet_link?.uri && {
      datasheet: fetchedMaterial.field_datasheet_link?.uri,
    }),
    ...(fetchedMaterial.field_catalogue_link?.uri && {
      catalogue: fetchedMaterial.field_catalogue_link?.uri,
    }),
    field_renewable_sources: fetchedMaterial.field_renewable_sources,
  };
  return output;
};

export const parseMaterialsCategories = ({
  fetchedCategories,
}: {
  fetchedCategories: IMaterialGeneralFilter[];
}) => {
  const categoriesAndSubCategories: {
    categoryName: string;
    categoryId: string;
    weight: number;
    subcategories: { subcategoryId: string; subcategoryName: string }[];
  }[] = [];
  fetchedCategories.map(entity => {
    if (entity.parent[0].id === 'virtual') {
      // It is a parent
      const alreadyFound = categoriesAndSubCategories.find(
        c => c.categoryId === entity.id
      );
      if (!alreadyFound) {
        //  It is a parent Category, it does not have other parents and it does not exists
        categoriesAndSubCategories.push({
          categoryId: entity.id,
          categoryName: entity.name,
          weight: entity.weight,
          subcategories: [],
        });
      }
    } else {
      const parent = entity.parent[0];
      // It is a child, appending to his father category
      const alreadyFoundParentIdx = categoriesAndSubCategories.findIndex(
        c => c.categoryId === parent.id
      );
      if (alreadyFoundParentIdx >= 0)
        categoriesAndSubCategories[alreadyFoundParentIdx].subcategories.push({
          subcategoryId: entity.id,
          subcategoryName: entity.name,
        });
      else
        categoriesAndSubCategories.push({
          categoryId: parent.id,
          categoryName: parent.name,
          weight: parent.weight,
          subcategories: [
            { subcategoryName: entity.name, subcategoryId: entity.id },
          ],
        });
    }
  });
  return categoriesAndSubCategories;
};

export const parseMaterialsChips = (
  data: IFormFiltersProps,
  fetchedFilters?: IDrawerFilters
) => {
  const parsedChipsData = Object.entries(data)
    .filter(
      ([k]) =>
        k !== 'sortingFieldName' && k !== 'sortingOrder' && k !== 'search'
    )
    .map(([k, v]) => {
      if (k === 'field_categories') {
        const categories = v.map((id: string) => {
          const foundCategory = fetchedFilters?.categories?.find(
            c => c.categoryId === id
          );
          return foundCategory
            ? {
                label: foundCategory.categoryName,
                value: foundCategory.categoryId,
                key: 'field_categories',
              }
            : { label: '', value: '', key: '' };
        });
        return categories;
      } else if (k === 'field_subcategories') {
        const subcategories = v.map((id: string) => {
          const foundSubCategory = fetchedFilters?.categories
            ?.find(cat => cat.subcategories.find(c => c.subcategoryId === id))
            ?.subcategories.find(sub => sub.subcategoryId === id);
          return foundSubCategory
            ? {
                label: foundSubCategory.subcategoryName,
                value: foundSubCategory.subcategoryId,
                key: 'field_subcategories',
              }
            : { label: '', value: '', key: '' };
        });
        return subcategories;
      } else {
        const otherFilter = fetchedFilters?.[
          MaterialDrupalReactFilterMapping[
            k as keyof FiltersMapping
          ] as keyof IDrawerFilters
        ] as unknown as ChipData[];
        const others = v.map((id: string) => {
          const foundOtherKey = otherFilter?.find(data => data.value === id);
          return foundOtherKey
            ? {
                label: foundOtherKey.label,
                value: foundOtherKey.value,
                key: k,
              }
            : { label: '', value: '', key: '' };
        });
        return others;
      }
    })
    .flat();
  return parsedChipsData;
};

export const unparseMaterialChip = (
  chip: ChipData,
  activeFilters: IActiveFilters
) => {
  const key = chip.key as keyof IActiveFilters;
  const selectedArrayFilter = activeFilters[key] as string[];
  return {
    ...activeFilters,
    [key]: selectedArrayFilter?.filter(id => id !== chip.value),
  };
};
