import { IntlShape } from 'react-intl';

export const HrQuestions = {
  ['question_54']:
    "L'organizzazione ha effettuato formazione al personale sull'<click id={10}>economia circolare</click> negli anni n e/on-1 e/o n-2?",
  ['question_55']:
    "<click id={501}>Indice di prestazione energetica</click> medio degli edifici per uso civile dell'organizzazione nell'anno n.",
  ['question_56']:
    "Gli edifici per uso civile dell'organizzazione hanno <click id={402}>certificazioni di sostenibilità</click> effettuate nell'anno n e/o n-1 e/o n-2?",
  ['question_57']:
    "L'organizzazione ha sviluppato e sta implementando una strategia di <click id={10}>economia circolare</click>? Soglie: - l'organizzazione non ha una strategia di economia circolare (0%) - l'organizzazione ha definito una strategia di economia circolare in concerto con gli stakeholder di riferimento (25%) - l'organizzazione ha definito una strategia di economia circolare e in concerto con gli stakeholder di riferimento individuato target di riferimento (50%); - l'organizzazione ha definito una strategia di economia circolare in concerto con gli <click id={502}>stakeholder</click> di riferimento, individuato target di riferimento e obiettivi specifici (75%) - l'organizzazione ha definito una strategia di economia circolare in concreto con gli stakeholder di riferimento, individuato target di riferimento, obiettivi specifici ed effettuato investimenti relativi per il raggiungimento degli obiettivi (100%).",
  ['question_58']:
    "L'organizzazione effettua la comunicazione esterna delle proprie prestazioni di <click id={503}>sostenibilità</click> e di <click id={504}>circolarità</click> (attraverso report di sostenibilità, dichiarazioni non finanziarie, ecc…)? Nota: nel caso in cui la comunicazione di sostenibilità e di circolarità fosse gestita da un gruppo più ampio o da un soggetto terzo, essa può essere considerata nella valutazione, a condizione che riporti esplicitamente le prestazioni al <click id={505}>perimetro di valutazione</click>.",
  ['question_59']:
    "L'organizzazione prevede premi e/o incentivi (interni) legati ad <click id={506}>obiettivi di economia circolare</click> nell'anno n?",
  ['question_60']:
    "L'organizzazione ha effettuato valutazioni documentate di <click id={507}>impatto</click> <click id={508}>sociale</click> (dell'organizzazione stessa) secondo standard riconosciuti a livello nazionale o internazionale negli anni n e/o n-1 e/o n-2?",
  ['question_61']:
    "L'organizzazione ha effettuato valutazioni documentate di <click id={507}>impatto ambientale</click> (dell'organizzazione stessa), tramite le UNI ISO 14040 e UNI EN ISO 14044 negli anni n e/o n-1 e/o n-2?",
  ['question_62']:
    "<click id={509}>Beni</click> e <click id={510}>infrastrutture</click> (ad esempio computer, veicoli, arredamento, edifici, terreni) acquistati dall'organizzazione sulla base del criterio del costo minore sul <click id={511}>ciclo di vita</click> rispetto al totale di <click id={509}>beni</click> e <click id={510}>infrastrutture</click> acquistati.",
  ['question_63']:
    "<click id={509}>Beni</click> e <click id={510}>infrastrutture</click> che prevedono <click id={512}>soluzioni circolari di gestione del fine vita</click> rispetto al totale di beni e infrastrutture dell'organizzazione.",
  ['question_64']:
    'Investimenti in attività di riconversione sostenibile di asset negli anni n e/o n-1 e/o n-2 rispetto agli investimenti in attività di <click id={513}>riconversione sostenibile di asset</click> negli n e/o n-1 e/o n-2 e agli investimenti in attività di costruzione e/o acquisizione di nuovi asset negli anni n e/o n-1 e/o n-2.',
  ['question_65']:
    "L'organizzazione ha effettuato piani di formazione e informazione interni del personale sull'<click id={10}>economia circolare</click>?",
  ['question_66']:
    "L'organizzazione ha effettuato piani di formazione e informazione esterni sull'<click id={10}>economia circolare</click> rivolti agli <click id={502}>stakeholder</click>? Nota: Nel caso in cui la formazione e/o informazione fosse gestita da un gruppo più ampio o da un soggetto terzo, essa può essere considerata nella valutazione, a condizione che riporti esplicitamente le azioni svolte relative al <click id={514}>perimetro di valutazione</click>.",
  ['question_67']:
    "Per i soggetti NON obbligati. L'organizzazione è dotata di un piano di efficientamento energetico?",
  ['question_68']:
    "Per i soggetti NON obbligati. L'organizzazione è dotata di un piano di mobilità sostenibile?",
  ['question_69']: "L'organizzazione adotta un sistema di Gestione Ambientale?",
};

export const hrModalsExplanations = (
  intl: IntlShape,
  htmlParserOptions?: object
) => ({
  501: {
    title: intl.formatMessage({
      defaultMessage: 'Indice di prestazione energetica',
      description: 'homepage modalsExplanations 501 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Quantità annua di energia primaria effettivamente
        consumata o che si prevede possa essere necessaria per soddisfare, con un uso standard
        dell'immobile, i vari bisogni energetici dell'edificio, la climatizzazione invernale ed estiva, la
        preparazione dell'acqua calda per usi igienici sanitari, la ventilazione e, per il settore terziario, l'illuminazione, gli impianti ascensori e scale mobili, riferita all’unità di superficie utile.`,
        description: 'homepage modalsExplanations 501 body',
      },
      { ...htmlParserOptions }
    ),
  },
  502: {
    title: intl.formatMessage({
      defaultMessage: 'Stakeholder',
      description: 'homepage modalsExplanations 502 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Individuo o gruppo che ha un interesse in qualsiasi decisione o attività di
        un'organizzazione.`,
        description: 'homepage modalsExplanations 502 body',
      },
      { ...htmlParserOptions }
    ),
  },
  503: {
    title: intl.formatMessage({
      defaultMessage: 'Sostenibilità',
      description: 'homepage modalsExplanations 503 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Stato del sistema globale, compresi gli aspetti ambientali, sociali ed
        economici, in cui i bisogni del presente sono soddisfatti senza compromettere la capacità
        delle generazioni future.<br></br>
        <b>Nota 1</b>: Gli aspetti ambientali, sociali ed economici interagiscono, sono interdipendenti e sono spesso indicati come le tre dimensioni della sostenibilità.<br></br>
        <b>Nota 2</b>: La sostenibilità è l'obiettivo dello sviluppo sostenibile.`,
        description: 'homepage modalsExplanations 503 body',
      },
      { ...htmlParserOptions }
    ),
  },
  504: {
    title: intl.formatMessage({
      defaultMessage: 'Circolarità',
      description: 'homepage modalsExplanations 504 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Allineamento ai principi dell’economia circolare.`,
        description: 'homepage modalsExplanations 504 body',
      },
      { ...htmlParserOptions }
    ),
  },
  505: {
    title: intl.formatMessage({
      defaultMessage: 'Perimetro di valutazione',
      description: 'homepage modalsExplanations 505 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Limiti, sia spaziali che temporali, che definiscono quali processi debbano essere inclusi nella misurazione della circolarità, anche al fine di includere o escludere fasi non direttamente dipendenti dall’organizzazione o fuori dagli obiettivi della misurazione. Il perimetro di valutazione deve risultare coerente con l’obiettivo della misurazione.`,
        description: 'homepage modalsExplanations 505 body',
      },
      { ...htmlParserOptions }
    ),
  },
  506: {
    title: intl.formatMessage({
      defaultMessage: 'Obiettivi di economia circolare',
      description: 'homepage modalsExplanations 506 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Risultati di economia circolare da raggiungere.`,
        description: 'homepage modalsExplanations 506 body',
      },
      { ...htmlParserOptions }
    ),
  },
  507: {
    title: intl.formatMessage({
      defaultMessage: "Impatto di un'organizzazione",
      description: 'homepage modalsExplanations 507 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Cambiamento positivo o negativo della società,
        dell'economia o dell'ambiente, in tutto o in parte derivante dalle decisioni e attività passate e presenti di un'organizzazione.`,
        description: 'homepage modalsExplanations 507 body',
      },
      { ...htmlParserOptions }
    ),
  },
  508: {
    title: intl.formatMessage({
      defaultMessage: 'Responsabilità sociale',
      description: 'homepage modalsExplanations 508 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Responsabilità da parte di un’organizzazione per gli impatti delle
        sue decisioni e delle sue attività sulla società e sull’ambiente, attraverso un
        comportamento etico e trasparente che:<ul>
        <li>contribuisce allo sviluppo sostenibile, inclusi la salute e il benessere della società;</li>
        <li>tiene conto delle aspettative degli stakeholder;</li>
        <li>è in conformità con la legge applicabile e coerente con le norme internazionali di
        comportamento;</li>
        <li>è integrata in tutta l’organizzazione e messa in pratica nelle sue relazioni.</li></ul><br></br>
        <b>Nota 1</b>: Il termine “attività” include prodotti, servizi e processi.<br></br>
        <b>Nota 2</b>: Le relazioni si riferiscono alle attività di un’organizzazione all’interno della propria sfera di influenza.`,
        description: 'homepage modalsExplanations 508 body',
      },
      { ...htmlParserOptions }
    ),
  },
  509: {
    title: intl.formatMessage({
      defaultMessage: 'Bene',
      description: 'homepage modalsExplanations 509 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Qualsiasi entità materiale (fisica) o immateriale mediante la quale gli individui soddisfano bisogni.<br></br><b>Nota</b>: Il bene si distingue dal servizio in quanto il primo è un prodotto fisico, mentre il secondo è l’erogazione di una prestazione.`,
        description: 'homepage modalsExplanations 509 body',
      },
      { ...htmlParserOptions }
    ),
  },
  510: {
    title: intl.formatMessage({
      defaultMessage: 'Infrastrutture',
      description: 'homepage modalsExplanations 510 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Sistema di strutture, attrezzature e servizi, necessarie al funzionamento di
        un’organizzazione.`,
        description: 'homepage modalsExplanations 510 body',
      },
      { ...htmlParserOptions }
    ),
  },
  511: {
    title: intl.formatMessage({
      defaultMessage:
        'Life Cycle Assessment (LCA, Valutazione del ciclo di vita)',
      description: 'homepage modalsExplanations 511 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Compilazione e valutazione
        attraverso tutto il ciclo di vita degli elementi in ingresso, in uscita e dei loro potenziali
        impatti ambientali di un sistema di prodotto.`,
        description: 'homepage modalsExplanations 511 body',
      },
      { ...htmlParserOptions }
    ),
  },
  512: {
    title: intl.formatMessage({
      defaultMessage: 'Soluzioni circolari di gestione del fine vita',
      description: 'homepage modalsExplanations 512 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Soluzioni di gestione del fine vita dei prodotti,
        che consentono di abbandonare il concetto di rifiuto o residuo prediligendo strategie
        sostenibili finalizzate alla valorizzazione dei materiali e al mantenimento delle loro
        caratteristiche tecniche, in ottica di minimizzazione dell’utilizzo di materie prime e di
        prevenzione/riduzione dei rifiuti.<br></br>
        <b>Nota</b>: Si tratta di strategie di estensione della vita utile, riuso, riciclo e recupero, anche attraverso la creazione di nuovi modelli di business (es. simbiosi industriale), in sostituzione del conferimento in discarica e del trattamento termico.`,
        description: 'homepage modalsExplanations 512 body',
      },
      { ...htmlParserOptions }
    ),
  },
  513: {
    title: intl.formatMessage({
      defaultMessage: 'Riconversione sostenibile degli asset',
      description: 'homepage modalsExplanations 513 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `<b>Riconversione degli asset</b>, ai sensi di standard di sostenibilità o schemi di certificazione di sostenibilità esistenti, laddove applicabili.<br></br><br></br>
        <b>Riconversione degli asset</b>: Interventi che realizzano un miglioramento dell’asset sotto il profilo dell’efficienza, funzionalità, consumo e/o stato di manutenzione.`,
        description: 'homepage modalsExplanations 513 body',
      },
      { ...htmlParserOptions }
    ),
  },
  514: {
    title: intl.formatMessage({
      defaultMessage: 'Perimetro di valutazione',
      description: 'homepage modalsExplanations 514 title',
    }),
    body: intl.formatMessage(
      {
        defaultMessage: `Limiti, sia spaziali che temporali, che definiscono quali processi
        debbano essere inclusi nella misurazione della circolarità, anche al fine di includere o
        escludere fasi non direttamente dipendenti dall’organizzazione o fuori dagli obiettivi della
        misurazione. Il perimetro di valutazione deve risultare coerente con l’obiettivo della
        misurazione.`,
        description: 'homepage modalsExplanations 514 body',
      },
      { ...htmlParserOptions }
    ),
  },
});

export const hrModalsNotes = (intl: IntlShape, htmlParserOptions?: object) => ({
  question_54: intl.formatMessage(
    {
      defaultMessage: `La vostra organizzazione ha effettuato formazione al personale sull'economia circolare negli ultimi 3 anni?
      Alcuni esempi di temi di economia circolare sono:<ul>
      <li>innovazione nei materiali e processi di trasformazione;</li>
      <li>i modelli di gestione dei materiali e prodotti a fine vita d’uso;</li>
      <li>il ruolo del packaging nell’economia circolare;</li>
      <li>i modelli di valutazione degli impatti socio-ambientali;</li>
      <li>l'ecoprogettazione;</li>
      <li>la simbiosi industriale;</li>
      <li>mobilità sostenibile.</li></ul>`,
      description: 'homepage notes question_54 title',
    },
    { ...htmlParserOptions }
  ),
  question_55: intl.formatMessage(
    {
      defaultMessage: `Qual è l'indice di prestazione energetica medio degli edifici utilizzati della vostra organizzazione nell'anno in corso?<br></br><br></br>
      <b>Soglie: - Classe A (100%); - Classi B-C (50%); - Classi D-F (25%); - Classe G (0%).</b><br></br><br></br>
      <b>Nota</b>: L’indice di prestazione è la media pesata delle soglie degli indici di prestazione rispetto alle superfici.`,
      description: 'homepage notes question_55 title',
    },
    { ...htmlParserOptions }
  ),
  question_56: intl.formatMessage(
    {
      defaultMessage: `Gli edifici per uso civile dell'organizzazione hanno certificazioni di sostenibilità (ad es. certificazioni LEED, BREEM; WELL, ITACA, CASACLIMA, ecc) effettuate nell'anno n e/o n-1 e/o n-2?`,
      description: 'homepage notes question_56 title',
    },
    { ...htmlParserOptions }
  ),
  question_57: intl.formatMessage(
    {
      defaultMessage: `Quale livello di strategia aziendale rispetto il tema dell’economia circolare? Valutare l'attuale situazione secondo queste opzioni:<ul>
      <li>Non ha ancora una strategia per l’economia circolare (0%)</li>
      <li>Ha stabilito una strategia condivisa con i suoi interlocutori principali, come i clienti, i fornitori, le istituzioni (25%)</li><li>Ha stabilito una strategia condivisa e ha fissato dei target da raggiungere, come ad esempio la quota di riciclo o di riduzione dei consumi (50%)</li>
      <li>Ha stabilito una strategia condivisa, dei target e degli obiettivi specifici, come ad esempio la progettazione di prodotti più sostenibili o la creazione di nuove catene di valore (75%)</li>
      <li>Ha stabilito una strategia condivisa, dei target, degli obiettivi specifici e ha investito per realizzarli, come ad esempio acquistando nuove tecnologie o formando il personale (100%)</li></ul>`,
      description: 'homepage notes question_57 title',
    },
    { ...htmlParserOptions }
  ),
  question_58: intl.formatMessage(
    {
      defaultMessage: `Questa domanda chiede se l’organizzazione comunica ai propri stakeholders le proprie performance in termini di sostenibilità e di circolarità, cioè quanto riduce gli impatti ambientali e sociali e quanto riutilizza le risorse. Questa comunicazione può essere fatta attraverso documenti come report di sostenibilità o dichiarazioni non finanziarie, che mostrano i dati e le azioni dell’organizzazione in questi ambiti. Se l’organizzazione fa parte di un gruppo più grande o si affida a un soggetto terzo per fare questa comunicazione, la domanda considera valida la comunicazione solo se specifica le prestazioni dell’organizzazione stessa e non quelle del gruppo o del soggetto terzo. La risposta alla domanda può essere sì oppure no, a seconda che l’organizzazione effettui o meno queste comunicazioni.`,
      description: 'homepage notes question_58 title',
    },
    { ...htmlParserOptions }
  ),
  question_59: intl.formatMessage(
    {
      defaultMessage: `L'organizzazione prevede premi e/o incentivi (interni) legati ad obiettivi di economia circolare nell'anno n ? 
      Esempio possono essere:<ul>
      <li>incentivi legato all'utilizzo di mobilità sostenibile (mezzi pubblici, car sharing, bicicletta);</li>
      <li>Incentivi legati alla riduzione di rifiuti (es. utilizzo di borraccie riutilizzabili).</li></ul>`,
      description: 'homepage notes question_59 title',
    },
    { ...htmlParserOptions }
  ),
  question_60: intl.formatMessage(
    {
      defaultMessage: `L'organizzazione ha effettuato valutazioni documentate di impatto sociale (dell'organizzazione stessa) secondo standard riconosciuti a livello nazionale o internazionale (Ad es. ISO 26000, global compact, ecc.) negli anni n e/o n-1 e/o n-2? La domanda considera valide le valutazioni fatte negli ultimi tre anni (n è l’anno corrente, n-1 è l’anno precedente e n-2 è due anni fa).`,
      description: 'homepage notes question_60 title',
    },
    { ...htmlParserOptions }
  ),
  question_61: intl.formatMessage(
    {
      defaultMessage: `Questa domanda vuole sapere se l’organizzazione ha effettuato delle analisi documentate per valutare gli impatti ambientali delle sue attività, seguendo le norme UNI ISO 14040 e UNI EN ISO 14044. Queste norme stabiliscono i principi e le modalità per realizzare l’analisi del ciclo di vita (LCA) di un prodotto o di un processo, cioè lo studio dei consumi di risorse e delle emissioni in tutte le fasi della sua vita. La domanda considera valide le valutazioni fatte negli ultimi tre anni (n è l’anno corrente, n-1 è l’anno precedente e n-2 è due anni fa).`,
      description: 'homepage notes question_61 title',
    },
    { ...htmlParserOptions }
  ),
  question_62: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra il valore (€) dei beni e infrastrutture (ad esempio computer, veicoli, arredamento, edifici, terreni) acquistati dall'organizzazione sulla base del criterio del costo minore sul ciclo di vita e il valore totale (€) di beni e infrastrutture acquistati.`,
      description: 'homepage notes question_62 title',
    },
    { ...htmlParserOptions }
  ),
  question_63: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra il valore (€) dei beni e infrastrutture (ad esempio computer, veicoli, arredamento, edifici, terreni) che prevedono soluzioni circolari di gestione del fine vita (ad es. riciclo e riuso del prodotto o parti di esso) e il valore totale (€) di beni e infrastrutture acquistati.`,
      description: 'homepage notes question_63 title',
    },
    { ...htmlParserOptions }
  ),
  question_64: intl.formatMessage(
    {
      defaultMessage: `Indicare la fascia percentuale in cui ricade il rapporto tra il valore (€) degli investimenti  in attività di riconversione sostenibile di asset negli anni n e/o n-1 e/o n-2 e il valore totale (€) dato dalla somma degli investimenti in attività di riconversione sostenibile di asset negli n e/o n-1 e/o n-2 e  in attività di costruzione e/o acquisizione di nuovi asset negli anni n e/o n-1 e/o n-2.<br></br>
      Se ad esempio un'azienda nell'anno 2021 ha investito nella riconversione sostenibile del proprio sito produttivo per un importo di 2.000.000 €, e ha poi acquistato sempre nel 2021 un nuovo stabile ad uso magazzino per un valore di 5.000.000 €, allora il rapporto percentuale sarà: (2.000.000/(2000.000 + 5.000.000))*100 = 28,6%.`,
      description: 'homepage notes question_64 title',
    },
    { ...htmlParserOptions }
  ),
  question_65: intl.formatMessage(
    {
      defaultMessage: `L'organizzazione ha effettuato piani di formazione e informazione interni del personale sull'economia circolare? Una formazione al personale sul tema di economia circolare per un’azienda del settore legno arredo può essere ad esempio: la progettazione ecocompatibile, la gestione dei rifiuti, l’uso di materiali riciclati e rinnovabili, la valutazione del ciclo di vita dei prodotti, le etichette ecologiche e la comunicazione ambientale.`,
      description: 'homepage notes question_65 title',
    },
    { ...htmlParserOptions }
  ),
  question_66: intl.formatMessage(
    {
      defaultMessage: `L'organizzazione ha effettuato piani di formazione e informazione esterni sull'economia circolare rivolti agli stakeholder?<br></br> <b>Nota</b>: Nel caso in cui la formazione e/o informazione fosse gestita da un gruppo più ampio o da un soggetto terzo, essa può essere considerata nella valutazione, a condizione che riporti esplicitamente le azioni svolte relative al perimetro di valutazione.`,
      description: 'homepage notes question_66 title',
    },
    { ...htmlParserOptions }
  ),
  question_67: intl.formatMessage(
    {
      defaultMessage: `L’organizzazione ha un documento che indica come usare meno energia? Un piano di efficientamento energetico è un documento che stabilisce come ridurre i consumi di energia e le emissioni di gas serra. Rispondi solo se la tua organizzazione non è obbligata ad avere un piano energetico per legge. Rispondi con sì o no.`,
      description: 'homepage notes question_67 title',
    },
    { ...htmlParserOptions }
  ),
  question_68: intl.formatMessage(
    {
      defaultMessage: `La vostra organizzazione ha adottato un piano strategico per rendere più sostenibile la mobilità? Ad esempio, incentivare il trasporto pubblico o il car pooling, ridurre le emissioni dei veicoli aziendali, ecc. Rispondi solo se la tua organizzazione non è obbligata ad avere un piano energetico per legge. Rispondi con sì o no.`,
      description: 'homepage notes question_68 title',
    },
    { ...htmlParserOptions }
  ),
  question_69: intl.formatMessage(
    {
      defaultMessage: `Un sistema di gestione ambientale è uno strumento che permette alle organizzazioni di controllare e migliorare le proprie prestazioni ambientali, riducendo gli impatti negativi sull’ambiente e rispettando la normativa vigente. Un sistema di gestione ambientale si basa sulla definizione di una politica ambientale, sulla pianificazione degli obiettivi e dei processi necessari per raggiungerli, sull’attuazione delle azioni previste, sul monitoraggio e sulla verifica dei risultati ottenuti e sul miglioramento continuo. La domanda mira a verificare se l'organizzazione ha implemnetato un sistema di Gestione Ambientale (ad es. ISO 14001, EMAS).`,
      description: 'homepage notes question_69 title',
    },
    { ...htmlParserOptions }
  ),
});
