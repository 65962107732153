import { BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IWasteIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function WasteIcon({ color = '#773DF5', style }: IWasteIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M14.7173 6.66244L10.0007 1.9541L5.28398 6.66244C3.98398 7.96244 3.33398 9.69577 3.33398 11.3624C3.33398 13.0291 3.98398 14.7874 5.28398 16.0874C6.58398 17.3874 8.29232 18.0458 10.0007 18.0458C11.709 18.0458 13.4173 17.3874 14.7173 16.0874C16.0173 14.7874 16.6673 13.0291 16.6673 11.3624C16.6673 9.69577 16.0173 7.96244 14.7173 6.66244ZM5.00065 11.6624C5.00898 9.99577 5.51732 8.93744 6.46732 7.99577L10.0007 4.38743L13.534 8.03744C14.484 8.97077 14.9923 9.99577 15.0007 11.6624H5.00065Z"
        fill={color}
      />
    </svg>
  );
}

export default WasteIcon;
