import { Paper, styled } from '@mui/material';

export const StyledFooter = styled(Paper)(({ theme }) => ({
  padding: 46,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '16px',
  backgroundColor: '#C7E4E5',
  width: '100%',
  gap: '25px',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  [theme.breakpoints.down('sm')]: {
    padding: 24,
    alignItems: 'flex-start',
  },
}));
