import { Box, Grow } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { SnackbarProvider as NotiStackProvider, SnackbarKey } from 'notistack';

import { ReactNode, useRef } from 'react';
import { SnackbarStyles } from './SnackbarProvider.styles';
import { SnackbarIconProps } from './SnackbarProvider.types';
import { Iconify } from 'components';

import { IconButtonAnimate } from 'components/animate';
import { ColorSchema } from 'theme/palette';

const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const notistackRef = useRef<any>(null);

  const onClose = (key: SnackbarKey) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <>
      <SnackbarStyles />
      <NotiStackProvider
        ref={notistackRef}
        maxSnack={4}
        autoHideDuration={3000}
        TransitionComponent={Grow}
        variant="default"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        iconVariant={{
          info: <SnackbarIcon icon={'eva:info-fill'} color="info" />,
          success: (
            <SnackbarIcon
              icon={'eva:checkmark-circle-2-fill'}
              color="success"
            />
          ),
          warning: (
            <SnackbarIcon icon={'eva:alert-triangle-fill'} color="warning" />
          ),
          error: <SnackbarIcon icon={'eva:alert-circle-fill'} color="error" />,
        }}
        action={key => (
          <IconButtonAnimate
            size="small"
            onClick={onClose(key)}
            sx={{ color: theme.palette.grey[500] }}
          >
            <Iconify icon={'eva:close-fill'} />
          </IconButtonAnimate>
        )}
      >
        {children}
      </NotiStackProvider>
    </>
  );
};

const overridePaletteMapping: {
  [key in ColorSchema]: string;
} = {
  info: '#00B4D8',
  success: '#6AC954',
  warning: '#FFC107',
  error: '#FF4842',
  primary: '#1E284A',
  secondary: '#FFC047',
};

const SnackbarIcon = ({ icon, color }: SnackbarIconProps) => (
  <Box
    component="span"
    sx={{
      mr: 1.5,
      width: 40,
      height: 40,
      display: 'flex',
      borderRadius: 1.5,
      alignItems: 'center',
      justifyContent: 'center',
      color: overridePaletteMapping[color],
      bgcolor: theme => alpha(theme.palette[color].main, 0.16),
    }}
  >
    <Iconify icon={icon} width={24} height={24} />
  </Box>
);

export { SnackbarProvider };
