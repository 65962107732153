import { View, Text, Link, Image } from '@react-pdf/renderer';
import LogoPath from 'assets/images/LogoFla-Plus.png';

const PdfHeader = ({ hasLogo = false }: { hasLogo?: boolean }) => {
  return (
    <View
      fixed
      style={{
        marginBottom: '1cm',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        flexDirection: 'row',
      }}
    >
      <Text style={{ fontSize: '10px' }}>
        Documento esportato da{' '}
        <Link src={'https:www.fla-plus.it'} style={{ fontWeight: 'bold' }}>
          www.fla-plus.it
        </Link>
      </Text>
      {hasLogo ? <Image source={LogoPath} style={{ width: '75px' }} /> : null}
    </View>
  );
};

export { PdfHeader };
