import { Box, BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface IProfileIconProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function ProfileIcon({
  color = '#212B36',
  style,
  ...other
}: IProfileIconProps) {
  return (
    <Box {...other}>
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill={color}
        style={style}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.00065 1.91536C7.96732 1.91536 8.75065 2.6987 8.75065 3.66536C8.75065 4.63203 7.96732 5.41536 7.00065 5.41536C6.03398 5.41536 5.25065 4.63203 5.25065 3.66536C5.25065 2.6987 6.03398 1.91536 7.00065 1.91536ZM7.00065 9.41536C9.47565 9.41536 12.084 10.632 12.084 11.1654V12.082H1.91732V11.1654C1.91732 10.632 4.52565 9.41536 7.00065 9.41536ZM7.00065 0.332031C5.15898 0.332031 3.66732 1.8237 3.66732 3.66536C3.66732 5.50703 5.15898 6.9987 7.00065 6.9987C8.84232 6.9987 10.334 5.50703 10.334 3.66536C10.334 1.8237 8.84232 0.332031 7.00065 0.332031ZM7.00065 7.83203C4.77565 7.83203 0.333984 8.9487 0.333984 11.1654V13.6654H13.6673V11.1654C13.6673 8.9487 9.22565 7.83203 7.00065 7.83203Z" />
      </svg>
    </Box>
  );
}

export default ProfileIcon;
