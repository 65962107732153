import { BoxProps } from '@mui/material';
import { CSSProperties } from 'react';
// @mui

interface ICFPIConProps extends BoxProps {
  color?: string;
  style?: CSSProperties;
}

// ----------------------------------------------------------------------

function CFPICon({ color = '#919EAB', style }: ICFPIConProps) {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M18.4387 3.5L17.0202 2L15.6018 3.5L14.1834 2L12.7649 3.5L11.3465 2L9.92808 3.5L8.50965 2L7.09122 3.5L5.6728 2L4.25437 3.5L2.83594 2V22L4.25437 20.5L5.6728 22L7.09122 20.5L8.50965 22L9.92808 20.5L11.3465 22L12.7649 20.5L14.1834 22L15.6018 20.5L17.0202 22L18.4387 20.5L19.8571 22V2L18.4387 3.5ZM17.9658 19.09H4.72718V4.91H17.9658V19.09ZM5.6728 15H17.0202V17H5.6728V15ZM5.6728 11H17.0202V13H5.6728V11ZM5.6728 7H17.0202V9H5.6728V7Z"
        fill={color}
      />
    </svg>
  );
}

export default CFPICon;
