/* eslint-disable @typescript-eslint/no-unused-vars */
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { useCallback } from 'react';
import { useResponsive, useTranslations } from 'hooks';
import { useStepStore } from 'stores/activeStep';

// ----------------------------------------------------------------------

const LinearAlternativeLabel = ({
  activeStep = 0,
}: {
  activeStep?: number;
}) => {
  const i18n = useTranslations();
  const isMobile = useResponsive('down', 'md');
  const setStep = useStepStore(state => state.setStep);
  const handleSetStep = useCallback((newStep: number) => {
    setStep(newStep);
  }, []);

  const steps = [
    i18n.checkout.stepper.participantsDatas,
    i18n.checkout.stepper.invoiceDatas,
    i18n.checkout.stepper.summaryAndPayment,
    i18n.checkout.stepper.confirm,
  ];
  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((label, index) => {
        const stepProps: { completed?: boolean } = {};
        const labelProps: {
          optional?: React.ReactNode;
        } = {};
        return (
          <Step
            key={label}
            {...stepProps}
            onClick={() => {
              if (index < activeStep && activeStep < 3) handleSetStep(index);
            }}
            sx={{ cursor: 'pointer' }}
          >
            <StepLabel {...labelProps}>
              {!isMobile || activeStep === index ? label : null}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export { LinearAlternativeLabel };
