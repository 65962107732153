import { styled, Paper } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  width: '100%',
  padding: '20px 0px',
  display: 'flex',
  gap: '25px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const StyledPaper = styled(Paper)(
  ({
    width,
    height,
    center,
    flex,
    padding,
  }: {
    width?: string;
    flex?: number;
    height: string;
    center?: boolean;
    padding?: string;
  }) => ({
    borderRadius: '20px',
    ...(width && {
      width: width,
    }),
    ...(flex && {
      flex: flex,
    }),
    height: height,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: center ? 'center' : 'flex-start',
    gap: '30px',
    padding: padding ? padding : '0',
  })
);
