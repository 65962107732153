import { styled } from '@mui/material/styles';
import { Outlet, useNavigate } from 'react-router-dom';
import { LogoSmall } from 'assets';
import * as PATHS from 'constants/paths';
// @mui
// components

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  const navigate = useNavigate();
  return (
    <>
      <HeaderStyle>
        <div
          style={{
            width: 48,
            marginLeft: 0,
            position: 'relative',
            cursor: 'pointer',
            backgroundColor: '#0A2233',
            borderRadius: 50,
          }}
          onClick={() => navigate(PATHS.ROOT)}
        >
          <LogoSmall />
        </div>
      </HeaderStyle>
      <Outlet />
    </>
  );
}
